import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import Swal from 'sweetalert2';
import { setCropping, hideCropping, showFabric, cleanHistory, enableFabricEdition, setCurrentFabricImage, setImageUrl, setImageData, setIsSaved, clearRedoHistory, setBtnRedoDisable, pushUndoHistory, setBtnUndoDisable, setBtnResetDisable, setDataBeforeCropper, setImageBeforeCropper, showModalApplyClipping, hideModalApplyClipping } from '../../store/actions/imageEditor';
import { HistoryEditorState } from '../../store/datatypesInterfaces/dataTypeInterfaces';

const ImageEditorCropperTools = () => {
    const { t } = useTranslation();
    const { croppingEnable, isSaved, cropper, imageData, btnUndoDisable, undo, imageURL } = useSelector((state: ApplicationState) => state.imageEditor);
    const dispatch = useDispatch();

    const handleAddFiltersAndElements = () => {
        if (!isSaved) {
            Swal.fire({
                title: t("Salvar imagem"),
                text: t("Para adicionar filtros e elementos você precisa salvar a imagem"),
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t('Sim'),
                cancelButtonText: t('Cancelar')
            }).then((result) => {
                if (result.value) {
                    openFiltersAndElements();
                }
            })
        } else {
            openFiltersAndElements();
        }
    }

    const openFiltersAndElements = () => {
        if (cropper && cropper.current && (cropper.current as any).cropper && (cropper.current as any).cropper.ready) {
            setCropping(true, dispatch);
            hideCropping(dispatch);
            showFabric(dispatch);
            cleanHistory(dispatch);

            openFabricJs(cropper.current.getCroppedCanvas({
                width: 160,
                height: 160,
            }).toDataURL())
        } else {
            Swal.fire({
                title: t("Adicionar filtros e elementos"),
                text: t("Aguarde enquanto o editor de imagens conclui o carregamento"),
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                confirmButtonText: t('Ok'),
            })
        }
    }

    const openFabricJs = (imageData: string) => {
        enableFabricEdition(true, dispatch);
        setCurrentFabricImage(null, dispatch);
        setImageUrl(imageData, dispatch);
    }

    const handleMoveUp = () => {
        setMove(0, -1)
    }

    const handleMoveDown = () => {
        setMove(0, 1);
    }

    const handleMoveLeft = () => {
        setMove(-1, 0);
    }

    const handleMoveRight = () => {
        setMove(1, 0);
    }

    const handleRotateRight = () => {
        setRotate(1);
    }

    const handleRotateLeft = () => {
        setRotate(-1);
    }

    const setRotate = (direction: number) => {
        if (cropper && cropper.current) {
            if (direction !== 0) {
                const rotate = 90 * direction;
                cropper.current.rotate(rotate);

                const imageDataAux = imageData;
                imageDataAux.rotate = 0;
                setImageData(imageDataAux, dispatch);

                pushHistory({ obj: { id: -1, action: "rotate", rotate }, component: "cropper" });
            }
        }
    }

    const pushHistory = (history: HistoryEditorState, isRedo?: boolean) => {
        setIsSaved(false, dispatch);
        if (!isRedo) {
            clearRedoHistory(dispatch);
            setBtnRedoDisable(true, dispatch);
        }
        pushUndoHistory(history, dispatch);

        if (btnUndoDisable && undo.length) {
            setBtnUndoDisable(false, dispatch);
            setBtnResetDisable(false, dispatch);
        }
    }

    const setMove = (x: number, y: number) => {
        if (cropper && cropper.current) {
            if (x === 0 && y === 0) {
                const imgW = cropper.current?.getImageData().width;
                const imgH = cropper.current?.getImageData().height;
                x = (cropper.current.getContainerData().width / 2) - (imgW / 2);
                x = (cropper.current.getContainerData().height / 2) - (imgH / 2);
                cropper.current.moveTo(x, y);
            } else {
                const fator = 10;
                x = fator * x;
                y = fator * y;
                cropper.current.move(x, y);
            }
            const imageDataAux: Cropper.ImageData = {
                ...imageData,
                top: imageData.top + y,
                left: imageData.left + x,
            }
            setImageData(imageDataAux, dispatch);
            // TODO

        }
    }

    const handleReflectH = () => {
        setReflect("h");
    }

    const handleReflectV = () => {
        setReflect("v");
    }

    const setReflect = (orientation: string) => {
        if (cropper && cropper.current) {
            let scaleX = imageData.scaleX || 1;
            let scaleY = imageData.scaleY || 1;
            let imageDataAux = imageData;
            if (orientation === "h") {
                cropper.current.scale(scaleX * (-1), scaleY || 1);
                imageDataAux.scaleX = scaleX * (-1);
                imageDataAux.scaleY = scaleY;
                pushHistory({ obj: { id: -1, action: "reflect", orientation, x: scaleX * (-1), y: scaleY }, component: "cropper" })
            } else if (orientation === "v") {
                cropper.current.scale(scaleX, scaleY * (-1));
                imageDataAux.scaleX = scaleX;
                imageDataAux.scaleY = scaleY * (-1);
                pushHistory({ obj: { id: -1, action: "reflect", orientation, x: scaleX, y: scaleY * (-1) }, component: "cropper" })
            }
            setImageData(imageDataAux, dispatch);
        }
    }

    const handleCrop = () => {
        initCrop()
    }

    const initCrop = () => {
        if (cropper && cropper.current) {
            setCropping(true, dispatch)
            setDataBeforeCropper(imageData, dispatch);
            setImageBeforeCropper(imageURL, dispatch);
            cropper.current.setDragMode("crop");
            cropper.current.crop();
            showHideModalApply(true);
        }
    }

    const showHideModalApply = (show?: boolean) => {
        if (show) {
            showModalApplyClipping(dispatch);
        } else {
            hideModalApplyClipping(dispatch);
        }
    }

    return (
        <div className={croppingEnable ? "" : "d-none"} id="cropped">
            <button className="btn btn-lg m-4 btn-outline-info" title={t("Adicionar filtros e elementos")} id="btn_editImage"  onClick={handleAddFiltersAndElements}>
                <i className="mr-2 fa fa-plus"></i>
                {t("Adicionar filtros e elementos")}
            </button>
            <div className="col-12 p-1 my-1 text-uppercase">
                <span>
                    <i className="mr-2 fa fa-eye"></i>
                    {t("Visualizar")}
                </span>
                <div className="d-flex mt-2">
                    <button className="btn mr-2 btn-outline-primary  mb-2" id="btn-move-up"  onClick={handleMoveUp}>
                        <i className="fa fa-arrow-up"></i>
                    </button>
                    <button className="btn mr-2 btn-outline-primary  mb-2" id="btn-move-down"  onClick={handleMoveDown}>
                        <i className="fa fa-arrow-down"></i>
                    </button>
                    <button className="btn mr-2 btn-outline-primary  mb-2" id="btn-move-left"  onClick={handleMoveLeft}>
                        <i className="fa fa-arrow-left"></i>
                    </button>
                    <button className="btn mr-2 btn-outline-primary  mb-2" id="btn-move-right"  onClick={handleMoveRight}>
                        <i className="fa fa-arrow-right"></i>
                    </button>
                </div>
            </div>
            <div className="col-12 p-1 my-1 text-uppercase">
                <span>
                    <i className="mr-2 fa fa-wrench"></i>
                    {t("Ferramentas")}
                </span>
            </div>
            <div className="col-12 p-1 my-1 d-flex">
                <button className="btn mr-2 btn-outline-primary  mb-2" id="btn-rotateRight"  onClick={handleRotateRight}>
                    <i className="fa fa-redo"></i>
                </button>
                <button className="btn mr-2 btn-outline-primary  mb-2" id="btn-rotateLeft"  onClick={handleRotateLeft}>
                    <i className="fa fa-undo"></i>
                </button>
                <button className="btn mr-2 btn-outline-primary  mb-2" id="btn-reflectH"  onClick={handleReflectH}>
                    <i className="fas fa-arrows-alt-h"></i>
                </button>
                <button className="btn mr-2 btn-outline-primary  mb-2" id="btn-reflectV"  onClick={handleReflectV}>
                    <i className="fas fa-arrows-alt-v"></i>
                </button>
                <button className="btn mr-2 btn-outline-primary  mb-2" id="btn-crop"  onClick={handleCrop}>
                    <i className="fa fa-crop"></i>
                </button>
            </div>
        </div>
    )
}

const style = {
    button: {
        backgroundColor: 'rgb(221,221,221)'
    }
}

export default ImageEditorCropperTools;