import React, { useState, useEffect, ReactNode } from "react";

import { ViewSelect } from "./view-select-element";
import PageContainer from "./page-container-element";


import { useDispatch, useSelector } from "react-redux";
import { DateUtil } from "../utils/dateUtils";
import { useTranslation } from "react-i18next";
import { SearchUtil } from "../utils/searchUtils";

import { ApplicationState } from "../../store";
import {
    setSelectedExam,
    setSelectedPatient,
    setSelectedPhysician,
    setSelectedReferringPhysician,
} from "../../store/actions/viewSelect";
import { setStopListening } from "../../store/actions/stopListening";
import { joinNames } from "../../utils/string-utils";
import { ProfissionalSaudeInterface } from "../../interfaces/new/profissionalSaudeInterface";

interface PropsReportViewSelectElementsPageInterface {
    examViewModalId: string;
    patientViewModalId: string;
    physicianViewModalId: string;
    referringPhysicianViewModalId: string;
    profissionaisSaude: Array<ProfissionalSaudeInterface>
    referringPhysicianOnClickButtonPrevious?: Function;
    physicianViewOnClickButtonPrevious?: Function;
    patientViewOnClickButtonPrevious?: Function;
    examViewOnClickButtonPrevious?: Function;
    examShowButtonPrevious?: boolean;
    patientShowButtonPrevious?: boolean;
}


const ReportViewSelectElements = (props: PropsReportViewSelectElementsPageInterface) => {
    const {
        examViewModalId,
        patientViewModalId,
        physicianViewModalId,
        referringPhysicianViewModalId,
        profissionaisSaude,
        patientViewOnClickButtonPrevious,
        examViewOnClickButtonPrevious,
        examShowButtonPrevious,
        patientShowButtonPrevious
    } = props;

    const examsToList = useSelector((states: ApplicationState) => states.exam.examsToList);
    const patientsToList = useSelector((states: ApplicationState) => states.patient.patientsToList);
    const physiciansToList = useSelector((states: ApplicationState) => states.physician.physiciansToList);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dateUtil = new DateUtil();
    const searchUtil = new SearchUtil();

    const incrementOffSet = 10;


    const [offSetViewSelectExam, setOffSetViewSelectExam] = useState(0);
    const [offSetViewSelectPatient, setOffSetViewSelectPatient] = useState(0);
    const [offSetViewSelectPhysician, setOffSetViewSelectPhysician] = useState(0);
    const [offSetViewSelectReferringPhysician, setOffSetViewSelectReferringPhysician] = useState(0);

    const [enableForwardButtonExam, setEnableForwardButtonExam] = useState(false);
    const [enablePreviousButtonExam, setEnablePreviousButtonExam] = useState(false);
    const [enableForwardButtonPatient, setEnableForwardButtonPatient] = useState(false);
    const [enablePreviousButtonPatient, setEnablePreviousButtonPatient] = useState(false);
    const [enableForwardButtonPhysician, setEnableForwardButtonPhysician] = useState(false);
    const [enablePreviousButtonPhysician, setEnablePreviousButtonPhysician] = useState(false);
    const [enableForwardButtonReferringPhysician, setEnableForwardButtonReferringPhysician] = useState(false);
    const [enablePreviousButtonReferringPhysician, setEnablePreviousButtonReferringPhysician] = useState(false);
    const [profissionaisSaudeFiltro, setProfissionaisSaudeFiltro] = useState<ProfissionalSaudeInterface[]>([]);

    useEffect(() => {
        setProfissionaisSaudeFiltro(profissionaisSaude);
    }, [profissionaisSaude]);

    const ViewSelectPatientItemsTHead = [
        "#",
        t("Nome"),
        t("Documento"),
        t("Data de nascimento"),
        t("Gênero")
    ];

    const ViewSelectPhysicianItemsTHead = [
        "#",
        t("Nome"),
        t("Documento"),
        t("Ocupação"),
        t("Email")
    ];

    const ViewSelectReferringPhysicianItemsTHead = [
        "#",
        t("Nome"),
        t("Documento"),
        t("Ocupação"),
        t("Email")
    ];

    const ViewSelectExamItemsTHead = [
        "#",
        t("Nome"),
        t("Título"),
        t("Modelo"),
    ];

    const filtrarProfissionais = (text: string) => {
        if (profissionaisSaude.length > 0) {
            const profissionaisFiltrados = profissionaisSaude.filter((item) => {
                const nome = `${item.primeiroNome} ${item.ultimoNome}`.toUpperCase();
                return nome.indexOf(text.toUpperCase()) > -1;
            });
            setProfissionaisSaudeFiltro(profissionaisFiltrados);
        }

        if (profissionaisSaude.length > 0 && !text) {
            setProfissionaisSaudeFiltro(profissionaisSaude);
        }
    }

    return (
        <PageContainer>
            <ViewSelect
                idModal={`${patientViewModalId}Id`}
                idTitleLabelModal={`${patientViewModalId}LabelId`}
                titleModalElement={t("Selecione o paciente")}
                idButtonCloseModal={`${patientViewModalId}CloselId`}
                idButtonPreviousModal={`${patientViewModalId}PreviousId`}
                onClickButtonPrevious={patientViewOnClickButtonPrevious}
                showButtonPrevious={patientShowButtonPrevious}        

                itemsThead={ViewSelectPatientItemsTHead}

                onClickButtonSearch={() => { }}
                onChangeTextToSearch={() => { }}
                onChangeFilterToSearch={() => { }}
                enablePreviousButton={enablePreviousButtonPatient}
                enableForwardButton={enableForwardButtonPatient}
                onClickSearchPrevious={() => searchUtil.searchPrevious(offSetViewSelectPatient, incrementOffSet, setOffSetViewSelectPatient, setEnablePreviousButtonPatient)}
                onClickSearchForward={() => searchUtil.searchForward(offSetViewSelectPatient, incrementOffSet, patientsToList.length, setOffSetViewSelectPatient, setEnableForwardButtonPatient)}
            >
                {
                    !!patientsToList.length && patientsToList
                        .slice(offSetViewSelectPatient, offSetViewSelectPatient + incrementOffSet)
                        .map((patient: any, index: number) => (
                            <tr key={`${patient.id}_${index}`} id={patient.id} onDoubleClick={(event) => {
                                (document.querySelector(`#${patientViewModalId}CloselId`) as HTMLButtonElement).click();

                                setSelectedPatient({ ...patient, idModal: patientViewModalId }, dispatch);
                                setStopListening(true, dispatch);
                            }}>
                                <td>{patient.id}</td>
                                <td>{`${patient.firstName} ${patient.lastName}`}</td>
                                <td>{patient.documents.length ? `${patient.documents[0].type.initials} - ${patient.documents[0].number}` : ""}</td>
                                <td>{patient.birthDate ? `${new Date(patient.birthDate).toLocaleDateString("pt-BR")}` : ""}</td>
                                <td>{patient.gender ? `${patient.gender.name}` : ""}</td>
                            </tr>
                        ))
                }
            </ViewSelect>

            <ViewSelect
                idModal={`${physicianViewModalId}Id`}
                idTitleLabelModal={`${physicianViewModalId}LabelId`}
                titleModalElement={t("Selecione o médico")}
                idButtonCloseModal={`${physicianViewModalId}CloselId`}
                onClickButtonClose={() => {setStopListening(true, dispatch)}}

                itemsThead={ViewSelectPhysicianItemsTHead}

                onClickButtonSearch={() => { }}
                onChangeTextToSearch={filtrarProfissionais}
                onChangeFilterToSearch={() => { }}
                enablePreviousButton={enablePreviousButtonPhysician}
                enableForwardButton={enableForwardButtonPhysician}
                onClickSearchPrevious={() => searchUtil.searchPrevious(offSetViewSelectPhysician, incrementOffSet, setOffSetViewSelectPhysician, setEnablePreviousButtonPhysician)}
                onClickSearchForward={() => searchUtil.searchForward(offSetViewSelectPhysician, incrementOffSet, physiciansToList.length, setOffSetViewSelectPhysician, setEnableForwardButtonPhysician)}
            >
                {
                    !!profissionaisSaudeFiltro.length && profissionaisSaudeFiltro
                        .map((physician, index: number) => (
                            <tr key={`${physician.idProfissionalSaude}_${index}`} id={`physician_${physician.idProfissionalSaude}`} onDoubleClick={(event) => {
                                (document.querySelector(`#${physicianViewModalId}CloselId`) as HTMLButtonElement).click();


                                setSelectedPhysician({ ...physician, idModal: physicianViewModalId }, dispatch);
                                setStopListening(true, dispatch);
                            }}>
                                <td>{physician.idProfissionalSaude}</td>
                                <td>{`${physician.primeiroNome} ${physician.ultimoNome}`}</td>
                                <td>{physician.tipoDocumento} - {physician.documento}</td>
                                <td>{physician.ocupacao}</td>
                                <td>{physician.contato ? `${physician.contato.email1}` : ""}</td>
                            </tr>
                        ))
                }
            </ViewSelect>

            <ViewSelect
                idModal={`${referringPhysicianViewModalId}Id`}
                idTitleLabelModal={`${referringPhysicianViewModalId}LabelId`}
                titleModalElement={t("Selecione o médico solicitante")}
                idButtonCloseModal={`${referringPhysicianViewModalId}CloselId`}
                onClickButtonClose={() => {setStopListening(true, dispatch)}}

                itemsThead={ViewSelectReferringPhysicianItemsTHead}

                onClickButtonSearch={() => { }}
                onChangeTextToSearch={filtrarProfissionais}
                onChangeFilterToSearch={() => { }}
                enablePreviousButton={enablePreviousButtonReferringPhysician}
                enableForwardButton={enableForwardButtonReferringPhysician}
                onClickSearchPrevious={() => searchUtil.searchPrevious(offSetViewSelectReferringPhysician, incrementOffSet, setOffSetViewSelectReferringPhysician, setEnablePreviousButtonReferringPhysician)}
                onClickSearchForward={() => searchUtil.searchForward(offSetViewSelectReferringPhysician, incrementOffSet, profissionaisSaude.length, setOffSetViewSelectReferringPhysician, setEnableForwardButtonReferringPhysician)}
            >
                {
                    !!profissionaisSaudeFiltro.length && profissionaisSaudeFiltro
                        .map((referringPhysician, index: number) => (
                            <tr key={`${referringPhysician.idProfissionalSaude}_${index}`} id={`referringPhysician_${referringPhysician.idProfissionalSaude}`} onClick={(event) => {
                                (document.querySelector(`#${referringPhysicianViewModalId}CloselId`) as HTMLButtonElement).click();

                                setSelectedReferringPhysician({ ...referringPhysician, idModal: referringPhysicianViewModalId }, dispatch);
                                setStopListening(true, dispatch);
                            }}>
                                <td>{referringPhysician.idProfissionalSaude}</td>
                                <td>{joinNames(referringPhysician)}</td>
                                <td>{referringPhysician.tipoDocumento} - {referringPhysician.documento}</td>
                                <td>{!!referringPhysician.ocupacao}</td>
                                <td>{referringPhysician.contato? referringPhysician.contato.email1 : ""}</td>
                            </tr>
                        ))
                }
            </ViewSelect>

            <ViewSelect
                idModal={`${examViewModalId}Id`}
                idTitleLabelModal={`${examViewModalId}LabelId`}
                titleModalElement={t("Selecione o exame")}
                idButtonCloseModal={`${examViewModalId}CloselId`}
                idButtonPreviousModal={`${examViewModalId}PreviousId`}
                onClickButtonPrevious = {examViewOnClickButtonPrevious}
                showButtonPrevious = {examShowButtonPrevious}

                itemsThead={ViewSelectExamItemsTHead}

                onClickButtonSearch={() => { }}
                onChangeTextToSearch={() => { }}
                onChangeFilterToSearch={() => { }}
                enablePreviousButton={enablePreviousButtonExam}
                enableForwardButton={enableForwardButtonExam}
                onClickSearchPrevious={() => searchUtil.searchPrevious(offSetViewSelectExam, incrementOffSet, setOffSetViewSelectExam, setEnablePreviousButtonExam)}
                onClickSearchForward={() => searchUtil.searchForward(offSetViewSelectExam, incrementOffSet, examsToList.length, setOffSetViewSelectExam, setEnableForwardButtonExam)}
            >
                {!!examsToList.length && examsToList
                    .slice(offSetViewSelectExam, offSetViewSelectExam + incrementOffSet)
                    .map((exam: any, index: number) => (
                        <tr key={`${exam.id}_${index}`} id={exam.id} onDoubleClick={(event) => {
                            (document.querySelector(`#${examViewModalId}CloselId`) as HTMLButtonElement).click();

                            setSelectedExam({ ...exam, idModal: examViewModalId }, dispatch);
                            setStopListening(true, dispatch);
                        }}>
                            <td>{exam.id}</td>
                            <td>{exam.name}</td>
                            <td>{exam.title}</td>
                            <td>{exam.template && exam.template.name}</td>
                        </tr>
                    ))
                }
            </ViewSelect>
        </PageContainer>
    )


}


export { ReportViewSelectElements };