import React, { useEffect, useState } from 'react';


// Tools/Utils
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';


//Interfaces 
import { ApplicationState } from '../../store';


// States/Redux
import {
    pushUndoHistory,
    hideModalApplyEditText,
    setRichTextCurrentColor,
    showModalApplyEditText,
} from '../../store/actions/imageEditor';


const ImageEditorRichTextModal = () => {
    const {
        textEditing,
        fabricCanvas,
        modalApplyEditText,
        richTextCurrentColor,
    } = useSelector((state: ApplicationState) => state.imageEditor);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [richTextFontSize, setRichTextFontSize] = useState<number>(!!textEditing ? textEditing.fontSize || 30 : 30);
    const [richTextFontFamily, setRichTextFontFamily] = useState<string>(!!textEditing ? textEditing.fontFamily || "Times New Roman" : "Times New Roman");


    useEffect(() => {
        if (!!textEditing) {
            showModalApplyEditText(dispatch);
            setRichTextFontSize(textEditing.fontSize || 30);
            setRichTextFontFamily(textEditing.fontFamily || "Times New Roman");
            setRichTextCurrentColor(textEditing.fill?.toString() || "#000", dispatch);
        }

    }, [textEditing]);


    const handleRichTextColorFont = () => {
        const richTextInputcolorText = document.querySelector("#richTextColorFontId") as HTMLInputElement;

        richTextInputcolorText.value = textEditing.fill?.toString() || "#000";

        richTextInputcolorText.click();
    }


    const handleRichTextColorChange = (color: string) => {
        if (!!textEditing) {
            setRichTextCurrentColor(color, dispatch);

            textEditing.set({ fill: color });

            if (textEditing.group && textEditing.group.type === "scaleGroup") {
                const rule = textEditing.group && textEditing.group._objects[1];

                if (rule) {
                    rule.set({ fill: color });
                }
            }

            fabricCanvas.renderAll();
        }
    }


    const handleRichTextBold = () => {
        let fontWeight = textEditing.fontWeight === "normal" ? "bold" : "normal";

        textEditing.fontWeight = fontWeight;

        fabricCanvas.renderAll();

        pushUndoHistory({
            obj: {
                id: (textEditing as any).id,
                action: "addBold",
                target: textEditing,
                fontWeight: fontWeight
            },
            component: "fabric"
        }, dispatch);
    }


    const handleRichTextItalic = () => {
        textEditing.fontStyle = textEditing.fontStyle === "normal" ? "italic" : "normal";

        fabricCanvas.renderAll();

        pushUndoHistory({
            obj: {
                id: (textEditing as any).id,
                action: "addItalic",
                target: textEditing,
                fontStyle: textEditing.fontStyle
            },
            component: "fabric"
        }, dispatch);
    }


    const handleRichTextUnderline = () => {
        textEditing.underline = !textEditing.underline;
        textEditing.setSelectionStyles({ underline: !textEditing.underline });

        fabricCanvas.renderAll();

        pushUndoHistory({
            obj: {
                id: (textEditing as any).id,
                action: "addUnderline",
                target: textEditing,
                underline: textEditing.underline
            },
            component: "fabric"
        }, dispatch);
    }


    const handleRichTextStrike = () => {
        textEditing.linethrough = !textEditing.linethrough;
        textEditing.setSelectionStyles({ linethrough: !textEditing.linethrough })

        fabricCanvas.renderAll();

        pushUndoHistory({
            obj: {
                id: (textEditing as any).id,
                action: "addLinethrough",
                target: textEditing,
                linethrough: textEditing.linethrough,
            },
            component: "fabric"
        }, dispatch);
    }


    const changeTextBoxAlignment = (alignment: any) => {
        const textAlignBefore = textEditing.textAlign;
        textEditing.textAlign = alignment;

        fabricCanvas.renderAll();

        pushUndoHistory({
            obj: {
                id: (textEditing as any).id,
                action: "changeAlign",
                target: textEditing,
                textAlignBefore: textAlignBefore,
                textAlign: alignment,
            },
            component: "fabric"
        }, dispatch);
    }


    const handleRichTextOnChangeFontSize = (fontSize: string) => {
        const textFontSizeBefore = textEditing.fontSize;
        textEditing.fontSize = parseInt(fontSize);

        fabricCanvas.renderAll();

        setRichTextFontSize(parseInt(fontSize));

        pushUndoHistory({
            obj: {
                id: (textEditing as any).id,
                action: "changeFontSize",
                target: textEditing,
                fontSizeBefore: textFontSizeBefore,
                fontSize: parseInt(fontSize),
            },
            component: "fabric"
        }, dispatch);
    }


    const handleRichTextOnChangeFontFamily = (fontFamily: string) => {
        const textFontFamilyBefore = textEditing.fontFamily;
        textEditing.fontFamily = fontFamily;

        fabricCanvas.renderAll();

        pushUndoHistory({
            obj: {
                id: (textEditing as any).id,
                target: textEditing,
                action: "changeFontFamily",
                fontFamily: fontFamily,
                fontFamilyBefore: textFontFamilyBefore,
            },
            component: "fabric"
        }, dispatch);
    }


    return (
        <div className={`position-absolute bg-light w-75 border text-center rounded ${modalApplyEditText ? "" : "d-none"}`} style={{ bottom: 0 }} id="div-apply-edit-text">
            <div className="d-inline-block m-0 p-0" style={{ display: "flex !important", justifyContent: "center" }}>
                <button type="button" className={`btn btn-light border border-secondary ml-1`} title={t("Cor do texto")} style={{ fontSize: "1.0em" }} onClick={handleRichTextColorFont}>
                    <i className="fa fa-font" aria-hidden="true" style={{ borderBottom: `4px solid ${richTextCurrentColor} `, padding: "0px 0px 4px 0px" }}></i>
                </button>
                <input className="d-none" type="color" id={`richTextColorFontId`} value={richTextCurrentColor} onChange={(event) => handleRichTextColorChange(event.target.value)} />
                <button type="button" className={`btn btn-${!!textEditing && textEditing.textAlign == "left" ? "secondary" : "light"} border border-secondary ml-1`} title={t("Alinhar à esquerda")} onClick={() => changeTextBoxAlignment("left")}>
                    <i className="fa fa-align-left" aria-hidden="true"></i>
                </button>
                <button type="button" className={`btn btn-${!!textEditing && textEditing.textAlign == "center" ? "secondary" : "light"} border border-secondary ml-1`} title={t("Alinhar ao centro")} onClick={() => changeTextBoxAlignment("center")}>
                    <i className="fa fa-align-center" aria-hidden="true"></i>
                </button>
                <button type="button" className={`btn btn-${!!textEditing && textEditing.textAlign == "right" ? "secondary" : "light"} border border-secondary ml-1`} title={t("Alinhar à direita")} onClick={() => changeTextBoxAlignment("right")}>
                    <i className="fa fa-align-right" aria-hidden="true"></i>
                </button>
                <button type="button" className={`btn btn-${!!textEditing && textEditing.textAlign == "justify" ? "secondary" : "light"} border border-secondary ml-1`} title={t("Alinhamento justificado")} onClick={() => changeTextBoxAlignment("justify")}>
                    <i className="fa fa-align-justify" aria-hidden="true"></i>
                </button>
                <button type="button" className={`btn btn-${!!textEditing && textEditing.fontWeight == "bold" ? "secondary" : "light"} border border-secondary ml-1`} title={t("Negrito")} onClick={handleRichTextBold}>
                    <i className="fa fa-bold" aria-hidden="true"></i>
                </button>
                <button type="button" className={`btn btn-${!!textEditing && textEditing.fontStyle == "italic" ? "secondary" : "light"} border border-secondary ml-1`} title={t("Itálico")} onClick={handleRichTextItalic}>
                    <i className="fa fa-italic" aria-hidden="true"></i>
                </button>
                <button type="button" className={`btn btn-${!!textEditing && !!textEditing.underline ? "secondary" : "light"} border border-secondary ml-1`} title={t("Sublinhado")} onClick={handleRichTextUnderline}>
                    <i className="fa fa-underline" aria-hidden="true"></i>
                </button>
                <button type="button" className={`btn btn-${!!textEditing && !!textEditing.linethrough ? "secondary" : "light"} border border-secondary ml-1`} title={t("Tachado")} onClick={handleRichTextStrike}>
                    <i className="fa fa-strikethrough" aria-hidden="true"></i>
                </button>
                <div className="ml-1">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" title={t("Tamanho da fonte")}>
                                <i className="fa fa-font" aria-hidden="true"></i>
                            </span>
                        </div>
                        <select className="form-control custom-select" value={richTextFontSize} defaultValue={"30"} onChange={(event) => handleRichTextOnChangeFontSize(event.target.value)}>
                            <option value="6">6</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="14">14</option>
                            <option value="18">18</option>
                            <option value="24">24</option>
                            <option value="30">30</option>
                            <option value="36">36</option>
                            <option value="48">48</option>
                            <option value="60">60</option>
                            <option value="72">72</option>
                            <option value="96">96</option>
                        </select>
                    </div>
                </div>
                <div className="ml-1">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" title={t("Tipo da fonte")}>
                                <i className="fa fa-font" aria-hidden="true"></i>
                            </span>
                        </div>
                        <select className="form-control custom-select" value={richTextFontFamily} defaultValue={"Times New Roman"} onChange={(event) => handleRichTextOnChangeFontFamily(event.target.value)}>
                            <option value="Arial">Arial</option>
                            <option value="Arial Black">Arial Black</option>
                            <option value="Courier New">Courier New</option>
                            <option value="Times New Roman">Times New Roman</option>
                        </select>
                    </div>
                </div>
                <button className="btn btn-danger btn-sm mr-2 m-1" id="btn-cancel-editText" onClick={() => hideModalApplyEditText(dispatch)}>
                    <i className="mr-2 fa fa-times"></i>
                    {t("Fechar")}
                </button>
            </div>
        </div >
    )
}


export { ImageEditorRichTextModal };