import React from 'react';

const PageHeader = (props: any) => {
    const { label } = props;
    return (
        <div className="d-sm-flex align-items-center justify-content-between mb-0">
            <h1 className="h3 mb-0 text-gray-800">{label}</h1>
        </div>
    )
};

export default PageHeader;
