import React from 'react';

interface propsInterface {
	src: Buffer | string,
	printPDF?: boolean
}

const PDF = ({ src, printPDF }: propsInterface) => {
	return (
		<div className="text-center h-100">
			{!!src && <iframe id="pdf-iframe" src={`${process.env.PUBLIC_URL}/assets/vendor/pdfjs-2.4.456-dist/web/viewer.html?file=${src}&print=${!!printPDF}`}></iframe>}
		</div>
	);
}

export { PDF };
