import React from 'react';

import { PatientInterface } from "../../interfaces/patientInterface";
import { useTranslation } from 'react-i18next';

const handleDate = (date: any) => {
    if (!date) {
        return date.toDate().toLocaleDateString();
    }
    if (date.indexOf(' ') > 0) {
        date = date.split(' ')[0];
    }
    return new Date(date + ' 00:00:00').toLocaleDateString();
}

const PatientInfo = (props: { patient: PatientInterface }) => {
    const patient: PatientInterface = props.patient;
    const { t } = useTranslation();

    const city = (patient.contacts as any)[0].city.name || "";
    const state = (!!(patient.contacts as any)[0].city.state) ? (patient.contacts as any)[0].city.state.name : "";
    const country = (!!(patient.contacts as any)[0].city.state && !!(patient.contacts as any)[0].city.state.country) ? (patient.contacts as any)[0].city.state.country.name : "";    

    return (
        <div className="tab-pane active" role="tabpanel" aria-labelledby="nav-information-tab" id="nav-information">
            <div className="row mb-2">
                <input type="hidden" id="patientCompleteNameId" value={patient.id} readOnly />
                <div className="col">
                    <label htmlFor="patientCompleteNameId">{t("Nome")}</label>
                    <div className="input-group">
                        <input
                            className="form-control"
                            type="text" id="patientCompleteNameId"
                            value={patient.firstName + " " + patient.middleName + " " + patient.lastName}
                            readOnly
                        />
                    </div>
                </div>
                <div className="col">
                    <label htmlFor="patientBirthDateId">{t("Data de Nascimento")}</label>
                    <div className="input-group">
                        <input
                            className="form-control"
                            type="text"
                            id="patientBirthDateId"
                            value={handleDate(patient.birthDate) || (new Date().toLocaleDateString() as any)}
                            readOnly
                        />
                    </div>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col">
                    <label htmlFor="patientDocumentNameId">{t("Documento")}</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="patientDocumentNameId">{(patient.documents as any)[0].type.initials}</span>
                        </div>
                        <input className="form-control" type="text" id="patientDocumentNumberId" value={(patient.documents as any)[0].number} readOnly />
                    </div>
                </div>
                <div className="col">
                    <label htmlFor="patientGenderId">{t("Gênero")}</label>
                    <div className="input-group">
                        <input className="form-control" type="text" id="patientGenderId" value={t(patient.gender.name) as string || ""} readOnly />
                    </div>
                </div>
                <div className="col">
                    <label htmlFor="patientMaritalStatus">{t("Estado Civil")}</label>
                    <div className="input-group">
                        <input className="form-control" type="text" id="patientMaritalStatus" value={t(patient.maritalStatus.name) as string || ""} readOnly />
                    </div>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col">
                    <label htmlFor="patientPhoneId">{t("Telefone")}</label>
                    <div className="input-group">
                        <input className="form-control" type="text" id="patientPhoneId" value={(patient.contacts as any)[0].phone1} readOnly />
                    </div>
                </div>
                <div className="col">
                    <label htmlFor="patientPhoneSecundaryId">{t("Telefone Secundário")}</label>
                    <div className="input-group">
                        <input className="form-control" type="text" id="patientPhoneSecundaryId" value={(patient.contacts as any)[0].phone2} readOnly />
                    </div>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col">
                    <label htmlFor="patientCellPhoneId">{t("Celular")}</label>
                    <div className="input-group">
                        <input className="form-control" type="text" id="patientCellPhoneId" value={(patient.contacts as any)[0].celphone1 || (patient as any).cellphone1} readOnly />
                    </div>
                </div>
                <div className="col">
                    <label htmlFor="patientCellPhoneSecundaryId">{t("Celular Secundário")}</label>
                    <div className="input-group">
                        <input className="form-control" type="text" id="patientCellPhoneSecundaryId" value={(patient.contacts as any)[0].celphone2 || (patient as any).cellphone2} readOnly />
                    </div>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col">
                    <label htmlFor="patientEmailId">{t("Email")}</label>
                    <div className="input-group">
                        <input className="form-control" type="text" id="patientEmailId" value={(patient.contacts as any)[0].email1} readOnly />
                    </div>
                </div>
                <div className="col">
                    <label htmlFor="patientEmailSecundaryId">{t("Email Secundário")}</label>

                    <div className="input-group">
                        <input className="form-control" type="text" id="patientEmailSecundaryId" value={(patient.contacts as any)[0].email2} readOnly />
                    </div>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col">
                    <label htmlFor="patientStreetId">{t("Rua")}</label>

                    <div className="input-group">
                        <input className="form-control" type="text" id="patientStreetId" value={(patient.contacts as any)[0].street} readOnly />
                    </div>
                </div>
                <div className="col">
                    <label htmlFor="patientNeighborhoodId">{t("Bairro")}</label>

                    <div className="input-group">
                        <input className="form-control" type="text" id="patientNeighborhoodId" value={(patient.contacts as any)[0].neighborhood} readOnly />
                    </div>
                </div>
                <div className="col">
                    <label htmlFor="patientZipCodeid">{t("CEP")}</label>

                    <div className="input-group">
                        <input className="form-control" type="text" id="patientZipCodeid" value={(patient.contacts as any)[0].zipCode} readOnly />
                    </div>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col">
                    <label htmlFor="patientPublicPlaceId">{t("Logradouro")}</label>

                    <div className="input-group">
                        <input className="form-control" type="text" id="patientPublicPlaceId" value={(patient.contacts as any)[0].publicPlace} readOnly />
                    </div>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col">
                    <label htmlFor="patientCityId">{t("Cidade")}</label>
                    <div className="input-group">
                        <input className="form-control" type="text" id="patientCityId" value={city} readOnly />
                    </div>
                </div>
                <div className="col">
                    <label htmlFor="patientStateId">{t("Estado")}</label>

                    <div className="input-group">
                        <input className="form-control" type="text" id="patientStateId" value={state} readOnly />
                    </div>
                </div>
                <div className="col">
                    <label htmlFor="patientCountry">{t("País")}</label>
                    <div className="input-group">
                        <input className="form-control" type="text" id="patientCountry" value={country} readOnly />
                    </div>
                </div>
            </div>
        </div>
    );
}

export { PatientInfo };
