import { baseURL } from '../config/api-config';
import { AtendimentoInterface } from '../interfaces/new/atendimentoInterface';
import { HttpClient } from './httpClient';

export class AtendimentoService {
    private httpClient: HttpClient;
    constructor() {
        this.httpClient = new HttpClient();
    }

    async lista(params?: any) {
        return await this.httpClient.get({ url: '/atendimento', baseURL: baseURL, params });
    }

    async atualizar(idAtendimento: number, atendimento: AtendimentoInterface) {
        return await this.httpClient.put({ url: `/atendimento/${idAtendimento}`, baseURL: baseURL, data: atendimento });
    }
}