import { baseURL } from '../config/api-config';
import { RespostaPredefinidaInterface } from '../interfaces/new/respostaPredefinidaInterface';
import { HttpClient } from './httpClient';

export class RespostaPredefinidaService {
    private httpClient: HttpClient;
    constructor() {
        this.httpClient = new HttpClient();
    }

    async maisUtilizados(idCampo: number) {
        return await this.httpClient.get({ url: `/respostapredefinida/maisutilizados`, baseURL: baseURL, params: { idCampo } });
    }

    async atualizaMaisUtilizados(idRespostaPredefinida: number | any): Promise<number> {
        return await this.httpClient.put({ url: `/respostapredefinida/maisutilizados/${idRespostaPredefinida}`, baseURL: baseURL });
    } 

    async lista(idCampo: number) {
        return await this.httpClient.get({ url: `/respostapredefinida?idCampo=${idCampo}`, baseURL: baseURL });
    }

    async inserir(respostaPredefinida: RespostaPredefinidaInterface): Promise<number> {
        return await this.httpClient.post({ url: '/respostapredefinida', baseURL: baseURL, data: respostaPredefinida });
    }

    async atualizar(respostaPredefinida: RespostaPredefinidaInterface): Promise<number> {
        return await this.httpClient.put({
            url: `/respostapredefinida/${respostaPredefinida.idRespostaPredefinida}`,
            baseURL: baseURL,
            data: respostaPredefinida
        });
    }

    async deletar(respostaPredefinida: RespostaPredefinidaInterface): Promise<number> {
        return await this.httpClient.delete({
            url: `/respostapredefinida/${respostaPredefinida.idRespostaPredefinida}`,
            baseURL: baseURL
        });
    }
}