export class LocalStorageUtils {

    static salvar<T>(key: string, value: T) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    static buscar<T>(key: string): T | undefined {
        const item = localStorage.getItem(key);
        if (!item) {
            return undefined;
        }

        return JSON.parse(item);
    }

    static remover<T>(key: string) {
        localStorage.removeItem(key);
    }
}