import React from "react";

import { PropsModalInterface } from "../../interfaces/propsModalElementInterface";

const Modal = (props: PropsModalInterface) => {
    const { children, idModal, idTitleLabelModal, idButtonCloseModal, idButtonPreviousModal, titleModalElement, onClickButtonClose, onClickButtonPrevious, showButtonPrevious } = props;

    return (
        <div className="modal" id={idModal} tabIndex={-1} role="dialog" aria-labelledby={idTitleLabelModal} aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable w-50" role="document" style={{ margin: '50px auto', maxWidth: '100%' }}>
                <div className="modal-content">
                    <div className="modal-header p-2 ml-2 mr-2 m-2 border-0" style={{  }}>
                        <button type="button" className={`close ${!showButtonPrevious ? "d-none" : "p-0, mr-2"}`} style={{float: 'left'}} data-dismiss="modal" aria-label="BackButton" id={idButtonPreviousModal} onClick={onClickButtonPrevious as any}>
                            <span aria-hidden="true"><i className="fa fa-arrow-left"></i></span>
                        </button>
                        <h5 className="modal-title" style={{ position: 'relative', flex: 'auto' }} id={idTitleLabelModal}>{titleModalElement}</h5>
                        <button type="button" className={`close ${!onClickButtonClose ? "d-none" : ""}`} data-dismiss="modal" aria-label="Close" id={idButtonCloseModal} onClick={onClickButtonClose as any}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;