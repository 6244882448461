import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModeloInterface } from '../../interfaces/new/modeloInterface';
import { IHeaderInfoDataInterface } from '../../interfaces/templateHeaderInfoInterface';
import { TemplateInterface } from '../../interfaces/templateInterface';
import Template from '../pages/template-page';

interface IPropsInterface {
    headerInfoData: IHeaderInfoDataInterface
    modelo: ModeloInterface,
}

const PaperHeaderInfo = (props: IPropsInterface) => {
    const { t } = useTranslation();

    let headerInfoData: IHeaderInfoDataInterface = props.headerInfoData;
    let template: ModeloInterface = props.modelo;

    return (
        <nav className="w-100">
            <dl className="row m-0 mb-2 " style={{fontFamily: "Arial"}}>
                {
                    !!headerInfoData.examTitle &&
                    <dd className="col-12 text-center" style={{fontFamily: "Arial"}}>
                        <h2> <u id="templateExamTitleId" className='text-decoration-none'>{headerInfoData.examTitle}</u></h2>
                    </dd>
                }
                
                {
                    !!headerInfoData.currentPage && headerInfoData.totalPage &&
                    <dd className="col-12 text-right p-0 pr-1 m-0" style={{ fontSize: "1.0em" }}>
                        <b>{`${t("Página")}: `}</b>
                        <span id="templatePageInfoId">{headerInfoData.currentPage} {`${t("de")}`} {headerInfoData.totalPage}</span>
                    </dd>
                }

                {
                    !!headerInfoData.patientName && template.mostrarPaciente &&
                    <dd className="col-12 text-left p-0 pr-1 m-0" style={{ fontSize: "1.0em" }}>
                        <b>{`${t("Paciente")}: `}</b>
                        <span id="templateInputPatientId">{headerInfoData.patientName}</span>
                    </dd>
                }
                
                {
                    template.mostrarSolicitante &&
                    <dd className="col-12 text-left p-0 pr-1 m-0" style={{ fontSize: "1.0em" }}>
                        <b>{`${t("Solicitante")}: `}</b>
                        <span id="templateInputRequesterId">{headerInfoData.requesterPhysicianName}</span>
                    </dd>
                }

                {
                    !!headerInfoData.patientBirthDate && template.mostrarNascimentoPaciente &&
                    <dd className="col-4 text-left p-0 pr-1 m-0" style={{ fontSize: "1.0em" }}>
                        <b>{`${t("Data de nascimento")}: `}</b>
                        <span id="templateInputBirthdateId">{headerInfoData.patientBirthDate}</span>
                    </dd>
                }

                {
                    !!headerInfoData.patientGender && template.mostrarGeneroPaciente &&
                    <dd className="col-4 text-left p-0 pr-1 m-0" style={{ fontSize: "1.0em" }}>
                        <b>{`${t("Gênero")}: `} </b>
                        <span id="templateInputGenderId">{headerInfoData.patientGender}</span>
                    </dd>
                }

                {
                    !!headerInfoData.patientAge && template.mostrarIdadePaciente &&
                    <dd className="col-4 text-left p-0 pr-1 m-0" style={{ fontSize: "1.0em" }}>
                        <b>{`${t("Idade")}: `}</b>
                        <span id="templateInputAgeId">{headerInfoData.patientAge}</span> 
                    </dd>
                }

                {
                    !!headerInfoData.physicianName && !template.esconderExaminador &&
                    <dd className="col-4 text-left p-0 pr-1 m-0" style={{ fontSize: "1.0em" }}>
                        <b>{`${t("Examinador")}: `}</b>
                        <span id="templateInputDoctorId">{headerInfoData.physicianName}</span>
                    </dd>
                }

                {
                    !!headerInfoData.deviceName && template.mostrarEquipamento &&
                    <dd className="col-4 text-left p-0 pr-1 m-0" style={{ fontSize: "1.0em" }} >
                        <b>{`${t("Dispositivo")}: `}</b>
                        <span id="templateInputDeviceId">{headerInfoData.deviceName}</span>
                    </dd>
                }

                {
                    !!headerInfoData.healthPlanBroker && template.mostrarPlanoSaude &&
                    <dd className="col-4 text-left p-0 pr-1 m-0" style={{ fontSize: "1.0em" }}>
                        <b>{`${t("Convênio")}: `}</b>
                        <span id="templateInputHealthPlanId">{headerInfoData.healthPlanBroker}</span>
                    </dd>
                }

                {
                    !!headerInfoData.dateHourCreated && template.mostrarData &&
                    <dd className="col-4 text-left p-0 pr-1 m-0" style={{ fontSize: "1.0em" }}>
                        <b>{`${t("Data")}: `}</b>
                        <span id="templateInputDateId">{headerInfoData.dateHourCreated}</span>
                    </dd>
                }

                {
                    !!headerInfoData.assistantName && template.mostrarAssistente &&
                    <dd className="col-4 text-left p-0 pr-1 m-0 d-none" style={{ fontSize: "1.0em" }}>
                        <b>{`${t("Enfermeira")}: `}</b>
                        <span id="templateInputNurseId">{headerInfoData.assistantName}</span>
                    </dd>
                }

                {
                    !!headerInfoData.technicianName && template.mostrarTecnico &&
                    <dd className="col-4 text-left p-0 pr-1 m-0 d-none" style={{ fontSize: "1.0em" }}>
                        <b>{`${t("Técnico")}: `}</b>
                        <span id="templateInputTechnicianId">{headerInfoData.technicianName}</span>
                    </dd>
                }

                {
                    !!headerInfoData.anesthetistName && template.mostrarAnestesista &&
                    <dd className="col-4 text-left p-0 pr-1 m-0 d-none" style={{ fontSize: "1.0em" }}>
                        <b>{`${t("Anestesista")}: `}</b>
                        <span id="templateInputAnesthetistId">{headerInfoData.anesthetistName}</span>
                    </dd>
                }

                {/* <dd className="col-12 text-left p-0 m-0 pb-3 d-none" style={{ fontSize: "1.0em" }}>
                    <b>Diagnosis: </b>
                    <span>Tudo Normal</span>
                </dd> */}
            </dl>

        </nav >
    )
}

export default PaperHeaderInfo;