import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { ApplicationState } from '../../store';
import { ImageService } from "../../service/image.service";
import { setIsSaved, clearImageEditorState, setImageUrl, setUpdateImages } from '../../store/actions/imageEditor';
import { showLoading, hideLoading } from '../../store/actions/loading';
import { ImagemDadosInterface } from '../../interfaces/new/imagemDadosInterface';

const ImageEditorHeader = () => {
    const { t } = useTranslation();
    const clinicaSelecionada = useSelector((state: ApplicationState) => state.app.clinicaSelecionada);
    const { dataImageOriginal, isFabricEdition, cropper, isSaved, currentFabricImage, fabricCanvas } = useSelector((state: ApplicationState) => state.imageEditor);

    const dispatch = useDispatch();

    const closeModal = () => {
        (document.querySelector("#closeImageEditorModal") as HTMLButtonElement).click();
    }

    const handleSave = () => {
        save();
    }

    const save = (isClosed = false, toClose: boolean = true) => {
        showLoading(dispatch, `${t("Aguarde um momento enquanto sua imagem é salva")}...`);
        if (isFabricEdition) {
            const options = {
                format: "png",
                quality: 1,
                multiplier: 1,
                left: currentFabricImage.left,
                top: currentFabricImage.top,
                width: (currentFabricImage.width as number) * (currentFabricImage.scaleX as number),
                height: (currentFabricImage.height as number) * (currentFabricImage.scaleY as number),
                enableRetinaScaling: false
            };
            return fetch(fabricCanvas.toDataURL(options))
                .then(res => res.blob())
                .then(blob => {
                    return sendImage(blob, isClosed, toClose);
                })
        } else {
            if (cropper && cropper.current) {
                return Promise.resolve(cropper.current.getCroppedCanvas()
                    .toBlob((blob) => {
                        return sendImage(blob, isClosed, toClose);
                    })
                )
            } else {
                hideLoading(dispatch);
            }
        }
    }

    const sendImage = (dataBlob: any, isClosed: boolean, toClose: boolean) => {

        let largura: number = 0, altura: number = 0;

        const image = document.querySelector("#img") as HTMLImageElement;

        if (isFabricEdition) {
            largura = currentFabricImage.width as number
            altura = currentFabricImage.height as number
        } else {
            largura = image.width;
            altura = image.height;
        }

        const dadosImagem: ImagemDadosInterface = {
            idContrato: dataImageOriginal.idContrato,
            largura,
            altura,
            idPaciente: dataImageOriginal.idPaciente,
            idMedico: dataImageOriginal.idMedico,
            idAtendimento: dataImageOriginal.idAtendimento,
            dimensoes: `${largura}x${altura}`,
            fabricante: dataImageOriginal.fabricante,
            modelo: dataImageOriginal.modelo,
            idExame: dataImageOriginal.idExame,
            idDispositivo: dataImageOriginal.idDispositivo,
            serial: clinicaSelecionada.serialPrincipal
        }

        salvarImagem(dadosImagem, dataBlob)
            .then((result) => {
                hideLoading(dispatch);
                if (isClosed) {
                    clearImageEditorState(dispatch);
                    hideLoading(dispatch);
                    closeModal();
                    setUpdateImages(true, dispatch);
                } else {
                    hideLoading(dispatch);
                    setIsSaved(true, dispatch);
                    Swal.fire({
                        icon: "success",
                        title: t("Edição de imagem"),
                        text: t("Imagem salva com sucesso!")
                    }).then(() => {
                        if (toClose) {
                            clearImageEditorState(dispatch);
                            closeModal();
                        }
                        setUpdateImages(true, dispatch);
                    })
                }
            })
            .catch((error) => {
                console.log('Error', error)
                hideLoading(dispatch);
                return Swal.fire({
                    icon: "warning",
                    title: t("Edição de imagem"),
                    text: `${t("Erro ao salvar imagem")}: ${error}`,
                });
            })

    }

    const salvarImagem = async (dadosImagem: any, imagem: Blob) => {
        const extensao = imagem.type?.split("/").pop();
        const nomeArquivo = `${new Date().getTime().toString()}.${extensao}`;

        const arquivo = new File([imagem], String(nomeArquivo));
        const form = new FormData();

        form.append('file', arquivo, arquivo.name);
        form.append('json', JSON.stringify(dadosImagem));

        return await new ImageService().inserir(form)
    }

    const handleCancel = () => {
        cancel();
    }

    const cancel = () => {
        Swal.fire({
            icon: "question",
            title: t("Cancelar edição de imagem"),
            text: t("Você realmente deseja cancelar a edição?"),
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('Sim'),
            cancelButtonText: t('Não')
        })
            .then((result) => {
                if (result.value) {
                    clearImageEditorState(dispatch);
                    closeModal();
                }
            })
    }

    const handleClose = () => {
        close();
    }

    const close = () => {
        if (!isSaved) {
            Swal.fire({
                icon: "question",
                title: t("Fechar edição de imagem"),
                text: t("Você quer salvar antes de fechar?"),
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t('Sim'),
                cancelButtonText: t('Não')
            })
                .then((result) => {
                    if (result.value) {
                        save(true);
                    } else {
                        clearImageEditorState(dispatch);
                        closeModal();
                    }
                })
        } else {
            clearImageEditorState(dispatch);
            closeModal();
        }
    }

    return (
        <div className="card-header p-1">
            <div className="row m-0">
                <div className="col p-1">
                    <span className="mx-2 ml-4 h4">
                        {t("Editor de Imagem")}
                    </span>
                </div>
                <div className="col p-1 text-right">
                    <button className="btn btn-sm btn-success mr-2" title={t("Salvar")} id="btn-save" onClick={handleSave}>
                        <i className="mr-2 fa fa-save"></i>
                        {t("Salvar")}
                    </button>
                    <button className="btn btn-sm btn-warning mr-2" title={t("Cancelar")} id="btn-cancel" onClick={handleCancel}>
                        <i className="mr-2 fa fa-ban"></i>
                        {t("Cancelar")}
                    </button>
                    <button className="btn btn-sm btn-danger mr-2" title={t("Fechar")} id="btn-close-image-edition" onClick={handleClose}>
                        <i className="mr-2 fa fa-times"></i>
                        {t("Fechar")}
                    </button>
                    <button type="button" className="close d-none" data-dismiss="modal" aria-label="Close" id="closeImageEditorModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ImageEditorHeader;