import React, { useState, useEffect, createRef } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// Elements
import PageContainer from "../elements/page-container-element";
import { ViewSelect } from "../elements/view-select-element";
import Table from "../elements/table-element";
import { FooterButtonsList } from "../elements/card-footer-buttons-list-elment";
import { SpeechRecognitionButton } from "../components/speech-recognition-button-component";


// Utis/Tools
import { DateUtil } from "../utils/dateUtils";
import { SearchUtil } from "../utils/searchUtils";
import { MountDataFromFirebaseUtil } from "../utils/mountDataUtils";

// Interfaces
import { ApplicationState } from "../../store";
import { DeviceInterface } from "../../interfaces/deviceInterface";
import { AppointmentToListInterface } from "../../interfaces/appointmentToListInterface";
import { ReportAnswerInterface } from "../../interfaces/reportAnswerInterface";
import { GenderMappings } from "../../interfaces/genderInterface";

// States/redux
import { setExamsToList } from "../../store/actions/exam";
import { setReportData } from "../../store/actions/reportData";
import { setPatientsToList } from "../../store/actions/patient";
import { setPhysiciansToList } from "../../store/actions/physician";
import { setSerialPath, setUserEmail, setSerial, setClinicGlobalPath } from "../../store/actions/app";
import { setReferringPhysiciansToList } from "../../store/actions/referringPhysician";


// Service
import { AtendimentoService } from "../../service/atendimento.service";

// Styles
import "./report-list-page.css";
import { setStopListening } from "../../store/actions/stopListening";
import { joinNames } from "../../utils/string-utils";
import { showLoading, hideLoading } from '../../store/actions/loading';
import { AtendimentoInterface } from "../../interfaces/new/atendimentoInterface";
import { PacienteInterface } from "../../interfaces/new/pacienteInterface";
import { PacienteService } from "../../service/paciente.service";
import { ProfissionalSaudeInterface } from "../../interfaces/new/profissionalSaudeInterface";
import { ProfissionalSaudeService } from "../../service/profissionalSaude.service";
import { ExameService } from "../../service/exame.service";
import { ExameInterface } from "../../interfaces/new/exameInterface";
import { ConvenioInterface } from "../../interfaces/new/convenioInterface";
import { ConvenioService } from "../../service/convenio.service";
import { LaudoInterface } from "../../interfaces/new/laudoInterface";
import { LaudoService } from "../../service/laudo.service";
import { param } from "jquery";
import { LanguageUtils } from "../../utils/language-utils";

interface PropsInterface {
}


const ReportList = (props: PropsInterface) => {
    const inputTextToSearchRef = createRef<HTMLInputElement>();

    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dateUtil = new DateUtil();
    const searchUtil = new SearchUtil();
    const mountDataFromFirebaseUtil = new MountDataFromFirebaseUtil();

    const clinicaSelecionada = useSelector((states: ApplicationState) => states.app.clinicaSelecionada);
    // const patientsToList = useSelector((states: ApplicationState) => states.patient.patientsToList);

    const addReportNavRef: any = createRef();
    const listReportNavRef: any = createRef();
    const inputEndDateFilter: any = createRef();
    const inputStartDateFilter: any = createRef();
    const wordsInReportDivRowRef: any = createRef();
    const referringPhysicianDivColRef: any = createRef();

    const [appointments, setAppointments] = useState<Array<AtendimentoInterface>>([]);
    const [pacientes, setPacientes] = useState<Array<PacienteInterface>>([]);
    const [profissionaisSaude, setProfissionaisSaude] = useState<Array<ProfissionalSaudeInterface>>([]);
    const [exames, setExames] = useState<Array<ExameInterface>>([]);
    const [convenios, setConvenios] = useState<Array<ConvenioInterface>>([]);


    const incrementOffSet = 10;

    const [offSetAppointment, setOffSetAppointment] = useState(0);
    const [offSetViewSelectExam, setOffSetViewSelectExam] = useState(0);
    const [offSetViewSelectPatient, setOffSetViewSelectPatient] = useState(0);
    const [offSetViewSelectPhysician, setOffSetViewSelectPhysician] = useState(0);
    const [offSetViewSelectReferringPhysician, setOffSetViewSelectReferringPhysician] = useState(0);

    const [timeoutTextMedico, setTimeoutTextMedico] = useState<any>(0);
    const [timeoutTextPatient, setTimeoutTextPatient] = useState<any>(0);
    const [timeoutTextExame, setTimeoutTextExame] = useState<any>(0);

    const [dateEndFilter, setDateEndFilter] = useState("");
    const [dateStringStartFilter, setDateStringStartFilter] = useState("");
    const [dateStartFilter, setDateStartFilter] = useState<Date>();
    const [wordsInReportFilter, setWordInReportFilter] = useState("");
    const [examFilter, setExamFilter] = useState<ExameInterface>();
    const [typeReportsToSearchFilter, setTypeReportsToSearchFilter] = useState(1);
    const [genderFilter, setGenderFilter] = useState({ id: -1, name: t("Gênero") });
    const [deviceFilter, setDeviceFilter] = useState({ id: -1, name: t("Dispositivo") });
    const [patientFilter, setPatientFilter] = useState<PacienteInterface>();
    const [patientName, setPatientName] = useState("");
    const [patientQuery, setPatientQuery] = useState("");
    const [medicoNome, setMedicoNome] = useState("");
    const [medicoQuery, setMedicoQuery] = useState("");
    const [exameNome, setExameNome] = useState("");
    const [exameQuery, setExameQuery] = useState("");
    const [healthPlanBrokerFilter, setHealthPlanBrokerFilter] = useState({ id: -1, name: t("Convênio") });
    const [referringPhysicianFilter, setReferringPhysicianFilter] = useState({ id: -1, firstName: t("Médico solicitante"), lastName: "" });


    const [radioDay, setRadioDay] = useState(true);
    const [radioMonth, setRadioMonth] = useState(false);
    const [radioPeriod, setRadioPeriod] = useState(false);

    const [examsToList, setExamToListL] = useState([] as any);
    const [deviceToList, setDeviceToList] = useState([] as any);
    const [genderToList, setGenderToList] = useState([] as any);
    const [physiciansToList, setPhysicianToListL] = useState([] as any);
    const [healthPlanBrokerToList, setHealthPlanBrokerToList] = useState([] as any);
    const [referringPhysiciansToList, setReferringPhysicianToListL] = useState([] as any);
    const [appointmentsToList, setAppointmentsToList]: Array<any> = useState([] as Array<any>);

    const [enableForwardButtonExam, setEnableForwardButtonExam] = useState(false);
    const [enablePreviousButtonExam, setEnablePreviousButtonExam] = useState(false);
    const [enableForwardButtonPatient, setEnableForwardButtonPatient] = useState(false);
    const [enablePreviousButtonPatient, setEnablePreviousButtonPatient] = useState(false);
    const [enableForwardButtonPhysician, setEnableForwardButtonPhysician] = useState(false);
    const [enablePreviousButtonPhysician, setEnablePreviousButtonPhysician] = useState(false);
    const [enableForwardButtonAppointment, setEnableForwardButtonAppointment] = useState(false);
    const [enablePreviousButtonAppointment, setEnablePreviousButtonAppointment] = useState(false);
    const [enableForwardButtonReferringPhysician, setEnableForwardButtonReferringPhysician] = useState(false);
    const [enablePreviousButtonReferringPhysician, setEnablePreviousButtonReferringPhysician] = useState(false);

    const [reportItemsTHead, setReportItemsTHead] = useState([
        t("Id"),
        t("Paciente"),
        t("Gênero"),
        t("Médico"),
        // t("Médico solicitante"),
        t("Data de Atendimento"),
        t("Exame"),
        // t("Dispositivo"),
        t("Opções")
    ]);

    const ViewSelectPatientItemsTHead = [
        "#",
        t("Nome"),
        t("Documento"),
        t("Data de nascimento"),
        t("Gênero")
    ];

    const ViewSelectPhysicianItemsTHead = [
        "#",
        t("Nome"),
        t("Documento"),
        t("Ocupação"),
        t("Email")
    ];

    const ViewSelectReferringPhysicianItemsTHead = [
        "#",
        t("Nome"),
        t("Documento"),
        t("Ocupação"),
        t("Email")
    ];

    const ViewSelectExamItemsTHead = [
        "#",
        t("Nome"),
        t("Título"),
    ];

    useEffect(() => {
        if (clinicaSelecionada) {
            getAppointments();
        }
    }, [
        typeReportsToSearchFilter,
        genderFilter,
        patientQuery,
        medicoQuery,
        exameQuery,
        dateStartFilter
    ]);

    useEffect(() => {
        if (clinicaSelecionada) {
            getGeneros();
            getExames();
            getConvenios();
            hideLoading(dispatch);
        }
    }, [clinicaSelecionada]);

    useEffect(() => {
        if(timeoutTextPatient) {
            clearTimeout(timeoutTextPatient);
        }
        setTimeoutTextPatient(setTimeout(() => {
            setPatientQuery(patientName);
        },1000))
    }, [patientName]);

    
    useEffect(() => {
        if(timeoutTextMedico) {
            clearTimeout(timeoutTextMedico);
        }

        setTimeoutTextMedico(setTimeout(() => {
            setMedicoQuery(medicoNome);
        },1000));
    }, [medicoNome]);
    
    useEffect(() => {
        if(timeoutTextExame) {
            clearTimeout(timeoutTextExame);
        }

        setTimeoutTextExame(setTimeout(() => {
            setExameQuery(exameNome);
        },1000));
    }, [exameNome]);

    const getAppointments = async () => {
        const queryParams = {
            'estado[]': typeReportsToSearchFilter || 0,
            genero: genderFilter.id !== -1 ? getOriginalGender(genderFilter.name) : null,
            pacienteNome: patientQuery || '',
            medicoNome: medicoQuery || '',
            dataInicioAtendimento: null,
            exameNome: exameQuery || ''
        } as any;

        if(dateStartFilter) {
            queryParams.dataInicioAtendimento = dateStartFilter;
        }

        const appointmentsList = await new AtendimentoService().lista(queryParams);
        setAppointments(appointmentsList);
    }

    const getGeneros = async () => {
        const generosList = await new PacienteService().buscaGeneros();

        setGenderToList(generosList);
    }

    const getPacientes = async () => {
        const pacientesList = await new PacienteService().lista();

        setPacientes(pacientesList);
    }

    const getProfissionaisSaude = async () => {
        const profissionaisSaudeList = await new ProfissionalSaudeService().lista();

        setProfissionaisSaude(profissionaisSaudeList);
    }

    const getExames = async () => {
        const examesList = await new ExameService().lista();

        setExames(examesList);
    }

    const getConvenios = async () => {
        const conveniosList = await new ConvenioService().lista();

        setConvenios(conveniosList);
    }


    // useEffect(() => {
    //     onClickSearchAppointment(true);
    //     initPatientDataToList();
    //     initGenderDataToList();
    //     initDataPhysicianToList();
    //     initDataReferringPhysicianToList();
    //     initDataExamToList();
    //     initDataHealthPlanBrokerToList();
    //     initDataDeviceToList();

    // }, [selectedClinic]);


    // useEffect(() => {
    //     searchUtil.checkPreviousButton(offSetAppointment, incrementOffSet, setEnablePreviousButtonAppointment);
    //     searchUtil.checkForwardButton(offSetAppointment, incrementOffSet, appointmentsToList.length, setEnableForwardButtonAppointment);

    // }, [appointmentsToList, offSetAppointment]);


    // useEffect(() => {
    //     toggleFormFilterToSearch();
    //     toggleColumnExaminer();
    //     onClickSearchAppointment();

    // }, [typeReportsToSearchFilter]);


    // useEffect(() => {
    //     if (examsToList.length) {
    //         setExamsToList(examsToList, dispatch);
    //     }

    //     if (physiciansToList.length) {
    //         setPhysiciansToList(physiciansToList, dispatch);
    //     }

    //     if (referringPhysiciansToList.length) {
    //         setReferringPhysiciansToList(referringPhysiciansToList, dispatch);
    //     }


    // }, [examsToList, physiciansToList, referringPhysiciansToList]);

    // const initPatientDataToList = () => {
    //     if (!!selectedClinic && !!selectedClinic.appointments) {
    //         setPatientsToList(
    //             removeDuplicateDataToList(
    //                 selectedClinic.appointments
    //                     .map((appointment: any) => appointment.data.patient)
    //             ),
    //             dispatch
    //         );
    //     }
    // }

    // const initGenderDataToList = () => {
    //     if (!!selectedClinic && !!selectedClinic.appointments) {
    //         setGenderToList(
    //             removeDuplicateDataToList(
    //                 selectedClinic.appointments
    //                     .map((appointment: any) => (!!appointment.data.patient.gender ? appointment.data.patient.gender : null))
    //             )
    //         );
    //     }
    // }

    // const initDataPhysicianToList = () => {
    //     if (!!selectedClinic && !!selectedClinic.appointments) {
    //         setPhysicianToListL(
    //             removeDuplicateDataToList(
    //                 selectedClinic.appointments
    //                     .map((appointment: any) => appointment.data.physician)
    //             )
    //         );
    //     }
    // }

    // const initDataReferringPhysicianToList = () => {
    //     if (!!selectedClinic && !!selectedClinic.appointments) {
    //         setReferringPhysicianToListL(
    //             removeDuplicateDataToList(
    //                 selectedClinic.appointments
    //                     .map((appointment: any) => (!!appointment.data.report && !!appointment.data.report.requester ? appointment.data.report.requester : null))
    //             )
    //         );
    //     }
    // }

    // const initDataExamToList = () => {
    //     if (!!selectedClinic && !!selectedClinic.appointments) {
    //         setExamToListL(
    //             removeDuplicateDataToList(
    //                 selectedClinic.appointments
    //                     .map((appointment: any) => appointment.data.exam)
    //             )
    //         );
    //     }
    // }

    // const initDataHealthPlanBrokerToList = () => {
    //     if (!!selectedClinic && !!selectedClinic.appointments) {
    //         setHealthPlanBrokerToList(
    //             removeDuplicateDataToList(
    //                 selectedClinic.appointments
    //                     .map((appointment: any) => (!!appointment.data.patient.insurance && appointment.data.patient.insurance.length ? appointment.data.patient.insurance[0].name : null))
    //             )
    //         );
    //     }
    // }



    // const initDataDeviceToList = () => {
    //     if (!!selectedClinic && !!selectedClinic.appointments) {
    //         let devices: Array<DeviceInterface> = [];

    //         selectedClinic.appointments
    //             .forEach((appointment: any) => {
    //                 if (!!appointment.data.patient && !!appointment.data.patient.images && appointment.data.patient.images.length) {
    //                     devices = devices.concat(
    //                         removeDuplicateDataToList(
    //                             appointment.data.patient.images
    //                                 .map((image: any) => (!!image.device ?
    //                                     {
    //                                         id: image.device,
    //                                         model: image.cameraModel || "",
    //                                         manufacturer: image.cameraManufacturer || "",
    //                                         serialNumber: image.cameraSerialNumber || "",
    //                                     }
    //                                     : null
    //                                 ))
    //                         )
    //                     )
    //                 }
    //             });

    //         setDeviceToList(removeDuplicateDataToList(devices));
    //     }
    // }


    // const removeDuplicateDataToList = (arrayDataToList: Array<any>) => {
    //     return arrayDataToList
    //         .filter(Boolean)
    //         .reduce((dataArrayNew: Array<DeviceInterface>, currentItem: any) => addNewItemInArrayToList(dataArrayNew, currentItem), [])
    // }

    // const addNewItemInArrayToList = (dataArrayNew: Array<any>, itemToAdd: any) => {
    //     return (dataArrayNew.find((itemToCMP) => itemToCMP.id == itemToAdd.id)) ? dataArrayNew : [...dataArrayNew, itemToAdd];
    // }


    // const onClickSearchAppointment = (isInit: boolean = false) => {
    //     setOffSetAppointment(0);

    //     if (!!selectedClinic && !!selectedClinic.appointments) {
    //         let dataSearched: Array<any> = selectedClinic.appointments;

    //         if (!isInit) {
    //             const wordsInReports: string = wordsInReportFilter.toLowerCase();

    //             if (typeReportsToSearchFilter != 1) {
    //                 dataSearched = dataSearched
    //                     .filter((appointment: AppointmentToListInterface) => {
    //                         return typeReportsToSearchFilter == 2 ? (appointment.data.pending || !appointment.data.report) : (!appointment.data.pending || !!appointment.data.report);
    //                     });
    //             }

    //             if (dateStartFilter != "" && dateEndFilter != "") {
    //                 let dateEnd = new Date(dateEndFilter).getTime();
    //                 let dateStart = new Date(dateStartFilter).getTime();

    //                 dataSearched = dataSearched
    //                     .filter((appointment: AppointmentToListInterface) => {
    //                         let dateAppointment = new Date(appointment.data.date).getTime();

    //                         return dateStart <= dateAppointment && dateAppointment <= dateEnd;
    //                     });

    //             } else {
    //                 if (dateStartFilter != "") {
    //                     if (radioDay === true) {
    //                         let dateStart = new Date(dateStartFilter).getTime();

    //                         dataSearched = dataSearched
    //                             .filter((appointment: AppointmentToListInterface) => {
    //                                 return dateStart == new Date(appointment.data.date).getTime();
    //                             });

    //                     } else {
    //                         if (radioMonth === true) {
    //                             let dateStart = dateStartFilter.toString().slice(5);

    //                             dataSearched = dataSearched
    //                                 .filter((appointment: AppointmentToListInterface) => {
    //                                     let dateAppointmentString = (appointment.data.date).toString().slice(5, 7);
    //                                     return dateStart == dateAppointmentString;
    //                                 });
    //                         }
    //                     }
    //                 }
    //             }


    //             if (patientFilter.id != -1) {
    //                 dataSearched = dataSearched
    //                     .filter((appointment: AppointmentToListInterface) => {
    //                         return appointment.data.patient.id === patientFilter.id;
    //                     });
    //             }

    //             if (genderFilter.id != -1) {
    //                 dataSearched = dataSearched
    //                     .filter((appointment: AppointmentToListInterface) => {
    //                         return !!appointment.data.patient.gender && appointment.data.patient.gender.id === genderFilter.id;
    //                     });
    //             }

    //             if (physicianFilter.id != -1) {
    //                 dataSearched = dataSearched
    //                     .filter((appointment: AppointmentToListInterface) => {
    //                         return appointment.data.physician.id === physicianFilter.id;
    //                     });
    //             }

    //             if (referringPhysicianFilter.id != -1) {
    //                 dataSearched = dataSearched
    //                     .filter((appointment: AppointmentToListInterface) => {
    //                         return !!appointment.data.report && !!appointment.data.report.requester && appointment.data.report.requester.id === referringPhysicianFilter.id;
    //                     });
    //             }

    //             if (examFilter.id != -1) {
    //                 dataSearched = dataSearched
    //                     .filter((appointment: AppointmentToListInterface) => {
    //                         return !!appointment.data.exam && appointment.data.exam.id === examFilter.id;
    //                     });
    //             }

    //             if (deviceFilter.id != -1) {
    //                 dataSearched = dataSearched
    //                     .filter((appointment: AppointmentToListInterface) => {
    //                         return (!!appointment.data.report && appointment.data.report.device?.id === deviceFilter.id) ||
    //                             (!!appointment.data.patient && !!appointment.data.patient.images && appointment.data.patient.images.length && appointment.data.patient.images[0].device === deviceFilter.id);
    //                     });
    //             }

    //             if (wordsInReports !== "") {
    //                 dataSearched = selectedClinic
    //                     .appointments
    //                     .filter((appointment: AppointmentToListInterface) => {
    //                         return (
    //                             !!appointment.data.report && !!appointment.data.report.answer ?
    //                                 appointment.data.report.answer
    //                                     .filter((answer: ReportAnswerInterface) => {
    //                                         return answer.answerText.indexOf(wordsInReports) != -1;
    //                                     }).length
    //                                 : false
    //                         );
    //                     });
    //             }
    //         } else {
    //             if (typeReportsToSearchFilter != 1) {
    //                 dataSearched = dataSearched
    //                     .filter((appointment: AppointmentToListInterface) => {
    //                         return typeReportsToSearchFilter == 2 ? (appointment.data.pending || !appointment.data.report) : (!appointment.data.pending || !!appointment.data.report);
    //                     });
    //             }
    //         }

    //         setAppointmentsToList(dataSearched);
    //     }
    // }


    // const toggleFormFilterToSearch = () => {
    //     let wordsInReportDivRow: HTMLElement = wordsInReportDivRowRef.current;
    //     let referringPhysicianDivCol: HTMLElement = referringPhysicianDivColRef.current;

    //     if (typeReportsToSearchFilter == 2) {
    //         wordsInReportDivRow.classList.add("d-none");
    //         referringPhysicianDivCol.classList.add("d-none");

    //     } else {
    //         wordsInReportDivRow.classList.remove("d-none");
    //         referringPhysicianDivCol.classList.remove("d-none");
    //     }
    // }

    // const toggleColumnExaminer = () => {
    //     if (typeReportsToSearchFilter == 2) {
    //         setReportItemsTHead(reportItemsTHead.filter((item) => item !== t("Médico solicitante")));
    //     } else {
    //         const exists = reportItemsTHead.find((item) => item === t("Médico solicitante"));
    //         if (!exists) {
    //             setReportItemsTHead([
    //                 t("Id"),
    //                 t("Paciente"),
    //                 t("Gênero"),
    //                 t("Médico"),
    //                 t("Médico solicitante"),
    //                 t("Exame"),
    //                 t("Dispositivo"),
    //                 t("Opções")
    //             ])
    //         }
    //     }
    // }

    const resetFormFilter = () => {
        setTypeReportsToSearchFilter(1);
        setGenderFilter({ id: -1, name: t("Gênero") });
        setPatientName("");
        setPatientQuery("");
        setMedicoNome("");
        setMedicoQuery("");
        setExameQuery("");
        setExameNome("");
        setDateStringStartFilter("");
        setDateStartFilter(undefined);
    }


    // const onChangeRadioDayHandler = (radioDay: HTMLInputElement) => {

    //     if (radioDay.checked) {
    //         let inputEndDate: any = inputEndDateFilter.current;
    //         let inputStartDate: any = inputStartDateFilter.current;

    //         inputEndDate.parentElement.classList.add("d-none");

    //         if (inputStartDate.type === "month") {
    //             let dateComplete: string = "";

    //             if (inputStartDate.value !== "") {
    //                 dateComplete = dateUtil.toJSONLocal(new Date(inputStartDate.value + "-")).split("T")[0];
    //             }

    //             inputStartDate.value = dateComplete;
    //         }

    //         inputStartDate.type = "date";
    //     }

    //     setRadioDay(radioDay.checked);
    //     setRadioMonth(!radioDay.checked);
    //     setRadioPeriod(!radioDay.checked);
    // }



    // const onChangeRadioMonthHandler = (radioMonth: HTMLInputElement) => {

    //     if (radioMonth.checked) {
    //         let monthAndYear: string = "";
    //         let inputEndDate: any = inputEndDateFilter.current;
    //         let inputStartDate: any = inputStartDateFilter.current;

    //         if (inputStartDate.value !== "") {
    //             monthAndYear = inputStartDate.value.slice(0, 7);
    //         }

    //         inputEndDate.parentElement.classList.add("d-none");

    //         inputStartDate.type = "month";
    //         inputStartDate.value = monthAndYear;
    //         inputEndDate.value = "";
    //     }

    //     setRadioMonth(radioMonth.checked);
    //     setRadioDay(!radioMonth.checked);
    //     setRadioPeriod(!radioMonth.checked);
    // }

    // const onChangeRadioPeriodHandler = (radioPeriod: HTMLInputElement) => {
    //     if (radioPeriod.checked) {
    //         let inputEndDate: any = inputEndDateFilter.current;
    //         let inputStartDate: any = inputStartDateFilter.current;

    //         if (inputStartDate.type === "month") {
    //             let dateComplete: string = "";

    //             if (inputStartDate.value !== "") {
    //                 dateComplete = dateUtil.toJSONLocal(new Date(inputStartDate.value + "-")).split("T")[0];
    //             }

    //             inputStartDate.type = "date";
    //             inputStartDate.value = dateComplete;
    //         }

    //         inputEndDate.parentElement.classList.remove("d-none");

    //         inputStartDate.type = "date";
    //         inputEndDate.value = inputStartDate.value;
    //     }

    //     setRadioPeriod(radioPeriod.checked);
    //     setRadioMonth(!radioPeriod.checked);
    //     setRadioDay(!radioPeriod.checked);
    // }

    const onChangeTextPatient = (texto: string) => {
        setPatientName(texto);
    }

    const onChangeTextMedico = (texto: string) => {
        setMedicoNome(texto);
    }

    const onChangeTextExame = (texto: string) => {
        setExameNome(texto);
    }

    const onChangeDataAtendimento = (texto: string) => {
        setDateStringStartFilter(texto);
        
        const [ano, mes, dia] = texto.split('-');
        setDateStartFilter(new Date(Number(ano), Number(mes) - 1, Number(dia), 0, 0, 0, 0))
    }

    const onClickCreateReport = async (route: string, atendimento: AtendimentoInterface = (null as any)) => {
        let laudo: Array<LaudoInterface> | null = null;
        if (atendimento.dataFimAtendimento) {
            const laudosList = await new LaudoService().buscaPorAtendimentoId(atendimento.idAtendimento);
            const ultimo = laudosList.length;
            laudo = laudosList[ultimo -1];
        }

        history.push(route, { atendimento, laudo }); 
    }

    const traduzirGenero = (genero: string) => {
        if (genero === 'Male') return t('Masculino');
        if (genero === 'Female') return t('Feminino');
        if (genero === 'Other') return t('Outros')
        return genero;
    }

    const getOriginalGender = (gender: string) => {
        const genderMappings: GenderMappings= {
            'masculino': 'Male',
            'femenino': 'Female',
            'feminino': 'Female',
            'male': 'Male',
            'female': 'Female',
        };
    
        return genderMappings[gender.toLowerCase()] || gender;
    };
  

    return (
        <PageContainer>
            <div className="container-fluid p-0 position-relative">
                <div className="card m-0 rounded-0 border-0" style={{width: "100%" }} >
                    <div className="card-header p-2 bg-white border-0">
                        <div className="nav nav-pills card-header-tabs card-header-pills m-0" role="tablist" style={{ fontSize: "1.0em" }}>
                            <a ref={listReportNavRef} className="nav-item nav-link active text-capitalize d-none" data-toggle="tab" href="#reportDivListId" role="tab" aria-controls="reportDivListId" style={{paddingTop: "5px", height: "31px", fontSize: "14px"}} aria-selected="false">{t("Lista de laudos")}</a>
                            {/* <a ref={addReportNavRef} className="nav-item nav-link" data-toggle="tab" href="#reportDivReportAddId" role="tab" aria-controls="reportDivReportAddId" aria-selected="true" onClick={() => { onClickCreateReport("/app/report")} }> {t("Criar laudo")} </a> */}
                            <button type="button" className="btn btn-success text-white ml-3 pt-1" style={{height: "31px", fontSize: "14px"}} onClick={() => { getAppointments() } }> <i className="bi bi-arrow-repeat mr-2" aria-hidden="true" accessKey="r" />{t("Atualizar")}</button>
                        </div>
                    </div>

                    <div className="tab-content">
                        <div className="tab-pane active" id="reportDivListId" role="tabpanel" arial-labelledby="nav-report-tab">
                            <div className="card-body">
                            {/* <div className="custom-control custom-switch mb-4">
                            <input type="checkbox" className="custom-control-input" id="laudosPendentes" onClick={(event) => { setTypeReportsToSearchFilter(3); setTypeReportsToSearchFilter(2)} } />
                            <label className="custom-control-label text-uppercase" htmlFor="laudosPendentes">Laudos Retificar</label>
                            </div> */}
                                <form action="#">
                                    {/* <div className="form-group row mb-2 d-none" ref={wordsInReportDivRowRef}>
                                        <div className="input-group col input-group-append">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-file-alt" aria-hidden="true" />
                                                </span>
                                            </div>

                                            <input type="text" className="form-control" placeholder={t("Procurar por alguma palavra no laudo")} maxLength={100} value={wordsInReportFilter} onChange={(event) => setWordInReportFilter(event.target.value)} />

                                            <SpeechRecognitionButton
                                                setReconizedText={setWordInReportFilter}
                                            />

                                        </div>
                                    </div> */}

                                    <div className="form-group row">

                                        <div className="input-group col">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-id-badge" aria-hidden="true" />
                                                </span>
                                            </div>

                                            <input value={patientName} placeholder={t("Digite o nome do paciente")} onChange={(event) => onChangeTextPatient(event.target.value)} type="text" autoFocus className="form-control" title={t("Paciente")} />
                                        </div>

                                        <div className="input-group col">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-venus-mars" aria-hidden="true" />
                                                </span>
                                            </div>

                                            <select className="form-control custom-select" value={genderFilter.id} defaultValue={-1} onChange={(event) => setGenderFilter({ id: parseInt(event.target.value), name: event.target[event.target.selectedIndex].label })}>
                                                <option value={-1} label={t("Gênero")} />
                                                {
                                                    genderToList.length && genderToList
                                                        .map((gender: any, index: number) => (
                                                            <option value={`${index}`} label={traduzirGenero(gender)} key={index} />
                                                        ))
                                                }
                                            </select>
                                        </div>

                                        <div className="input-group col">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-user-md" aria-hidden="true" />
                                                </span>
                                            </div>
                                            <input value={medicoNome} placeholder={t("Digite o nome do médico examinador")} onChange={(event) => onChangeTextMedico(event.target.value)} type="text" className="form-control" title={t("Médico Examinador")} />
                                        </div>

                                        <div className="input-group col d-none" ref={referringPhysicianDivColRef}>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-user-md" aria-hidden="true" />
                                                </span>
                                            </div>

                                            <button type="button" className="form-control btn btn-light border text-left bg-white" title={t("Médico solicitante")} data-toggle="modal" data-target="#modalViewSelectReportListReferringPhysicianId" value={`${referringPhysicianFilter.id}`}>{joinNames(referringPhysicianFilter as any)}</button>
                                        </div>
                                    </div>

                                    <div className="form-group row">

                                        <div className="input-group col">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-calendar-alt" aria-hidden="true" />
                                                </span>
                                            </div>

                                            <input ref={inputStartDateFilter} value={dateStringStartFilter} onChange={(event) => onChangeDataAtendimento(event.target.value)} type="date" min="1600-01-01" max="9999-12-31" className="form-control" title={t("Data início")} />
                                        </div>

                                        {/* <div className="input-group col d-none">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-calendar-alt" aria-hidden="true" />
                                                </span>
                                            </div>

                                            <input ref={inputEndDateFilter} value={dateEndFilter} onChange={(event) => setDateEndFilter(event.target.value)} type="date" min="1600-01-01" max="9999-12-31" className="form-control" title={t("Data final")} />
                                        </div> */}

                                        <div className="input-group col-4">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-file-alt" aria-hidden="true" />
                                                </span>
                                            </div>

                                            <input value={exameNome} placeholder={t("Digite o nome do exame")} onChange={(event) => onChangeTextExame(event.target.value)} type="text" className="form-control" title={t("Exame")} />
                                        </div>

                                        <div className="input-group col-4">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-file" aria-hidden="true" />
                                                </span>
                                            </div>

                                            <select id="reportStatusSelect" className="form-control custom-select" value={typeReportsToSearchFilter} defaultValue={1} onChange={(event) => setTypeReportsToSearchFilter(parseInt(event.target.value))}>
                                                <option value={0} label={t("Selecionar todos")} accessKey="t"/>
                                                <option value={1} label={t("Pendentes")} />
                                                <option value={2} label={t("Realizados")} />
                                            </select>
                                        </div>

                                        {/* <div className="input-group col">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-adjust" aria-hidden="true" />
                                                </span>
                                            </div>

                                            <select className="form-control custom-select" value={`${healthPlanBrokerFilter.id}`} defaultValue={-1} onChange={(event) => setHealthPlanBrokerFilter({ id: parseInt(event.target.value), name: event.target[event.target.selectedIndex].label })}>
                                                <option value={-1} label={t("Convênio")} disabled />
                                                {
                                                    convenios.length && convenios
                                                        .map((convenio, index: number) => (
                                                            <option value={`${convenio.idConvenio}`} label={convenio.nome} />
                                                        ))
                                                }
                                            </select>
                                        </div> */}

                                        {/* <div className="input-group col">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-microchip" aria-hidden="true" />
                                                </span>
                                            </div>

                                            <select className="form-control custom-select" value={`${deviceFilter.id}`} defaultValue={-1} onChange={(event) => setDeviceFilter({ id: parseInt(event.target.value), name: event.target[event.target.selectedIndex].label })}>
                                                <option value={-1} label={t("Dispositivo")} disabled />
                                                {
                                                    deviceToList.length && deviceToList
                                                        .map((device: DeviceInterface, index: number) => (
                                                            <option value={`${device.id}`} label={`${device.manufacturer} ${device.model}`} key={index} />
                                                        ))
                                                }
                                            </select>
                                        </div> */}
                                    </div>

                                    <div className="form-row">
                                        {/* <div className="col-9">
                                            <div className="form-check form-check-inline">
                                                <input type="radio" id="day" name="reportListSelectDateSearch" className="form-check-input" checked={radioDay} onChange={(event) => { onChangeRadioDayHandler(event.target)} } />
                                                <label className="form-check-label"> {t("Dia")}</label>
                                            </div>

                                            <div className="form-check form-check-inline">
                                                <input type="radio" name="reportListSelectDateSearch" className="form-check-input" checked={radioMonth} onChange={(event) => { onChangeRadioMonthHandler(event.target)} } />
                                                <label className="form-check-label"> {t("Mês")}</label>
                                            </div>

                                            <div className="form-check form-check-inline">
                                                <input type="radio" name="reportListSelectDateSearch" className="form-check-input" checked={radioPeriod} onChange={(event) => { onChangeRadioPeriodHandler(event.target)} } />
                                                <label className="form-check-label"> {t("Período")}</label>
                                            </div>
                                        </div> */}

                                        <div className="col-12">
                                            <div className="row justify-content-md-end mb-3 mr-1">
                                                <button type="button" className="btn btn-outline-secondary ml-3" onClick={(event) => { resetFormFilter() } }>
                                                    <i className="fa fa-times mr-2" aria-hidden="true" />
                                                    {t("Redefinir")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {/* <div className="custom-control custom-switch h5">
                                        <input type="checkbox" className="custom-control-input" id="reportStatusSelect"  value={typeReportsToSearchFilter} defaultValue={1} onChange={(event) => setTypeReportsToSearchFilter(parseInt(event.target.value))}/>
                                        <label className="custom-control-label" htmlFor="reportStatusSelect" >Pendentes</label>
                                </div> */}
                                <Table itemsThead={reportItemsTHead}>
                                    {
                                        (!appointments.length) ?
                                            (
                                                <tr>
                                                    <td className="text-center" colSpan={8}>
                                                        <p className="text-center text-muted display-4 py-5"> {t("Nenhum laudo pendente")} </p>
                                                    </td>
                                                </tr>
                                            ) :
                                            (
                                                appointments
                                                    // .slice(offSetAppointment, offSetAppointment + incrementOffSet)
                                                    .map((appointment: AtendimentoInterface, index: number) => (
                                                        <tr key={`${appointment.idAtendimento}`} id={appointment.idAtendimento.toString()}>
                                                            <td>{appointment.idAtendimento}</td>
                                                            <td>{appointment.paciente ? `${appointment.paciente.primeiroNome} ${appointment.paciente.sobrenome} ${appointment.paciente.ultimoNome}` : ""}</td>
                                                            <td>{appointment.paciente.genero ? traduzirGenero(appointment.paciente.genero) : ""}</td>
                                                            <td>{joinNames(appointment.medico)}</td>
                                                            <td>{appointment.dataInicioAtendimento ? `${new Date(appointment.dataInicioAtendimento as any).toLocaleString(LanguageUtils.getLanguage())}` : ""}</td>
                                                            {/* {(typeReportsToSearchFilter != 2) && */}
                                                            {/* <td>{!!appointment.data.report ? joinNames(appointment.data.report.requester) : ""}</td>} */}
                                                            <td>{appointment.exame ? appointment.exame.nome : ""}</td>
                                                            {/* <td>{!!appointment.data.patient && !!appointment.data.patient.images && appointment.data.patient.images.length ? `${appointment.data.patient.images[0].cameraManufacturer || ""} ${appointment.data.patient.images[0].cameraModel || ""}` : ""}</td> */}
                                                            <td className="m-0 p-0 wm-25">
                                                                <div className="row p-0 m-0">
                                                                    <div className="col">
                                                                        <button
                                                                            type="button"
                                                                            title={!appointment.dataFimAtendimento ? t("Laudar") : t("Retificar")}
                                                                            className="btn btn-sm btn-outline-light text-dark text-uppercase border-0 w-100"
                                                                            onClick={(event) => {
                                                                                showLoading(dispatch); 
                                                                                onClickCreateReport("/app/report", appointment)}
                                                                            }
                                                                        >
                                                                        
                                                                            <i className={`bi ${!appointment.dataFimAtendimento ? "bi-file-text text-info" : "bi-pencil-square text-warning"} mr-2`} aria-hidden="true"></i>
                                                                            <span className="d-none d-xl-inline-flex">{!appointment.dataFimAtendimento ? t("Laudar") : t("Retificar")}</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                            )
                                    }
                                </Table>

                                <FooterButtonsList
                                    enablePreviousButton={enablePreviousButtonAppointment}
                                    enableForwardButton={enableForwardButtonAppointment}
                                    onClickSearchPrevious={() => searchUtil.searchPrevious(offSetAppointment, incrementOffSet, setOffSetAppointment, setEnablePreviousButtonAppointment)}
                                    onClickSearchForward={() => searchUtil.searchForward(offSetAppointment, incrementOffSet, appointmentsToList.length, setOffSetAppointment, setEnableForwardButtonAppointment)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <ViewSelect
                idModal="modalViewSelectPatientReportListId"
                idTitleLabelModal="modalViewSelectPatientReportListLabelId"
                titleModalElement={t("Selecione o paciente")}
                idButtonCloseModal="modalViewSelectPatientReportListCloselId"
                onClickButtonClose={() => { setStopListening(true, dispatch) }}

                itemsThead={ViewSelectPatientItemsTHead}

                onClickButtonSearch={() => { }}
                onChangeTextToSearch={() => { }}
                onChangeFilterToSearch={() => { }}
                enablePreviousButton={enablePreviousButtonPatient}
                enableForwardButton={enableForwardButtonPatient}
                onClickSearchPrevious={() => searchUtil.searchPrevious(offSetViewSelectPatient, incrementOffSet, setOffSetViewSelectPatient, setEnablePreviousButtonPatient)}
                onClickSearchForward={() => searchUtil.searchForward(offSetViewSelectPatient, incrementOffSet, pacientes.length, setOffSetViewSelectPatient, setEnableForwardButtonPatient)}
            >
                {
                    !!pacientes.length && pacientes
                        .map((paciente, index: number) => (
                            <tr key={`${paciente.idPaciente}_${index}`} id={`patient_${paciente.idPaciente.toString()}`} onDoubleClick={(event) => {
                                (document.querySelector("#modalViewSelectPatientReportListCloselId") as HTMLButtonElement).click();

                                setPatientFilter(paciente);
                                setStopListening(true, dispatch);
                            }}>
                                <td>{paciente.idPaciente}</td>
                                <td>{`${paciente.primeiroNome} ${paciente.ultimoNome}`}</td>
                                <td>{paciente.tipoDocumento} - {paciente.documento}</td>
                                <td>{paciente.dataNascimento ? `${new Date(paciente.dataNascimento).toLocaleDateString("pt-BR")}` : ""}</td>
                                <td>{paciente.genero ? `${paciente.genero}` : ""}</td>
                            </tr>
                        ))
                }
            </ViewSelect> */}

            {/* <ViewSelect
                idModal="modalViewSelectReportListPhysicianId"
                idTitleLabelModal="modalViewSelectReportListPhysicianLabelId"
                titleModalElement={t("Selecione o médico")}
                idButtonCloseModal="modalViewSelectReportListPhysicianCloselId"
                onClickButtonClose={() => { setStopListening(true, dispatch) }}

                itemsThead={ViewSelectPhysicianItemsTHead}

                onClickButtonSearch={() => { }}
                onChangeTextToSearch={() => { }}
                onChangeFilterToSearch={() => { }}
                enablePreviousButton={enablePreviousButtonPhysician}
                enableForwardButton={enableForwardButtonPhysician}
                onClickSearchPrevious={() => searchUtil.searchPrevious(offSetViewSelectPhysician, incrementOffSet, setOffSetViewSelectPhysician, setEnablePreviousButtonPhysician)}
                onClickSearchForward={() => searchUtil.searchForward(offSetViewSelectPhysician, incrementOffSet, physiciansToList.length, setOffSetViewSelectPhysician, setEnableForwardButtonPhysician)}
            >
                {
                    !!profissionaisSaude.length && profissionaisSaude
                        .map((profissionalSaude, index: number) => (
                            <tr key={`${profissionalSaude.idProfissionalSaude}_${index}`} id={`physician_${profissionalSaude.idProfissionalSaude}`} onDoubleClick={(event) => {
                                (document.querySelector("#modalViewSelectReportListPhysicianCloselId") as HTMLButtonElement).click();


                                setPhysicianFilter(profissionalSaude);
                                setStopListening(true, dispatch);
                            }}>
                                <td>{profissionalSaude.idProfissionalSaude}</td>
                                <td>{`${profissionalSaude.primeiroNome} ${profissionalSaude.ultimoNome}`}</td>
                                <td>{profissionalSaude.tipoDocumento} - {profissionalSaude.documento}</td>
                                <td>{profissionalSaude.ocupacao}</td>
                                <td>{profissionalSaude.contato ? `${profissionalSaude.contato.email1}` : ""}</td>
                            </tr>
                        ))
                }
            </ViewSelect> */}


            {/* <ViewSelect
                idModal="modalViewSelectReportListReferringPhysicianId"
                idTitleLabelModal="modalViewSelectReportListReferringPhysicianLabelId"
                titleModalElement={t("Selecione o médico solicitante")}
                idButtonCloseModal="modalViewSelectReportListReferringPhysicianCloselId"
                onClickButtonClose={() => { setStopListening(true, dispatch) }}

                itemsThead={ViewSelectReferringPhysicianItemsTHead}

                onClickButtonSearch={() => { }}
                onChangeTextToSearch={() => { }}
                onChangeFilterToSearch={() => { }}
                enablePreviousButton={enablePreviousButtonReferringPhysician}
                enableForwardButton={enableForwardButtonReferringPhysician}
                onClickSearchPrevious={() => searchUtil.searchPrevious(offSetViewSelectReferringPhysician, incrementOffSet, setOffSetViewSelectReferringPhysician, setEnablePreviousButtonReferringPhysician)}
                onClickSearchForward={() => searchUtil.searchForward(offSetViewSelectReferringPhysician, incrementOffSet, referringPhysiciansToList.length, setOffSetViewSelectReferringPhysician, setEnableForwardButtonReferringPhysician)}
            >
                {
                    !!referringPhysiciansToList.length && referringPhysiciansToList
                        .slice(offSetViewSelectReferringPhysician, offSetViewSelectReferringPhysician + incrementOffSet)
                        .map((referringPhysician: any, index: number) => (!!referringPhysician.id && (!!referringPhysician.firstName || !!referringPhysician.middleName || !!referringPhysician.lastName) ?
                            <tr key={`${referringPhysician.id}_${index}`} id={referringPhysician.id} onDoubleClick={(event) => {
                                (document.querySelector("#modalViewSelectReportListReferringPhysicianCloselId") as HTMLButtonElement).click();

                                setReferringPhysicianFilter(referringPhysician);
                                setStopListening(true, dispatch);
                            }}>
                                <td>{referringPhysician.id}</td>
                                <td>{`${joinNames(referringPhysician)}`}</td>
                                <td>{!!referringPhysician.documents && referringPhysician.documents.length ? `${referringPhysician.documents[0].type.initials} - ${referringPhysician.documents[0].number}` : ""}</td>
                                <td>{!!referringPhysician.occupations ? `${referringPhysician.occupations.description}` : ""}</td>
                                <td>{!!referringPhysician.contacts ? `${referringPhysician.contacts.email1}` : ""}</td>
                            </tr>

                            : null
                        ))
                }
            </ViewSelect> */}

            {/* <ViewSelect
                idModal="modalViewSelectReportListExamId"
                idTitleLabelModal="modalViewSelectReportListExamLabelId"
                titleModalElement={t("Selecione o exame")}
                idButtonCloseModal="modalViewSelectReportListExamCloselId"
                onClickButtonClose={() => { setStopListening(true, dispatch) }}

                itemsThead={ViewSelectExamItemsTHead}

                onClickButtonSearch={() => { }}
                onChangeTextToSearch={() => { }}
                onChangeFilterToSearch={() => { }}
                enablePreviousButton={enablePreviousButtonExam}
                enableForwardButton={enableForwardButtonExam}
                onClickSearchPrevious={() => searchUtil.searchPrevious(offSetViewSelectExam, incrementOffSet, setOffSetViewSelectExam, setEnablePreviousButtonExam)}
                onClickSearchForward={() => searchUtil.searchForward(offSetViewSelectExam, incrementOffSet, examsToList.length, setOffSetViewSelectExam, setEnableForwardButtonExam)}
            >
                {exames.length && exames
                    .map((exame, index: number) => (
                        <tr key={`${exame.idExame}_${index}`} id={`exam_${exame.idExame}`} onDoubleClick={(event) => {
                            (document.querySelector("#modalViewSelectReportListExamCloselId") as HTMLButtonElement).click();


                            setExamFilter(exame);
                            setStopListening(true, dispatch);
                        }}>
                            <td>{exame.idExame}</td>
                            <td>{exame.nome}</td>
                            <td>{exame.titulo}</td>
                        </tr>
                    ))
                }
            </ViewSelect> */}
        </PageContainer >
    );
};

export default ReportList;