import { ActionInterface } from "../actions/interface/ActionInterface"
import { PhysicianTypes } from "../actions/types/ActionsTypes";
import { PhysicianState } from "../datatypesInterfaces/dataTypeInterfaces";

const INITIAL_STATE: PhysicianState = {
    physiciansToList: [],
}

const Physician = (state = INITIAL_STATE, action: ActionInterface) => {
    switch (action.type) {
        case PhysicianTypes.PHYSICIAN_TO_LIST:
            return {
                ...state,
                physiciansToList: action.payload
            }
        default:
            return state;
    }
}

export { Physician };