import { showLoading, hideLoading } from "../../store/actions/loading";
import { useDispatch } from "react-redux";


export class SearchUtil {
    private dispatch: any;
    
    constructor() {
        this.dispatch = useDispatch();
    }


    public searchPrevious(offSet: number, incrementOffset: number, setOffSet: Function, setEnablePreviousButton: Function) {
        showLoading(this.dispatch);

        if (this.checkPreviousButton(offSet, incrementOffset, setEnablePreviousButton)) {
            setOffSet(offSet - incrementOffset);
        }

        hideLoading(this.dispatch);
    }

    public searchForward(offSet: number, incrementOffset: number, totalElements: number, setOffSet: Function, setEnableForwardButton: Function) {
        showLoading(this.dispatch);

        if (!this.checkForwardButton(offSet, incrementOffset, totalElements, setEnableForwardButton)) {
            setOffSet(offSet + incrementOffset);
        }

        hideLoading(this.dispatch);
    }


    public checkPreviousButton(offSet: number, incrementOffset: number, setEnablePreviousButton: Function) {
        if ((offSet - incrementOffset) >= 0) {
            setEnablePreviousButton(true);

            return true;
        } else {
            setEnablePreviousButton(false);

            return false;
        }
    }

    public checkForwardButton(offSet: number, incrementOffset: number, totalElements: number, setEnableForwardButton: Function) {
        if ((offSet + incrementOffset) >= totalElements) {
            setEnableForwardButton(false);

            return true;

        } else {
            setEnableForwardButton(true);

            return false;
        }
    }

}