import { ActionInterface } from "../actions/interface/ActionInterface"
import { TwakToTypes } from "../actions/types/ActionsTypes";
import { TawkToState } from "../datatypesInterfaces/dataTypeInterfaces";

const INITIAL_STATE: TawkToState = {
    showChat: false,
}

const TawkTo = (state = INITIAL_STATE, action: ActionInterface) => {
    switch (action.type) {
        case TwakToTypes.SET_SHOW_CHAT:
            return {
                ...state,
                showChat: action.payload
            }

        default:
            return state;
    }
}

export { TawkTo };
