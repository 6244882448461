import React, { useEffect } from 'react';


// Tools/utils
import { fabric } from 'fabric';
import { useSelector, useDispatch } from 'react-redux';


// Interfaces
import { ApplicationState } from '../../store';


// States/Redux
import { setFabricCanvas, setRemoveFabricActiveElement } from '../../store/actions/imageEditor';


const ImageEditorCanvas = () => {
    const dispatch = useDispatch();

    const { imageURL, fabricEnable } = useSelector((state: ApplicationState) => state.imageEditor);
    const divImageExibition = document.querySelector("#divImageExibition") as HTMLDivElement;

    useEffect(() => {
        setFabricCanvas(new fabric.Canvas("canvasImageEditorId", {
            backgroundColor: "#222",
            renderOnAddRemove: true
        }), dispatch);

        document.addEventListener("keyup", onKeyDivCanvasUpHandler)
    }, []);


    const onKeyDivCanvasUpHandler = (event: KeyboardEvent) => {
        if (!!fabricEnable && event.keyCode === 46) {
            setRemoveFabricActiveElement(true, dispatch);
        }
    }



    return (
        <div>
            <canvas
                id="canvasImageEditorId"
                className="position-absolute w-100 h-100 border border-info"
                width={divImageExibition.offsetWidth || 600}
                height={divImageExibition.offsetHeight || 600}
            >
                <img src={imageURL} />
            </canvas>
        </div>
    )
}

export { ImageEditorCanvas };