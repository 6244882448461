import { LoadingTypes } from "./types/ActionsTypes"

export const showLoading = (dispatch: Function, message = "") => {
    dispatch({
        type: LoadingTypes.SHOW_LOADING,
        payload: {
            message
        }
    })
}

export const hideLoading = (dispatch: Function) => {
    dispatch({
        type: LoadingTypes.HIDE_LOADING
    })
}

export const toggleLoading = (dispatch: Function) => {
    dispatch({
        type: LoadingTypes.HIDE_LOADING
    })
}

export const showLoadingMessage = (dispatch: Function) => {
    dispatch({
        type: LoadingTypes.SHOW_LOADING_MESSAGE
    })
}

export const hideLoadingMessage = (dispatch: Function) => {
    dispatch({
        type: LoadingTypes.HIDE_LOADING_MESSAGE
    })
}