export class NumberUtils {
    constructor() {
    }

    public static getRandomNumber(min: number, max: number) {
        if (max < min) {
            throw "The maximum number cannot be less than the minimum number";
        }

        return Math.floor(Math.random() * (max - min)) + min;
    }
}