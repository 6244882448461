import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { useHistory } from 'react-router';
import { PatientInterface } from "../../interfaces/patientInterface";
import PageContainer from '../elements/page-container-element';

import './patient.css';

import { PatientInfo } from '../components/patientView-pane-info-component';
import { PatientImages } from '../components/patientView-pane-images-component';
import { PatientLaudos } from '../components/patientView-pane-reports-component';
import Loading from '../elements/loading-element';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setHeaderTitle } from '../../store/actions/headerBar';

interface PropsInterface {
    location: any | {
        state: PatientInterface
    };
}


const PatientView = (props: PropsInterface) => {
    const history = useHistory();
    const { t } = useTranslation();

    const dispatch = useDispatch();
    setHeaderTitle(t("Paciente"), dispatch);
    
    const patient: PatientInterface = props.location.state;
    const uid = patient && patient.uid;
    
    if (!patient || !uid) {
        history.goBack();
    }
    
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [modalImages, setModalImages] = useState([] as Array<any>);

    return (
        <PageContainer>
            {(!patient) ?
                <p>{t("Paciente não encontrado")}</p> :
                <div className="card shadow">
                    <div className="card-header p-0 m-0 border-bottom-0">
                        <button className="btn btn-sm btn-secondary ml-2 mb-2 mt-2" onClick={() => history.goBack()}>
                            <i className="fa fa-arrow-left"></i> {t("Voltar")}
                        </button>
                        <p className="ml-3 mb-2 py-0 font-weight-bold">
                            {patient.firstName + " " + patient.middleName + " " + patient.lastName}
                        </p>
                        <div className="nav nav-tabs pl-1 pt-1" role="tablist">
                            <a className="nav-item nav-link p-2 small text-dark active" data-toggle="tab" href="#nav-information" aria-controls="nav-information" aria-selected="true" title="Informação">{t("Informação")}</a>
                            <a className="nav-item nav-link p-2 small text-dark" data-toggle="tab" href="#nav-images" aria-controls="nav-images" aria-selected="false" title="Imagens">{t("Imagens")}</a>
                            <a className="nav-item nav-link p-2 small text-dark" data-toggle="tab" href="#nav-laudos" aria-controls="nav-laudos" aria-selected="false" title="Laudos">{t("Laudos")}</a>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="tab-content">
                            <PatientInfo patient={patient} />
                            <PatientImages uid={uid ? uid : ''} serialPath={patient.serialPath} setIsOpen={setIsOpen} setModalImages={setModalImages} setPhotoIndex={setPhotoIndex} />
                            <PatientLaudos uid={uid ? uid : ''} serialPath={patient.serialPath} />
                        </div>
                    </div>

                    {/* MODAL IMAGE */}
                    <div className="modal " id="imageViewModal" tabIndex={-1} role="dialog" aria-labelledby="imageViewModalLabel" aria-hidden="true">
                        <div className="modal-dialog w-75" role="document" style={{ margin: '50px auto', maxWidth: '100%' }}>
                            <div className="modal-content">
                                <div className="modal-header p-2 ml-2 mr-2 m-2 border-0">
                                    <h5 className="modal-title" id="imageViewModalLabel">{t("Visualizador de imagem")}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="closeImageViewModal">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modalImage">
                                    {isOpen && (
                                        <Lightbox
                                            mainSrc={modalImages[photoIndex]}
                                            nextSrc={modalImages[(photoIndex + 1) % modalImages.length]}
                                            prevSrc={modalImages[(photoIndex + modalImages.length - 1) % modalImages.length]}
                                            onCloseRequest={() => setIsOpen(false)}
                                            onMovePrevRequest={() =>
                                                setPhotoIndex((photoIndex + modalImages.length - 1) % modalImages.length)
                                            }
                                            onMoveNextRequest={() =>
                                                setPhotoIndex((photoIndex + 1) % modalImages.length)
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* MODAL IMAGE END */}
                </div>
            }
        <Loading />
        </PageContainer>
    );
};

export default PatientView;
