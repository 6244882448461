import { baseURL } from '../config/api-config';
import { HttpClient } from './httpClient';
import { SMSInterface } from '../interfaces/new/smsInterface';

export class SMSService {
    private httpClient: HttpClient;
    constructor() {
        this.httpClient = new HttpClient();
    }

    async enviaSMS(dados: SMSInterface) {
        return await this.httpClient.post({ url: '/sms/laudo', baseURL: baseURL, data: dados });
    }
}