import React, { useEffect, useState, useCallback } from "react";


// tool/utils
import Swal from "sweetalert2";
import { fabric } from "fabric";
import { useTranslation } from "react-i18next";
import { NumberUtils } from "../../utils/numberUtils";
import { useSelector, useDispatch } from "react-redux";


//Interfaces 
import { ApplicationState } from "../../store";
import { FabricImageInterface, HistoryEditorState } from "../../store/datatypesInterfaces/dataTypeInterfaces";


// States/Redux
import {
    showCropping,
    cleanHistory,
    hideFabric,
    pushUndoHistory,
    enableFabricEdition,
    setCurrentFabricImage,
    setTextEditing,
    hideModalApplyEditText,
    setIsSaved,
    setImageUrl,
    setUndoFabric,
    popUndoHistory,
    popRedoHistory,
    pushRedoHistory,
    setRedoFabric,
    setRemoveFabricActiveElement,
    hideModalDrawArrow,
    showModalDrawArrow,
    showModalBrush,
    hideModalBrush,
    setUndoHistory,
} from "../../store/actions/imageEditor";


const ImageEditorFabric = () => {
    const {
        undo,
        redo,
        imageURL,
        cropper,
        isSaved,
        fabricEnable,
        fabricCanvas,
        currentFabricImage,
        textOptions,
        richTextCurrentColor,
        arrowOptions,
        resetFabricEditing,
        undoFabric,
        redoFabric,
        textEditing,
        brushOptions,
        removeFabricActiveElement,
    } = useSelector((state: ApplicationState) => state.imageEditor);


    const dispatch = useDispatch();
    const { t } = useTranslation();


    const [drawingArrowActive, setDrawingArrowActive] = useState(false);
    const [startXDrawArrow, setStartXDrawArrow] = useState<number>(null as any);
    const [startYDrawArrow, setStartYDrawArrow] = useState<number>(null as any);
    const [arrowEditing, setArrowEditing] = useState<fabric.Polyline>(null as any);
    const [firstPointArrow, setFirstPointArrow] = useState<fabric.Circle>(null as any);
    const [onCanvasEvent, setOnCanvasEvent] = useState<{ type: string, objEvent: any }>(null as any);


    useEffect(() => {
        if (!undo.length) {
            setIsSaved(true, dispatch);

        } else {
            if (isSaved) {
                setIsSaved(false, dispatch);
            }
        }

    }, [undo]);


    useEffect(() => {
        if (!!onCanvasEvent) {
            verifyTypeCanvasEvent()
        }

    }, [onCanvasEvent]);


    useEffect(() => {
        if (!!fabricCanvas) {
            initFabricEvents();
        }
    }, [fabricCanvas]);


    useEffect(() => {
        if (resetFabricEditing) {
            resetFabricImage()
        }

    }, [resetFabricEditing]);


    useEffect(() => {
        if (undoFabric && undo.length) {
            const elementUndo: HistoryEditorState = undo[undo.length - 1];

            popUndoHistory(dispatch);

            undoFabricElement(elementUndo);
            pushRedoHistory(elementUndo, dispatch);
        }

    }, [undoFabric]);


    useEffect(() => {
        if (redoFabric && redo.length) {
            const elementRedo: HistoryEditorState = redo[redo.length - 1];

            popRedoHistory(dispatch);

            redoFabricElement(elementRedo);
            pushUndoHistory(elementRedo, dispatch);
        }

    }, [redoFabric]);


    useEffect(() => {
        if (removeFabricActiveElement) {
            removeActiveElement();
        }
    }, [removeFabricActiveElement]);


    useEffect(() => {
        if (!!arrowEditing) {
            showModalDrawArrow(dispatch);
        }

    }, [arrowEditing]);


    useEffect(() => {
        if (!!fabricCanvas) {
            fabricCanvas.freeDrawingBrush.width = brushOptions.width;
            fabricCanvas.freeDrawingBrush.color = brushOptions.color;
        }

    }, [brushOptions]);


    const initFabricEvents = () => {
        fabric.textureSize = 8192;

        fabric.Image.fromURL(imageURL, (img: FabricImageInterface | any) => {
            setCurrentFabricImage(img, dispatch);

            if (fabricCanvas.height < fabricCanvas.width) {
                img.scaleToHeight(fabricCanvas.height);

            } else {
                img.scaleToWidth(fabricCanvas.width);
            }

            img.selectable = false;

            fabricCanvas.add(img);

            img.center();

            fabricCanvas.renderAll();
        }, { crossOrigin: "*" });

        fabricCanvas.on({ "mouse:up": (event) => setOnCanvasEvent({ type: "mouse:up", objEvent: event }) });
        fabricCanvas.on({ "mouse:down": (event) => setOnCanvasEvent({ type: "mouse:down", objEvent: event }) });
        fabricCanvas.on({ "mouse:move": (event) => setOnCanvasEvent({ type: "mouse:move", objEvent: event }) });
        fabricCanvas.on({ "selection:cleared": (event) => setOnCanvasEvent({ type: "selection:cleared", objEvent: event }) });
        fabricCanvas.on({ "selection:created": (event) => setOnCanvasEvent({ type: "selection:created", objEvent: event }) });
        fabricCanvas.on({ "selection:updated": (event) => setOnCanvasEvent({ type: "selection:updated", objEvent: event }) });
    }

    const verifyTypeCanvasEvent = (): void => {
        if (onCanvasEvent.type == "mouse:down") {
            onCanvasMouseDown(onCanvasEvent.objEvent);

        } else {
            if (onCanvasEvent.type == "mouse:move") {
                onCanvasMouseMove(onCanvasEvent.objEvent);

            } else {
                if (onCanvasEvent.type == "mouse:up") {
                    onCanvasMouseUp(onCanvasEvent.objEvent);

                } else {
                    if (onCanvasEvent.type == "object:added") {
                        onCanvasObjectAdd(onCanvasEvent.objEvent);

                    } else {
                        if (onCanvasEvent.type == "selection:created") {
                            onCanvasCreateSelection(onCanvasEvent.objEvent);

                        } else {
                            if (onCanvasEvent.type == "selection:updated") {
                                onCanvasUpdateSelection(onCanvasEvent.objEvent);

                            } else {
                                if (onCanvasEvent.type == "selection:cleared") {
                                    stopTextEditing();
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    const onCanvasCreateSelection = (event: { target: any }): void => {
        checkTypeFabricSelection(event);
    }

    const onCanvasUpdateSelection = (event: { target: any }): void => {
        checkTypeFabricSelection(event);
    }

    const onCanvasMouseDown = (event: any) => {
        if (drawingArrowActive && !startXDrawArrow && !startYDrawArrow) {
            let pointer = fabricCanvas.getPointer(event.e);

            setStartXDrawArrow(pointer.x);
            setStartYDrawArrow(pointer.y);

            const firstPointArrow = new fabric.Circle({
                fill: "yellow",
                radius: 5,
                left: pointer.x,
                top: pointer.y,
            });

            fabricCanvas.add(firstPointArrow);
            fabricCanvas.renderAll();

            setFirstPointArrow(firstPointArrow);
        }
    }

    const onCanvasMouseUp = (event: any): void => {
        if (drawingArrowActive && startXDrawArrow && startYDrawArrow) {
            cleanDrawArrow();
        }
    }

    const onCanvasMouseMove = (event: any): void => {
        if (drawingArrowActive && startXDrawArrow && startYDrawArrow) {

            let pointer = fabricCanvas.getPointer(event.e);
            let endXDrawArrow = pointer.x;
            let endYDrawArrow = pointer.y;

            if (arrowEditing) {
                fabricCanvas.remove(arrowEditing);
            }

            drawArrow(startXDrawArrow, startYDrawArrow, endXDrawArrow, endYDrawArrow);
        }
    }

    const onCanvasObjectAdd = (objAdd: any): void => {
        if (objAdd.target.type !== "textbox" && !!textEditing) {
            stopTextEditing();
        }

        if (drawingArrowActive && objAdd.target.type !== "polyline" && objAdd.target.type !== "circle") {
            stopDrawArrow()
        }

        if (fabricCanvas.isDrawingMode && objAdd.target.type != "path") {
            fabricCanvas.isDrawingMode = false;
        }
    }

    const checkTypeFabricSelection = (event: { target: any }): void => {
        stopDrawingMode();
        stopDrawArrow();

        if (event.target.type === "textbox") {
            setTextEditing(event.target, dispatch);
            stopDrawArrow();
        } else if (event.target.type === "scaleGroup") {
            const [label] = event.target._objects;

            setTextEditing(label, dispatch);
            stopDrawArrow();
        } else {
            stopTextEditing();
        }
    }


    const startDrawingMode = (): void => {
        fabricCanvas.discardActiveObject();

        stopDrawArrow();
        stopTextEditing();
        showModalBrush(dispatch);

        fabricCanvas.isDrawingMode = true;
        fabricCanvas.on({ "object:added": (event) => setOnCanvasEvent({ type: "object:added", objEvent: event }) });

        fabricCanvas.renderAll();
    }


    const stopTextEditing = () => {
        if (!!textEditing) {
            hideModalApplyEditText(dispatch);
            setTextEditing(null as any, dispatch);
        }
    }

    const stopDrawingMode = (): void => {
        if (fabricCanvas.isDrawingMode) {
            hideModalBrush(dispatch);
            fabricCanvas.isDrawingMode = false;
        }
    }


    const stopDrawArrow = () => {
        if (drawingArrowActive) {
            cleanDrawArrow();
            setDrawingArrowActive(false);
            hideModalDrawArrow(dispatch);
        }
    }


    const stopAllInteractiveElements = () => {
        stopDrawArrow();
        stopDrawingMode();
        stopTextEditing();
    }

    const removeActiveElement = () => {
        const activeElement: any = fabricCanvas.getActiveObject();

        if (!!activeElement) {
            let undoHistory = [...undo];

            const historyToRedo: HistoryEditorState = undoHistory.find((history: HistoryEditorState) => {
                return history.obj.id == activeElement.id && activeElement.type == history.obj.target.type;
            });

            if (!!historyToRedo) {
                undoHistory = undoHistory.filter((history: HistoryEditorState) => {
                    return history.obj.id !== activeElement.id;
                })

                setUndoHistory(undoHistory, dispatch);
                pushRedoHistory(historyToRedo, dispatch);
            }

            fabricCanvas.remove(activeElement);
        }

        setRemoveFabricActiveElement(false, dispatch);
    }


    const setImage = (url: string, hasSameSize: boolean = false) => {
        if (cropper && cropper.current) {
            cropper.current.replace(url, hasSameSize)
        }
    }


    const cleanDrawArrow = () => {
        setArrowEditing(null as any);
        setStartXDrawArrow(null as any);
        setStartYDrawArrow(null as any);

        if (!!firstPointArrow) {
            fabricCanvas.remove(firstPointArrow);
            setFirstPointArrow(null as any);
        }
    }


    const handleZoomIn = () => {
        applyZoomIn();
    }

    const handleZoomOut = () => {
        applyZoomOut();
    }

    const applyZoomIn = () => {
        currentFabricImage.scale((currentFabricImage.scaleX as any) + 0.1);
        currentFabricImage.center();

        pushUndoHistory({ obj: { action: "zoom", scale: 0.1, target: null }, component: "fabric" }, dispatch);
    }

    const applyZoomOut = () => {
        currentFabricImage.scale((currentFabricImage.scaleX as any) - 0.1);
        currentFabricImage.center();

        pushUndoHistory({ obj: { action: "zoom", scale: -0.1, target: null }, component: "fabric" }, dispatch);
    }

    const drawArrow = (fromx: number, fromy: number, tox: number, toy: number): void => {
        const id: number = new Date().getTime();
        const angle = Math.atan2(toy - fromy, tox - fromx);
        const headlen = 10;  // arrow head size

        // bring the line end back some to account for arrow head.
        tox = tox - (headlen) * Math.cos(angle);
        toy = toy - (headlen) * Math.sin(angle);

        // calculate the points.
        const points = [
            {
                x: fromx,  // start point
                y: fromy
            }, {
                x: fromx - (headlen / 4) * Math.cos(angle - Math.PI / 2),
                y: fromy - (headlen / 4) * Math.sin(angle - Math.PI / 2)
            }, {
                x: tox - (headlen / 4) * Math.cos(angle - Math.PI / 2),
                y: toy - (headlen / 4) * Math.sin(angle - Math.PI / 2)
            }, {
                x: tox - (headlen) * Math.cos(angle - Math.PI / 2),
                y: toy - (headlen) * Math.sin(angle - Math.PI / 2)
            }, {
                x: tox + (headlen) * Math.cos(angle),  // tip
                y: toy + (headlen) * Math.sin(angle)
            }, {
                x: tox - (headlen) * Math.cos(angle + Math.PI / 2),
                y: toy - (headlen) * Math.sin(angle + Math.PI / 2)
            }, {
                x: tox - (headlen / 4) * Math.cos(angle + Math.PI / 2),
                y: toy - (headlen / 4) * Math.sin(angle + Math.PI / 2)
            }, {
                x: fromx - (headlen / 4) * Math.cos(angle + Math.PI / 2),
                y: fromy - (headlen / 4) * Math.sin(angle + Math.PI / 2)
            }, {
                x: fromx,
                y: fromy
            }
        ];

        const pline = new fabric.Polyline(points, { ...arrowOptions, id: id } as any);

        fabricCanvas.add(pline);
        fabricCanvas.renderAll();

        setArrowEditing(pline);
        pushUndoHistory({ obj: { id: id, action: "addArrow", target: pline }, component: "fabric" }, dispatch);
    }

    const addTextElement = () => {
        stopDrawArrow();
        stopDrawingMode();

        const id: number = new Date().getTime();
        const textbox = new fabric.Textbox(t("Insira seu texto aqui"), {
            ...textOptions,
            id: id,
            fill: richTextCurrentColor,
            top: NumberUtils.getRandomNumber(50, 600),
            left: NumberUtils.getRandomNumber(50, 300),
        } as any);

        fabricCanvas.add(textbox);

        textbox.center();
        textbox.bringToFront();

        fabricCanvas.setActiveObject(textbox);
        fabricCanvas.renderAll();

        setTextEditing(textbox, dispatch);
        pushUndoHistory({ obj: { id: id, action: "addTxt", target: textbox }, component: "fabric" }, dispatch);
    }


    const addCircleElement = (): any => {
        stopAllInteractiveElements();

        const id: number = new Date().getTime();
        const circle = new fabric.Circle({
            id: id,
            radius: 50,
            stroke: "#fff",
            strokeWidth: 3,
            selected: true,
            fill: "rgba(255,255,255,0.0)",
        } as any);

        fabricCanvas.add(circle);

        circle.center();
        circle.bringToFront();

        fabricCanvas.renderAll();

        pushUndoHistory({ obj: { id: id, action: "addCircle", target: circle }, component: "fabric" }, dispatch);
    }

    const addSquareElement = (): any => {
        stopAllInteractiveElements();

        const id: number = new Date().getTime();
        const rect = new fabric.Rect({
            id: id,
            width: 100,
            height: 100,
            stroke: "#fff",
            strokeWidth: 3,
            selected: true,
            fill: "rgba(255,255,255,0.0)",
        } as any);

        fabricCanvas.add(rect);

        rect.center();
        rect.bringToFront();

        fabricCanvas.renderAll();

        pushUndoHistory({ obj: { id: id, action: "addRect", target: rect }, component: "fabric" }, dispatch);
    }

    const addScaleElement = (): Promise<any> => {
        stopDrawArrow();
        stopDrawingMode();

        return Swal.fire({
            title: `${t("Informe o tamanho da régua- Ex 3mm")}.`,
            input: "text",
        })
            .then((result: any) => {
                if (!!result && !!result.value) {
                    appendScaleElement(result.value);
                }
            })
            .catch((error: Error) => {
                console.error(error);

                Swal.fire({
                    title: t("Erro"),
                    text: error.message,
                    icon: "error",
                    confirmButtonText: "Ok"
                })
            })
    }

    const appendScaleElement = (label: string): void => {
        const id: number = new Date().getTime();
        const rulePath = "M 0,0 V 5.291664 H 0.26458334 26.458333 26.722916 V 0 H 26.458333 V 5.027084 H 20.108334 V 1.322917 H 19.84375 v 3.704167 h -6.35 V 0 H 13.229167 V 5.027084 H 6.8791668 V 1.322917 H 6.6145835 V 5.027084 H 0.26458334 V 0 Z";
        const ruleEl = new fabric.Path(rulePath, { id: id } as any);

        ruleEl.set({
            left: 0,
            top: 0,
            fill: "#000000",
            opacity: 1
        });

        const labelEl = new fabric.Textbox(label, { ...textOptions, fontSize: 10, top: ruleEl.height, left: 0, id: id } as any);

        ruleEl.set({ width: labelEl.width });

        const ruleGroup = new fabric.Group([labelEl, ruleEl], { id: id } as any);

        fabricCanvas.add(ruleGroup);


        ruleGroup.set({ type: "scaleGroup", scaleX: 5, scaleY: 5 });
        ruleGroup.center();
        ruleGroup.bringToFront();

        fabricCanvas.renderAll();

        setTextEditing(labelEl, dispatch);
        pushUndoHistory({ obj: { id: id, action: "addRule", target: ruleGroup }, component: "fabric" }, dispatch);
    }


    const addArrowElement = (): void => {
        fabricCanvas.selection = false;
        fabricCanvas.discardActiveObject();
        fabricCanvas.renderAll();

        stopDrawingMode();
        stopTextEditing();
        setDrawingArrowActive(true);
        showModalDrawArrow(dispatch);
    }

    const openCropAndAdjust = () => {
        let imageUrl: string = fabricCanvas.toDataURL({
            format: "png",
            quality: 1,
            multiplier: 1,
            left: 0,
            top: 0,
            enableRetinaScaling: false
        });

        setImage(imageUrl);
        setImageUrl(imageUrl, dispatch);

        hideFabric(dispatch);
        cleanHistory(dispatch);
        showCropping(dispatch);
        enableFabricEdition(false, dispatch);
    }


    const handleCropAndAdjustImage = () => {
        if (!isSaved) {
            Swal.fire({
                title: t("Salvar imagem"),
                text: t("Para recortar e ajustar a imagem, você precisa salvar a foto"),
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: t("Sim"),
                cancelButtonText: t("Cancelar")
            })
                .then((result) => {
                    if (result.value) {
                        openCropAndAdjust();
                        setIsSaved(true, dispatch);
                    }
                })
        } else {
            openCropAndAdjust();
        }
    }

    const setFilterGray = (event: any) => {
        const id: number = new Date().getTime();
        const grayscale = new fabric.Image.filters.Grayscale({ id: id } as any)

        if (event.target.checked) {
            setFilterImage(0, grayscale);

        } else {
            setFilterImage(0, null);
        }

        pushUndoHistory({
            obj: {
                id: id,
                action: "setFilterChecked",
                index: 0,
                filterBefore: event.target.checked ? null : grayscale as any,
                filter: event.target.checked ? grayscale as any : null,
                input: event.target,
                target: null,
            },
            component: "fabric"
        }, dispatch);
    }

    const setFilterInvert = (event: any) => {
        const id: number = new Date().getTime();
        const invert = new fabric.Image.filters.Invert({ id: id } as any)

        if (event.target.checked) {
            setFilterImage(1, invert);

        } else {
            setFilterImage(1, null);
        }

        pushUndoHistory({
            obj: {
                id: id,
                action: "setFilterChecked",
                index: 1,
                filterBefore: event.target.checked ? null : invert as any,
                filter: event.target.checked ? invert as any : null,
                input: event.target,
                target: null,
            },
            component: "fabric"
        }, dispatch);
    }

    const setFilterSaturation = (event: any) => {
        if (!!currentFabricImage) {
            const id: number = new Date().getTime();
            let saturation = new fabric.Image.filters.Saturation({
                id: id,
                saturation: event.target.value / 100
            } as any);

            let filterBefore = (currentFabricImage.filters as any)[2];

            pushUndoHistory({
                obj: {
                    id: id,
                    action: "setFilterRange",
                    index: 2,
                    target: null,
                    filter: saturation as any,
                    filterBefore: filterBefore,
                    input: event.target,
                    valueInput: event.target.value,
                    valueInputBefore: filterBefore ? filterBefore.saturation * 100 : 0
                },
                component: "fabric"
            }, dispatch);

            setFilterImage(2, saturation);
        }
    }


    const setFilterSepia = (event: any) => {
        const id: number = new Date().getTime();
        const sepia = new fabric.Image.filters.Sepia({ id: id } as any);

        if (event.target.checked) {
            setFilterImage(3, sepia);

        } else {
            setFilterImage(3, null);
        }

        pushUndoHistory({
            obj: {
                id: id,
                action: "setFilterChecked",
                index: 3,
                filterBefore: event.target.checked ? null : sepia as any,
                filter: event.target.checked ? sepia as any : null,
                input: event.target,
                target: null,
            },
            component: "fabric"
        }, dispatch);
    }


    const setFilterHueRotate = (event: any) => {
        if (!!currentFabricImage) {
            const id: number = new Date().getTime();
            const hueRotation = new (fabric.Image.filters as any).HueRotation({
                rotation: event.target.value / 100
            })

            const filterBefore = (currentFabricImage.filters as any)[4];

            pushUndoHistory({
                obj: {
                    id: id,
                    action: "setFilterRange",
                    index: 4,
                    filter: hueRotation,
                    filterBefore,
                    target: null,
                    input: event.target,
                    valueInput: event.target.value,
                    valueInputBefore: filterBefore ? filterBefore.hueRotation * 100 : 0
                },
                component: "fabric"
            }, dispatch);

            setFilterImage(4, hueRotation);
        }
    }


    const setFilterBrightness = (event: any) => {
        if (!!currentFabricImage) {
            const id: number = new Date().getTime();
            const brightness = new fabric.Image.filters.Brightness({
                id: id,
                brightness: event.target.value / 100
            } as any)

            const filterBefore = (currentFabricImage.filters as any)[5];

            pushUndoHistory({
                obj: {
                    id: id,
                    action: "setFilterRange",
                    index: 5,
                    filter: brightness as any,
                    filterBefore,
                    target: null,
                    input: event.target,
                    valueInput: event.target.value,
                    valueInputBefore: filterBefore ? filterBefore.brightness * 100 : 0
                },
                component: "fabric"
            }, dispatch);

            setFilterImage(5, brightness);
        }
    }


    const setFilterContrast = (event: any) => {
        if (!!currentFabricImage) {
            const id: number = new Date().getTime();
            const contrast = new fabric.Image.filters.Contrast({
                id: id,
                contrast: event.target.value / 100
            } as any);

            const filterBefore = (currentFabricImage.filters as any)[6];

            pushUndoHistory({
                obj: {
                    id: id,
                    action: "setFilterRange",
                    index: 6,
                    target: null,
                    filterBefore,
                    filter: contrast as any,
                    input: event.target,
                    valueInput: event.target.value,
                    valueInputBefore: filterBefore ? filterBefore.contrast * 100 : 0
                },
                component: "fabric"
            }, dispatch);

            setFilterImage(6, contrast);
        }
    }


    const setFilterImage = (index: number, filter: any): void => {
        stopAllInteractiveElements();

        if (!!currentFabricImage) {
            (currentFabricImage.filters as any)[index] = filter;
            currentFabricImage.applyFilters();

            fabricCanvas.renderAll();
        }
    }


    const resetFabricImage = () => {
        resetFilters();
        resetPosition();
        resetElements();

        fabricCanvas.renderAll();
    }


    const resetPosition = () => {
        if (fabricCanvas.height < fabricCanvas.width) {
            currentFabricImage.scaleToHeight(fabricCanvas.height);

        } else {
            currentFabricImage.scaleToWidth(fabricCanvas.width);
        }
    }


    const resetFilters = () => {
        currentFabricImage.filters = [];
        currentFabricImage.applyFilters();
    }


    const resetElements = () => {
        let objects = fabricCanvas.getObjects();

        for (let i in objects) {
            if (
                objects[i].type === "path"
                || objects[i].type === "textbox"
                || objects[i].type === "circle"
                || objects[i].type === "rect"
                || objects[i].type === "polyline"
            ) {
                fabricCanvas.remove(objects[i])
            }
        }

        currentFabricImage.selectable = false;
    }

    const undoFabricElement = (history: HistoryEditorState) => {
        let obj = history.obj;

        if (obj.action === "addTxt" || obj.action === "addArrow" || obj.action === "addRect" || obj.action === "addCircle" || obj.action == "addRule") {
            fabricCanvas.remove(obj.target)

            if (obj.target.type === "textbox" || obj.target.type === "path") {
                setTextEditing(null as any, dispatch);
            }

        } else {
            if (obj.action === "zoom") {
                currentFabricImage.scale((currentFabricImage as any).scaleX + -(obj.scale as any));
                currentFabricImage.center();

            } else {
                if (obj.action === "moveObj") {
                    obj.target.setPositionByOrigin(
                        { x: obj.pointBefore.x, y: obj.pointBefore.y },
                        obj.pointBefore.originX, obj.pointBefore.originY)
                    obj.target.setCoords()

                } else {
                    if (obj.action === "changeOffsetX") {
                        obj.target.width = obj.xBefore;
                        obj.target.left = obj.leftBefore;
                        obj.target.setCoords()

                    } else {
                        if (obj.action === "changeOffsetY") {
                            obj.target.scaleY = obj.scaleYBefore;
                            obj.target.top = obj.topBefore;
                            obj.target.setCoords()

                        } else {
                            if (obj.action === "addBold") {
                                if (obj.fontWeight === "bold") {
                                    obj.target.fontWeight = "normal";
                                }
                                else {
                                    obj.target.fontWeight = "bold";
                                }
                                obj.target.setCoords()

                            } else {
                                if (obj.action === "addItalic") {
                                    if (obj.fontStyle === "italic") {
                                        obj.target.fontStyle = "normal";
                                    }
                                    else {
                                        obj.target.fontStyle = "italic";
                                    }
                                    obj.target.setCoords()

                                } else {
                                    if (obj.action === "addUnderline") {
                                        if (obj.underline) {
                                            obj.target.setSelectionStyles({ underline: false })
                                            obj.target.underline = false;
                                        }
                                        else {
                                            obj.target.setSelectionStyles({ underline: true })
                                            obj.target.underline = true;
                                        }
                                        obj.target.setCoords()

                                    } else {
                                        if (obj.action === "addLinethrough") {
                                            if (obj.linethrough) {
                                                obj.target.setSelectionStyles({ linethrough: false })
                                                obj.target.linethrough = false;
                                            }
                                            else {
                                                obj.target.setSelectionStyles({ linethrough: true })
                                                obj.target.linethrough = true;
                                            }
                                            obj.target.setCoords()

                                        } else {
                                            if (obj.action === "changeFontSize") {
                                                obj.target.fontSize = obj.fontSizeBefore;
                                                obj.target.width = obj.width;
                                                obj.target.height = obj.height;
                                                obj.target.setCoords()

                                            } else {
                                                if (obj.action === "changeFontFamily") {
                                                    obj.target.fontFamily = obj.fontFamilyBefore;
                                                    obj.target.width = obj.width;
                                                    obj.target.height = obj.height;
                                                    obj.target.setCoords()

                                                } else {
                                                    if (obj.action === "changeAlign") {
                                                        obj.target.textAlign = obj.textAlignBefore;
                                                        obj.target.setCoords()

                                                    } else {
                                                        if (obj.action === "objectDraw:add") {
                                                            fabricCanvas.remove(obj.target);

                                                        } else {
                                                            if (obj.action === "setFilterChecked") {
                                                                (currentFabricImage as any).filters.splice(obj.index, 1);
                                                                ((currentFabricImage as any).filters)[obj.index as number] = obj.filterBefore;

                                                                (obj.input as any).checked = !(obj as any).input.checked;

                                                                (currentFabricImage as any).applyFilters();

                                                            } else {
                                                                if (obj.action === "setFilterRange") {
                                                                    (currentFabricImage as any).filters.splice(obj.index, 1);
                                                                    ((currentFabricImage as any).filters as any)[obj.index as number] = obj.filterBefore;
                                                                    (obj.input as any).value = obj.valueInputBefore;
                                                                    (currentFabricImage as any).applyFilters();
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        fabricCanvas.requestRenderAll();

        setUndoFabric(false, dispatch);
    }


    const redoFabricElement = (history: HistoryEditorState) => {
        let obj = history.obj;

        if (obj.action === "zoom") {
            currentFabricImage.scale((currentFabricImage.scaleX as any) + obj.scale);
            currentFabricImage.center();

        } else {
            if (obj.action === "addTxt" || obj.action === "addArrow" || obj.action === "addRect" || obj.action === "addCircle" || obj.action === "addRule" || obj.action === "objectDraw:add") {
                fabricCanvas.add(obj.target)

            } else {
                if (obj.action === "moveObj") {
                    obj.target.setPositionByOrigin({ x: obj.point.x, y: obj.point.y }, obj.point.originX, obj.point.originY);

                    obj.target.setCoords();

                } else {
                    if (obj.action === "changeOffsetX") {
                        obj.target.width = obj.x;
                        obj.target.left = obj.left;
                        obj.target.setCoords();

                    } else {
                        if (obj.action === "changeOffsetY") {
                            obj.target.scaleY = obj.scaleY;
                            obj.target.top = obj.top;
                            obj.target.setCoords();

                        } else {
                            if (obj.action === "addBold") {
                                obj.target.fontWeight = obj.fontWeight;
                                obj.target.setCoords();

                            } else {
                                if (obj.action === "addItalic") {
                                    obj.target.fontStyle = obj.fontStyle;
                                    obj.target.setCoords();
                                }
                                else {
                                    if (obj.action === "addUnderline") {
                                        obj.target.setSelectionStyles({ underline: obj.underline })
                                        obj.target.underline = obj.underline;
                                        obj.target.setCoords();
                                    }
                                    else {
                                        if (obj.action === "addLinethrough") {
                                            obj.target.setSelectionStyles({ linethrough: obj.linethrough })
                                            obj.target.linethrough = obj.linethrough;
                                            obj.target.setCoords();
                                        }
                                        else {
                                            if (obj.action === "changeFontSize") {
                                                obj.target.fontSize = obj.fontSize;
                                                obj.target.setCoords();
                                            }
                                            else {
                                                if (obj.action === "changeFontFamily") {
                                                    obj.target.fontFamily = obj.fontFamily;
                                                    obj.target.setCoords();
                                                }
                                                else {
                                                    if (obj.action === "changeAlign") {
                                                        obj.target.textAlign = obj.textAlign;
                                                        obj.target.setCoords();
                                                    }
                                                    else {
                                                        if (obj.action === "setFilterChecked") {
                                                            (currentFabricImage.filters as any).splice(obj.index, 1);
                                                            (currentFabricImage.filters as any)[obj.index as number] = obj.filter;

                                                            (obj.input as any).checked = !(obj.input as any).checked;

                                                            currentFabricImage.applyFilters();
                                                        }
                                                        else {
                                                            if (obj.action === "setFilterRange") {
                                                                (currentFabricImage.filters as any).splice(obj.index, 1);
                                                                (currentFabricImage.filters as any)[obj.index as number] = obj.filter;

                                                                (obj.input as any).value = obj.valueInput;

                                                                currentFabricImage.applyFilters();
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        fabricCanvas.requestRenderAll()

        setRedoFabric(false, dispatch);
    }


    return (
        <div className={fabricEnable ? "" : "d-none"} id="fabrick">
            <button className="btn m-4 btn-outline-info btn-lg" title={t("Cortar e ajustar imagem")} id="btn-cropImage"  onClick={handleCropAndAdjustImage}>
                <i className="mr-2 fa fa-plus"></i>
                {t("Cortar e ajustar imagem")}
            </button>
            <div className="col-12 p-1 my-1 text-uppercase">
                <span>
                    <i className="mr-2 fa fa-eye"></i>
                    {t("Visualizar")}
                </span>
                <div className="col-12 p-1 my-1 d-flex">
                    <button className="btn mr-2 mb-2 btn-sm btn-outline-primary" id="btn-zoomIn"  onClick={handleZoomIn}>
                        <i className="fa fa-search-plus"></i>
                    </button>
                    <button className="btn mr-2 mb-2 btn-sm btn-outline-primary" id="btn-zoomOut"  onClick={handleZoomOut}>
                        <i className="fa fa-search-minus"></i>
                    </button>
                </div>
                <div className="col-12 p-1 my-1 d-flex text-uppercase">
                    <span className="d-block">
                        <i className="mr-2 fa fa-star"></i>
                        {t("Elementos")}
                    </span>
                </div>
                <div className="col-12 p-1 my-1 d-flex">
                    <button className="btn mr-2 mb-2 btn-sm btn-outline-primary" title={t("Adicionar texto")} id="btn-text"  onClick={() => addTextElement()}>
                        <i className="fa fa-font"></i>
                    </button>
                    <button className="btn mr-2 mb-2 btn-sm btn-outline-primary" title={t("Adicionar lápis")} id="btn-brush"  onClick={() => startDrawingMode()}>
                        <i className="fa fa-paint-brush"></i>
                    </button>
                    <button className="btn mr-2 mb-2 btn-sm btn-outline-primary" title={t("Adicionar seta")} id="btn-pointer"  onClick={() => addArrowElement()}>
                        <i className="fa fa-mouse-pointer"></i>
                    </button>
                </div>
                <div className="col-12 p-1 my-1 d-flex">
                    <button className="btn mr-2 mb-2 btn-sm btn-outline-primary" title={t("Adicionar círculo")} id="btn-circle"  onClick={() => addCircleElement()}>
                        <i className="far fa-circle"></i>
                    </button>
                    <button className="btn mr-2 mb-2 btn-sm btn-outline-primary" title={t("Adicionar quadrado")} id="btn-square"  onClick={() => addSquareElement()}>
                        <i className="far fa-square"></i>
                    </button>
                    <button className="btn mr-2 mb-2 btn-sm btn-light" title={t("Adicionar régua")} id="btn-scale"  onClick={() => addScaleElement()}>
                        <img height={20} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAD+0lEQVR4XtWby8tPWxjHP69cjjJQYmQiw5MUfwEyIoQ4A9ckA6aKnFzDxFjJwO0M3GXAwNs5/4MwZsCElKIkHHpqLW2rtfdea+9nXX578ta7115rfz/PZT/r8ptisq75wAfNV57S7CxxX2uB28AO4JHWWJMCQMQ/BOYCX4AtWhAmAUBTvDW8GoTaATTF/wCuAzuBGVqeUDMAV/wB4DKwC7iiBaFWAG3ibQioQagRgBvz34DtwH0n86tAqA2Aa/nvwEwgGYSaAPjc/j1wqwHhL+CepifUAqAr5jc7EFYAz7Qg1ACgL+GJVil8bgIXgKMtVeCgnFAaQIh4q3eZx/Iui2gIJQG42V7q+/UKNX4UhFIAfOWtaD8DHM8JoQQA1+3PA1LlLTDCTwMnckHIDaAt5pcD/5aAkBNAX8JzIZwCTqb2hFwA+sRbndkh5ADgJryDwMUOy7oQpAZw5wFDHMP7dUgNwJftH5jJzdcACE/M/F/mBBrXVWC36UjWFpekBOC6/RtgsRk8BMJS4BWgJX4/cAkQzZ+BjcB0KgC+mJc1vf+APyMgaFhd+vCKlxspAHQlvEUOBInLG1oqW/ppFZ8CQEi2X2ggSOl7pKR4bQAh4q3eecCn0uI1AbjZ/jWwEnibWGRb951u33xIIwe0TWxeAKsLQAgWr+EBrtsfAuR/mwxlgbAKeJfJE6LEjwXQFvOzzB5ebgjR4scA6Et4AuGOKTZknOcmHFJ5wiDxQwH0ibfe7kKQhcw1CcJhsPghANyEdw441hHfAuEusMG0OQv8rZgPRomPBeDL9rJuL1Z92iFqtgmHj2YikrS2j4Ub+hl03f4xsM4MFgJBPOH/1BObWPGhHtAW87KAad05BMKQ9/M9M9rtYwqhvoTXhCDLV7KMlfJSFd/nAX3irVCB8AdwOKXyrintmHHbckCo+DFjxzyrbnk7uA+Am+1la3obIKs4Ja5k4n0h4Fre7s/L+p1sTWssTsZATCreBeBze8nusisrn7HcEJKLbwLoinl3f953XCXGqiFts4i3AOT46UtA/sp1DdjjvKULwXdSI0RYSJts4pseIFWdHD2ZYyq2veZMXvOF7SEFe2YnBYSs4t0cEAvB5ykhFm5rk1287ysQAmGrOciwr8baPtYCvjogBELsOF3ti1i+qxCSe7kgFBXvC4GmpVJDKC6+D0BKT6hCfAiAFBCqER8KQBNCVeJjAGhAqE58LIAxEKoUPwTAEAjVih8KIAZC1eLHAAiBUL34sQC6IEyEeA0APghy5kfO/vx2Gktz8qDZV+jOUN+YzbLZtv11FK3v4ZL3tQC4njAR4rVCwJ1A/WOW0adLWjZ0bE0PsGOq/8Q9VMyQdj8BejupYODzaukAAAAASUVORK5CYII="></img>
                    </button>
                </div>
                <div className="col-12 p-1 my-1 text-uppercase">
                    <span>
                        <i className="mr-2 fa fa-filter"></i>
                        {t("Filtros")}
                    </span>
                </div>
                <div className="col-12 p-1 my-1">
                    <div className="d-flex flex-row align-items-center mb-2">
                        <label className="mr-2 mb-0" htmlFor="input-filter-grayscale">{t("Escala de cinza")}</label>
                        <input type="checkbox" id="input-filter-grayscale" checked={!!currentFabricImage && !!(currentFabricImage.filters as any)[0]} onChange={(event) => { setFilterGray(event) }} />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                        <label className="mr-2 mb-0" htmlFor="input-filter-invert">{t("Inverter")}</label>
                        <input type="checkbox" id="input-filter-invert" checked={!!currentFabricImage && !!(currentFabricImage.filters as any)[1]} onChange={(event) => { setFilterInvert(event) }} />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-4">
                        <label className="mr-2 mb-0" htmlFor="input-filter-sepia">{t("Sepia")}</label>
                        <input type="checkbox" id="input-filter-sepia" checked={!!currentFabricImage && !!(currentFabricImage.filters as any)[3]} onChange={(event) => { setFilterSepia(event) }} />
                    </div>
                    <div className="form-group mt-2">
                        <label className="small">
                            {t("Brilho")}
                        </label>
                        <input className="form-control" defaultValue={"0"} type="range" min="-100" max="100" id="input-filter-brightness" checked={!!currentFabricImage && !!(currentFabricImage.filters as any)[5]} onChange={(event) => { setFilterBrightness(event) }} />
                    </div>
                    <div className="form-group">
                        <label className="small">
                            {t("Contraste")}
                        </label>
                        <input className="form-control" defaultValue={"0"} type="range" min="-100" max="100" id="input-filter-contrast" checked={!!currentFabricImage && !!(currentFabricImage.filters as any)[6]} onChange={(event) => { setFilterContrast(event) }} />
                    </div>
                    <div className="form-group">
                        <label className="small">
                            {t("Saturação")}
                        </label>
                        <input className="form-control" defaultValue={"0"} type="range" min="-100" max="100" id="input-filter-saturation" checked={!!currentFabricImage && !!(currentFabricImage.filters as any)[2]} onChange={(event) => { setFilterSaturation(event) }} />
                    </div>
                    <div className="form-group">
                        <label className="small">
                            {t("Rotação Hue")}
                        </label>
                        <input className="form-control" defaultValue={"0"} type="range" min="-100" max="100" id="input-filter-hueRotate" checked={!!currentFabricImage && !!(currentFabricImage.filters as any)[4] ? true : false} onChange={(event) => { setFilterHueRotate(event) }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const style = {
    button: {
        backgroundColor: "rgb(221,221,221)"
    }
}

export { ImageEditorFabric };