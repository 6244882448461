import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import App from './app/App';
import Login from './login/Login';
import LoginPage from './login/pages/login-page';

import { useSelector, useDispatch } from 'react-redux';
import PrivateRoute from './utils/privateRoute-utils';
import Loading from './app/elements/loading-element';
import { firebaseAuth } from "./config/firebase-config";
import './app/utils/i18nextTranslation';

import { ApplicationState } from './store';
import { setLogout } from './store/actions/logout';

// import { Logout } from './app/elements/logout-element';


const Routes = () => {
    const dispatch = useDispatch();
    const logout = useSelector((states: ApplicationState) => states.logout.logout);

    return (
        <BrowserRouter>
            <Loading />
            <Switch>
                <PrivateRoute condition={true} path="/app">
                    <App />
                </PrivateRoute>

                <Route path="/login">
                    {/* <Login /> */}
                    <LoginPage />
                </Route>

                <Route path={`/logout`}>
                    {/* <Logout /> */}
                    <Redirect to={{ pathname: "/login" }} />
                </Route>

                <Route path="/">
                    <Redirect to={{ pathname: "/login" }} />
                </Route>
            </Switch>
        </BrowserRouter>
    )
}

export default Routes;
