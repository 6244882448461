import { ActionInterface } from "../actions/interface/ActionInterface"
import { ViewSelectTypes } from "../actions/types/ActionsTypes";
import { ViewSelectState } from "../datatypesInterfaces/dataTypeInterfaces";

const INITIAL_STATE: ViewSelectState = {
    selectedExam: null as any,
    selectedPatient: null as any,
    selectedPhysician: null as any,
    selectedReferringPhysician: null as any,
}

const ViewSelect = (state = INITIAL_STATE, action: ActionInterface) => {
    switch (action.type) {
        case ViewSelectTypes.SELECTED_EXAM:
            return {
                ...state,
                selectedExam: action.payload
            }

        case ViewSelectTypes.SELECTED_PATIENT:
            return {
                ...state,
                selectedPatient: action.payload
            }
        case ViewSelectTypes.SELECTED_PHYSICIAN:
            return {
                ...state,
                selectedPhysician: action.payload
            }

        case ViewSelectTypes.SELECTED_REFERRING_PHYSICIAN:
            return {
                ...state,
                selectedReferringPhysician: action.payload
            }
        default:
            return state;
    }
}

export { ViewSelect };