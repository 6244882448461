import React from 'react';
import { useTranslation } from 'react-i18next';

const ModalConfigEmail = () => {
    const { t } = useTranslation();

    const hideConfigEmail = () => {
        (document.querySelector('#divEmailForm') as HTMLDivElement).classList.remove('d-none');
        (document.querySelector('#divConfigEmail') as HTMLDivElement).classList.add('d-none');
    }
    
    return (
        <div className="d-none modal-content card w-75" style={{ margin: "50px auto" }} id="divConfigEmail">
            <div className="card-header bg-white p-4 d-flex flex-row align-items-center justify-content-between">
                <span data-l10n-id="modal_config_email_title" className="translate">{t("Configuração da conta")}</span>
                <button type="button" aria-label="Close" className="close" id="btnConfigurationEmailClose" onClick={hideConfigEmail}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="card-body p-4" id="configurationDefaultBody">
                <div className="form-group row mb-4">
                    <div className="col">
                        <div className="custom-control custom-switch" id="inpConfigurationDefault">
                            <input className="custom-control-input checkbox-report" type="checkbox" id="configurationDefaultZscan" />
                            <label data-l10n-id="modal_config_email_default_label" className="custom-control-label w-100" style={{ textAlign: "left" }} htmlFor="configurationDefaultZscan">{t("Utilizar configurações automáticas")}</label>
                        </div>
                    </div>
                </div>
                <div className="form-group row mb-4">
                    <div className="input-group col">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                            </span>
                        </div>
                        <input type="text" data-l10n-id="modal_config_email_input" className="form-control" placeholder="Email" id="inpConfigurationEmail" />
                    </div>
                </div>
                <div className="form-group row mb-4">
                    <div className="input-group col">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                            </span>
                        </div>
                        <input type="text" data-l10n-id="modal_config_sender_input" className="form-control" placeholder="Sender" id="inpConfigurationEmailSender" />
                    </div>
                </div>
                <div className="form-group row mb-4">
                    <div className="input-group col-6">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <i className="fa fa-user" aria-hidden="true"></i>
                            </span>
                        </div>
                        <input type="text" data-l10n-id="modal_config_user_input" className="form-control" placeholder="User" id="inpConfigurationEmailUser" />
                    </div>
                    <div className="input-group col-6">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <i className="fa fa-key" aria-hidden="true"></i>
                            </span>
                        </div>
                        <input type="password" data-l10n-id="modal_config_password_input" className="form-control" placeholder="Password" id="inpConfigurationEmailPassword" />
                    </div>
                </div>
                <div className="form-group row mb-4">
                    <div className="input-group col-6">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <i className="fa fa-server" aria-hidden="true"></i>
                            </span>
                        </div>
                        <input type="text" data-l10n-id="modal_config_host_input" className="form-control" placeholder="Host" id="inpConfigurationEmailServer" />
                    </div>
                    <div className="input-group col-6">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <i className="fa fa-rub" aria-hidden="true"></i>
                            </span>
                        </div>
                        <input type="text" data-l10n-id="modal_config_port_input" className="form-control" placeholder="Port" id="inpConfigurationEmailPort" />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                        <a href="https://support.google.com/mail/answer/7126229?hl=pt-BR&ref_topic=3398031" target="_blank">Gmail Help</a>
                    </div>
                    <div className="col-12">
                        <a href="https://support.office.com/pt-br/article/adicionar-sua-conta-do-outlook-com-a-outro-aplicativo-de-email-ou-dispositivo-dom%c3%a9stico-73f3b178-0009-41ae-aab1-87b80fa94970?ui=pt-BR&rs=pt-BR&ad=BR" target="_blank">Outlook Help</a>
                    </div>
                </div>
            </div>
            <div className="card-footer p-4">
                <div className="row m-0 p-0">
                    <div className="col text-right p-2 p-0">
                        <button className="btn btn-warning ml-3" type="button" id="btnConfigEmailCancel" onClick={hideConfigEmail}>
                            <i className="fa fa-ban mr-2" aria-hidden="true"></i>
                            <span data-l10n-id="modal_config_email_cancel" className="translate">{t("Cancelar")}</span>
                        </button>
                        <button className="btn btn-success ml-3" type="button" id="btnConfigEmailConfirm">
                            <i className="fa fa-check mr-2" aria-hidden="true"></i>
                            <span data-l10n-id="modal_config_email_confirm" className="translate">{t("Confirmar")}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ModalConfigEmail;