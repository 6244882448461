import { firebaseDatabase } from '../../config/firebase-config';
import { Component } from 'react';
import { PredefinitionReportAnswerInterface } from '../../interfaces/predefinitionReportAnswerInterface';
import { QuerySnapshot, DocumentSnapshot, DocumentReference } from '../../types/types';
import { FieldInterface } from '../../interfaces/fieldInterface';
import { SectionInterface } from '../../interfaces/sectionInterface';
import { LegendInterface } from '../../interfaces/legendInterface';
import { LegendHistoryInterface } from '../../interfaces/legendHistoryInterface';
import { FavoriteReportInterface } from '../../interfaces/favoriteReportInterface';
import { FavoriteReportAnswerInterface } from '../../interfaces/favoriteReportAnswerInterface';
import { TemplateInterface } from '../../interfaces/templateInterface';
import { ImageInterface } from '../../interfaces/imageInterface';
import { QueryWhereInterface } from '../../interfaces/queryWhereInterface';
import { firebaseServiceUtils } from '../utils/firebaseServiceUtils';

// import { ImageInterface } from '../../interfaces/imageInterface';

export class FirebaseService extends Component {

    static patientCollection = (serialPath: string) => {
        return firebaseDatabase.collection(`${serialPath}/patients`)
    }
    // static patientCollection = () => {
    //     return firebaseDatabase.collection('development/development/users/doctorzscan/serials/8057-0312-58/patients')
    // }

    static getImagesByPatientRef = (serialPath: string, uidPatient: string) => {
        const patientRef = firebaseDatabase.doc(`${serialPath}/patients/${uidPatient}`);

        return firebaseDatabase.collection(`${patientRef.path}/images`).orderBy('id', "desc")
            .get().catch(e => {
                console.log("Error", e);
            })
    }

    static getReports = (clinicPath: string) => {
        return firebaseDatabase.collection(`${clinicPath}/reports`)
            .get().catch(e => {
                console.error("Error", e);
            })
    }

    static getEmployeeByOccupationId = (serialInClinicPath: string, occupationUid?: number) => {
        return firebaseDatabase.collection(`${serialInClinicPath}/employees`)
            .where("occupations.id", "==", occupationUid)
            .get()
            .catch(e => {
                console.error("Error", e);
            })
    }

    static getEmployee = (serialInClinicPath: string, query?: QueryWhereInterface | Array<QueryWhereInterface>) => {
        let employeeCollection = firebaseDatabase.collection(`${serialInClinicPath}/employees`);

        if (!!query) {
            employeeCollection = firebaseServiceUtils.objectQueryToFirebaseQuery(employeeCollection, query);
        }

        return employeeCollection.get()
    }

    static getReportsByPatient = (serialPath: string, uid: string | number) => {
        const local = `${serialPath}/patients/${uid}/reports`;
        return firebaseDatabase.collection(local).orderBy('date', 'desc').get();
    }

    static getTemplates = (clinicPath: string) => {
        return firebaseDatabase.collection(`${clinicPath}/templates`)
    }

    static saveTemplate = (clinicPath: string, templateData: Partial<TemplateInterface>) => {
        const templateDoc = firebaseDatabase.collection(`${clinicPath}/templates`).doc();

        let uid = templateDoc.id;
        delete templateData.fields;

        return templateDoc.set(templateData).then(() => {
            return uid;
        }).catch(e => {
            console.log("Error: ", e)
        })
    }

    static updateTemplate = (clinicPath: string, templateData: Partial<TemplateInterface>) => {
        let uid = templateData.uid;
        delete templateData.uid;
        delete templateData.fields;

        const templateDoc = firebaseDatabase.doc(`/${clinicPath}/templates/${uid}`)

        return templateDoc.set(templateData, { merge: true }).then(() => {
            return templateDoc;
        }).catch(e => {
            console.log("Error: ", e)
        })

    }


    static deleteTemplate = (clinicPath: string, templateUid: string | number) => {
        const templateDoc = firebaseDatabase.doc(`/${clinicPath}/templates/${templateUid}`)
        return templateDoc.delete()
            .then(() => {
                return templateUid;
            }).catch(e => {
                console.log("Error", e)
            })
    }

    static deleteReport = (serialPath: string, patientUid: string | number, reportUid: string | number) => {
        const reportDoc = firebaseDatabase.doc(`/${serialPath}/${patientUid}/reports/${reportUid}`)
        return reportDoc.delete()
            .then(() => {
                return reportUid;
            }).catch(e => {
                console.log("Error", e)
            })
    }

    static getFields = (clinicPath: string, templateUid: string | number) => {
        return firebaseDatabase.collection(`/${clinicPath}/templates/${templateUid}/fields`)
            .get().then((documentSnapshot: QuerySnapshot) => {
                let fields: Array<FieldInterface> = [];
                documentSnapshot.forEach((item: any) => {
                    let field = item.data();
                    field.uid = item.id;
                    delete field.predefinitionAnswers;
                    fields.push(field);
                })
                return fields;
            })
    }

    static deleteField = (clinicPath: string, templateUid: string | number, fieldUid: string | number) => {
        const fieldDoc = firebaseDatabase.doc(`/${clinicPath}/templates/${templateUid}/fields/${fieldUid}`);
        return fieldDoc.delete()
            .then(() => {
                return fieldUid;
            })
            .catch(e => {
                console.log("Error", e);
            })
    }

    static predefinitionAnswersCollection = (clinicPath: string) => {
        return firebaseDatabase.collection(`/${clinicPath}/predefinitionreportanswers`);
    }

    static savePredefinitionAnswer = (clinicPath: string, fieldId: number, data: PredefinitionReportAnswerInterface) => {
        data.fieldId = fieldId;

        const predefinitionAnswerDoc = firebaseDatabase.collection(`/${clinicPath}/predefinitionreportanswers`).doc();

        delete data.uid;
        return predefinitionAnswerDoc.set(data)
    }

    static updatePredefinitionAnswer = (clinicPath: string, fieldId: number, data: PredefinitionReportAnswerInterface) => {
        const uid = data.uid;

        delete data.uid;

        const predefinitionAnswerDoc = firebaseDatabase.doc(`/${clinicPath}/predefinitionreportanswers/${uid}`);
        return predefinitionAnswerDoc.set(data, { merge: true })
            .then(() => {
                return uid;
            })
            .catch(e => {
                console.log("Error", e)
            })
    }

    static deletePredefinitionAnswer = (clinicPath: string, templateId: string, fieldId: string, uid: string | number) => {
        const predefinitionAnswerDoc = firebaseDatabase.doc(`/${clinicPath}/templates/${templateId}/fields/${fieldId}/predefinitionAnswers/${uid}`);
        return predefinitionAnswerDoc.delete()
            .then(() => {
                return uid;
            })
            .catch(e => {
                console.log("Error", e);
            })
    }

    static legendsCollection = (serialPath: string) => {
        return firebaseDatabase.collection(`/${serialPath}/legends/`);
    }

    static saveLegend = (serialPath: string, data: LegendInterface) => {
        const legendDoc = firebaseDatabase.collection(`/${serialPath}/legends/`).doc();
        const uid = legendDoc.id;
        delete data.uid;

        return legendDoc.set(data)
            .then(() => {
                return uid;
            })
            .catch(e => {
                console.log("Error", e);
            })
    }

    static updateLegend = (serialPath: string, data: LegendInterface) => {
        const uid = data.uid;
        delete data.uid;

        let legendDoc = firebaseDatabase.doc(`/${serialPath}/legends/${uid}`);
        return legendDoc.set(data)
            .then(() => {
                return uid;
            })
            .catch(e => {
                console.log("Error", e);
            })
    }

    static deleteLegend = (serialPath: string, uid: string | number) => {
        const legendDoc = firebaseDatabase.doc(`/${serialPath}/legends/${uid}`);
        return legendDoc.delete()
            .then(() => {
                return uid;
            })
            .catch(e => {
                console.log("Error", e);
            })
    }

    static saveLegendHistory = (serialPath: string, imageId: string, data: LegendHistoryInterface) => {
        const legendHistoryDoc = firebaseDatabase.collection(`/${serialPath}/images/${imageId}/legendsHistory`).doc();

        const uid = legendHistoryDoc.id;
        delete data.uid;

        return legendHistoryDoc.set(data)
            .then(() => {
                return uid;
            })
            .catch(e => {
                console.log("Error", e);
            })
    }

    static sectionCollection = (clinicPath: string) => {
        return firebaseDatabase.collection(`${clinicPath}/sections`)
    }

    static saveSection = (clinicPath: string, data: SectionInterface) => {
        const sectionDoc = firebaseDatabase.collection(`/${clinicPath}/sections/`).doc();
        const uid = sectionDoc.id;
        delete data.uid;

        return sectionDoc.set(data)
            .then(() => {
                return uid;
            })
            .catch(e => {
                console.log("Error", e);
            })
    }

    static updateSection = (clinicPath: string, data: SectionInterface) => {
        const uid = data.uid;
        delete data.uid;

        let sectionDoc = firebaseDatabase.doc(`/${clinicPath}/sections/${uid}`);
        return sectionDoc.set(data)
            .then(() => {
                return uid;
            })
            .catch(e => {
                console.log("Error", e);
            })
    }

    static getExams = (serialPath: string) => {
        return firebaseDatabase.collection(`${serialPath}/exams`)
            .get().catch(e => {
                console.error("Error", e);
            })
    }

    static favoriteReportsCollection = (clinicPath: string) => {
        return firebaseDatabase.collection(`/${clinicPath}/favoritereports`);
    }

    static getFavoritesReports = (clinicPath: string, examUid: string | number) => {
        let path = `/${clinicPath}/favoritereports`;
        let examDoc = firebaseDatabase.doc(`${clinicPath}/exams/${examUid}`);
        let reportDoc = firebaseDatabase.collection(path)

        return reportDoc.where("examRef", "==", examDoc).get().then((result: QuerySnapshot) => {
            let favoriteReport: Array<FavoriteReportInterface> = [];
            result.forEach((item: DocumentSnapshot) => {
                let favRep: FavoriteReportInterface = item.data() as FavoriteReportInterface;
                favRep.uid = item.id;
                favoriteReport.push(favRep);
            })
            return favoriteReport;
        });
    }

    static saveImage = (serialPath: string, appointmentId: number, patientId: number, data: any) => {
        const imageDoc = firebaseDatabase.collection(`/${serialPath}/patients/${patientId}/images`).doc();
        const uid = imageDoc.id;
        delete data.uid;

        return imageDoc.set(data)
            .then(() => {
                return firebaseDatabase.doc(`/${serialPath}/appointments/${appointmentId}/images/${uid}`).set({
                    path: imageDoc
                })
                    .then(() => {
                        return uid;
                    })
            })
            .catch(e => {
                console.log("Error", e);
                return e;
            })
    }

    static updateImage = (serialPath: string, patientId: number, data: ImageInterface) => {
        const imageDoc = firebaseDatabase.doc(`/${serialPath}/patients/${patientId}/images/${data.id}`);

        return imageDoc.update(data);
    }

    static getAppointmentPatientImages = (appointment: any) => {
        const imagesCollection = firebaseDatabase.collection(`/${appointment.serialPath}/appointments/${appointment.id}/images`);

        return imagesCollection.get()
            .then((snapshot) => {
                return Promise.all(snapshot.docs.map((doc) => {
                    return new Promise((resolve, reject) => {
                        const imagePath: DocumentReference = doc.data().path;
                        resolve(imagePath.get());
                    })
                }))
                    .then((images: Array<any>) => {
                        return images.map((img) => {
                            const image = img.data();
                            image.uid = img.id?.toString();
                            image.serialPath = appointment.serialPath;

                            return image;
                        })
                    })
            })
    }

    static getPatientImages = (patientId: number, serialPath: string)/*: Array<ImageInterface>*/ => {
        const imagesCollection = firebaseDatabase.collection(`/${serialPath}/patients/${patientId}/images`);

        return imagesCollection.get()
            .then((snapshot) => {
                return snapshot.docs.map((imageDoc: { data: Function, id: any }) => {
                    const image = imageDoc.data();
                    image.uid = imageDoc.id.toString();

                    return image;
                })
            })
    }
}

