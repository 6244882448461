import i18next from "../utils/i18nextTranslation";

export class DateUtil {
    
    public calcAgeAndMonths(bornDate: string) {// DD/MM/YYYY
        if (!bornDate || !bornDate.match(/[\d]{2}\/[\d]{2}\/[\d]{4}/g)) {
            return "";
        }
        const { years, months, days } = this.calcYearsMonthsDays(bornDate);
        //only days of live
        if (years === 0 && months === 0) {
            return `${days} ${i18next.t("dias")}.`;
        }
        //year and month
        return `${years} ${i18next.t("anos")}, ${months} ${i18next.t("meses")}.`;
    }

    private calcYearsMonthsDays(bornDate: string): { years: number; months: number; days: number; } {
        const [bD, bM, bY] = bornDate.split("/");
        const bornYear = parseInt(bY);
        const bornMonth = parseInt(bM);
        const bornDay = parseInt(bD);

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const currentDay = currentDate.getDate();

        let years: number = 0;
        let months: number = 0;
        let days: number = 0;

        //invalid
        if (bornYear > currentYear) {
            return { years, months, days };
        }
        //newborn
        else if (bornYear === currentYear) {
            //month completed
            if (bornMonth < currentMonth) {
                months = currentMonth - bornMonth;
            }
            //only days
            else {
                days = currentDay - bornDay;
            }
        }
        //birthday
        else if (bornMonth === currentMonth) {
            //birthday or completed year
            if (bornDay === currentDay || bornDay < currentDay) {
                years = currentYear - bornYear;
            }
            //year not completed
            else {
                years = currentYear - bornYear - 1;
                months = 11;
            }
        }
        //birth past
        else if (bornMonth < currentMonth) {
            years = currentYear - bornYear;
            months = currentMonth - bornMonth;
            //completed month - inrrelevant
            if (bornDay < currentDay) { }
        }
        //birth come
        else if (bornMonth > currentMonth) {
            years = currentYear - bornYear - 1;
            months = 12 - (bornMonth - currentMonth);
            //completed month - inrrelevant
            if (bornDay < currentDay) { }
        }
        return { years, months, days };
    }

    public getDateToString(d: Date, format?: string): string {
        let day = correctNumber(d.getUTCDate());
        let month = correctNumber(d.getUTCMonth() + 1);
        let year = d.getUTCFullYear().toString();
        let hour = correctNumber(d.getUTCHours());
        let minutes = correctNumber(d.getUTCMinutes());
        let seconds = correctNumber(d.getUTCSeconds());

        if (!format) return `${day}/${month}/${year}`;
        else {
            format = format.replace(/dd/g, day);
            format = format.replace(/MM/g, month);
            format = format.replace(/yyyy/g, year);
            format = format.replace(/hh/g, hour);
            format = format.replace(/mm/g, minutes);
            format = format.replace(/ss/g, seconds);
            return format;
        }


        function correctNumber(number: number): string {
            if (number < 10) return '0' + number;
            else return number.toString();
        }
    }

    public toJSONLocal(d: Date): string {
        if (!d) return "";
        d = this.getUTCDateToLocalDate(d);
        return d.toJSON();
    }

    public getUTCDateToLocalDate(d: Date): Date {
        let offsetInMilliseconds = d.getTimezoneOffset() * 60 * 1000;
        let date = d.getTime() - offsetInMilliseconds;
        return new Date(date);
    }

    public dateOnlyStringToDate(dateOnly: string) {
        const [year, month, day] = dateOnly.split('-');
        return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    }
}
