import { ActionInterface } from "../actions/interface/ActionInterface"
import { LogoutTypes } from "../actions/types/ActionsTypes";
import { LogoutState } from "../datatypesInterfaces/dataTypeInterfaces";

const INITIAL_STATE: LogoutState = {
    logout: false,
}

const Logout = (state = INITIAL_STATE, action: ActionInterface) => {
    switch (action.type) {
        case LogoutTypes.SET_LOGOUT:
            return {
                ...state,
                logout: action.payload
            }
        default:
            return state;
    }
}

export { Logout };
