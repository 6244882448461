import { ActionInterface } from "../actions/interface/ActionInterface"
import { HeaderBarTypes } from "../actions/types/ActionsTypes";
import { HeaderBarState } from "../datatypesInterfaces/dataTypeInterfaces";

const INITIAL_STATE: HeaderBarState = {
    title: '',
}

const HeaderBar = (state = INITIAL_STATE, action: ActionInterface) => {
    switch (action.type) {
        case HeaderBarTypes.SET_HEADER_TITLE:
            return {
                ...state,
                title: action.payload
            }
        default:
            return state;
    }
}

export { HeaderBar };