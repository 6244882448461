import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import zscanLogo from '../../assets/svg/logo-zscan.svg';

import CardSelectLang from './card-select-lang';

import Swal from "sweetalert2";

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from '../utils/i18nextTranslation';
import { useSelector, useDispatch } from 'react-redux';

import { version } from '../../../package.json';
import { ApplicationState } from '../../store';

import { setLogout } from '../../store/actions/logout';
import { open } from 'inspector';

declare global {
    interface Window {
        MessageBirdChatWidget: any;
    }
}

const FooterBar = () => {
    const [lang, setLang] = useState('');
    const [showLangModal, setShowLangModal] = useState(false);

    const history = useHistory();

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleSelectLang = (l: string) => {
        setShowLangModal(false);
        setLang(l);
        i18next.changeLanguage(l, (err: Error) => { if (!!err) console.error(err) });
    }

    const language = i18next.language;

    useEffect(() => {
        if (!lang && !!language) {
            setLang(language);
        }
    }, [lang, language]);

    const position = {
        position: "fixed",
        right: 5,
        bottom: 30,
        height: 300,
        width: 300, zIndex: 99999
    }

    const openMessageBird = () => {
        if (window.MessageBirdChatWidget.hidden || !window.MessageBirdChatWidget.isOpen) {
            window.MessageBirdChatWidget.show();
            window.MessageBirdChatWidget.toggleChat(true);
        } else {
            window.MessageBirdChatWidget.hide();
        }
    }

    const logout = () => {
        Swal.fire({
            title: t("Sair"),
            text: t("Você deseja mesmo sair do iLaudo?"),
            icon: "warning",
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            showCancelButton: true,
            confirmButtonText: t("Sim"),
            cancelButtonText: t("Não"),
        }).then(resp => {
            if (resp.value) {
                setLogout(true, dispatch);   
            }
        })
    }

    return (
        <div className="fixed-bottom bg-primary w-100 p-1 px-2 m-0 d-flex flex-row justify-content-between align-items-center">
            <div>
                <span onClick={() => logout()} className="btn btn-sm py-0 px-1 btn-primary ml-1 text-light" title={t("Sair")} id="footer-btn-logout">
                    <i className="bi bi-box-arrow-left m-0"></i>
                </span>

                <span className="text-white">
                    {t("Versão")}: {version}
                </span>
                
            </div>
            <div>
                <CardSelectLang lang={lang} visible={showLangModal} position={position} onCloseClick={() => { setShowLangModal(false) }} onChange={handleSelectLang} />

                <button title={t("Idioma")} className="btn btn-sm btn-primary py-0 my-0 mr-1" onClick={() => { setShowLangModal(true) }}>
                    {lang}
                </button>

                <button className="btn btn-sm btn-primary py-0 my-0 mr-1" title={t("Suporte")} onClick={() => openMessageBird()}>
                    <i className="bi bi-question-circle"></i>
                </button>

                <a target="_blank" href="https://forms.gle/4M2NKucphs9LdqFz9" title={t("Feedback")} className="btn btn-sm btn-primary py-0 my-0 mr-1">
                    <i className="bi bi-chat"></i>
                </a>

                <i className="small text-white ml-2 mr-1"> {t('Powered By')} </i>

                <a target="_blank" href="https://zscansoftware.com" title="Zscan Software">
                    <img src={zscanLogo} alt="Zscan Software" width="48" className="mx-1" />
                </a>
            </div>
        </div>
    );
}

export default FooterBar;
