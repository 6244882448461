import { ActionInterface } from "../actions/interface/ActionInterface"
import { ReferringPhysicianTypes } from "../actions/types/ActionsTypes";
import { ReferringPhysicianState } from "../datatypesInterfaces/dataTypeInterfaces";

const INITIAL_STATE: ReferringPhysicianState = {
    referringPhysiciansToList: [],
}

const ReferringPhysician = (state = INITIAL_STATE, action: ActionInterface) => {
    switch (action.type) {
        case ReferringPhysicianTypes.REFERRING_PHYSICIAN_TO_LIST:
            return {
                ...state,
                referringPhysiciansToList: action.payload
            }
        default:
            return state;
    }
}

export { ReferringPhysician };