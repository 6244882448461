import { ActionInterface } from "../actions/interface/ActionInterface"
import { ExamTypes } from "../actions/types/ActionsTypes";
import { ExamState } from "../datatypesInterfaces/dataTypeInterfaces";

const INITIAL_STATE: ExamState = {
    examsToList: [],
    favoriteReports: [],
}

const Exam = (state = INITIAL_STATE, action: ActionInterface) => {
    switch(action.type) {
        case ExamTypes.EXAM_TO_LIST:
            return {
                ...state,
                examsToList: action.payload
            }
        case ExamTypes.SET_FAVORITE_REPORTS:
            return {
                ...state,
                favoriteReports: action.payload
            }
        default:
            return state;
    }
}

export { Exam };
