import { QueryWhereInterface } from "../../interfaces/queryWhereInterface";

export class firebaseServiceUtils {
    constructor() {

    }


    public static objectQueryToFirebaseQuery(documentReference: any, query: QueryWhereInterface | Array<QueryWhereInterface>) {
        if (Array.isArray(query)) {
            query.forEach((qur: QueryWhereInterface) => {
                documentReference = documentReference.where(qur.fieldName, qur.operator, qur.valueCompare) as any;
            });

        } else {
            documentReference = documentReference.where(query.fieldName, query.operator, query.valueCompare) as any;
        }

        return documentReference;
    }
}