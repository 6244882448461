import { ActionInterface } from "../actions/interface/ActionInterface"
import { FavoriteReportState } from "../datatypesInterfaces/dataTypeInterfaces"
import { FavoriteReportTypes } from "../actions/types/ActionsTypes";

const INITIAL_STATE: FavoriteReportState = {
    data: {
        uid: "",
        id: 0,
        favoriteTitle: "",
        favoriteReport: false,
        favoriteAnswers: [],
        templateId: ""
    },
    modal: {
        isOpen: false,
    },
    table: {
        inputSearch: "",
        rowsOnTable: 0,
        firstVisible: null,
        lastVisible: null,
        previousFirstValues: [],
        pageIndex: 0,
        offsetTotal: 0,
        enablePreviousButton: false,
        enableForwardButton: false,
    }
}

const FavoriteReport = (state = INITIAL_STATE, action: ActionInterface): FavoriteReportState => {
    switch (action.type) {
        case FavoriteReportTypes.SET_INPUT_SEARCH_VALUE:
            return {
                ...state,
                table: {
                    ...state.table,
                    inputSearch: action.payload
                }
            }
        case FavoriteReportTypes.SET_ROWS_ON_TABLE:
            return {
                ...state,
                table: {
                    ...state.table,
                    rowsOnTable: action.payload
                }
            }
        case FavoriteReportTypes.SET_FIRST_VISIBLE:
            return {
                ...state,
                table: {
                    ...state.table,
                    firstVisible: action.payload
                }
            }
        case FavoriteReportTypes.SET_LAST_VISIBLE:
            return {
                ...state,
                table: {
                    ...state.table,
                    lastVisible: action.payload
                }
            }
        case FavoriteReportTypes.SET_PREVIOUS_FIRST_VALUES:
            return {
                ...state,
                table: {
                    ...state.table,
                    previousFirstValues: action.payload
                }
            }
        case FavoriteReportTypes.SET_PAGE_INDEX:
            return {
                ...state,
                table: {
                    ...state.table,
                    pageIndex: action.payload
                }
            }
        case FavoriteReportTypes.SET_OFFSET_TOTAL:
            return {
                ...state,
                table: {
                    ...state.table,
                    offsetTotal: action.payload
                }
            }
        case FavoriteReportTypes.SET_ENABLE_PREVIOUS_BUTTON:
            return {
                ...state,
                table: {
                    ...state.table,
                    enablePreviousButton: action.payload
                }
            }
        case FavoriteReportTypes.SET_ENABLE_FORWARD_BUTTON:
            return {
                ...state,
                table: {
                    ...state.table,
                    enableForwardButton: action.payload
                }
            }
        case FavoriteReportTypes.SET_MODAL_OPEN:
            return {
                ...state,
                modal: {
                    isOpen: true
                }
            }
        case FavoriteReportTypes.SET_MODAL_CLOSED:
            return {
                ...state,
                modal: {
                    isOpen: false
                }
            }
        case FavoriteReportTypes.RESET_PAGINATION:
            return {
                ...state,
                table: {
                    inputSearch: "",
                    rowsOnTable: 0,
                    firstVisible: null,
                    lastVisible: null,
                    previousFirstValues: [],
                    pageIndex: 0,
                    offsetTotal: 0,
                    enablePreviousButton: false,
                    enableForwardButton: false,
                }
            }
        default:
            return state;
    }
}

export { FavoriteReport };