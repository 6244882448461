import { baseURL } from '../config/api-config';
import { ExameInterface } from '../interfaces/new/exameInterface';
import { LegendaInterface } from '../interfaces/new/legendaInterface';
import { HttpClient } from './httpClient';
import { ParamsRequest } from './paramsRequest';
 
export class LegendaService {
    private httpClient: HttpClient;
    constructor() {
        this.httpClient = new HttpClient();
    }

    async lista(params: any) {
        const paramsRequest = new ParamsRequest;

        paramsRequest.baseURL = baseURL;
        paramsRequest.url = `/legenda`;
        paramsRequest.params = params;

        return await this.httpClient.get(paramsRequest);
    }

    async inserir(legenda: LegendaInterface): Promise<number> {
        return await this.httpClient.post({ url: '/legenda', baseURL: baseURL, data: legenda });
    }

    async atualizar(legenda: LegendaInterface): Promise<number> {
        return await this.httpClient.put({
            url: `/legenda/${legenda.idLegenda}`,
            baseURL: baseURL,
            data: legenda
        });
    }

    async deletar(legenda: LegendaInterface): Promise<number> {
        return await this.httpClient.delete({
            url: `/legenda/${legenda.idLegenda}`,
            baseURL: baseURL
        });
    }
}