import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import { ApplicationState } from '../../store';
import { setCropper, setImageData } from '../../store/actions/imageEditor';
import { ImageEditorCanvas } from './image-editor-canvas-component';

const ImageEditorImage = (props: any) => {
    const { imageURL, cropper, imageData, isFabricEdition } = useSelector((state: ApplicationState) => state.imageEditor);
    const dispatch = useDispatch();

    const getCropOptions = (): any => {
        let opt: any = {
            aspectRatio: null,
            autoCrop: false,
            background: false,
            checkCrossOrigin: true,
            checkOrientation: true,
            cropBoxMovable: true,
            cropBoxResizable: true,
            data: null,
            dragMode: "none",
            guides: true,
            highlight: true,
            minContainerWidth: 100,
            minContainerHeight: 100,
            minCanvasWidth: 0,
            minCanvasHeight: 0,
            minCropBoxWidth: 0,
            minCropBoxHeight: 0,
            modal: true,
            movable: true,
            preview: "",
            responsive: true,
            restore: true,
            rotatable: true,
            scalable: true,
            toggleDragModeOnDblclick: true,
            viewMode: 0,
            wheelZoomRatio: 0.1,
            zoomable: true,
            zoomOnTouch: true,
            zoomOnWheel: true,
            ready: () => {
                _showImageInfo();
            },
            crop: (evt: any) => {
                _showImageInfo();
            },
            // cropstart: (evt: any) => {
            //     if (evt.detail.action === "crop") {
            //         this.cropping = true;
            //         this.showHideModalApply(true);
            //     }
            // },
            // cropend: (evt: any) => { },
            // cropmove: () => {
            // },
            // zoom: (event: any) => {
            //     if (event.detail.ratio < 0.2 || event.detail.ratio > 8) {
            //         event.preventDefault();
            //     }
            //     this.showImageInfo();
            // },
        };
        return opt;
    }

    const _showImageInfo = () => {
        if (!imageData && cropper && cropper.current) {
            setImageData(cropper.current.getImageData(), dispatch);
        }
        // const template = "Image Info:"
        //     + "left=" + this.imageDataLeft + ", "
        //     + "top=" + this.imageDataTop + ", "
        //     + "width=" + this.imageData.width + ", "
        //     + "height=" + this.imageData.height + ", "
        //     + "naturalWidth=" + this.imageData.naturalWidth + ", "
        //     + "naturalHeight=" + this.imageData.naturalHeight + ", "
        //     + "aspectRatio=" + this.imageData.aspectRatio + ", "
        //     + "rotate=" + this.imageData.rotate + ", "
        //     + "scaleX=" + this.imageData.scaleX + ", "
        //     + "scaleY=" + this.imageData.scaleY + "."

        // this.spanImageInfo.innerHTML = template;
    }

    if (!cropper) {
        setCropper(React.createRef<Cropper>(), dispatch);
    }

    const cropOptions = getCropOptions();

    return (
        <>
            {
                !isFabricEdition ?
                    <>
                        <img className="position-absolute mw-100 mh-100 border border-warning cropper-hidden" id="img" src={imageURL}></img>
                        <Cropper
                            {...cropOptions}
                            ref={cropper}
                            src={imageURL}
                            style={{ height: "100%", width: "100%" }}
                        />
                    </> :
                    <ImageEditorCanvas />
            }
        </>
    )
}

export { ImageEditorImage };