import { createStore, Store } from 'redux';
import reducers from './reducers';

// alphabetically import
import {
    AppState,
    ExamState,
    FavoriteReportState,
    HeaderBarState,
    ImageEditorState,
    LoadingState,
    PatientState,
    PhysicianState,
    ReferringPhysicianState,
    ReportState,
    ReportDataState,
    StopListeningState,
    ViewSelectState,
    NotificationState,
    TawkToState,
    LogoutState,
} from './datatypesInterfaces/dataTypeInterfaces'
import { ProfissionalSaudeInterface } from '../interfaces/new/profissionalSaudeInterface';

// export interface ApplicationState {
//     loading: LoadingState;
//     headerBar: HeaderBarState;
//     reportData: ReportDataState;
//     viewSelect: ViewSelectState;
//     app: AppState,
//     exam: ExamState;
//     favoriteReport: FavoriteReportState;
//     imageEditor: ImageEditorState,
//     patient: PatientState;
//     physician: PhysicianState;
//     referringPhysician: ReferringPhysicianState;
//     report: ReportState,
//     notification: NotificationState,
//     tawkTo: TawkToState,
//     logout: LogoutState,
//     stopListening: StopListeningState
// }

export interface ApplicationState {
    loading: LoadingState;
    headerBar: HeaderBarState;
    reportData: ReportDataState;
    viewSelect: ViewSelectState;
    app: AppState,
    exam: ExamState;
    favoriteReport: FavoriteReportState;
    imageEditor: ImageEditorState,
    patient: PatientState;
    physician: PhysicianState;
    medicoSolicitante: ProfissionalSaudeInterface;
    report: ReportState,
    notification: NotificationState,
    tawkTo: TawkToState,
    logout: LogoutState,
    stopListening: StopListeningState
}

const store: Store<ApplicationState> = createStore(reducers);

export default store;