import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, useRouteMatch, useHistory } from "react-router-dom";

import Footer from "./components/footer-component";

import Profile from "./pages/profile-page";
import Report from "./pages/report-page";

import PatientView from "./pages/patient-view-page";
import ReportList from "./pages/report-list-page"
import FooterBar from "./elements/footer-bar-element";
import ilaudoLogo from '../assets/icons/logo128.png';


// Utis/Tools
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// States/redux
import { ApplicationState } from "../store";
import { logoutClinica, setClinicaSelecionada } from "../store/actions/app";

import PrivateRoute from "../utils/privateRoute-utils";
import { LocalStorageUtils } from "../utils/localStorage-utils";
import { UsuarioInterface } from "../interfaces/new/usuarioInterface";

let intervalTimer: any;

const App = () => {
    const { usuario, clinicaSelecionada } = useSelector((state: ApplicationState) => state.app);
    const { mostrarBotaoSincronizacao } = useSelector((state: ApplicationState) => state.app)
    const dispatch = useDispatch();
    let match: any = useRouteMatch();
    const { t } = useTranslation();

    const history = useHistory();

    const routes = {
        reportList: `${match.path}`,
        dashboard: `${match.path}/dashboard`,
        profile: `${match.path}/profile`,
        patient: `${match.path}/patient`,
        patientview: `${match.path}/patientView`,
        report: `${match.path}/report`,
        section: `${match.path}/section`,
        template: `${match.path}/template`,
    }

    useEffect(() => {
        if (!usuario || !usuario.contratos?.length || !clinicaSelecionada) {
            history.replace("/login");
        } else if (!clinicaSelecionada.idContrato) {
            setInformacaoClinicaSelecionada();
        }
    }, [clinicaSelecionada, usuario]);

    useEffect(() => {
        logoutForExpireDate();
        if (intervalTimer) {
            clearInterval(intervalTimer)
        }

        intervalTimer = setInterval(logoutForExpireDate, 6e4);
    }, []);

    function logoutForExpireDate() {
        const date = LocalStorageUtils.buscar<number>('expireDate') || 0;
        const usuario = LocalStorageUtils.buscar<UsuarioInterface>('usuario');

        if (!usuario || !date || date < new Date().getTime()) {
            logoutClinica(dispatch);
        }
    }

    function setInformacaoClinicaSelecionada() {
        let licencaIlaudo = usuario.contratos.filter((licenca) => licenca.produto.idProdutoLegado === 'iLaudo2');

        if (licencaIlaudo.length) {
            setClinicaSelecionada(licencaIlaudo[0] as any, dispatch);
        }
    }

    return (
        <BrowserRouter>
            {/* <SideMenu routes={routes} /> */}
            <div id="content-wrapper" className="d-flex flex-column bg-white">
                <div id="content">
                <nav className="site-header sticky-top py-1 bg-secondary mb-1">
                    <div className="container d-flex flex-column flex-md-row ml-2">
                        <div className="row ml-1">
                        <img src={ilaudoLogo} width="24" alt="" />
                        <p className="h5 my-0 py-0 text-white mt-1 h5" style={{ marginLeft: 2, paddingTop: 10 }}>{t("iLaudo Editor")}</p>
                        <span className="text-white text-center h5" style={{ paddingTop: 4, position: "fixed", right: 20, zIndex: 99999}}>
                        <i className="bi bi-person-circle mr-2"></i>
                        {usuario.primeiroNome} {usuario.ultimoNome}
                        </span>
                    </div>
                    </div>
                </nav>
                    {/* <HeaderBar doctor={doctor} clinics={clinics} selectedClinicName={(!!selectedClinicDocReport) && selectedClinicDoc.clinic.nome ? selectedClinicDoc.clinic.nome : ""} /> */}
                    <Switch>
                        <PrivateRoute condition={usuario?.contratos?.length && clinicaSelecionada}>
                            <Route exact path={routes.profile}>
                                <Profile
                                    user={usuario}
                                />
                            </Route>
                            {/* <Route exact path={routes.patient}>
                                <Patient
                                    clinicPath={(!!selectedClinic.clinicDoc) && selectedClinic.clinicDoc.ref.path}
                                    patients={patients}
                                />
                            </Route>
                            <Route exact path={routes.report} render={(props) => <Report {...props} />}>
                                    clinicPath={(!!selectedClinicDoc.clinicDoc) && selectedClinicDoc.clinicDoc.ref.path}                                
                                />
                            </Route> */}
                            <Route exact path={routes.report} render={(props) => <Report {...props as any} />}>
                            </Route>
                            <Route exact path={routes.patientview} render={(props) => <PatientView {...props} />}>
                            </Route>

                            <Route exact path={routes.reportList}>
                                <ReportList />
                                {/* {
                                    mostrarBotaoSincronizacao && <FloatingActionButton type="info" title={t("Atualizar dados")} icon="bi bi-arrow-repeat-alt" />
                                } */}
                            </Route>
                        </PrivateRoute>
                    </Switch>
                    <Footer />
                </div>
            </div>
            <FooterBar />
        </BrowserRouter>
    );
};

export default App;