import { baseURL } from '../config/api-config';
import { ExameInterface } from '../interfaces/new/exameInterface';
import { LaudoFavoritoInterface } from '../interfaces/new/laudoFavoritoInterface';
import { LegendaInterface } from '../interfaces/new/legendaInterface';
import { HttpClient } from './httpClient';

export class LaudoFavoritoService {
    private httpClient: HttpClient;
    constructor() {
        this.httpClient = new HttpClient();
    }

    async maisUtilizados(idExame: number) {
        return await this.httpClient.get({ url: `/laudofavorito/maisutilizados`, baseURL: baseURL, params: { idExame } });
    }

    async atualizaMaisUtilizados(idLaudoFavorito: number): Promise<number> {
        return await this.httpClient.put({ url: `/laudofavorito/maisutilizados/${idLaudoFavorito}`, baseURL: baseURL });
    } 

    async lista() {
        return await this.httpClient.get({ url: `/laudofavorito`, baseURL: baseURL });
    }

    async listaPorIdExame(idExame: number) {
        return await this.httpClient.get({ url: `/laudofavorito`, baseURL: baseURL, params: { idExame } });
    }

    async inserir(laudoFavorito: LaudoFavoritoInterface): Promise<number> {
        return await this.httpClient.post({ url: '/laudofavorito', baseURL: baseURL, data: laudoFavorito });
    }

    async deletar(laudoFavorito: LaudoFavoritoInterface): Promise<number> {
        return await this.httpClient.delete({
            url: `/laudofavorito/${laudoFavorito.idLaudoFavorito}`,
            baseURL: baseURL
        });
    }
}