import { ActionInterface } from "../actions/interface/ActionInterface"
import { StopListeningTypes } from "../actions/types/ActionsTypes";
import { StopListeningState } from "../datatypesInterfaces/dataTypeInterfaces";

const INITIAL_STATE: StopListeningState = {
    stopListening: false,
}

const StopListening = (state = INITIAL_STATE, action: ActionInterface) => {
    switch (action.type) {
        case StopListeningTypes.SET_STOPLISTENING:
            return {
                ...state,
                stopListening: action.payload
            }
            default:
                return state;
    }
}

export { StopListening };