import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { baseURL } from "../../config/api-config";


export const sendForgetPassword = (email: string) => {
    const localLanguage = localStorage.getItem('i18nextLng') || navigator.language
    const options = {
        method: 'POST',
        url: `${baseURL}/auth/recuperacaosenha/ilaudo`,
        data: { email, localLanguage }
    }

    return Axios(options as AxiosRequestConfig)
        .then((resp: AxiosResponse) => {
            if (resp.status >= 200 || resp.status < 300) {
                return resp.data;
            }
            return false;
        })
        .catch((err: Error) => {
            console.error(err);
            return false;
        });
}
