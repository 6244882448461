import React, { useEffect } from 'react';


// tool/utils
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';


//Interfaces 
import { ApplicationState } from '../../store';
import {
    hideModalDrawArrow,
    setArrowOptions,
} from '../../store/actions/imageEditor';


// states/redux



const ImageEditorDrawArrowModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        arrowOptions,
        modalDrawArrowEnable,
    } = useSelector((state: ApplicationState) => state.imageEditor);


    return (
        <div className={`position-absolute bg-light w-25 border text-center rounded ${modalDrawArrowEnable ? "" : "d-none"}`} style={{ bottom: 0 }} id="div-draw-arrow-editor">
            <div className="form-group">
                <label className="small text-black">{t("Espessura da linha")}</label>
                <input className="form-control" type="range" value={arrowOptions.strokeWidth} min="1" max="50" id="input-arrow-line-width" onChange={(event) => setArrowOptions({ strokeWidth: parseInt(event.target.value) }, dispatch)} />
            </div>
            
            <div className="form-group">
                <label className="small text-black">{t("Contorno")}</label>
                <input className="form-control" type="color" value={arrowOptions.stroke} style={{ backgroundColor: arrowOptions.stroke }} id="input-arrow-stroke-line-color" onChange={(event) => setArrowOptions({ stroke: event.target.value }, dispatch)} />
            </div>
            
            <div className="form-group">
                <label className="small text-black">{t("Preenchimento")}</label>
                <input className="form-control" type="color" value={arrowOptions.fill} style={{ backgroundColor: arrowOptions.fill }} id="input-arrow-fill-line-color" onChange={(event) => setArrowOptions({ fill: event.target.value }, dispatch)} />
            </div>

            <button className="btn btn-success btn-sm mr-2 m-1" id="btn-apply-elements-draw-arrow" onClick={(event) => hideModalDrawArrow(dispatch)}>
                <i className="mr-2 fa fa-check"></i>
                {t("Sair do desenho da seta")}
            </button>
        </div>
    )
}


export { ImageEditorDrawArrowModal };