function getBaseURL() {
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
        return "http://localhost:3003";
     }
    if (window.location.hostname === "editor-homologacao.ilaudo.com") {
        return "https://api-homologacao.zscansoftware.com";
    }
    return "https://api.zscansoftware.com";
}
export const baseURL = getBaseURL();

export const commonHeaders = {
    "Content-Type": "application/json",
    "Authorization": "Bearer wCp2IgxHdEwA2ROHkTh7YAE4snyu5zxM" //view functions passport
};