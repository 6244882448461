import React from 'react';

import PageContainer from '../elements/page-container-element';

import nonprofile from '../../assets/svg/non-profile.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setHeaderTitle } from '../../store/actions/headerBar';

const Profile = (props: any) => {
    const { displayName, email, photoURL } = props.user;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    setHeaderTitle(t("Perfil"), dispatch)
    return (
        <PageContainer>
            <div className="row justify-content-center">
                <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 text-center">
                    <img className="w-100 rounded-circle" src={photoURL || nonprofile} style={{ maxWidth: '200px' }} alt={displayName} />
                </div>
                <div className='col-xl-10 col-12'>
                    <div className='row'>
                        <div className="col-xl-6 col-md-12 col-12 mb-3">
                            <label htmlFor="userName">{t("Nome")}</label>
                            <div className="input-group">
                                <input type="text" className="form-control" id="userName" readOnly value={displayName} />
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12 col-12 ">
                            <label htmlFor="userEmail">{t("Email")}</label>
                            <div className="input-group">
                                <input type="text" className="form-control" id="userEmail" readOnly value={email} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageContainer>
    );
}


export default Profile;
