import { baseURL } from '../config/api-config';
import { HttpClient } from './httpClient';

export class ImageService {
    private httpClient: HttpClient;
    constructor() {
        this.httpClient = new HttpClient();
    }

    async buscaPorPaciente(idPaciente: number) {
        return await this.httpClient.get({ url: `/imagem/paciente/${idPaciente}`, baseURL: baseURL });
    }

    async inserir(data: any): Promise<any> {
        return await this.httpClient.post({ url: '/imagem', baseURL: baseURL, data: data });
    }

    async atualizarImagem(idImagem: number, data: any): Promise<any> {
        return await this.httpClient.put({ url: `/imagem/${idImagem}`, baseURL: baseURL, data: data });
    }
    
    async deleteImage(idImage: number) {
        try{
            return await this.httpClient.delete({ url: `/imagem/${idImage}`, baseURL: baseURL});  
        }catch(err){
            return err
        }
    }
}