import { baseURL } from '../config/api-config';
import { HttpClient } from './httpClient';

export class ModeloService {
    private httpClient: HttpClient;
    constructor() {
        this.httpClient = new HttpClient();
    }

    async buscaPorId(idModelo: number) {
        return await this.httpClient.get({ url: `/modelo/${idModelo}`, baseURL: baseURL });
    }
}