import { baseURL } from '../config/api-config';
import { HttpClient } from './httpClient';

export class AuthService {
  private httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient();
  }

  async login(username: string, password: string) {
    try {
      return await this.httpClient.post({ url: '/auth/medico', baseURL: baseURL, data: { username, password } });
    } catch (error) {
      return { erro: error }
    }
  }
}