import React, { useState, useEffect, useRef, createRef } from "react";

// Elements
import Table from "./table-element";
import { SpeechRecognitionButton } from "../components/speech-recognition-button-component";


// Tools/Utils
import Swal from "sweetalert2";
import { FirebaseService } from "../services/firebaseService";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";


// Interfaces
import { PredefinitionReportAnswerInterface } from "../../interfaces/predefinitionReportAnswerInterface";


// States/Redux
import { showLoading, hideLoading } from "../../store/actions/loading";
import { setStopListening } from "../../store/actions/stopListening";
import { RespostaPredefinidaInterface } from "../../interfaces/new/respostaPredefinidaInterface";
import { RespostaPredefinidaService } from "../../service/respostasPredefinidas.service";
import { CampoInterface } from "../../interfaces/new/campoInterface";
import { ApplicationState } from "../../store";
import { useMemo } from "react";

const ModalRespostasPredefinidas = (props: {
    campo?: CampoInterface,
    respostasPredefinidas: any,
    maisUtilizados: any,
    enablePreviousButton: boolean,
    enableForwardButton: boolean,
    callbackPredefinitionAnswerToField: Function,
    callbackUpdatePredefinitionAnswers: Function,
    callbackSearchPrevious?: Function,
    callbackSearchForward?: Function,
    callbackButtonSearch: Function,
}) => {
    const { t } = useTranslation();
    const campo = props.campo;

    const [respostasPredefinidas, setRespostasPredefinidas] = useState<any>();
    const [respostaPreDefinidasMaisUtilizadas, setRespostaPreDefinidasMaisUtilizadas] = useState<any>();

    useEffect(() => {
        setRespostasPredefinidas({ ...props.respostasPredefinidas });
        setRespostaPreDefinidasMaisUtilizadas({ ...props.maisUtilizados })
    }, [props.respostasPredefinidas, props.maisUtilizados]);

    const enablePreviousButton = props.enablePreviousButton;
    const enableForwardButton = props.enableForwardButton;
    const callbackRespostaPredefinidaParaCampo = props.callbackPredefinitionAnswerToField;
    const callbackUpdatePredefinitionAnswers = props.callbackUpdatePredefinitionAnswers;
    const callbackSearchPrevious = props.callbackSearchPrevious;
    const callbackSearchForward = props.callbackSearchForward;
    const callbackButtonSearch = props.callbackButtonSearch;

    const clinicaSelecionada = useSelector((state: ApplicationState) => state.app.clinicaSelecionada);

    const predefinitionAnswerDefault = {
        idRespostaPredefinida: undefined,
        idContrato: undefined,
        idExterno: undefined,
        idCampo: 0,
        texto: '',
        titulo: '',
        html: '',
        favorito: false,
        maisUtilizados: 0,
        idUsuarioCadastro: ''
    }

    const [textoToSearch, setTextoToSearch] = useState("")
    const [respostaPredefinida, setRespostaPredefinida] = useState<RespostaPredefinidaInterface>(predefinitionAnswerDefault);
    const [arrayRespostasSelecionadas, setArrayRespostasSelecionadas] = useState([] as Array<RespostaPredefinidaInterface>);
    const [mostrarDescricao, setMostrarDescricao] = useState<any>(null);
    const [respostaPredefinidaFiltro, setRespostaPredefinidaFiltro] = useState<RespostaPredefinidaInterface | any>(predefinitionAnswerDefault);

    const dispatch = useDispatch();

    useEffect(() => {
        const mostrarDescricaoRespostaPredefinida = localStorage.getItem("showPredefinitionAnswerDescription");

        if (mostrarDescricaoRespostaPredefinida === "true" || !mostrarDescricaoRespostaPredefinida) {
            setMostrarDescricao(true);
        } else {
            setMostrarDescricao(false);
        }
    }, []);

    useEffect(() => {
        if (Object.keys({ ...props.respostasPredefinidas }).length !== 0) {
            setRespostaPredefinidaFiltro({ ...props.respostasPredefinidas });
        }
        if(Object.keys({ ...props.maisUtilizados }).length !== 0){
            setRespostaPreDefinidasMaisUtilizadas({ ...props.maisUtilizados })
        }
    }, [props.respostasPredefinidas, props.maisUtilizados]);

    useMemo(() => {
        const propRespostaPredefinida = { ...props.respostasPredefinidas };
        if (Object.keys(propRespostaPredefinida).length !== 0) {
   
            if (propRespostaPredefinida?.values?.length > 0) {
                const respostasPredefinidasFiltradas =
                propRespostaPredefinida.values.filter(
                    (item: any) =>
                        item.titulo.toUpperCase().indexOf(textoToSearch.toUpperCase()) > -1
                    );
                
                setRespostaPredefinidaFiltro({  indexCampo: propRespostaPredefinida.indexCampo, values: respostasPredefinidasFiltradas });
            }
        
            if (propRespostaPredefinida?.values?.length > 0 && !textoToSearch) {
                setRespostaPredefinidaFiltro(propRespostaPredefinida);
            }
        }
    }, [textoToSearch, props.respostasPredefinidas, props.maisUtilizados]);

    const habilitarMostrarDescricao = () => {
        if (mostrarDescricao) {
            setMostrarDescricao(false);
            localStorage.setItem("showPredefinitionAnswerDescription", "false");
        } else {
            setMostrarDescricao(true);
            localStorage.setItem("showPredefinitionAnswerDescription", "true");
        }
    }

    const handleChange = (value: string | boolean | number, field: string) => {
        let resposta = {
            ...respostaPredefinida,
            [field]: value
        }

        setRespostaPredefinida(resposta);

    }

    const setHtmlRespostaPredefinida = (value: string) => {
        const respostaPredefinidaHtml: HTMLDivElement = document.querySelector('#predefinitionAnswerHtmlId') as HTMLDivElement;

        const text = value.replace(/\n/g, '<br/>');

        respostaPredefinidaHtml.innerHTML = text;
    }

    const getHtmlRespostaPredefinida = () => {
        const respostaPredefinidaHtml: HTMLDivElement = document.querySelector('#predefinitionAnswerHtmlId') as HTMLDivElement;

        return respostaPredefinidaHtml.innerHTML;
    }

    const updateArrayPredefinitionAnswers = (predefinitionAnswer: PredefinitionReportAnswerInterface, isNew: boolean = true) => {
        let predefinitionAnswersToUpdate = { ...respostasPredefinidas };

        if (isNew) {
            predefinitionAnswersToUpdate.values.push({ ...predefinitionAnswer, uid: predefinitionAnswer.uid })
        } else {
            let predefinitionAnswerIndex = (predefinitionAnswersToUpdate.values as Array<any>).findIndex((answer) => {
                return answer.uid === predefinitionAnswer.uid
            })

            predefinitionAnswersToUpdate.values[predefinitionAnswerIndex] = { ...predefinitionAnswer, uid: predefinitionAnswer.uid };
        }


        callbackUpdatePredefinitionAnswers(predefinitionAnswersToUpdate);
    }

    const salvar = () => {
      //  showLoading(dispatch);
        const data: RespostaPredefinidaInterface = {
            ...respostaPredefinida,
            idCampo: campo?.idCampo as number,
            idContrato: clinicaSelecionada.idContratoPrincipal,
            maisUtilizados: 0
        };

        if (data.idRespostaPredefinida) {
            atualizarRespostaPredefinida(data);
        } else {
            salvarRespostaPredefinida(data);
        }
    }

    const atualizarRespostaPredefinida = async (respostaPredefinida: RespostaPredefinidaInterface) => {
        try {
            const idRespostaPredefinida = await new RespostaPredefinidaService().atualizar({ ...respostaPredefinida, html: getHtmlRespostaPredefinida() });

            setRespostaPredefinida(predefinitionAnswerDefault);
            hideLoading(dispatch);
            Swal.fire(
                t("Resposta predefinida atualizada com sucesso"),
                t("Clique em ok para continuar"),
                "success"
            ).then(() => {
                callbackButtonSearch()
                mostrarListagemRespostasPredefinidas();
            })

        } catch (err) {
            Swal.fire(
                t(`Atualizar resposta predefinida`),
                `${t("Ocorreu um erro ao atualizar a resposta predefinida")}. ${t("Contate o suporte técnico")}.`,
                "error",
            );
            hideLoading(dispatch);
        }
    }

    const salvarRespostaPredefinida = async (respostaPredefinida: RespostaPredefinidaInterface) => {
        try {
            const idRespostaPredefinida = await new RespostaPredefinidaService().inserir({ ...respostaPredefinida, html: getHtmlRespostaPredefinida() });

            setRespostaPredefinida(predefinitionAnswerDefault);
            hideLoading(dispatch);
            Swal.fire({
                title: t("Resposta predefinida salva com sucesso"),
                text: t("Clique em ok para continuar"),
                icon: "success",
            }).then(() => {
                callbackButtonSearch()
                mostrarListagemRespostasPredefinidas();
            })

        } catch (err) {
            Swal.fire(
                t(`Salvar resposta predefinida`),
                `${t("Ocorreu um erro ao salvar a resposta predefinida")}. ${t("Contate o suporte técnico")}.`,
                "error",
            );
            hideLoading(dispatch);
        }
    }

    const deletarRespostaPredefinida = () => {
        Swal.fire({
            title: t("Deletar resposta predefinida"),
            text: t("Você deseja mesmo excluir a resposta predefinida?"),
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Sim"),
            cancelButtonText: t("Cancelar")
        }).then(async (result) => {
            if (result.value) {
                try {
                    const idRespostaPredefinida = await new RespostaPredefinidaService().deletar(respostaPredefinida);

                    setRespostaPredefinida(predefinitionAnswerDefault);

                    Swal.fire(
                        t("Resposta predefinida deletada com sucesso"),
                        t("Clique em ok para continuar"),
                        "success"
                    ).then(() => {
                        mostrarListagemRespostasPredefinidas();
                    })

                } catch (err) {
                    Swal.fire(
                        t(`Deletar resposta predefinida`),
                        `${t("Ocorreu um erro ao deletar a resposta predefinida")}. ${t("Contate o suporte técnico")}.`,
                        "error",
                    );
                    hideLoading(dispatch);
                }
            }
        })
    }

    const atualizarFavoritoRespostaPredefinida = async (respostaPredefinida: RespostaPredefinidaInterface) => {
        respostaPredefinida.favorito = !respostaPredefinida.favorito;

        const idRespostaPredefinida = await new RespostaPredefinidaService().atualizar(respostaPredefinida);
    }

    const atualizarRespostaMaisUtilizada = async (idRespostaPredefinida: RespostaPredefinidaInterface) => {
        await new RespostaPredefinidaService().atualizaMaisUtilizados(idRespostaPredefinida.idRespostaPredefinida);
    }

    const inserirArrayRespostaPredefinida = (predefinitionAnswer: RespostaPredefinidaInterface) => {
        setArrayRespostasSelecionadas([...arrayRespostasSelecionadas, predefinitionAnswer]);
    }

    const removerArrayRespostaPredefinida = (respostaPredefinida: RespostaPredefinidaInterface) => {
        const array = [...arrayRespostasSelecionadas];
        const indexResposta = array.findIndex((resposta: RespostaPredefinidaInterface) => {
            return resposta.idRespostaPredefinida === respostaPredefinida.idRespostaPredefinida;
        });

        if (indexResposta !== -1) {
            array.splice(indexResposta, 1);

            setArrayRespostasSelecionadas(array);
        }
    }

    const selecionarRespostasPredefinidas = () => {
        let resposta = "";

        for (let index = 0; index < arrayRespostasSelecionadas.length; index++) {
            if (index > 0) {
                resposta += `\n${arrayRespostasSelecionadas[index].texto}`;
            } else {
                resposta += `${arrayRespostasSelecionadas[index].texto}`;
            }
        }

        callbackRespostaPredefinidaParaCampo(respostasPredefinidas.indexCampo, resposta);
        setArrayRespostasSelecionadas([]);
    }

    const mostrarAdicionarRespostaPredefinida = () => {
        (document.querySelector("#predefinitionAnswersList") as HTMLDivElement).classList.add("d-none");
        (document.querySelector("#predefinitionAnswersAdd") as HTMLDivElement).classList.remove("d-none");
        // (document.querySelector("#addPredefinitionButtonDeleteId") as HTMLDivElement).classList.add("d-none");
    }

    const mostrarEditarRespostaPredefinida = () => {
        (document.querySelector("#predefinitionAnswersList") as HTMLDivElement).classList.add("d-none");
        (document.querySelector("#predefinitionAnswersAdd") as HTMLDivElement).classList.remove("d-none");
        // (document.querySelector("#addPredefinitionButtonDeleteId") as HTMLDivElement).classList.remove("d-none");
    }

    const fecharModal = () => {
        setStopListening(true, dispatch);
        (document.querySelector("#closePredefinitionAnswersModal") as HTMLButtonElement).click();
    }

    const mostrarListagemRespostasPredefinidas = () => {
        (document.querySelector("#predefinitionAnswersList") as HTMLDivElement).classList.remove("d-none");
        (document.querySelector("#predefinitionAnswersAdd") as HTMLDivElement).classList.add("d-none");
        // (document.querySelector("#addPredefinitionButtonDeleteId") as HTMLDivElement).classList.add("d-none");
    }

    const resetarModal = () => {
        setStopListening(true, dispatch);
        setRespostaPredefinida(predefinitionAnswerDefault);
        mostrarListagemRespostasPredefinidas();
    }

    const RespostasPredefinidasList = () => {
        let colums;

        if (mostrarDescricao) {
            colums = [t("Marcar"), t("Título"), t("Descrição"), t("Configuração")];
        } else {
            colums = [t("Marcar"), t("Título"), t("Configuração")];
        }

        return (
            <div id="predefinitionAnswersList">
                <div className="modal-body">
                    <div className="row mb-3">
                        <div className="input-group col">
                            <input id="predefinitionInputId" className="form-control" type="text" value={textoToSearch} onChange={(event) => setTextoToSearch(event.target.value)} placeholder={t("Buscar por")} />
                            <div className="input-group-append">
                                <button className="btn btn-secondary rounded-right" type="button" onClick={(event) => { callbackButtonSearch() }}>
                                    <i className="fas fa-search" aria-hidden="true"></i>
                                </button>
                            </div>

                            <SpeechRecognitionButton setReconizedText={setTextoToSearch} />
                        </div>
                        <div className="mr-4">
                            <div className="input-group-append">
                                <button id="buttonAdd" className="btn btn-primary" type="button" style={{width: 27, height: 27, padding: 4}} onClick={mostrarAdicionarRespostaPredefinida}>
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h4>Mais Utilizados</h4>
                        <Table itemsThead={colums}>
                            {
                                respostaPreDefinidasMaisUtilizadas && respostaPreDefinidasMaisUtilizadas.values && respostaPreDefinidasMaisUtilizadas.values.map((respostaPredefinida: RespostaPredefinidaInterface, index: number) => (
                                    <tr key={index} onDoubleClick={() => {
                                        setStopListening(true, dispatch);
                                        callbackRespostaPredefinidaParaCampo(respostasPredefinidas.indexCampo, respostaPredefinida.texto);
                                        atualizarRespostaMaisUtilizada(respostaPredefinida);
                                    }}>
                                        <td align="center" style={{ width: "40px" }}>
                                            <div className="custom-control custom-switch d-table-cell">
                                                <input className="custom-control-input checkbox-predefinition-answer" type="checkbox" id={`checkbox_${index + 1}`} onChange={(event) => {
                                                    if (event.target.checked) {
                                                        inserirArrayRespostaPredefinida(respostaPredefinida);
                                                    } else {
                                                        removerArrayRespostaPredefinida(respostaPredefinida)
                                                    }
                                                }} />
                                                <label className="custom-control-label" htmlFor={`checkbox_${index + 1}`}></label>
                                            </div>
                                        </td>
                                        <td>{respostaPredefinida.titulo}</td>
                                        {
                                            mostrarDescricao && <td className="w-75">{respostaPredefinida.texto}</td>
                                        }
                                        <td align="center" style={{ width: "75px" }}>
                                            <button type="button" className="btn btn-light border border-secondary btn-sm mr-1 bg-white" id="btnEdit"
                                                onClick={() => {
                                                    setRespostaPredefinida(respostaPredefinida);
                                                    mostrarEditarRespostaPredefinida();
                                                }}
                                            ><i className="fas fa-pencil-alt text-primary" aria-hidden="true"></i></button>
                                            <button type="button" className="btn btn-light border border-secondary btn-sm bg-white" id="btnEdit"
                                                onClick={() => {
                                                    atualizarFavoritoRespostaPredefinida(respostaPredefinida);
                                                }}
                                            >
                                                <i className={`text-warning ${respostaPredefinida.favorito ? "fas" : "far"} fa-star`} aria-hidden="true"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </Table>
                    </div>
                    <h4>Todos</h4>
                    <Table itemsThead={colums}>
                        {
                            respostaPredefinidaFiltro && respostaPredefinidaFiltro.values && respostaPredefinidaFiltro.values.map((respostaPredefinida: RespostaPredefinidaInterface, index: number) => (
                                <tr key={index} onDoubleClick={() => {
                                    setStopListening(true, dispatch);
                                    callbackRespostaPredefinidaParaCampo(respostasPredefinidas.indexCampo, respostaPredefinida.texto);
                                    atualizarRespostaMaisUtilizada(respostaPredefinida);
                                }}>
                                    <td align="center" style={{ width: "40px" }}>
                                        <div className="custom-control custom-switch d-table-cell">
                                            <input className="custom-control-input checkbox-predefinition-answer" type="checkbox" id={`checkbox_${index + 1}`} onChange={(event) => {
                                                if (event.target.checked) {
                                                    inserirArrayRespostaPredefinida(respostaPredefinida);
                                                } else {
                                                    removerArrayRespostaPredefinida(respostaPredefinida)
                                                }
                                            }} />
                                            <label className="custom-control-label" htmlFor={`checkbox_${index + 1}`}></label>
                                        </div>
                                    </td>
                                    <td>{respostaPredefinida.titulo}</td>
                                    {
                                        mostrarDescricao && <td className="w-75">{respostaPredefinida.texto}</td>
                                    }
                                    <td align="center" style={{ width: "75px" }}>
                                        <button type="button" className="btn btn-light border border-secondary btn-sm mr-1 bg-white" id="btnEdit"
                                            onClick={() => {
                                                setRespostaPredefinida(respostaPredefinida);
                                                mostrarEditarRespostaPredefinida();
                                            }}
                                        ><i className="fas fa-pencil-alt text-primary" aria-hidden="true"></i></button>
                                        <button type="button" className="btn btn-light border border-secondary btn-sm bg-white" id="btnEdit"
                                            onClick={() => {
                                                atualizarFavoritoRespostaPredefinida(respostaPredefinida);
                                            }}
                                        >
                                            <i className={`text-warning ${respostaPredefinida.favorito ? "fas" : "far"} fa-star`} aria-hidden="true"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </Table>
                    <div className="row">
                        <div className="col">
                            <div className="custom-control custom-switch float-right">
                                <input className="custom-control-input" type="checkbox" id="show-description" checked={mostrarDescricao} onChange={habilitarMostrarDescricao} />
                                <label className="custom-control-label" htmlFor="show-description">{t("Mostrar descrição")}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer p-0 pt-1 pb-1">
                    <div className="row p-0 m-0">
                        <div className="col-6">
                            <div className="text-right">
                                <button className={`btn btn-link ${enablePreviousButton ? "" : "disabled"}`} title={t("Anterior")} id="predefinitionButtonPreviousId"
                                    onClick={() => { /*callbackSearchPrevious(predefinitionAnswers.fieldId, predefinitionAnswers.fieldUid)*/ }} disabled={!enablePreviousButton}
                                >
                                    <i className="fa fa-backward" aria-hidden="true"></i>
                                </button>
                                <button className={`btn btn-link ${enableForwardButton ? "" : "disabled"}`} title={t("Próximo")} id="predefinitionButtonNextId"
                                    onClick={() => { /*callbackSearchForward(predefinitionAnswers.fieldId, predefinitionAnswers.fieldUid)*/ }} disabled={!enableForwardButton}
                                >
                                    <i className="fa fa-forward" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                        <div className="col-6 text-right p-2">
                            <button className="btn btn-success" type="button" id="predefinitionButtonConfirmId" onClick={selecionarRespostasPredefinidas}>
                                <i className="fa fa-check mr-1" aria-hidden="true"></i>
                                {t("Confirmar")}
                            </button>
                            <button className="btn btn-warning ml-3" type="button" id="predefinitionButtonCancelId" onClick={fecharModal}>
                                <i className="fa fa-ban mr-1" aria-hidden="true"></i>
                                {t("Cancelar")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const RespostasPredefinidasAdd = () => (
        <div id="predefinitionAnswersAdd" className="d-none">
            <div className="modal-body">
                <h5>*{t("Resposta predefinida")}</h5>
                <input className="d-none" type="text" value={respostaPredefinida.idRespostaPredefinida} />
                <div className="form-group row mt-3">
                    <div className="input-group col">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <i className="fa fa-text-width" aria-hidden="true"></i>
                            </span>
                        </div>
                        <input className="form-control d-none" type="text" id="predefinitionAnswerId" style={{ backgroundColor: "rgb(255, 255, 255)" }} />
                        <input className="form-control" type="text" placeholder={t("Título")} id="predefinitionAnswerTitleId" value={respostaPredefinida.titulo} onChange={(event) => handleChange(event.target.value, "titulo")} style={{ backgroundColor: "rgb(255, 255, 255)" }} />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="input-group col">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <i className="far fa-comment-dots" aria-hidden="true"></i>
                            </span>
                        </div>
                        <textarea
                            className="form-control"
                            rows={3}
                            placeholder={t("Descrição")}
                            id="predefinitionAnswerDescriptionId"
                            value={respostaPredefinida.texto}
                            onChange={(event) => {
                                handleChange(event.target.value, "texto");
                                setHtmlRespostaPredefinida(event.target.value);
                            }}
                        ></textarea>
                    </div>
                </div>
                <div hidden={true} contentEditable={true} className="form-control" id="predefinitionAnswerHtmlId" onChange={(event) => { }}></div>
                <div className="form-group row">
                    <div className="col">
                        <div className="custom-control custom-switch" id="predefinitionAnswerCustomCheckboxId">
                            <input className="custom-control-input checkbox-report" type="checkbox" id="predefinitionAnswerFavoriteId" checked={respostaPredefinida.favorito} onChange={(event) => handleChange(event.target.checked, "favorito")} style={{ backgroundColor: "rgb(255, 255, 255)" }} />
                            <label className="custom-control-label w-100" style={{ textAlign: "left" }} htmlFor={"predefinitionAnswerFavoriteId"}> {t("Resposta Favorita?")}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer p-0 pt-1 pb-1">
                <div className="row p-0 m-0">
                    <div className="col text-right p-2">
                        <button className="ml-3 btn btn-success" type="button" onClick={() => { salvar() }} id="addPredefinitionButtonConfirmId">
                            <i className="fa fa-check mr-1" aria-hidden="true"></i>
                            {t("Confirmar")}
                        </button>
                        <button className="ml-3 btn btn-warning" type="button" id="addPredefinitionButtonCancelId"
                            onClick={() => {
                                setRespostaPredefinida(predefinitionAnswerDefault);
                                mostrarListagemRespostasPredefinidas();
                            }}
                        >
                            <i className="fa fa-ban mr-1" aria-hidden="true"></i>
                            {t("Cancelar")}
                        </button>
                        {/* <button className="ml-3 btn btn-danger d-none" type="button" id="addPredefinitionButtonDeleteId"
                            onClick={() => { deletarRespostaPredefinida() }}
                        >
                            <i className="fa fa-trash mr-1" aria-hidden="true"></i> 
                            {t("Apagar")}
                        </button> */}
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div className="modal " id="predefinitionAnswersModal" tabIndex={-1} role="dialog" aria-labelledby="predefinitionAnswersModalLabel" aria-hidden="true">
            <div className="modal-dialog w-50" role="document" style={{ margin: "50px auto", maxWidth: "100%" }}>
                <div className="modal-content">
                    <div className="modal-header p-2 m-2 ml-2 mr-2 border-0">
                        <h5 className="modal-title" id="predefinitionAnswersModalLabel">{t("Respostas predefinidas")}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="closePredefinitionAnswersModal" onClick={resetarModal}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {RespostasPredefinidasList()}
                    {RespostasPredefinidasAdd()}

                </div>
            </div>
        </div>
    )
}

export default ModalRespostasPredefinidas;