import React, { useEffect, createRef, useState } from 'react';

// Elements
import Table from './table-element';


// Tools/Utils
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { FirebaseService } from '../services/firebaseService';
import { firebaseDatabase } from '../../config/firebase-config';



// Interfaces
import { QuerySnapshot, Query } from '../../types/types';
import { FavoriteReportInterface } from '../../interfaces/favoriteReportInterface';
import { propsFavoriteReportModalInteface } from '../../interfaces/propsFavoriteReportModalInteface';



// States/Redux
import { ApplicationState } from '../../store';
import { setFavoriteReports } from '../../store/actions/exam';
import { showLoading, hideLoading } from '../../store/actions/loading';
import {
    setFavoriteReportModalClosed,
    setFavoriteReportInputSearchValue,
    setFavoriteReportRowsOnTable,
    setFavoriteReportPreviousFirstValues,
    setFavoriteReportFirstVisible,
    setFavoriteReportLastVisible,
    setFavoriteReportEnablePreviousButton,
    setFavoriteReportEnableForwardButton,
    setFavoriteReportOffsetTotal,
    setFavoriteReportPageIndex,
    resetFavoriteReportPagination
} from '../../store/actions/favoriteReport';
import { SpeechRecognitionButton } from '../components/speech-recognition-button-component';
import { setStopListening } from '../../store/actions/stopListening';
import { LaudoFavoritoService } from '../../service/laudoFavorito.service';
import { LaudoFavoritoInterface } from '../../interfaces/new/laudoFavoritoInterface';
import { useMemo } from 'react';



const ModalLaudosFavoritos = (props: propsFavoriteReportModalInteface) => {
    const { callbackSelectFavoriteReport: callbackSelecionarLaudoFavorito, idExame } = props;
    const inputTextToSearchRef = createRef<HTMLInputElement>();

    const [laudosFavoritos, setLaudosFavoritos] = useState<Array<LaudoFavoritoInterface>>([]);
    const [laudosFavoritosFiltro, setLaudosFavoritosFiltro] = useState<Array<LaudoFavoritoInterface>>([]);
    const [laudosFavoritosMaisUtilizados, setLaudosFavoritosMaisUtilizados] = useState<Array<LaudoFavoritoInterface>>([]);
    const isOpen = useSelector((state: ApplicationState) => state.favoriteReport.modal.isOpen);
    const {
        inputSearch,
        firstVisible,
        lastVisible,
        rowsOnTable,
        offsetTotal,
        pageIndex,
        previousFirstValues,
        enablePreviousButton,
        enableForwardButton
    } = useSelector((state: ApplicationState) => state.favoriteReport.table);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const limitOfTable = 10;
    const incrementOffset = 10;

    useMemo(() => {
        if (laudosFavoritos.length > 0) {
            const profissionaisFiltrados = laudosFavoritos.filter(
                (item) => item.titulo.toUpperCase().indexOf(inputSearch.toUpperCase()) > -1        
            );
            setLaudosFavoritosFiltro(profissionaisFiltrados);
        }

        if (laudosFavoritos.length > 0 && !inputSearch) {
            setLaudosFavoritosFiltro(laudosFavoritos);
        }
    }, [inputSearch, laudosFavoritos])

    useEffect(() => {
        if (isOpen) {
            //showLoading(dispatch);
            getLaudosFavoritos();
        }
    }, [isOpen]);

    const getLaudosFavoritos = async () => {
        const laudosFavoritos = await new LaudoFavoritoService().listaPorIdExame(idExame);
        const laudosFavoritosMaisUtilizados = await new LaudoFavoritoService().maisUtilizados(idExame);

        setLaudosFavoritos(laudosFavoritos);
        setLaudosFavoritosMaisUtilizados(laudosFavoritosMaisUtilizados);
        //hideLoading(dispatch);
    }

    const setMaisUtilizados = (idLaudoFavorito: number) => {
        new LaudoFavoritoService().atualizaMaisUtilizados(idLaudoFavorito);
    }

    $("#favoriteReportsModal").on("hidden.bs.modal", () => {
        if (isOpen) {
            resetFavoriteReportPagination(dispatch);
            setFavoriteReportModalClosed(dispatch);
        }
    })

    return (
        <div className="modal " id="favoriteReportsModal" tabIndex={-1} role="dialog" aria-labelledby="favoriteReportsModalLabel" aria-hidden="true">
            <div className="modal-dialog w-100" role="document" >
                <div className="modal-content">
                    <div className="modal-header p-2 ml-2 mr-2 m-2 border-0">
                        <h5 className="modal-title" id="favoriteReportsModalLabel">{t("Laudos Favoritos")}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="closefavoriteReportsModalLabel" onClick={()=>{setStopListening(true, dispatch)}}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row mb-3">
                            <div className="input-group col">
                                <input id="favoriteReportInput" className="form-control" type="text" value={inputSearch} onChange={(event) => setFavoriteReportInputSearchValue(event.target.value, dispatch)} placeholder={t("Buscar por")} />
                                <div className="input-group-append">
                                    <button className="btn btn-secondary rounded-right" type="button" onClick={(event) => {
                                        showLoading(dispatch);
                                        getLaudosFavoritos();
                                    }}>
                                        <i className="fas fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>

                                <SpeechRecognitionButton setReconizedText={(textTranscript: string) => setFavoriteReportInputSearchValue(textTranscript, dispatch)} />
                            </div>
                        </div>
                        {
                            laudosFavoritosMaisUtilizados.length !== 0 ? (
                                <div className="mt-5">
                                    <Table itemsThead={[t("Mais Utilizados")]}>
                                        {
                                            laudosFavoritosMaisUtilizados && laudosFavoritosMaisUtilizados.map((favoriteReport, index: number) => (
                                                <tr key={index} onDoubleClick={() => { 
                                                    setStopListening(true, dispatch);
                                                    callbackSelecionarLaudoFavorito(favoriteReport.respostasFavoritas);
                                                    setMaisUtilizados(favoriteReport.idLaudoFavorito as number)
                                                    }}>
                                                    <td>{favoriteReport.titulo}</td>
                                                </tr>
                                            ))
                                        }
                                    </Table>
                                </div>
                            ) : (
                                <></>
                            )
                        }
                        <Table itemsThead={[t("Todos")]}>
                            {
                                laudosFavoritosFiltro && laudosFavoritosFiltro.map((favoriteReport, index: number) => (
                                    <tr key={index} onDoubleClick={() => { 
                                        setStopListening(true, dispatch);
                                        callbackSelecionarLaudoFavorito(favoriteReport.respostasFavoritas);
                                        setMaisUtilizados(favoriteReport.idLaudoFavorito as number)
                                        }}>
                                        <td>{favoriteReport.titulo}</td>
                                    </tr>
                                ))
                            }
                        </Table>
                    </div>
                    <div className="modal-footer p-0 pt-1 pb-1">
                        <div className="row w-100 justify-content-center">
                            <button className={`btn btn-link ${enablePreviousButton ? '' : 'disabled'}`} title={t("Anterior")} id="favoriteReportsButtonPreviousId"
                                onClick={() => { /*searchPreviousFavoriteReports()*/ }}
                            >
                                <i className="fa fa-backward" aria-hidden="true"></i>
                            </button>
                            <button className={`btn btn-link ${enableForwardButton ? '' : 'disabled'}`} title={t("Próximo")} id="favoriteReportsButtonNextId"
                                onClick={() => { /*searchForwardFavoriteReports()*/ }}
                            >
                                <i className="fa fa-forward" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalLaudosFavoritos;