import { PacienteInterface } from "../interfaces/new/pacienteInterface";
import { ProfissionalSaudeInterface } from "../interfaces/new/profissionalSaudeInterface";

const joinNames = (entity: PacienteInterface | ProfissionalSaudeInterface) => {
    if (!entity) {
        return "";
    }

    const name = `${entity.primeiroNome || ""} ${entity.sobrenome || ""} ${entity.ultimoNome || ""}`;
    
    return name;
}

export {
    joinNames
}