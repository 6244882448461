import { ReportDataTypes } from "./types/ActionsTypes"
import { ExamInterface } from "../../interfaces/examInterface"
import { AppointmentDataInterface } from "../../interfaces/appointmentDataInterface"

export const setReportData = (reportData: AppointmentDataInterface & { exams: Array<ExamInterface> }, dispatch: Function) => {
    dispatch({
        type: ReportDataTypes.REPORT_DATA,
        payload: reportData
    })
}

export const setPatientReportImages = (images: any, dispatch: Function) => {
    dispatch({
        type: ReportDataTypes.SET_PATIENT_IMAGES,
        payload: images
    })
}

export const setSelectedImages = (images: Array<any>, dispatch: Function) => {
    dispatch({
        type: ReportDataTypes.SET_SELECTED_IMAGES,
        payload: images
    })
}

export const resetReportData = (dispatch: Function) => {
    dispatch({
        type: ReportDataTypes.RESET_REPORT_DATA,
    })
}