import { baseURL } from '../config/api-config';
import { HttpClient } from './httpClient';

export class PacienteService {
    private httpClient: HttpClient;
    constructor() {
        this.httpClient = new HttpClient();
    }

    async lista() {
        return await this.httpClient.get({ url: '/paciente', baseURL: baseURL });
    }

    async buscaGeneros() {
        return await this.httpClient.get({ url: '/paciente/generos/lista', baseURL: baseURL });
    }
}