import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { ApplicationState } from "../../store";
import { showLoadingMessage, hideLoadingMessage } from "../../store/actions/loading";

const Loading = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isLoading, showMessage, message } = useSelector((state: ApplicationState) => state.loading);

    useEffect(() => {
        if (isLoading) {
            setTimeout(() => {
                if (isLoading) {
                    showLoadingMessage(dispatch);
                }
            }, 2000);
        } else {
            hideLoadingMessage(dispatch);
        }
    }, [isLoading]);

    const style: React.CSSProperties = {
        top: 0,
        bottom: 0,
        width: "100vw",
        height: "100vh",
        background: "rgba(0,0,0,0.5)",
        zIndex: 99999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#DDD",
        flexDirection: "column"
    }

    return isLoading ? (
        <div className="position-absolute" style={style}>
            <h1>
                <i className="fas fa-circle-notch fa-pulse fa-3x fa-fw"></i>
            </h1>
            {/* {showMessage &&
                <h4>{message ? message : `${t("Aguarde um momento enquanto carregamos suas informações")}...`}</h4>
            } */}
        </div>
    ) : null;
}
export default Loading;