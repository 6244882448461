import React, { useState, ReactNode } from 'react';

import ModalElement from "./modal-element";
import TableList from './table-list-element';

import { PropsViewSelect } from '../../interfaces/propsViewSelect';
import { FooterButtonsList } from './card-footer-buttons-list-elment';
import { PropsTableListInterface } from '../../interfaces/propsTableListInterface';
import { PropsModalInterface } from "../../interfaces/propsModalElementInterface";
import { PropsFooterButtonsListInterface } from '../../interfaces/propsCardFooterButtonsListInterface';


const ViewSelect = (props: PropsViewSelect & PropsModalInterface & PropsTableListInterface & PropsFooterButtonsListInterface) => {
    const {
        idModal,
        idTitleLabelModal,
        titleModalElement,
        idButtonCloseModal,
        idButtonPreviousModal,
        onClickButtonClose,
        onClickButtonPrevious,
        itemsThead,
        filters,
        onClickButtonSearch,
        onChangeTextToSearch,
        onChangeFilterToSearch,
        enablePreviousButton,
        enableForwardButton,
        onClickSearchPrevious,
        onClickSearchForward,
        showButtonPrevious,
        children
    } = props;


    return (
        <ModalElement idModal={idModal} idTitleLabelModal={idTitleLabelModal} titleModalElement={titleModalElement} idButtonPreviousModal={idButtonPreviousModal} onClickButtonPrevious={onClickButtonPrevious} idButtonCloseModal={idButtonCloseModal} onClickButtonClose={onClickButtonClose} showButtonPrevious={showButtonPrevious} >
            <TableList itemsThead={itemsThead} filters={filters} onClickButtonSearch={onClickButtonSearch} onChangeTextToSearch={onChangeTextToSearch} onChangeFilterToSearch={onChangeFilterToSearch}>
                {children}
            </TableList>

            <FooterButtonsList enablePreviousButton={enablePreviousButton} enableForwardButton={enableForwardButton} onClickSearchPrevious={onClickSearchPrevious} onClickSearchForward={onClickSearchForward}></FooterButtonsList>
        </ModalElement>
    )
}


export { ViewSelect };