import React, { useEffect, useState } from 'react';


// tool/utils
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';


//Interfaces 
import { ApplicationState } from '../../store';


// Redux/States
import { setBrusOptions, hideModalBrush } from '../../store/actions/imageEditor';


const ImageEditorDrawModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();


    const { modalDrawEnable, brushOptions } = useSelector((state: ApplicationState) => state.imageEditor);

    return (
        <div className={`position-absolute bg-light w-25 border text-center rounded ${modalDrawEnable ? "" : "d-none"}`} style={{ bottom: 0 }} id="div-draw-editor">
            <div className="form-group">
                <label className="small text-black">{t("Espessura da linha")}</label>
                <input className="form-control" type="range" value={brushOptions.width} min="1" max="150" id="input-line-width" onChange={(event) => setBrusOptions({ width: parseInt(event.target.value) }, dispatch)} />
            </div>

            <div className="form-group">
                <label className="small text-black">{t("Cor")}</label>
                <input className="form-control" type="color" value={brushOptions.color} style={{ backgroundColor: brushOptions.color }} id="input-line-color" onChange={(event) => setBrusOptions({ color: event.target.value }, dispatch)} />
            </div>

            {/* <button className="btn btn-success btn-sm mr-2 m-1" id="btn-apply-elements-draw">
                <i className="mr-2 fa fa-check"></i>
                {t("Aplicar desenho")}
            </button> */}

            <button className="btn btn-danger btn-sm mr-2 m-1" id="btn-cancel-draw" onClick={(event) => hideModalBrush(dispatch)}>
                <i className="mr-2 fa fa-times"></i>
                {t("Fechar")}
            </button>
        </div>
    )
}


export { ImageEditorDrawModal };