import { AppTypes } from "../actions/types/ActionsTypes";
import { AppState } from "../datatypesInterfaces/dataTypeInterfaces";
import { ActionInterface } from "../actions/interface/ActionInterface";
import { UsuarioInterface } from "../../interfaces/new/usuarioInterface";
import { LocalStorageUtils } from "../../utils/localStorage-utils";

const INITIAL_STATE: AppState = {
    clinicas: [],
    usuario: getUsuario(),
    serial: "",
    serialPath: "",
    userEmail: "",
    clinicGlobalPath: "",
    clinicaSelecionada: {
        idContrato: undefined,
        idContratoPrincipal: undefined,
        produto: null,
        nrSerie: '',
        serialPrincipal: ''
    },
    mostrarBotaoSincronizacao: true
};

const App = (state = INITIAL_STATE, action: ActionInterface): AppState => {
    switch (action.type) {
        case AppTypes.SET_CLINICS:
            return {
                ...state,
                clinicas: action.payload
            }
        case AppTypes.SET_USER_DOCTOR:
            if (action.payload) {
                const data = new Date().getTime() + 144e5;
                LocalStorageUtils.salvar('usuario', action.payload);
                LocalStorageUtils.salvar('expireDate', data);
            } else {
                LocalStorageUtils.remover('usuario');
                LocalStorageUtils.remover('clinicaSelecionada');
                LocalStorageUtils.remover('expireDate');
            }

            return {
                ...state,
                usuario: action.payload
            }
        case AppTypes.SET_SELECTED_CLINIC:
            LocalStorageUtils.salvar('clinicaSelecionada', action.payload);
            return {
                ...state,
                clinicaSelecionada: action.payload
            }
        case AppTypes.SET_SERIAL:
            return {
                ...state,
                serial: action.payload
            }
        case AppTypes.SET_SERIAL_PATH:
            return {
                ...state,
                serialPath: action.payload
            }
        case AppTypes.SET_USER_EMAIL:
            return {
                ...state,
                userEmail: action.payload
            }
        case AppTypes.SET_CLINIC_GLOBAL_PATH:
            return {
                ...state,
                clinicGlobalPath: action.payload
            }
        case AppTypes.ENABLE_SYNC_BUTTON:
            return {
                ...state,
                mostrarBotaoSincronizacao: action.payload
            }
        default:
            return state;
    }
}

function getUsuario(): UsuarioInterface {
    const usuario = LocalStorageUtils.buscar<UsuarioInterface>('usuario');
    if (usuario) {
        return usuario;
    }
    return {
        email: "",
        primeiroNome: "",
        ultimoNome: "",
        token: null,
        contratos: [],
    }
}

export default App;