import { ImageEditorTypes } from "./types/ActionsTypes"
import { CropperImageState, HistoryEditorState, FabricImageInterface, arrowOptionsInterface } from "../datatypesInterfaces/dataTypeInterfaces"

export const setImageToEdit = (image: any, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_IMAGE_TO_EDIT,
        payload: image
    })
}

export const setImageOriginalUrl = (url: string, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_IMAGE_ORIGINAL_URL,
        payload: url
    })
}

export const setImageUrl = (url: string, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_IMAGE_URL,
        payload: url
    })
}

export const setCropper = (cropper: React.RefObject<Cropper>, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_CROPPER,
        payload: cropper,
    })
}

export const setImageData = (imageData: CropperImageState, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_IMAGE_DATA,
        payload: imageData
    })
}

export const setCropping = (value: boolean, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_CROPPING,
        payload: value
    })
}

export const showCropping = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SHOW_CROPPING,
    })
}

export const hideCropping = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.HIDE_CROPPING,
    })
}

export const showFabric = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SHOW_FABRIC,
    })
}

export const hideFabric = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.HIDE_FABRIC,
    })
}

export const cleanHistory = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.CLEAN_HISTORY
    })
}

export const setUndoHistory = (history: Array<HistoryEditorState>, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_UNDO_HISTORY,
        payload: history
    })
}

export const pushHistory = (history: HistoryEditorState, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.PUSH_HISTORY,
        payload: history
    })
}

export const popHistory = (history: HistoryEditorState, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.POP_HISTORY,
        payload: history
    })
}

export const setIsSaved = (isSaved: boolean, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_IS_SAVED,
        payload: isSaved
    })
}

export const pushUndoHistory = (history: HistoryEditorState, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.PUSH_UNDO_HISTORY,
        payload: history
    })
}

export const popUndoHistory = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.POP_UNDO_HISTORY
    })
}

export const clearUndoHistory = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.CLEAR_UNDO_HISTORY
    })
}

export const pushRedoHistory = (history: HistoryEditorState, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.PUSH_REDO_HISTORY,
        payload: history
    })
}

export const popRedoHistory = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.POP_REDO_HISTORY
    })
}

export const clearRedoHistory = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.CLEAR_REDO_HISTORY
    })
}

export const setBtnRedoDisable = (value: boolean, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_BUTTON_REDO_DISABLE,
        payload: value
    })
}

export const setBtnUndoDisable = (value: boolean, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_BUTTON_UNDO_DISABLE,
        payload: value
    })
}

export const setBtnResetDisable = (value: boolean, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_BUTTON_RESET_DISABLE,
        payload: value
    })
}

export const setDataBeforeCropper = (imageData: CropperImageState | null, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_DATA_BEFORE_CROPPER,
        payload: imageData
    })
}

export const setImageBeforeCropper = (imageURL: string | null, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_IMAGE_BEFORE_CROPPER,
        payload: imageURL
    })
}

export const showModalApplyClipping = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SHOW_MODAL_APPLY_CLIPPING
    })
}

export const hideModalApplyClipping = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.HIDE_MODAL_APPLY_CLIPPING
    })
}

export const showModalBrush = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SHOW_MODAL_BRUSH
    })
}

export const hideModalBrush = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.HIDE_MODAL_DRAW
    })
}

export const showModalDrawArrow = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SHOW_MODAL_DRAW_ARROW
    })
}

export const hideModalDrawArrow = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.HIDE_MODAL_DRAW_ARROW
    })
}

export const showModalApplyEditText = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SHOW_MODAL_APPLY_EDIT_TEXT
    })
}

export const hideModalApplyEditText = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.HIDE_MODAL_APPLY_EDIT_TEXT
    })
}

export const setImageDataLeft = (value: number, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_IMAGE_DATA_LEFT,
        payload: value
    })
}

export const setImageDataTop = (value: number, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_IMAGE_DATA_TOP,
        payload: value
    })
}

export const setFabricCanvas = (fabricCanvas: fabric.Canvas, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_FABRIC_CANVAS,
        payload: fabricCanvas
    })
}

export const enableFabricEdition = (value: boolean, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.ENABLE_FABRIC_EDITION,
        payload: value
    })
}

export const setCurrentFabricImage = (image: FabricImageInterface | null, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_CURRENT_FABRIC_IMAGE,
        payload: image
    })
}

export const setNoDispatchObjectAdd = (value: boolean, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_NO_DISPATCH_OBJECT_ADD,
        payload: value
    })
}


export const setIsDrawingMode = (value: boolean, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_FABRIC_IS_DRAWING_MODE.length,
        payload: value
    })
}

export const pushHistoryUndoBeforeDrawing = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.PUSH_HISTORY_UNDO_BEFORE_DRAWING,
    })
}

export const popHistoryUndoBeforeDrawing = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.POP_HISTORY_UNDO_BEFORE_DRAWING,
    })
}

export const clearHistoryUndoBeforeDrawing = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.CLEAR_HISTORY_UNDO_BEFORE_DRAWING,
    })
}

export const pushHistoryRedoBeforeDrawing = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.PUSH_HISTORY_REDO_BEFORE_DRAWING,
    })
}

export const popHistoryRedoBeforeDrawing = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.POP_HISTORY_REDO_BEFORE_DRAWING,
    })
}

export const clearHistoryRedoBeforeDrawing = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.CLEAR_HISTORY_REDO_BEFORE_DRAWING,
    })
}

export const setStateFabric = (value: string | null, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_STATE_FABRIC,
        payload: value
    })
}

export const enableDrawingArrow = (value: boolean, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.ENABLE_DRAWING_ARROW,
        payload: value
    })
}

export const setFabricSelection = (value: boolean, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_FABRIC_SELECTION,
        payload: value
    })
}

export const cleanDrawArrow = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.CLEAN_DRAW_ARROW
    })
}

export const setTextEditing = (textEditing: fabric.Textbox, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_EDITOR_TEXT,
        payload: textEditing,
    })
}


export const setRichTextCurrentColor = (color: string, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_RICH_TEXT_CURRENT_COLOR,
        payload: color
    })
}

export const clearImageEditorState = (dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.CLEAR_STATE
    })
}

export const setResetFabricEditing = (resetFabricEditing: boolean, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_RESET_FABRIC_EDITING,
        payload: resetFabricEditing
    })
}


export const setUndoFabric = (undoFabric: boolean, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_UNDO_FABRIC,
        payload: undoFabric
    })
}

export const setRedoFabric = (redoFabric: boolean, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_REDO_FABRIC,
        payload: redoFabric
    })
}


export const setRemoveFabricActiveElement = (removeFabricActiveElement: boolean, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_REMOVE_FABRIC_ACTIVE_ELEMENT,
        payload: removeFabricActiveElement
    })
}


export const setArrowOptions = (arrowOptions: arrowOptionsInterface | { fill: string } | { strokeWidth: number } | { stroke: string }, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_ARROW_OPTIONS,
        payload: arrowOptions
    })
}

export const setBrusOptions = (brushOptions: { width: number; color: string; } | { width: number; } | { color: string; }, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_BRUSH_OPTIONS,
        payload: brushOptions
    })
}

export const setUpdateImages = (update: boolean, dispatch: Function) => {
    dispatch({
        type: ImageEditorTypes.SET_UPDATE_IMAGES,
        payload: update
    })
}