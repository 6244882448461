import { FavoriteReportTypes } from "./types/ActionsTypes";

export const setFavoriteReportInputSearchValue = (text: string, dispatch: Function) => {
    dispatch({
        type: FavoriteReportTypes.SET_INPUT_SEARCH_VALUE,
        payload: text
    })
}

export const setFavoriteReportRowsOnTable = (rowsLength: number, dispatch: Function) => {
    dispatch({
        type: FavoriteReportTypes.SET_ROWS_ON_TABLE,
        payload: rowsLength
    })
}

export const setFavoriteReportFirstVisible = (firstVisible: any, dispatch: Function) => {
    dispatch({
        type: FavoriteReportTypes.SET_FIRST_VISIBLE,
        payload: firstVisible
    })
}

export const setFavoriteReportLastVisible = (lastVisible: any, dispatch: Function) => {
    dispatch({
        type: FavoriteReportTypes.SET_LAST_VISIBLE,
        payload: lastVisible
    })
}

export const setFavoriteReportPreviousFirstValues = (previousFirstValues: any, dispatch: Function) => {
    dispatch({
        type: FavoriteReportTypes.SET_PREVIOUS_FIRST_VALUES,
        payload: previousFirstValues
    })
}

export const setFavoriteReportPageIndex = (pageIndex: number, dispatch: Function) => {
    dispatch({
        type: FavoriteReportTypes.SET_PAGE_INDEX,
        payload: pageIndex
    })
}

export const setFavoriteReportOffsetTotal = (offsetTotal: number, dispatch: Function) => {
    dispatch({
        type: FavoriteReportTypes.SET_OFFSET_TOTAL,
        payload: offsetTotal
    })
}

export const setFavoriteReportEnablePreviousButton = (enablePreviousButton: boolean, dispatch: Function) => {
    dispatch({
        type: FavoriteReportTypes.SET_ENABLE_PREVIOUS_BUTTON,
        payload: enablePreviousButton
    })
}

export const setFavoriteReportEnableForwardButton = (enableForwardButton: boolean, dispatch: Function) => {
    dispatch({
        type: FavoriteReportTypes.SET_ENABLE_FORWARD_BUTTON,
        payload: enableForwardButton
    })
}

export const setFavoriteReportModalOpen = (dispatch: Function) => {
    dispatch({
        type: FavoriteReportTypes.SET_MODAL_OPEN,
    })
}

export const setFavoriteReportModalClosed = (dispatch: Function) => {
    dispatch({
        type: FavoriteReportTypes.SET_MODAL_CLOSED,
    })
}

export const resetFavoriteReportPagination = (dispatch: Function) => {
    dispatch({
        type: FavoriteReportTypes.RESET_PAGINATION,
    })
}