import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Tools/Utils
import Swal from 'sweetalert2';

import { ExameInterface } from '../../interfaces/new/exameInterface';
// Interfaces
import { LegendaInterface } from '../../interfaces/new/legendaInterface';
import { LegendaService } from '../../service/legenda.service';
import { ApplicationState } from '../../store';
import { setStopListening } from '../../store/actions/stopListening';
import { SpeechRecognitionButton } from '../components/speech-recognition-button-component';
//Elements
import Table from './table-element';

const ModalLegenda = (props: {
    legendas: Array<LegendaInterface>,
    exames: Array<any>,
    exameSelecionado: ExameInterface,
    enablePreviousButton: boolean,
    enableForwardButton: boolean,
    callbackLegendaParaImagem: Function,
    callbackSetLegendas: Function,
    callbackSearchPrevious?: Function,
    callbackSearchForward?: Function,
    callbackButtonSearch: Function
}) => {
    const legendas = props.legendas;
    const exames = props.exames;
    const exameSelecionado = props.exameSelecionado;
    const enablePreviousButton = props.enablePreviousButton;
    const enableForwardButton = props.enableForwardButton;
    const callbackLegendaParaImagem = props.callbackLegendaParaImagem;
    const callbackSetLegendas = props.callbackSetLegendas;
    // const callbackSearchPrevious = props.callbackSearchPrevious;
    // const callbackSearchForward = props.callbackSearchForward;
    const callbackButtonSearch = props.callbackButtonSearch;

    const clinicaSelecionada = useSelector((state: ApplicationState) => state.app.clinicaSelecionada);

    const [legendInputSearchValue, setLegendInputSearchValue] = useState<string>("");

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const legendDefault: LegendaInterface = {
        idLegenda: undefined,
        idContrato: undefined,
        idExterno: undefined,
        descricao: '',
        favorito: false,
        exames: [exameSelecionado]
    }

    const [legenda, setLegenda] = useState(legendDefault);

    useEffect(() => {
        preencherCheckboxExames();
    }, [legenda])

    const mostrarAdicionarLegenda = () => {
        (document.querySelector('#legendList') as HTMLDivElement).classList.add('d-none');
        (document.querySelector('#legendAdd') as HTMLDivElement).classList.remove('d-none');
        (document.querySelector('#addLegendButtonDeleteId') as HTMLDivElement).classList.add('d-none');
    }

    const mostrarEditarLegenda = () => {
        (document.querySelector('#legendList') as HTMLDivElement).classList.add('d-none');
        (document.querySelector('#legendAdd') as HTMLDivElement).classList.remove('d-none');
        (document.querySelector('#addLegendButtonDeleteId') as HTMLDivElement).classList.remove('d-none');
    }

    const fecharModal = () => {
        (document.querySelector("#closeLegendModal") as HTMLButtonElement).click();
        setStopListening(true, dispatch);
    }

    const resetarModal = () => {
        setLegenda(legendDefault);
        mostrarListagemLegendas();
    }

    const mostrarListagemLegendas = async () => {
        const legendas = await new LegendaService().lista({ idExame: exameSelecionado.idExame });

        callbackSetLegendas(legendas);
        (document.querySelector('#legendList') as HTMLDivElement).classList.remove('d-none');
        (document.querySelector('#legendAdd') as HTMLDivElement).classList.add('d-none');
        (document.querySelector('#addLegendButtonDeleteId') as HTMLDivElement).classList.add('d-none');
    }
          

    const checkboxExames = () => (
        exames && exames.length > 0 && exames.map((exame: ExameInterface, index: number) => {
            let display = `${exame.idExame === exameSelecionado.idExame ? 'd-none' : ''}`;
            return (
                <div className={`col-4 ${display}`} key={index}>
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input checkbox-legend" id={`checkbox_${exame.idExame}`} value={exame.idExame} onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeExameCheckbox(event, exame)} />
                        <label className="custom-control-label w-100" style={{ textAlign: 'left' }} htmlFor={`checkbox_${exame.idExame}`}>{exame.nome}</label>
                    </div>
                </div>
            )
        })
    )

    const handleChangeDescricao = (event: ChangeEvent<HTMLInputElement>) => {
        let obj = {
            ...legenda,
            ['descricao']: event.target.value
        };

        setLegenda(obj);
    }

    const handleChangeCheckboxFavorito = () => {
        let obj = {
            ...legenda,
            ['favorito']: !legenda.favorito
        };

        setLegenda(obj);
    }

    const handleChangeExameCheckbox = (event: ChangeEvent<HTMLInputElement>, exame: ExameInterface) => {
        let obj = {
            ...legenda
        }

        if (event.target.checked) {
            obj.exames.push(exame);
        } else {
            let index = obj.exames.findIndex((e) => (
                e.idExame === exame.idExame
            ));

            obj.exames.splice(index, 1);
        }

        setLegenda(obj);
    }

    const preencherCheckboxExames = () => {
        let checkboxes = document.querySelectorAll('.checkbox-legend');

        for(let index = 0; index < checkboxes.length; index++) {
            const hasExam = legenda.exames.some((e) => e.idExame === parseInt((checkboxes[index] as HTMLInputElement).value));

            if (hasExam) {
                (checkboxes[index] as HTMLInputElement).checked = true;
            } else {
                (checkboxes[index] as HTMLInputElement).checked = false;
            }
        }
    }

    const salvar = () => {

        const data: LegendaInterface = {
            ...legenda,
            idContrato: clinicaSelecionada.idContratoPrincipal
        }

        if (legenda.idLegenda) {
            atualizarLegenda(data)
        } else {
            salvarLegenda(data);
        }
    }

    const atualizarLegenda = async (legenda: LegendaInterface) => {
        try {
            const idLegenda = await new LegendaService().atualizar(legenda);

            setLegenda(legendDefault);

            Swal.fire(
                t('Legenda atualizada com sucesso'),
                t('Clique em ok para continuar'),
                'success'
            ).then(() => {
                mostrarListagemLegendas();
            })

        } catch (err) {
            Swal.fire(
                t(`Atualizar legenda`),
                `${t("Ocorreu um erro ao atualizar a legenda")}. ${t("Contate o suporte técnico")}.`,
                'error',
            );
        }
    }

    const salvarLegenda = async (legenda: LegendaInterface) => {
        try {
            const idLegenda = await new LegendaService().inserir(legenda);

            setLegenda(legendDefault);

            Swal.fire(
                t('Legenda salva com sucesso'),
                t('Clique em ok para continuar'),
                'success'
            ).then(() => {
                
                mostrarListagemLegendas();
            })

        } catch (err) {
            Swal.fire(
                t(`Salvar legenda`),
                `${t("Ocorreu um erro ao salvar a legenda")}. ${t("Contate o suporte técnico")}.`,
                'error',
            );
        }
    }

    const deletarLegend = () => {
        Swal.fire({
            title: t('Deletar legenda'),
            text: t("Você deseja mesmo excluir a legenda?"),
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('Sim'),
            cancelButtonText: t('Cancelar')
        }).then(async (result) => {
            if (result.value) {
                try {
                    const idLegenda = await new LegendaService().deletar(legenda);

                    setLegenda(legendDefault);

                    Swal.fire(
                        t('Legenda deletada com sucesso'),
                        t('Clique em ok para continuar'),
                        'success'
                    ).then(() => {
                        mostrarListagemLegendas();
                    });
                } catch (err) {
                    Swal.fire(
                        t(`Deletar legenda`),
                        `${t("Ocorreu um erro ao deletar a legenda")}. ${t("Contate o suporte técnico")}.`,
                        'error',
                    );
                }
            }
        })
    }

    const atualizarFavoritoLegenda = async (legenda: LegendaInterface) => {
        legenda.favorito = !legenda.favorito;

        const idLegenda = await new LegendaService().atualizar(legenda);

        atualizarArrayLegendas(legenda)
    }

    const atualizarArrayLegendas = (legendaAtualizada: LegendaInterface) => {
        let arrayLegends = [...legendas];

        let legendIndex = arrayLegends.findIndex((legend: LegendaInterface) => {
            return legend.idLegenda === legendaAtualizada.idLegenda;
        });

        arrayLegends[legendIndex] = legendaAtualizada;

        callbackSetLegendas(arrayLegends);
    }

    const LegendaList = () => (
        <div id="legendList">
            <div className="modal-body">
                <div className="row mb-3">
                    <div className="input-group col">
                        <input id="legendModalId" className="form-control" type="text" placeholder={t("Buscar por")} value={legendInputSearchValue} onChange={(event) => setLegendInputSearchValue(event.target.value)} />
                        <div className="input-group-append">
                            <button className="btn btn-secondary rounded-right" type="button">
                                <i className="fas fa-search" aria-hidden="true"></i>
                            </button>
                        </div>

                        <SpeechRecognitionButton setReconizedText={setLegendInputSearchValue} />
                    </div>
                    <div className="mr-4">
                        <div className="input-group-append">
                            <button id="buttonAdd" className="btn btn-primary" type="button" style={{width: 27, height: 27, padding: 4}} onClick={mostrarAdicionarLegenda}>
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <Table itemsThead={[t('Descrição'), t('Configuração')]}>
                    {
                        legendas && legendas.length > 0 && legendas.map((legenda: LegendaInterface, index: number) => (
                            <tr key={index} style={{ cursor: 'pointer' }}
                                onDoubleClick={() => {
                                    setStopListening(true, dispatch);
                                    callbackLegendaParaImagem(legenda);
                                    fecharModal();
                                }}
                            >
                                <td>{legenda.descricao}</td>
                                <td align="center" style={{ width: '75px' }}>
                                    <button type="button" className="btn btn-light border border-secondary btn-sm mr-1 bg-white" id="btnEdit"
                                        onClick={() => {
                                            setLegenda(legenda);
                                            mostrarEditarLegenda();
                                        }}
                                    ><i className="fas fa-pencil-alt text-primary" aria-hidden="true"></i></button>
                                    <button type="button" className="btn btn-light border border-secondary btn-sm bg-white" id="btnEdit"
                                        onClick={() => {
                                            atualizarFavoritoLegenda(legenda);
                                        }}
                                    >
                                        <i className={`text-warning ${legenda.favorito ? 'fas' : 'far'} fa-star`} aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                        ))
                    }
                </Table>
            </div>
            <div className="card-footer p-0 pt-1 pb-1">
                <div className="row p-0 m-0">
                    <div className="col-6">
                        <div className="text-right">
                            <button className={`btn btn-link ${enablePreviousButton ? '' : 'disabled'}`} title={t("Anterior")} id="legendButtonPreviousId"
                                onClick={() => { /*callbackSearchPrevious()*/ }} disabled={!enablePreviousButton}
                            >
                                <i className="fa fa-backward" aria-hidden="true"></i></button>
                            <button className={`btn btn-link ${enableForwardButton ? '' : 'disabled'}`} title={t("Próximo")} id="legendButtonNextId"
                                onClick={() => { /*callbackSearchForward()*/ }} disabled={!enableForwardButton}
                            >
                                <i className="fa fa-forward" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-6 text-right p-2">
                        <button className="btn btn-warning ml-3" type="button" id="legendButtonCancelId" onClick={fecharModal}>
                            <i className="fa fa-ban mr-1" aria-hidden="true"></i>
                            {t("Cancelar")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )

    const LegendaAdd = () => (
        <div id="legendAdd" className="d-none">
            <div className="card-body p-3" id="addLegendBodyId">
                <h5> {t("Adicionar legenda")} </h5>
                <div className="form-group row mt-3">
                    <div className="input-group col">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <i className="far fa-comment-dots" aria-hidden="true"></i>
                            </span>
                        </div>
                        <input className="form-control d-none" type="text" id="legendId" />
                        <input className="form-control" type="text" placeholder={t("Descrição")} id="legendDescriptionId" value={legenda.descricao} onChange={handleChangeDescricao} />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col">
                        <div className="custom-control custom-switch" id="addLegendCustomCheckboxId">
                            <input className="custom-control-input" type="checkbox" id="addLegendFavoriteId" maxLength={30} checked={legenda.favorito} onChange={handleChangeCheckboxFavorito} />
                            <label className="custom-control-label w-100" style={{ textAlign: 'left' }} htmlFor="addLegendFavoriteId">{t("Legenda favorita?")}</label>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header p-0 pl-3 pt-1">
                        <h6>*{t("Adicionar para mais exames")}</h6>
                    </div>
                    <div className="card-body p-2">
                        <div className="form-group row">
                            {checkboxExames()}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer p-0 pt-1 pb-1">
                <div className="row p-0 m-0">
                    <div className="col text-right p-2">
                        <button className="ml-3 btn btn-success" type="button" onClick={() => salvar()} id="addLegendButtonConfirmId">
                            <i className="fa fa-check mr-1" aria-hidden="true"></i>
                            {t("Confirmar")}
                        </button>
                        <button className="ml-3 btn btn-warning" type="button" id="addLegendButtonCancelId"
                            onClick={() => {
                                setLegenda(legendDefault);
                                mostrarListagemLegendas();
                            }}
                        >
                            <i className="fa fa-ban mr-1" aria-hidden="true"></i>
                            {t("Cancelar")}
                        </button>
                        <button className="ml-3 btn btn-danger d-none" type="button" id="addLegendButtonDeleteId"
                            onClick={deletarLegend}
                        >
                            <i className="fa fa-trash mr-1" aria-hidden="true"></i>
                            {t("Apagar")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div className="modal " id="legendModal" tabIndex={-1} role="dialog" aria-labelledby="legendModalLabel" aria-hidden="true">
            <div className="modal-dialog w-50" role="document" style={{ margin: '50px auto', maxWidth: '100%' }}>
                <div className="modal-content">
                    <div className="modal-header mr-2 ml-2 border-0">
                        <h5 className="modal-title" id="legendModalLabel">{t("Legendas")}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="closeLegendModal" onClick={resetarModal}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {LegendaList()}
                    {LegendaAdd()}
                </div>
            </div>
        </div>
    )
}

export default ModalLegenda;
