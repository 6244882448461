import { AppTypes } from "../actions/types/ActionsTypes";
import { ClinicInterface } from "../../interfaces/clinicInterface"
import { UserInterface } from "../../interfaces/userInterface"
import { ClinicaInterface } from "../../interfaces/new/clinicaInterface";

export const setClinics = (clinics: Array<ClinicInterface>, dispatch: Function) => {
    dispatch({
        type: AppTypes.SET_CLINICS,
        payload: clinics
    })
}

export const setUserDoctor = (userDoctor: UserInterface, dispatch: Function) => {
    dispatch({
        type: AppTypes.SET_USER_DOCTOR,
        payload: userDoctor
    })
}

export const logoutClinica = (dispatch: Function) => {
    dispatch({
        type: AppTypes.SET_USER_DOCTOR,
        payload: ""
    })
}

export const setClinicaSelecionada = (clinica: ClinicaInterface, dispatch: Function) => {
    dispatch({
        type: AppTypes.SET_SELECTED_CLINIC,
        payload: clinica
    })
}

export const setSerial = (serial: string, dispatch: Function) => {
    dispatch({
        type: AppTypes.SET_SERIAL,
        payload: serial
    })
}

export const setSerialPath = (serialPath: string, dispatch: Function) => {
    dispatch({
        type: AppTypes.SET_SERIAL_PATH,
        payload: serialPath
    })
}

export const setUserEmail = (email: string, dispatch: Function) => {
    dispatch({
        type: AppTypes.SET_USER_EMAIL,
        payload: email
    })
}

export const setClinicGlobalPath = (clinicGlobalPath: string, dispatch: Function) => {
    dispatch({
        type: AppTypes.SET_CLINIC_GLOBAL_PATH,
        payload: clinicGlobalPath
    })
}

export const enableSyncButton = (value: boolean, dispatch: Function) => {
    dispatch({
        type: AppTypes.ENABLE_SYNC_BUTTON,
        payload: value
    })
}