import React, { useState, createRef } from "react";


// Elements
import Table from './table-element';
import { SpeechRecognitionButton } from "../components/speech-recognition-button-component";


// Tools/Utils
import { useTranslation } from "react-i18next";


// Interfaces
import { PropsTableListInterface } from "../../interfaces/propsTableListInterface";
import { PropsFilterListInterface } from "../../interfaces/propsFilterListInterface";


const TableListAndSearch = (props: PropsTableListInterface) => {
    const inputTextToSearchRef = createRef<HTMLInputElement>();

    const { t } = useTranslation();

    const [filter, setFilter] = useState("");
    const [textToSearch, setTextToSearch] = useState("");
    const { children, filters, itemsThead, onClickButtonSearch, onChangeTextToSearch, onChangeFilterToSearch } = props;


    const onChangeTextToSearchValue = (value: string) => {
        setTextToSearch(value);

        if (!!onChangeTextToSearch) {
            onChangeTextToSearch(value)
        }
    }

    return (
        <div>
            <form>
                <div className="form-group ">
                    <div className="input-group">
                        <input ref={inputTextToSearchRef} type="text" className="form-control" placeholder={t("Procurar por")} maxLength={100} value={textToSearch} onChange={(event) => onChangeTextToSearchValue(event.target.value)
                        } />
                        <div className="input-group-append">
                            <button type="submit" className="btn btn-secondary rounded-right" onClick={(event) => { event.preventDefault(); onClickButtonSearch(event, textToSearch, filter) }}>
                                <i className="fa fa-search" aria-hidden="true"></i>
                            </button>

                            <SpeechRecognitionButton setReconizedText={onChangeTextToSearchValue} />
                        </div>
                    </div>
                </div>

                {
                    filters && <div className="form-group ">
                        <div className="input-group">
                            <select className="form-control custom-select" defaultValue="-1" onChange={(event) => {
                                setFilter(event.target.value);

                                if (!!onChangeFilterToSearch) {
                                    onChangeFilterToSearch(event.target.value)
                                }
                            }}>
                                <option value="-1" disabled>{t("Filtro")}</option>
                                {
                                    filters.map((filter: PropsFilterListInterface, index: number) => {
                                        return <option key={index} value={filter.value}>{filter.title}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                }
            </form>

            <Table itemsThead={itemsThead}>
                {children}
            </Table>
        </div>
    )
}

export default TableListAndSearch;