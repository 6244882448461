import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/performance';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyDd_Hcfiz1Nn7eit5keTXwmhpTTh7KYrI8",
    authDomain: "ilaudo2editor.firebaseapp.com",
    databaseURL: "https://ilaudo2editor.firebaseio.com",
    projectId: "ilaudo2editor",
    storageBucket: "ilaudo2editor.appspot.com",
    messagingSenderId: "323662709130",
    appId: "1:323662709130:web:e303371e48c014f45b8873",
    measurementId: "G-CVZ91MZG5X"
};

export const firebaseImpl = firebase.initializeApp(config);
export const firebaseAnalitics = firebase.analytics();
export const firebasePerformance = firebase.performance();
export const firebaseDatabase = firebase.firestore();
export const firebaseAuth = firebase.auth();
export const firebaseStorage = firebase.storage();
export const firebaseLib = firebase;
