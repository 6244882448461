import React, { useEffect, useState } from 'react';

import PageHeader from './page-header-element';


const PageContainer = (props: any) => {
    return (
        <div className="container-fluid bg-white p-0 m-0 w-100">
            <PageHeader label={props.label} />
            <div className="container-fluid py-2 px-2">
                {props.children}
            </div>
        </div>
    );
};

export default PageContainer;
