import { baseURL } from '../config/api-config';
import { LaudoInterface } from '../interfaces/new/laudoInterface';
import { HttpClient } from './httpClient';
import { ParamsRequest } from './paramsRequest';

export class LaudoService {
    private httpClient: HttpClient;
    constructor() {
        this.httpClient = new HttpClient();
    }

    async lista() {
        return await this.httpClient.get({ url: `/laudo`, baseURL: baseURL });
    }

    async buscaPorAtendimentoId(idAtendimento: number) {
        const paramsRequest = new ParamsRequest();

        paramsRequest.baseURL = baseURL;
        paramsRequest.url = '/laudo';
        paramsRequest.params = { idAtendimento: idAtendimento }
        return await this.httpClient.get(paramsRequest);
    }

    async inserir(laudo: LaudoInterface, arquivo: any): Promise<any> {
        const fileName = new Date().getTime();
        const file = new File([arquivo], String(fileName));
        const form = new FormData();

        form.append('file', file, file.name);
        form.append('json', JSON.stringify(laudo));

        return await this.httpClient.post({ url: '/laudo', baseURL: baseURL, data: form });
    }
}