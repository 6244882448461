import React from 'react';

import { Route, Redirect } from 'react-router-dom';

function PrivateRoute(props: any) {
    const { children, condition, ...rest } = props;
    return (
        <Route
            {...rest}
            render={
                ({ location }) => (condition) ? (children) : (<Redirect to={{ pathname: "/login", state: { from: location } }} />)
            } />
    );
}

export default PrivateRoute;
