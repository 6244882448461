import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface PropsInterface {
    itemsThead: Array<string>;
    children?: ReactNode
}

const Table = (props: PropsInterface) => {
    const { t } = useTranslation();
    const { children, itemsThead } = props;

    return (
        <div className="table-responsive">
            <table className="table table-striped table-hover table-sm">
                <thead>
                    <tr>
                        {
                            itemsThead.map((element: string, index: number) => {
                                return <th key={index}>{t(element)}</th>
                            })
                        }
                    </tr>
                </thead>

                <tbody>
                    {children}
                </tbody>
            </table>
        </div>
    )
}

export default Table;