import { useHistory } from "react-router-dom";

import axios from "axios";
import SWAL from "sweetalert2";
import { useTranslation } from "react-i18next";

import { firebaseDatabase, firebaseStorage } from "../../config/firebase-config";

import { useDispatch } from "react-redux";
import { showLoading, hideLoading } from "../../store/actions/loading";
import { setReportData, setSelectedImages } from "../../store/actions/reportData";
import { ImageUtil } from "../../utils/image-utils";

export class MountDataFromFirebaseUtil {
    public history: any;
    public t: Function;
    private dispatch: any;
    private imageutil: ImageUtil;

    constructor() {
        this.history = useHistory();
        this.t = useTranslation().t;
        this.dispatch = useDispatch();
        this.imageutil = new ImageUtil();
    }

    public async onClickCreateReport(route: string, appointment: any = null) {
        showLoading(this.dispatch);

        if (!!appointment) {
            try {

                const appointmentMounted = await this.mountAppointment(appointment);

                if (!appointmentMounted) {
                    throw `${this.t("Appointment not mounted")}.`
                }

                const appointmentToReport = appointmentMounted.data;
                appointmentToReport.serialPath = appointment.data.serialPath;

                if (!!appointmentToReport.examRef) {
                    delete appointmentToReport.examRef;
                }

                if (!!appointmentToReport.imagesRef) {
                    delete appointmentToReport.imagesRef;
                }

                if (!!appointmentToReport.reportRef) {
                    delete appointmentToReport.reportRef;
                }

                if (!!appointmentToReport.patientRef) {
                    delete appointmentToReport.patientRef;
                }

                if (!!appointmentToReport.employeeRef) {
                    delete appointmentToReport.employeeRef;
                }

                if (!!appointmentToReport.exam.templateRef) {
                    delete appointmentToReport.exam.templateRef;
                }

                if (!!appointmentToReport.physician.selectedClinic) {
                    delete appointmentToReport.physician.selectedClinic;
                }

                if (!!appointmentToReport.exam.template.footerRef) {
                    delete appointmentToReport.exam.template.footerRef;
                }

                if (!!appointmentToReport.exam.template.headerRef) {
                    delete appointmentToReport.exam.template.headerRef;
                }

                setReportData(appointmentToReport, this.dispatch);

                this.history.push(route);

            } catch (error: any) {
                hideLoading(this.dispatch);

                SWAL.fire({
                    title: this.t("Erro"),
                    text: error,
                    icon: "error"
                });
            }

        } else {
            this.history.push(route);
        }
    }


    public async mountAppointment(appointment: any/*{ data: AppointmentInterface, docRef: DocumentReference }*/) {
        if (!appointment.data.examRef && !appointment.data.exam) {
            return null;
        }

        const examRef = await appointment.data.examRef;
        const examData = { ...appointment.data.exam };

        if (examData.templateRef && !examData.template) {
            const template = await this.mountTemplate(examData.templateRef);
            const favoriteReports = await this.mountFavoriteReports(examRef.collection("favoriteReports"), template.uid);

            appointment.data.exam.template = template;
            appointment.data.exam.favoriteReports = favoriteReports;

            appointment.data.images = await this.mountImages(await appointment.docRef.collection("images").get());

            if (!!appointment.data.report && !!appointment.data.report.imagesId) {
                const selectedImagesIds: Array<number> = appointment.data.report.imagesId;
                const patientImageCollection = appointment.data.patientRef.collection("images");

                const selectedImages = await this.imageutil.getImagesUrl(await this.mountImagesToRetificationReport(selectedImagesIds, patientImageCollection));

                setSelectedImages(selectedImages, this.dispatch);
            }

            return appointment;
        }

        if (!!examData.template && !!examData.template.header && !!examData.template.footer) {
            return appointment;
        }

        return null;
    }

    public mountFields(fieldDocs: any): Array<any> {
        let fields: Array<any> = [];
        fieldDocs.forEach(async (doc: any) => {
            let field = doc.data();
            field.uid = doc.id;
            fields.push(field);
        })
        return fields;
    }

    public getImageSection(imagePath: string) {
        if (!imagePath) {
            throw `${this.t("getImageSection: Image path not passed")}.`;
        }

        const storageRef = firebaseStorage.ref();
        const imageRef = storageRef.child(imagePath);

        return imageRef.getDownloadURL().then((imageUrl: any) => {
            return axios.get(imageUrl, { responseType: "arraybuffer" })
                .then((response: any) => {
                    let image: any = response.data;
                    let imageBase64: string = `data:image/jpeg;base64,${new Buffer(image, "binary").toString("base64")}`;

                    return imageBase64;
                })

        }).catch((err) => {
            console.error(err);
        })
    }


    public async mountTemplate(templateDoc: any) {
        // const fieldsList = await templateDoc.collection("fields").orderBy("order", "asc").get();
        const template = (await templateDoc.get()).data();
        template.uid = templateDoc.id;
        template.header = (await template.headerRef.get()).data();
        template.footer = (await template.footerRef.get()).data();
        // const fields = mountFields(fieldsList.docs);
        // template.fields = fields;

        template.header = this.mountSection(template.header);
        template.footer = this.mountSection(template.footer);

        return template;
    }

    public mountSection(section: any) {
        if (!section) {
            throw `${this.t("MountSection: Section not passed")}.`;
        }
        const sectionCotainer = document.createElement("div");

        sectionCotainer.innerHTML = section.design.replace(/contenteditable=\"true\"/gi, "").replace(/border/, "");
        (sectionCotainer.querySelector("#sectionContainerId") as HTMLDivElement).style.height = "";

        sectionCotainer.querySelectorAll(".d-none").forEach((element: any) => {
            element.remove();
        });

        if (!!section.design) {
            section.layout = "justText";
            const divContainer: HTMLDivElement = document.createElement("div");
            divContainer.innerHTML = section.design;

            if (!section.image) {
                const imageContainer: HTMLImageElement = divContainer.querySelector("#dragAndDropImageDraggable") as HTMLImageElement;

                if (!!imageContainer && imageContainer.src.indexOf("base64") !== -1) {
                    section.image = imageContainer.src;
                }
            }

            if (!section.text) {
                const textContainer: HTMLImageElement = divContainer.querySelector(`div[contenteditable="true"]`) as HTMLImageElement;

                if (!!textContainer) {
                    section.text = textContainer.innerHTML;
                }
            }

            section.layout = this.getSectionLayout(divContainer);

        } else {
            section.text = "";
            section.image = null;
        }



        return {
            text: section.text,
            image: section.image,
            layout: section.layout,
            section: sectionCotainer.innerHTML,
            height: section.height
        };
    }

    public getSectionLayout(sectionContainer: HTMLDivElement) {
        if (!sectionContainer) {
            throw `${this.t("getSectionLayout: sectionContainer not passed")}.`;
        }

        const leftColumn = sectionContainer.querySelector("#sectionColLeft");
        const rightColumn = sectionContainer.querySelector("#sectioncolRight");

        if (!!leftColumn) {
            if (leftColumn.classList.contains("d-none")) {
                return "justText";
            }
        }
        if (!!rightColumn) {
            if (rightColumn.classList.contains("d-none")) {
                return "justImage";
            } else if (rightColumn.classList.contains("w-75")) {
                return "imgLeft";
            } else {
                return "imgRight";
            }
        }
    }


    public async mountFavoriteReports(favoriteReportsCollection: any, templateId: string) {
        const favoriteReports: any = [];

        if (!favoriteReportsCollection || !templateId) {
            const errorMessage: string = !favoriteReportsCollection ? "Favorite report collection not passed" : "TemplateId not passed";

            throw `MountFavoriteReports: ${errorMessage}`;

        } else {
            const favoriteReportsDocs = await favoriteReportsCollection.where("templateId", "==", templateId).get();

            favoriteReportsDocs.forEach(async (favoriteReportDoc: any) => {
                const answers = await this.mountFavoriteAnswers((await favoriteReportDoc.ref.collection("favoriteAnswers").orderBy("order", "asc").get()).docs);
                const favoriteReport = favoriteReportDoc.data();
                favoriteReport.favoriteAnswers = answers;
                favoriteReports.push(favoriteReport);
            });
        }

        return favoriteReports;
    }

    public async mountFavoriteAnswers(favoriteAnswersDoc: any) {
        let favoriteAnswers: Array<any> = [];

        if (!favoriteAnswersDoc) {
            throw `${this.t("mountFavoriteAnswers: Favorite answer collection not passed")}.`;

        } else {
            favoriteAnswersDoc.forEach(async (doc: any) => {
                let favoriteAnswer = doc.data();
                favoriteAnswers.push(favoriteAnswer);
            })
        }

        return favoriteAnswers;
    }

    public async mountImages(imagesRefs: any) {
        let images: Array<any> = [];

        if (!imagesRefs) {
            throw `${this.t("Images ref not passed")}.`;

        } else {
            for (let index = 0; index < imagesRefs.docs.length; index++) {
                const imageDoc = await imagesRefs.docs[index].ref.get();
                const image = (await imageDoc.data().path.get()).data();
                image.uid = imageDoc.id;
                images.push(this.clearImageInfo(image));
            }
        }

        return images;
    }

    public async mountImagesToRetificationReport(selectedImagesIds: Array<number>, patientImageCollectionRef: any) {
        let selectedImages: Array<any> = [];

        if (!selectedImagesIds || !patientImageCollectionRef) {
            const errorMessage: string = !selectedImagesIds ? "Favorite report collection not passed" : "patient Image Collection not passed";

            throw `MountFavoriteReports: ${errorMessage}`;

        } else {
            for (let index = 0; index < selectedImagesIds.length; index++) {
                const imageDoc = await patientImageCollectionRef.doc(`${selectedImagesIds[index]} `).get();
                const imageData = imageDoc.data();

                imageData.uid = imageDoc.id;

                selectedImages.push(imageData);
            }
        }

        return selectedImages;
    }

    public clearImageInfo(image: any) {
        delete image.date;
        delete image.exam;
        delete image.examiner;
        delete image.patient;
        return image;
    }
}