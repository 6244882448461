import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from './store';
import Routes from './Routes';

const System = () => {
    return (
        <Provider store={store}>
            <React.StrictMode>
                <Routes />
            </React.StrictMode>
        </Provider>
    )
};

ReactDOM.render(<System />, document.getElementById('wrapper'));