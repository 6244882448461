import React from "react";

import { PropsFooterButtonsListInterface } from "../../interfaces/propsCardFooterButtonsListInterface";

const FooterButtonsList = (props: PropsFooterButtonsListInterface) => {
    const { enableForwardButton, enablePreviousButton, onClickSearchForward, onClickSearchPrevious } = props;

    return (
        <div className="card-footer d-flex justify-content-center p-1">
            <button className={`btn btn-link ${enablePreviousButton ? '' : 'disabled'} backward`} onClick={() => onClickSearchPrevious()} disabled={!enablePreviousButton}>
                <i className="fa fa-backward"></i>
            </button>

            <button className={`btn btn-link ${enableForwardButton ? '' : 'disabled'} forward`} onClick={() => onClickSearchForward()} disabled={!enableForwardButton} >
                <i className="fa fa-forward"></i>
            </button>
        </div>
    );
}

export { FooterButtonsList };