import { firebaseStorage } from '../config/firebase-config';
import { ImageInterface } from '../interfaces/imageInterface';

import Swal from "sweetalert2";
import { useDispatch } from "react-redux";

import { showLoading, hideLoading } from '../store/actions/loading';
import { useTranslation } from 'react-i18next';

export class ImageUtil {
    public t: Function;
    private dispatch: any;

    constructor() {
        this.t = useTranslation().t;
        this.dispatch = useDispatch();
    }

    public getStorageAccessImagePath(imageStoragePath: string): Promise<string> {
        if (!imageStoragePath) return Promise.resolve('');
        const imgRef = firebaseStorage
            .ref()
            .child(imageStoragePath);
        return imgRef
            .getDownloadURL()
            .then((imageUrl: any) => {
                return imageUrl;
            })
            .catch((err) => {
                console.error(err);
                return '';
            });
    }


    public getImagesUrl(images: Array<ImageInterface>) {
        showLoading(this.dispatch);

        return new Promise(async (resolve: any) => {
            let imagesReport: Array<any> = [];
            for (let i = 0; i < images.length; i++) {
                if (!!images[i] && images[i].storage && images[i].storage.filepath) {
                    let imageUrl = await this.getStorageAccessImagePath(images[i].storage.filepath);

                    if (!!imageUrl) {
                        imagesReport.push({
                            id: images[i].id,
                            uid: images[i].uid,
                            url: imageUrl,
                            legend: images[i].legend || null,
                        });
                    }
                }
            }

            resolve(imagesReport);
        })
            .then((images: any) => {
                hideLoading(this.dispatch);

                return images;
            })
            .catch((err: any) => {
                hideLoading(this.dispatch);
                Swal.fire({
                    text: err,
                    icon: "error",
                    confirmButtonText: "Ok",
                    title: this.t("Erro ao carregar imagens do paciente"),
                })
            })
    }
}