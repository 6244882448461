import { ActionInterface } from "../actions/interface/ActionInterface"
import { LoadingTypes } from "../actions/types/ActionsTypes";
import { LoadingState } from "../datatypesInterfaces/dataTypeInterfaces";

const INITIAL_STATE: LoadingState = {
    isLoading: false,
    showMessage: false,
    message: "",
}

const Loading = (state = INITIAL_STATE, action: ActionInterface) => {
    switch (action.type) {
        case LoadingTypes.SHOW_LOADING:
            return {
                ...state,
                isLoading: true,
                message: action.payload.message
            }
        case LoadingTypes.HIDE_LOADING:
            return {
                ...state,
                isLoading: false,
                showMessage: false,
            }
        case LoadingTypes.TOGGLE_LOADING:
            return {
                ...state,
                isLoading: !state.isLoading
            }
        case LoadingTypes.SHOW_LOADING_MESSAGE:
            return {
                ...state,
                showMessage: true,
            }
        case LoadingTypes.HIDE_LOADING_MESSAGE:
            return {
                ...state,
                showMessage: false,
            }
        default:
            return state;
    }
}

export { Loading };