import React, { useState, useEffect } from 'react';

import { ImageInterface } from '../../interfaces/imageInterface';

import { FirebaseService } from '../services/firebaseService';
import { firebaseStorage } from '../../config/firebase-config';
import { useTranslation } from 'react-i18next';

const PatientImages = (props: { uid: string, serialPath: string, setIsOpen: Function, setPhotoIndex: Function, setModalImages: Function }) => {
    const { t } = useTranslation();
    
    const [images, setImages] = useState([] as Array<any>);
    const [isLoading, setIsLoading] = useState(false);
    const { uid, serialPath, setIsOpen, setPhotoIndex, setModalImages } = props;

    useEffect(() => {
        if (!!uid) {
            setIsLoading(true);

            FirebaseService
                .getImagesByPatientRef(serialPath, uid)
                .then((imagesSnapshot: any) => {
                    let imgs: Array<ImageInterface> = [];
                    imagesSnapshot.forEach((image: any) => {
                        imgs.push(image.data());
                    })
                    return imgs;
                })
                .then(async (imgs: Array<ImageInterface>) => {
                    let imageUrls: Array<any> = [];
                    for (let i = 0; i < imgs.length; i++) {
                        let imageUrl = await mountImageUrl(imgs[i]);
                        if (!!imageUrl) {
                            imageUrls.push(imageUrl);
                        }
                    }
                    return imageUrls;
                })
                .then((imageUrls: any) => {
                    setImages(imageUrls);
                    setIsLoading(false);
                })
        }
    }, [uid]);

    const mountImageUrl = (imageData: any) => {
        const storageRef = firebaseStorage.ref();
        const imgRef = storageRef.child(`/${imageData.storage.filepath}`);
        return imgRef.getDownloadURL()
            .then((file: any) => {
                return file;
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const imageView = (images: Array<any>, index: number) => {
        let arrayOfImages: Array<any> = [];
        if (images.length) {
            images.map(imagem => {
                arrayOfImages.push(imagem);
            })
        }
        setIsOpen(true)
        setPhotoIndex(index);
        setModalImages(arrayOfImages);
    };

    return (
        <div className="tab-pane" role="tabpanel" aria-labelledby="nav-images-tab" id="nav-images">
            {
                isLoading ?
                    <div>
                        <p className="h4 text-muted text-center py-5">{t("Carregando imagens")}...</p>
                    </div> :
                    (!!images.length) ?
                        <div className="row m-0 p-0">
                            {
                                images.map((image: string, index: number) => (
                                    <div key={index} className="card col-3 px-2 mb-2 border-0">
                                        <img className="card-img-top divImage rounded-bottom bg-black" src={image} alt={t("Image Not Found")} />
                                        <div
                                            className="text-center p-1 position-absolute w-100 rounded-bottom d-flex align-items-center justify-content-center"
                                            style={{
                                                backgroundColor: "rgba(255, 255, 255, 0.2)",
                                                bottom: '0px'
                                            }}
                                        >
                                            <button onClick={() => { imageView(images, index) }} className="btn btn-sm btn-outline-light border-0 px-2 py-0" title={t("Visualizar")}>
                                                <i className="bi bi-eye" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div> :
                        <div>
                            <p className="h4 text-muted text-center py-5">{t("Nenhuma imagem encontrada!")}</p>
                        </div>
            }
        </div>
    );
}
export { PatientImages };
