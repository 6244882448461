import { baseURL } from '../config/api-config';
import { HttpClient } from './httpClient';

export class EmailService {
    private httpClient: HttpClient;
    constructor() {
        this.httpClient = new HttpClient();
    }

    async enviarEmail(dadosEmail: any) {
        return await this.httpClient.post({ url: `/email/entregaLaudo`, baseURL: baseURL, data: dadosEmail });
    }
}