import { ActionInterface } from "../actions/interface/ActionInterface"
import { ReportDataTypes } from "../actions/types/ActionsTypes";
import { ReportDataState } from "../datatypesInterfaces/dataTypeInterfaces";

const INITIAL_STATE: ReportDataState = {
    reportData: null as any,
    selectedImages: []
}

const ReportData = (state = INITIAL_STATE, action: ActionInterface): ReportDataState => {
    switch (action.type) {
        case ReportDataTypes.REPORT_DATA:
            return {
                ...state,
                reportData: action.payload
            }
        case ReportDataTypes.SET_PATIENT_IMAGES: {
            return {
                ...state,
                reportData: {
                    ...state.reportData,
                    patient: {
                        ...state.reportData.patient,
                        images: action.payload
                    }
                }
            }
        }
        case ReportDataTypes.SET_SELECTED_IMAGES: {
            return {
                ...state,
                selectedImages: action.payload
            }
        }
        case ReportDataTypes.RESET_REPORT_DATA: {
            return INITIAL_STATE;
        }
        default:
            return state;
    }
}

export { ReportData };