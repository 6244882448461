import Axios, { AxiosRequestConfig } from "axios";
import { useSelector } from "react-redux";
import { ApplicationState } from "../store";
import { ParamsRequest } from "./paramsRequest";
import { LocalStorageUtils } from "../utils/localStorage-utils";

export class HttpClient {
  async get(paramsRequest: ParamsRequest): Promise<any> {
    paramsRequest.method = 'GET';
    return this.callRequest(paramsRequest);
  }

  async post(paramsRequest: ParamsRequest) {
    paramsRequest.method = 'POST';
    return this.callRequest(paramsRequest);
  }

  async put(paramsRequest: ParamsRequest) {
    paramsRequest.method = 'PUT';
    return this.callRequest(paramsRequest);
  }

  async delete(paramsRequest: ParamsRequest) {
    paramsRequest.method = 'DELETE';
    return this.callRequest(paramsRequest);
  }

  private async callRequest(paramsRequest: ParamsRequest): Promise<any> {
    paramsRequest.url = `${paramsRequest.baseURL}${paramsRequest.url}`

    delete paramsRequest.baseURL;

    return new Promise((resolve, reject) => {
      const config = this.getRequestConfig(paramsRequest);

      Axios(paramsRequest.url || "", config)
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });

  }

  private getRequestConfig(paramsRequest: ParamsRequest): AxiosRequestConfig {
    const clinicaSelecionada = LocalStorageUtils.buscar('clinicaSelecionada') as any;
    const usuario = LocalStorageUtils.buscar('usuario') as any;

    const headers = {
      ...paramsRequest.headers,
    }

    if (usuario && clinicaSelecionada && clinicaSelecionada?.idContratoPrincipal) {
      headers.idContrato = clinicaSelecionada?.idContratoPrincipal;
    }

    if (usuario && usuario?.token) {
      headers.Authorization = `Bearer ${usuario?.token}`
    }

    headers.application = "iLaudo 2.0";

    return {
      headers,
      data: paramsRequest.data,
      params: paramsRequest.params,
      method: paramsRequest.method,
      baseURL: paramsRequest.baseURL
    }
  }
}
