import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const CardSelectLang = ({ visible, lang, onCloseClick, onChange, position }: any) => {
    const { t } = useTranslation();

    const langs = [
        {
            acr: 'pt-BR',
            name: 'Português (BR)'
        },
        {
            acr: 'en-US',
            name: "English (BETA)"
        },
        {
            acr: 'es-ES',
            name: "Español (BETA)"
        }
    ];

    const [selLang, setSelLang] = useState('');

    useEffect(() => {
        if (!selLang) {
            setSelLang(lang);
        }
    }, [selLang]);

    return (

        < div
            style={position}
            className={(!visible) ? "d-none" : "d-block"}
        >
            <div className="card h-100 shadow">
                <div className="card-header p-2 border-0">
                    <span className="h5">{t("Selecione o idioma")}</span>
                    <button type="button" className="close" aria-label="Close" onClick={onCloseClick}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="card-body p-2">
                    <ul className="list-group list-group-flush border-0">
                        {
                            langs.map(itemLang => (
                                <li key={itemLang.acr} className="list-group-item border-0">
                                    <div className="custom-control custom-radio">
                                        <input
                                            type="radio"
                                            name="radioLang"
                                            id={`customRadioLang_${itemLang.acr}`}
                                            className="custom-control-input"
                                            value={itemLang.acr}
                                            onChange={(ev) => setSelLang(ev.target.value)}
                                            checked={itemLang.acr === selLang}
                                        />
                                        <label className="custom-control-label" htmlFor={`customRadioLang_${itemLang.acr}`}>{itemLang.name}</label>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div className="card-footer p-2 text-right border-0">
                    <button className="btn btn-success" onClick={() => { onChange(selLang) }}>{t("Aplicar")}</button>
                </div>
            </div>
        </div >

    )
};

export default CardSelectLang;
