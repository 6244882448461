import { baseURL } from '../config/api-config';
import { ExameInterface } from '../interfaces/new/exameInterface';
import { HttpClient } from './httpClient';

export class ExameService {
    private httpClient: HttpClient;
    constructor() {
        this.httpClient = new HttpClient();
    }

    async lista() {
        return await this.httpClient.get({ url: `/exame`, baseURL: baseURL });
    }
}