import { ActionInterface } from '../actions/interface/ActionInterface'
import { ImageEditorTypes } from '../actions/types/ActionsTypes';
import { ImageEditorState } from '../datatypesInterfaces/dataTypeInterfaces';
import { NumberUtils } from '../../utils/numberUtils';

const INITIAL_STATE: ImageEditorState = {
    dataImageOriginal: null,
    originalImgUrl: "",
    imageURL: "",
    cropper: null,
    imageData: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        naturalWidth: 0,
        naturalHeight: 0,
        aspectRatio: 0,
        rotate: 0,
        scaleX: 0,
        scaleY: 0,
    },
    isSaved: true,
    cropping: false,
    croppingEnable: true,
    fabricEnable: false,
    redo: [],
    undo: [],
    btnRedoDisable: true,
    btnUndoDisable: true,
    btnResetDisable: true,
    dataBeforeCropper: null,
    imageBeforeCropper: null,
    modalClippingEnable: false,
    modalDrawEnable: false,
    modalDrawArrowEnable: false,
    modalApplyEditText: false,
    imageDataLeft: 0,
    imageDataTop: 0,
    isFabricEdition: false,
    fabricCanvas: null as any,
    currentFabricImage: null as any,
    textEditing: null as any,
    textOptions: {
        fill: "#000",
        shadow: "2px 2px 3px #2222",
        fontSize: 30,
        top: NumberUtils.getRandomNumber(50, 600),
        left: NumberUtils.getRandomNumber(50, 300),
        selected: true,
    },
    arrowOptions: {
        fill: "black",
        stroke: "black",
        opacity: 1,
        strokeWidth: 1,
        originX: "left",
        originY: "top",
        selectable: true,
        selected: true,
    },
    brushOptions: {
        width: 1,
        color: "#000"
    },
    richTextCurrentColor: "#000",
    noDispatchObjectAdd: false,
    historyUndoBeforeDrawing: [],
    historyRedoBeforeDrawing: [],
    stateFabric: null,
    drawingArrowActive: false,
    startXDrawArrow: null,
    startYDrawArrow: null,
    endXDrawArrow: null,
    endYDrawArrow: null,
    firstPointArrow: null,
    resetFabricEditing: false,
    undoFabric: false,
    redoFabric: false,
    removeFabricActiveElement: false,
    updateImages: false,
};

const ImageEditor = (state = INITIAL_STATE, action: ActionInterface): ImageEditorState => {
    switch (action.type) {
        case ImageEditorTypes.SET_IMAGE_TO_EDIT:
            return {
                ...state,
                dataImageOriginal: action.payload
            }
        case ImageEditorTypes.SET_IMAGE_ORIGINAL_URL:
            return {
                ...state,
                originalImgUrl: action.payload
            }
        case ImageEditorTypes.SET_IMAGE_URL:
            return {
                ...state,
                imageURL: action.payload
            }
        case ImageEditorTypes.SET_CROPPER:
            return {
                ...state,
                cropper: action.payload
            }
        case ImageEditorTypes.SET_IMAGE_DATA:
            return {
                ...state,
                imageData: action.payload
            }
        case ImageEditorTypes.SET_CROPPING:
            return {
                ...state,
                cropping: action.payload
            }
        case ImageEditorTypes.SHOW_CROPPING:
            return {
                ...state,
                croppingEnable: true
            }
        case ImageEditorTypes.HIDE_CROPPING:
            return {
                ...state,
                croppingEnable: false
            }
        case ImageEditorTypes.SHOW_FABRIC:
            return {
                ...state,
                fabricEnable: true
            }
        case ImageEditorTypes.HIDE_FABRIC:
            return {
                ...state,
                fabricEnable: false
            }
        case ImageEditorTypes.CLEAN_HISTORY:
            return {
                ...state,
                redo: [],
                undo: [],
                btnRedoDisable: true,
                btnUndoDisable: true,
                btnResetDisable: true,
            }
        case ImageEditorTypes.SET_UNDO_HISTORY:
            return {
                ...state,
                undo: action.payload
            }
        case ImageEditorTypes.PUSH_HISTORY:
            return {
                ...state,
                undo: [
                    ...state.undo,
                    action.payload
                ]
            }
        case ImageEditorTypes.SET_IS_SAVED:
            return {
                ...state,
                isSaved: action.payload
            }
        case ImageEditorTypes.PUSH_UNDO_HISTORY:
            return {
                ...state,
                undo: [
                    ...state.undo,
                    action.payload
                ]
            }
        case ImageEditorTypes.POP_UNDO_HISTORY:
            return {
                ...state,
                undo: state.undo.filter((item: any, index: number) => index !== (state.undo.length - 1))
            }
        case ImageEditorTypes.CLEAR_UNDO_HISTORY:
            return {
                ...state,
                undo: []
            }
        case ImageEditorTypes.PUSH_REDO_HISTORY:
            return {
                ...state,
                redo: [
                    ...state.redo,
                    action.payload
                ]
            }
        case ImageEditorTypes.POP_REDO_HISTORY:
            return {
                ...state,
                redo: state.redo.filter((item: any, index: number) => index !== (state.redo.length - 1))
            }
        case ImageEditorTypes.CLEAR_REDO_HISTORY:
            return {
                ...state,
                redo: []
            }
        case ImageEditorTypes.SET_BUTTON_REDO_DISABLE:
            return {
                ...state,
                btnRedoDisable: action.payload
            }
        case ImageEditorTypes.SET_BUTTON_UNDO_DISABLE:
            return {
                ...state,
                btnUndoDisable: action.payload
            }
        case ImageEditorTypes.SET_BUTTON_RESET_DISABLE:
            return {
                ...state,
                btnResetDisable: action.payload
            }
        case ImageEditorTypes.SET_DATA_BEFORE_CROPPER:
            return {
                ...state,
                dataBeforeCropper: action.payload
            }
        case ImageEditorTypes.SET_IMAGE_BEFORE_CROPPER:
            return {
                ...state,
                imageBeforeCropper: action.payload
            }
        case ImageEditorTypes.SHOW_MODAL_APPLY_CLIPPING:
            return {
                ...state,
                modalClippingEnable: true
            }
        case ImageEditorTypes.HIDE_MODAL_APPLY_CLIPPING:
            return {
                ...state,
                modalClippingEnable: false
            }
        case ImageEditorTypes.SHOW_MODAL_BRUSH:
            return {
                ...state,
                modalDrawEnable: true
            }
        case ImageEditorTypes.HIDE_MODAL_DRAW:
            return {
                ...state,
                modalDrawEnable: false
            }
        case ImageEditorTypes.SHOW_MODAL_DRAW_ARROW:
            return {
                ...state,
                modalDrawArrowEnable: true
            }
        case ImageEditorTypes.HIDE_MODAL_DRAW_ARROW:
            return {
                ...state,
                modalDrawArrowEnable: false
            }
        case ImageEditorTypes.SHOW_MODAL_APPLY_EDIT_TEXT:
            return {
                ...state,
                modalApplyEditText: true
            }
        case ImageEditorTypes.HIDE_MODAL_APPLY_EDIT_TEXT:
            return {
                ...state,
                modalApplyEditText: false
            }
        case ImageEditorTypes.SET_IMAGE_DATA_LEFT:
            return {
                ...state,
                imageDataLeft: action.payload
            }
        case ImageEditorTypes.SET_IMAGE_DATA_TOP:
            return {
                ...state,
                imageDataTop: action.payload
            }
        case ImageEditorTypes.ENABLE_FABRIC_EDITION:
            return {
                ...state,
                isFabricEdition: action.payload
            }
        case ImageEditorTypes.SET_FABRIC_CANVAS:
            return {
                ...state,
                fabricCanvas: action.payload
            }
        case ImageEditorTypes.SET_CURRENT_FABRIC_IMAGE:
            return {
                ...state,
                currentFabricImage: action.payload
            }
        case ImageEditorTypes.SET_NO_DISPATCH_OBJECT_ADD:
            return {
                ...state,
                noDispatchObjectAdd: action.payload
            }
        case ImageEditorTypes.SET_FABRIC_IS_DRAWING_MODE:
            state.fabricCanvas.isDrawingMode = action.payload;

            return {
                ...state,
                fabricCanvas: state.fabricCanvas
            }
        case ImageEditorTypes.PUSH_HISTORY_UNDO_BEFORE_DRAWING:
            return {
                ...state,
                historyUndoBeforeDrawing: [
                    ...state.historyUndoBeforeDrawing,
                    action.payload
                ]
            }
        case ImageEditorTypes.POP_HISTORY_UNDO_BEFORE_DRAWING:
            return {
                ...state,
                historyUndoBeforeDrawing: state.historyUndoBeforeDrawing.filter((item: any, index: number) => index !== (state.historyUndoBeforeDrawing.length - 1))
            }
        case ImageEditorTypes.CLEAR_HISTORY_UNDO_BEFORE_DRAWING:
            return {
                ...state,
                historyUndoBeforeDrawing: []
            }
        case ImageEditorTypes.PUSH_HISTORY_REDO_BEFORE_DRAWING:
            return {
                ...state,
                ...state,
                historyRedoBeforeDrawing: [
                    ...state.historyRedoBeforeDrawing,
                    action.payload
                ]
            }
        case ImageEditorTypes.POP_HISTORY_REDO_BEFORE_DRAWING:
            return {
                ...state,
                historyRedoBeforeDrawing: state.historyRedoBeforeDrawing.filter((item: any, index: number) => index !== (state.historyRedoBeforeDrawing.length - 1))
            }
        case ImageEditorTypes.CLEAR_HISTORY_REDO_BEFORE_DRAWING:
            return {
                ...state,
                historyRedoBeforeDrawing: []
            }
        case ImageEditorTypes.SET_STATE_FABRIC:
            return {
                ...state,
                stateFabric: action.payload
            }
        case ImageEditorTypes.ENABLE_DRAWING_ARROW:
            return {
                ...state,
                drawingArrowActive: action.payload
            }
        case ImageEditorTypes.SET_FABRIC_SELECTION:
            state.fabricCanvas.selection = action.payload;

            return {
                ...state,
                fabricCanvas: state.fabricCanvas
            }
        case ImageEditorTypes.CLEAN_DRAW_ARROW:
            return {
                ...state,
                startXDrawArrow: null,
                startYDrawArrow: null,
                endXDrawArrow: null,
                endYDrawArrow: null,
                firstPointArrow: null,
            }
        case ImageEditorTypes.SET_EDITOR_TEXT:
            return {
                ...state,
                textEditing: action.payload
            }

        case ImageEditorTypes.SET_RICH_TEXT_CURRENT_COLOR:
            return {
                ...state,
                richTextCurrentColor: action.payload.onChange,
            }
        case ImageEditorTypes.SET_RESET_FABRIC_EDITING:
            return {
                ...state,
                resetFabricEditing: action.payload
            }
        case ImageEditorTypes.SET_UNDO_FABRIC:
            return {
                ...state,
                undoFabric: action.payload
            }
        case ImageEditorTypes.SET_REDO_FABRIC:
            return {
                ...state,
                redoFabric: action.payload
            }

        case ImageEditorTypes.SET_REMOVE_FABRIC_ACTIVE_ELEMENT:
            return {
                ...state,
                removeFabricActiveElement: action.payload,
            }

        case ImageEditorTypes.SET_ARROW_OPTIONS:
            return {
                ...state,
                arrowOptions: {
                    ...state.arrowOptions,
                    ...action.payload,
                },
            }
        case ImageEditorTypes.SET_BRUSH_OPTIONS:
            return {
                ...state,
                brushOptions: {
                    ...state.brushOptions,
                    ...action.payload,
                },
            }
        case ImageEditorTypes.SET_UPDATE_IMAGES:
            return {
                ...state,
                updateImages: action.payload,
            }

        case ImageEditorTypes.CLEAR_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}

export { ImageEditor };