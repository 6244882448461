import { NotificationState } from "../datatypesInterfaces/dataTypeInterfaces";

import { ActionInterface } from "../actions/interface/ActionInterface"
import { NotificationTypes } from "../actions/types/ActionsTypes";

const INITIAL_STATE: NotificationState = {
    message: '',
    title: '',
    show: false,
    type: "primary"
}

const Notification = (state = INITIAL_STATE, action: ActionInterface): NotificationState => {
    switch (action.type) {
        case NotificationTypes.SET_NOTIFICATION_TITLE:
            return {
                ...state,
                title: action.payload
            }
        case NotificationTypes.SET_NOTIFICATION_MESSAGE:
            return {
                ...state,
                message: action.payload
            }
        case NotificationTypes.SET_NOTIFICATION_TYPE:
            return {
                ...state,
                type: action.payload
            }
        case NotificationTypes.SHOW_NOTIFICATION:
            return {
                ...state,
                show: true
            }
        case NotificationTypes.HIDE_NOTIFICATION:
            return {
                ...state,
                show: false
            }
        case NotificationTypes.RESET_NOTIFICATION:
            return INITIAL_STATE;
        default:
            return state;
    }
}

export { Notification };