import React from 'react';
import ImageEditorHeader from '../components/image-editor-header-component';
import ImageEditorContent from '../components/image-editor-content-component';

const ImageEditor = () => {
    return (
        <div className="modal  p-0" id="imageEditorModal" role="dialog" aria-labelledby="imageEditorTitleModal" aria-hidden="true">
            <div className="modal-dialog w-100 m-0" role="document" style={{ margin: '50px auto', maxWidth: '100%' }}>
                <div className="modal-content">
                    <div className="w-100 bg-dark position-absolute" style={style.container}>
                        <div className="card bg-dark h-100 rounded-0 text-light">
                            <ImageEditorHeader />
                            <ImageEditorContent />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

const style = {
    container: {
        height: "99.9vh",
        maxHeight: "99.9vh"
    }
}

export { ImageEditor };