export enum AppTypes {
    SET_CLINICS = "set_clinics",
    SET_USER_DOCTOR = "set_user_doctor",
    SET_SELECTED_CLINIC = "set_selected_clinic",
    SET_SERIAL = "set_serial",
    SET_SERIAL_PATH = "set_serial_path",
    SET_USER_EMAIL = "set_user_email",
    SET_CLINIC_GLOBAL_PATH = "set_clinic_global_path",
    ENABLE_SYNC_BUTTON = "enable_sync_button",
}

export enum LoadingTypes {
    SHOW_LOADING = "show_loading",
    HIDE_LOADING = "hide_loading",
    TOGGLE_LOADING = "toggle_loading",
    SHOW_LOADING_MESSAGE = "SHOW_LOADING_MESSAGE",
    HIDE_LOADING_MESSAGE = "HIDE_LOADING_MESSAGE",
}

export enum HeaderBarTypes {
    SET_HEADER_TITLE = "set_header_title"
}

export enum ReportDataTypes {
    REPORT_DATA = "report_data",
    SET_PATIENT_IMAGES = "set_patient_images",
    SET_SELECTED_IMAGES = "set_selected_images",
    RESET_REPORT_DATA = "reset_report_data",
}

export enum ViewSelectTypes {
    SELECTED_EXAM = "selected_exam",
    SELECTED_PATIENT = "selected_patient",
    SELECTED_PHYSICIAN = "selected_physician",
    SELECTED_REFERRING_PHYSICIAN = "selected_referring_physician",
}

export enum ImageEditorTypes {
    SET_IMAGE_TO_EDIT = "set_image_to_edit",
    SET_IMAGE_ORIGINAL_URL = "set_image_original_url",
    SET_IMAGE_URL = "set_image_url",
    SET_CROPPER = "set_cropper",
    SET_IMAGE_DATA = "set_image_data",
    SET_CROPPING = "set_cropping",
    SHOW_CROPPING = "show_cropping",
    HIDE_CROPPING = "hide_cropping",
    SHOW_FABRIC = "show_fabric",
    HIDE_FABRIC = "hide_fabric",
    CLEAN_HISTORY = "clean_history",
    SET_UNDO_HISTORY = "set_undo_history",
    PUSH_HISTORY = "push_history",
    POP_HISTORY = "pop_history",
    SET_IS_SAVED = "set_is_saved",
    PUSH_UNDO_HISTORY = "push_undo_history",
    POP_UNDO_HISTORY = "pop_undo_history",
    CLEAR_UNDO_HISTORY = "clear_undo_history",
    PUSH_REDO_HISTORY = "push_redo_history",
    POP_REDO_HISTORY = "pop_redo_history",
    CLEAR_REDO_HISTORY = "clear_redo_history",
    SET_BUTTON_REDO_DISABLE = "set_button_redo_disable",
    SET_BUTTON_UNDO_DISABLE = "set_button_undo_disable",
    SET_BUTTON_RESET_DISABLE = "set_button_reset_disable",
    SET_DATA_BEFORE_CROPPER = "set_data_before_cropper",
    SET_IMAGE_BEFORE_CROPPER = "set_image_before_cropper",
    SHOW_MODAL_APPLY_CLIPPING = "show_modal_apply_clipping",
    HIDE_MODAL_APPLY_CLIPPING = "hide_modal_apply_clipping",
    SET_IMAGE_DATA_LEFT = "set_image_data_left",
    SET_IMAGE_DATA_TOP = "set_image_data_top",
    ENABLE_FABRIC_EDITION = "enable_fabric_edition",
    SET_FABRIC_CANVAS = "set_fabric_canvas",
    SET_CURRENT_FABRIC_IMAGE = "set_current_fabric_image",
    SET_NO_DISPATCH_OBJECT_ADD = "set_no_dispatch_object_add",
    SET_FABRIC_IS_DRAWING_MODE = "set_fabric_is_drawing_mode",
    PUSH_HISTORY_UNDO_BEFORE_DRAWING = "push_history_undo_before_drawing",
    POP_HISTORY_UNDO_BEFORE_DRAWING = "pop_history_undo_before_drawing",
    PUSH_HISTORY_REDO_BEFORE_DRAWING = "push_history_redo_before_drawing",
    POP_HISTORY_REDO_BEFORE_DRAWING = "pop_history_redo_before_drawing",
    CLEAR_HISTORY_UNDO_BEFORE_DRAWING = "clear_history_undo_before_drawing",
    CLEAR_HISTORY_REDO_BEFORE_DRAWING = "clear_history_redo_before_drawing",
    SET_STATE_FABRIC = "set_state_fabric",
    SHOW_MODAL_BRUSH = "show_modal_brush",
    HIDE_MODAL_DRAW = "hide_modal_draw",
    ENABLE_DRAWING_ARROW = "enable_drawing_arrow",
    SET_FABRIC_SELECTION = "set_fabric_selection",
    CLEAN_DRAW_ARROW = "clean_draw_arrow",
    SHOW_MODAL_DRAW_ARROW = "show_modal_draw_arrow",
    HIDE_MODAL_DRAW_ARROW = "hide_modal_draw_arrow",
    SHOW_MODAL_APPLY_EDIT_TEXT = "show_modal_apply_edit_text",
    HIDE_MODAL_APPLY_EDIT_TEXT = "hide_modal_apply_edit_text",
    SET_EDITOR_TEXT = "set_editor_text",
    CLEAR_STATE = "clear_state",
    SET_RICH_TEXT_CURRENT_COLOR = "set_rich_text_current_color",
    SET_RESET_FABRIC_EDITING = "set_reset_fabric_editing",
    SET_UNDO_FABRIC = "set_undo_fabric",
    SET_REDO_FABRIC = "set_redo_fabric",
    SET_REMOVE_FABRIC_ACTIVE_ELEMENT = "set_remove_fabric_active_element",
    SET_ARROW_OPTIONS = "set_arrow_options",
    SET_BRUSH_OPTIONS = "set_brush_options",
    SET_UPDATE_IMAGES = "set_update_images",
}

export enum ExamTypes {
    EXAM_TO_LIST = "exam_to_list",
    SET_FAVORITE_REPORTS = "set_favorite_reports"
}

export enum FavoriteReportTypes {
    SET_INPUT_SEARCH_VALUE = "set_favorite_report_input_search_value",
    SET_ROWS_ON_TABLE = "set_favorite_report_rows_on_table",
    SET_FIRST_VISIBLE = "set_favorite_report_first_visible",
    SET_LAST_VISIBLE = "set_favorite_report_last_visible",
    SET_PREVIOUS_FIRST_VALUES = "set_favorite_report_previous_first_values",
    SET_PAGE_INDEX = "set_favorite_report_page_index",
    SET_OFFSET_TOTAL = "set_favorite_report_offset_total",
    SET_ENABLE_PREVIOUS_BUTTON = "set_favorite_report_enable_previous_button",
    SET_ENABLE_FORWARD_BUTTON = "set_favorite_report_enable_forward_button",
    SET_MODAL_OPEN = "set_favorite_report_modal_open",
    SET_MODAL_CLOSED = "set_favorite_report_modal_closed",
    RESET_PAGINATION = "reset_favorite_report_pagination",
}

export enum PatientTypes {
    PATIENT_TO_LIST = "patient_to_list",
}

export enum PhysicianTypes {
    PHYSICIAN_TO_LIST = "physician_to_list",
}

export enum ReferringPhysicianTypes {
    REFERRING_PHYSICIAN_TO_LIST = "referring_physician_to_list",
}

export enum NotificationTypes {
    SET_NOTIFICATION_TITLE = "set_notification_title",
    SET_NOTIFICATION_MESSAGE = "set_notification_message",
    SET_NOTIFICATION_TYPE = "set_notification_type",
    SHOW_NOTIFICATION = "show_notification",
    HIDE_NOTIFICATION = "hide_notification",
    RESET_NOTIFICATION = "reset_notification",
}

export enum TwakToTypes {
    SET_SHOW_CHAT = "set_show_chat",
}

export enum LogoutTypes {
    SET_LOGOUT = "set_logout",
}

export enum StopListeningTypes {
    SET_STOPLISTENING = "set_stop_listening",
}