import React from 'react';

const Footer = () => (
    <footer className="my-0 p-0">
        {/* 
        <div className="container">
            <div className="copyright text-center small text-muted">
                <span>Copyright © Zscan Software 2020</span>
            </div>
        </div> 
        */}
    </footer>
);

export default Footer;
