import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ModalConfigEmail from "./config-email-modal-element";
import { commonHeaders, baseURL } from "../../config/api-config";
import { showLoading, hideLoading } from "../../store/actions/loading";
import { useDispatch } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import { ReportCloudInterface } from "../../interfaces/reportCloudInterface";
import { PatientInterface } from "../../interfaces/patientInterface";
import { PeopleContactInterface } from "../../interfaces/peopleContactInterface";
import { EmailService } from "../../service/email.service";

const ModalSendEmail = (props: { file: any, reportData: ReportCloudInterface, pdfCaminho: string }) => {
    const { t, i18n   } = useTranslation();
    const dispatch = useDispatch();

    const [emailAddress, setEmailAddress] = useState("");
    const [copyEmailAddress, setCopyEmailAddress] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [patient, setPatient] = useState(null as any);

    const { file, reportData, pdfCaminho } = props;

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (patient && (patient.contacts as Array<PeopleContactInterface>).length && (patient.contacts as Array<PeopleContactInterface>)[0].email1) {
            if ((patient.contacts as Array<PeopleContactInterface>)[0].email1) {
                setEmailAddress((patient.contacts as Array<PeopleContactInterface>)[0].email1);
            }
            if ((patient.contacts as Array<PeopleContactInterface>)[0].email2) {
                setCopyEmailAddress((patient.contacts as Array<PeopleContactInterface>)[0].email2 as string);
            }
        }
    }, [patient]);

    const init = async () => {
        // const patientData = (await reportData.patientRef.get()).data() as PatientInterface;
        // setPatient(patientData);
    }

    const closeModal = () => {
        document.getElementById("btnSendEmailCloseId")?.click();
    }

    const showConfigEmail = () => {
        (document.querySelector("#divEmailForm") as HTMLDivElement).classList.add("d-none");
        (document.querySelector("#divConfigEmail") as HTMLDivElement).classList.remove("d-none");
    }

    function cleanForm() {
        if (patient && (patient.contacts as Array<PeopleContactInterface>).length && (patient.contacts as Array<PeopleContactInterface>)[0].email1) {
            setEmailAddress((patient.contacts as Array<PeopleContactInterface>)[0].email1);
        } else {
            setEmailAddress("");
        }
        setCopyEmailAddress("");
        setSubject("");
        setMessage("");
    }

    const sendEmail = () => {

        const dataEmail = {
            to: emailAddress,
            type: "PDF",
            text: message,
            subject: subject,
            cc: copyEmailAddress,
            pdf: pdfCaminho,
            idioma: i18n.language
        };
        
        showLoading(dispatch);
        return new EmailService().enviarEmail(dataEmail)
            .then(() => {
                hideLoading(dispatch);
                return Swal.fire({
                    title: t("E-mail enviado com sucesso"),
                    text: t("Clique em ok para continuar"),
                    icon: "success",
                    confirmButtonText: t("Ok")
                })
                    .then(() => {
                        cleanForm();
                        closeModal();
                    })
            })
            .catch((error: any) => {
                hideLoading(dispatch);
                console.error(error);

                return Swal.fire({
                    title: t("Erro ao enviar laudo"),
                    text: error,
                    icon: "error",
                    confirmButtonText: t("Ok")
                })
            })
    }

    return (
        <div className="modal " id="sendEmailModal" tabIndex={-1} role="dialog" aria-labelledby="sendEmailModalLabel" aria-hidden="true">
            <div className="modal-dialog w-75" role="document" >
                <div className="modal-content card w-75" style={{ margin: "50px auto" }} id="divEmailForm">
                    <div className="card-header ml-2 mr-2 bg-white p-2 d-flex flex-row align-items-center justify-content-between">
                        <span data-l10n-id="modal_header_title" className="translate m-0 p-0 font-weight-light">{t("Enviar um e-mail")}</span>
                        <button type="button" arial-label="Close" data-dismiss="modal" className="close" id="btnSendEmailCloseId">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="card-body p-4" id="sendEmailBody">
                        <div className="form-group row mb-4">
                            <div className="input-group col-12">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className="fa fa-envelope" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <input type="text" data-l10n-id="modal_email_sender" className="form-control" placeholder="From" id="sendEmailSenderId" disabled title="Sender" value={"noreply@zscansoftware.com"} />
                            </div>
                        </div>
                        <div className="form-group row mb-4">
                            <div className="input-group col-12">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className="far fa-envelope" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <input type="text" className="form-control" placeholder={t("Para")} title={t("Para")} value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-group row mb-4">
                            <div className="input-group col-12">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className="fa fa-copy" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <input type="text" className="form-control" placeholder={t("Cc")} title={t("CcTitle")} value={copyEmailAddress} onChange={(e) => setCopyEmailAddress(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-group row mb-4">
                            <div className="input-group col-12">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className="fa fa-tag" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <input type="text" className="form-control" placeholder={t("Adicionar assunto")} title={t("Assunto")} value={subject} onChange={(e) => setSubject(e.target.value)} />
                                {/* <div className="input-group-append d-inline">
                                    <button className="btn btn-secondary rounded-right" type="button" id="btnPredefinitionEmail">
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div> */}
                            </div>
                        </div>
                        <div className="form-group row mb-4">
                            <div className="input-group col-12">
                                <div className="input-group-prepend d-inline">
                                    <span className="input-group-text">
                                        <i className="far fa-comment-dots" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <textarea name="Message" className="form-control" placeholder={t("Mensagem")} style={{height: "auto"}} cols={30} rows={10} value={message} onChange={(e) => setMessage(e.target.value)} ></textarea>
                            </div>
                        </div>
                        {/* <div className="row mb-4">
                            <div className="col font-weight-bold my-2">
                                <i className="fa fa-paperclip mr-2"></i>
                                <label id="descriptionDocumentId" title={t("Anexo")}>{reportData.pdfName}</label>
                            </div>
                        </div> */}
                    </div>
                    <div className="card-footer p-2">
                        <div className="row m-0 p-0">
                            <div className="col text-left p-0 d-none">
                                <button className="btn btn-light" id="btnConfigEmail" type="button" onClick={showConfigEmail}>
                                    <i className="fa fa-cog" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="col text-right p-0">
                                <button className="btn btn-warning ml-3" type="button" id="btnSendEmailCancelId" data-dismiss="modal" onClick={cleanForm}>
                                    <i className="fa fa-ban mr-2" aria-hidden="true"> </i>
                                    <span className="translate">{t("Cancelar")}</span>
                                </button>
                                <button className="btn btn-success ml-3" type="button" id="btnSendEmailConfirmId" onClick={sendEmail}>
                                    <i className="fa fa-paper-plane mr-2" aria-hidden="true"> </i>
                                    <span className="translate">{t("Enviar")}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalConfigEmail />
            </div>
        </div>
    )
}

export default ModalSendEmail;