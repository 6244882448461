import { combineReducers } from "redux";

// alphabetically import
import App from './app';
import { Exam } from "./exam";
import { HeaderBar } from "./headerBar";
import { ImageEditor } from './imageEditor';
import { Loading } from "./loading";
import { Patient } from "./patient";
import { Physician } from "./physician";
import { ReferringPhysician } from "./referringPhysician";
import { ReportData } from "./reportData";
import { StopListening } from "./stopListening";
import { ViewSelect } from "./viewSelect";
import { FavoriteReport } from './favoriteReport';
import { Notification } from './notification';
import { TawkTo } from './tawkTo';
import { Logout } from './logout';


const reducers = combineReducers({
    app: App,
    loading: Loading,
    headerBar: HeaderBar,
    imageEditor: ImageEditor,
    exam: Exam,
    patient: Patient,
    physician: Physician,
    referringPhysician: ReferringPhysician,
    reportData: ReportData,
    stopListening: StopListening,
    viewSelect: ViewSelect,
    favoriteReport: FavoriteReport,
    notification: Notification,
    tawkTo: TawkTo,
    logout: Logout,
});

export default reducers;