import { ActionInterface } from "../actions/interface/ActionInterface"
import { PatientTypes } from "../actions/types/ActionsTypes";
import { PatientState } from "../datatypesInterfaces/dataTypeInterfaces";

const INITIAL_STATE: PatientState = {
    patientsToList: [],
}

const Patient = (state = INITIAL_STATE, action: ActionInterface) => {
    
    switch (action.type) {
        case PatientTypes.PATIENT_TO_LIST:
            return {
                ...state,
                patientsToList: action.payload
            }
        default:
            return state;
    }
}

export { Patient };