import i18next from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const myDetector = {
	name: 'myDetectorsName',
	lookup(options: any) {
		const langStored = localStorage[options.lookupLocalStorage];
		if (!!langStored) {
			return langStored;
		}
		return 'pt-BR';
	},
	cacheUserLanguage(lng: string, options: any) {
		localStorage[options.lookupLocalStorage] = lng;
	}
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(myDetector);

i18next
	.use(Backend)
	.use(languageDetector)
	.use(initReactI18next)
	.init({
		debug: false,
		fallbackLng: "pt-BR",
		// saveMissing: true,
		// lng: "pt-BR",
		preload: ["{{lng}}"],
		whitelist: ['pt-BR', 'es-ES', 'en-US'],//'en-US', 'pt-PT', 'fr-FR', 
		interpolation: {
			escapeValue: false,
		},
		backend: {
			jsonIndent: 4,
			addPath: "/locales/pt-BR.json",
			loadPath: "/locales/{{lng}}.json",
		},
		react: {
			wait: true,
			useSuspense: false,
		},
		detection: {
			order: ['myDetectorsName', 'localStorage', 'querystring', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],
			lookupLocalStorage: 'i18nextLng',
			// caches: ['localStorage'],
			checkWhitelist: true
		}
	});


export default i18next;