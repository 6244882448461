//@ts-ignore
import message from "@davistran86/notification";
import { withWidth } from "@material-ui/core";
// Utis/Tools
import axios from "axios";
import clipboard from "clipboard";
import { ContentState, convertFromHTML, convertFromRaw, convertToRaw, EditorState, Modifier, RawDraftContentState } from "draft-js";
import draftjsToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React, { createRef, ReactNode, useEffect, useState } from "react";
import { Editor } from 'react-draft-wysiwyg';
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import Lightbox from "react-image-lightbox";
import { useDispatch, useSelector } from "react-redux";
// Elements
import { RouteComponentProps, useHistory } from "react-router";
import Swal, { SweetAlertIcon } from "sweetalert2";

//@ts-ignore
import nonprofile from "../../assets/svg/non-profile.svg";
import { baseURL, commonHeaders } from "../../config/api-config";
// New
import { AtendimentoInterface } from "../../interfaces/new/atendimentoInterface";
import { CampoInterface } from "../../interfaces/new/campoInterface";
import { EnderecoInterface } from "../../interfaces/new/enderecoInterface";
import { ExameInterface } from "../../interfaces/new/exameInterface";
import { ImagemInterface } from "../../interfaces/new/imagemInterface";
import { LaudoFavoritoInterface } from "../../interfaces/new/laudoFavoritoInterface";
import { LaudoInterface } from "../../interfaces/new/laudoInterface";
import { LegendaInterface } from "../../interfaces/new/legendaInterface";
import { ModeloInterface } from "../../interfaces/new/modeloInterface";
import { PacienteInterface } from "../../interfaces/new/pacienteInterface";
import { ProfissionalSaudeInterface } from "../../interfaces/new/profissionalSaudeInterface";
import { RespostaFavoritaInterface } from "../../interfaces/new/respostaFavoritaInterface";
import { RespostaInterface } from "../../interfaces/new/respostaInterface";
import { SecaoInterface } from "../../interfaces/new/secaoInterface";
import { SMSInterface } from "../../interfaces/new/smsInterface";
import { ReportAnswerInterface } from "../../interfaces/reportAnswerInterface";
import { ReportCloudInterface } from "../../interfaces/reportCloudInterface";
// Interfaces
import { IHeaderInfoDataInterface } from "../../interfaces/templateHeaderInfoInterface";
import { ArquivoService } from "../../service/arquivo.service";
import { AtendimentoService } from "../../service/atendimento.service";
import { EmailService } from "../../service/email.service";
import { ExameService } from "../../service/exame.service";
import { ImageService } from "../../service/image.service";
import { LaudoService } from "../../service/laudo.service";
import { LaudoFavoritoService } from "../../service/laudoFavorito.service";
import { LegendaService } from "../../service/legenda.service";
//Services
import { ModeloService } from "../../service/modelo.service";
import { ProfissionalSaudeService } from "../../service/profissionalSaude.service";
import { RespostaPredefinidaService } from "../../service/respostasPredefinidas.service";
import { SMSService } from "../../service/sms.service";
import { ApplicationState } from "../../store";
import { setFavoriteReportModalOpen } from "../../store/actions/favoriteReport";
import { setHeaderTitle } from "../../store/actions/headerBar";
import { setImageOriginalUrl, setImageToEdit, setImageUrl, setUpdateImages } from "../../store/actions/imageEditor";
// States/redux
import { hideLoading, showLoading } from "../../store/actions/loading";
import { setReferringPhysiciansToList } from "../../store/actions/referringPhysician";
import { resetReportData, setSelectedImages } from "../../store/actions/reportData";
import { setStopListening } from "../../store/actions/stopListening";
import { setSelectedExam, setSelectedPatient, setSelectedPhysician, setSelectedReferringPhysician } from "../../store/actions/viewSelect";
import { Loading } from "../../store/reducers/loading";
import { ImageUtil } from "../../utils/image-utils";
import { LanguageUtils } from "../../utils/language-utils";
import { joinNames } from "../../utils/string-utils";
import { centimetersToPoints } from "../../utils/unitConverter-utils";
import { SpeechRecognitionButton } from "../components/speech-recognition-button-component";
import PaperHeaderInfo from "../components/template-paper-header-info-component";
import TemplateSection from "../components/template-section-component";
import ModalLaudosFavoritos from "../elements/favorite-reports-modal-element";
import { ImageEditor } from "../elements/image-editor-element";
import ModalLegenda from "../elements/legend-modal";
import PageContainer from "../elements/page-container-element";
import PdfModalView from "../elements/pdf-modal-view";
import ModalRespostasPredefinidas from "../elements/predefinition-answers-modal-element";
import { ReportViewSelectElements } from "../elements/report-view-select-elements-element";
import ModalSendEmail from "../elements/send-email-modal-element";
import Table from "../elements/table-element";
import { DateUtil } from "../utils/dateUtils";
import { pdfmake_fonts } from '../utils/vfs_fonts';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// Others (images, styles, etc.)
import "react-image-lightbox/style.css";

interface ImageReportInterface {
    id: any,
    url: string,
    legend?: LegendaInterface,
}

interface CampoLaudoInterface {
    idCampo: number,
    valor: EditorState | boolean
}

interface CheckboxGroup {
    order: number;
    items: any[]; 
}

type RespostaType = {
    texto: string,
    html: string,
    idCampo: number
}

const htmlToPdfmake = require("html-to-pdfmake");
const PDF_WIDTH_SIZE = 595.28;
const PDF_HEIGHT_SIZE = 841.89;

pdfMake.vfs = pdfFonts.pdfMake.vfs = pdfmake_fonts;
pdfMake.fonts = {
    Fontello: {
        normal: "fontello.ttf",
        bold: "fontello.ttf",
        italics: "fontello.ttf",
        bolditalics: "fontello.ttf",
    },
    fontello: {
        normal: "fontello.ttf",
        bold: "fontello.ttf",
        italics: "fontello.ttf",
        bolditalics: "fontello.ttf",
    },
    roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf"
    },
    Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf"
    },
    montserrat: {
        normal: "Montserrat-Regular.ttf",
        bold: "Montserrat-Bold.ttf",
        italics: "Montserrat-Italic.ttf",
        bolditalics: "Montserrat-MediumItalic.ttf"
    },
    "times new roman": {
        normal: "Times-New-Roman-Regular.ttf",
        bold: "Times-New-Roman-Bold.ttf",
        italics: "Times-New-Roman-Italic.ttf",
        bolditalics: "Times-New-Roman-BoldItalic.ttf",
    },
    arial: {
        normal: "Arial-Regular.ttf",
        bold: "Arial-Bold.ttf",
        italics: "Arial-Italic.ttf",
        bolditalics: "Arial-BoldItalic.ttf",
    },
    georgia: {
        normal: "Georgia-Regular.ttf",
        bold: "Georgia-Bold.ttf",
        italics: "Georgia-Italic.ttf",
        bolditalics: "Georgia-BoldItalic.ttf",
    },
    tahoma: {
        normal: "Tahoma-Regular.ttf",
        bold: "Tahoma-Bold.ttf",
        italics: "Tahoma-Italic.ttf",
        bolditalics: "Tahoma-BoldItalic.ttf",
    },
    verdana: {
        normal: "Verdana-Regular.ttf",
        bold: "Verdana-Bold.ttf",
        italics: "Verdana-Italic.ttf",
        bolditalics: "Verdana-BoldItalic.ttf",

    },
}

new clipboard("#buttonClipboardId");


const Report = (props: RouteComponentProps) => {
    const atendimento = (props.history.location.state as any).atendimento as AtendimentoInterface;
    const laudo = (props.history.location.state as any).laudo as LaudoInterface;

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const imageUtil = new ImageUtil();
    const history = useHistory();

    const clinicaSelecionada = useSelector((states: ApplicationState) => states.app.clinicaSelecionada);
    const selectedExam = useSelector((states: ApplicationState) => states.viewSelect.selectedExam);
    const selectedPatient = useSelector((states: ApplicationState) => states.viewSelect.selectedPatient);
    const selectedPhysician = useSelector((states: ApplicationState) => states.viewSelect.selectedPhysician);
    const medicoSolicitanteSelecionado = useSelector((states: ApplicationState) => states.viewSelect.selectedReferringPhysician);

    const { updateImages } = useSelector((states: ApplicationState) => states.imageEditor);

    const [exame, setExame] = useState<ExameInterface>();
    const [paciente, setPaciente] = useState<PacienteInterface>();
    const [endereco, setEndereco] = useState<EnderecoInterface>();
    const [medico, setMedico] = useState<ProfissionalSaudeInterface>();
    const [medicoSolicitante, setMedicoSolicitante] = useState<ProfissionalSaudeInterface>();

    const [modelo, setModelo] = useState<ModeloInterface>();
    const [exames, setExames] = useState<Array<ExameInterface>>([]);
    const [profissionaisSaude, setProfissionaisSaude] = useState<Array<ProfissionalSaudeInterface>>([]);
    const [veiwTextImagemSelecionada, setVeiwTextImagemSelecionada] = useState<Boolean>(false);
    const [idImagemSeleciona, setIdImagemSelecionada] = useState<number>(0);
    const [adicionaLegendaMiniatura, setAdicionaLegendaMiniatura] = useState<Boolean>(false);
    const [visualizarLegenda, setVisualizarLegenda] = useState<Boolean>(true);
    const [removerLegenda, setRemoverLegenda] = useState<Boolean>(false);

    const report: any = laudo;

    const dateUtil = new DateUtil();

    const [pdfURL, setPdfURL] = useState<string>(null as any);
    const [imagensLaudo, setImagensLaudo] = useState<Array<ImageReportInterface>>([]);

    const [imagensSelecionadas, setImagensSelecionadas] = useState([] as Array<any>);
    const [stateClickNaImage, setStateClickNaImage] = useState<Boolean>(false);
    const [imagesSelecionadasParaExclusao, setImageSelecionadasParaExclusao] = useState<Array<Object>>([])
    const [imagensSelecionadasOnClick, setImagensSelecionadasOnClick] = useState<any>();
    const [legendasSelecionadas, setLegendasSelecionadas] = useState([] as Array<any>);
    const [imageTarget, setImageTarget] = useState(null as any);
    const [campoSelecionado, setCampoSelecionado] = useState<CampoInterface>();
    const [predefinitionAnswers, setPredefinitionAnswers] = useState({} as any);
    const [respostaPreDefinidasMaisUtilizadas, setRespostaPreDefinidasMaisUtilizadas] = useState({} as any);
    const [legendas, setLegendas] = useState<Array<LegendaInterface>>([]);
    const [valorCampos, setValorCampos] = useState([] as Array<CampoLaudoInterface>);
    const [fieldRefs, setFieldRefs] = useState([] as Array<string>);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [modalImages, setModalImages] = useState([] as Array<any>);
    const [pdfBlob, setPdfBlob] = useState(null);
    const [reportSaved, setReportSaved] = useState(false);
    const [reportSavedData, setReportSavedData] = useState(null as any);
    const [imagensPaciente, setImagensPaciente] = useState<Array<ImagemInterface>>([]);
    const [imageDates, setImageDates] = useState<Array<string>>([]);
    const [imagesDatesFilter, setImagesDatesFilter] = useState<Array<string>>([])
    const [isContatoPaciente, setContatoPaciente] = useState(false);
    const [isModalEmailVisible, setModalEmailVisible] = useState(false);

    const [showToolbar, setShowToolbar] = useState({ index: -1, show: false });

    //pagination states
    const [enablePreviousButton, setEnablePreviousButton] = useState(false);
    const [btnSalvarHabilitado, setBtnSalvarHabilitado] = useState(true);
    const [enableForwardButton, setEnableForwardButton] = useState(false);
    const [previousFirstValues, setPreviousFirstValues] = useState([] as Array<any>);
    const [pageIndex, setPageIndex] = useState(0);
    const [firstVisible, setFirstVisible] = useState(null as any);
    const [lastVisible, setLastVisible] = useState(null as any);
    const [rowsOnTable, setRowsOnTable] = useState(0);
    const [offsetTotal, setOffsetTotal] = useState(0);
    const [tituloLaudoFavorito, setTituloLaudoFavorito] = useState("");
    const [localStorageAnswers, setLocalStorageAnswers] = useState<Array<any>>([]);
    const [indexSelecionado, setIndexSelecionado] = useState<{ indexCampo: number, idCampo: number }>({ indexCampo: 0, idCampo: 0 });

    const limitOfTable = 10;
    const incrementOffset = 10;

    let marginTop = !!modelo ? centimetersToPoints(modelo.margemSuperior) : 0;
    let marginLeft = !!modelo ? centimetersToPoints(modelo.margemEsquerda) : 0;
    let marginRight = !!modelo ? centimetersToPoints(modelo.margemDireita) : 0;
    let marginBottom = !!modelo ? centimetersToPoints(modelo.margemInferior) : 0;

    let headerHeight = !!modelo ? centimetersToPoints(modelo.cabecalho?.altura) : 0;
    let footerHeight = !!modelo ? centimetersToPoints(modelo.rodape?.altura) : 0;

    const botaoPacienteViewSelectRef: any = createRef();
    const botaoMedicoViewSelectRef: any = createRef();
    const botaoMedicoSolicitanteViewSelectRef: any = createRef();
    const botaoExameViewSelectRef: any = createRef();

    const [isAltPressed, setIsAltPressed] = useState(false);
    const [deParaDragAndDrop, setDeDragAndDrop] = useState<number | undefined>()

    useEffect(() => {
        setPaciente(atendimento.paciente);
        setExame(atendimento.exame);
        setMedico(atendimento.medico);
        setEndereco(atendimento.paciente?.endereco[0])

        if (atendimento.paciente?.contato) {
            setContatoPaciente(true);
        }

        getExames();
        getProfissionaisSaude();
    }, []);

    useEffect(() => {
        if (exame) {
            getModelo(exame.idModelo);
        }
    }, [exame]);

    useEffect(() => {
        if (modelo && modelo.campos) {
            criarValoresCampos();
            const imagemContainer: HTMLImageElement = document.querySelector("#dragAndDropImageDraggable") as HTMLImageElement;

            if (!!imagemContainer) {
                imagemContainer.style.width = 'auto';
                imagemContainer.style.height = 'auto';
                imagemContainer.style.maxWidth = '100%';
                imagemContainer.style.maxHeight = '100%';
            }
        }
    }, [modelo]);

    useEffect(() => {
        if (paciente) {
            getPatientImages(paciente.idPaciente);

            if (!!botaoPacienteViewSelectRef) {
                botaoPacienteViewSelectRef.current.text = `${paciente.primeiroNome} ${paciente.ultimoNome}`;
            }
        }
    }, [paciente]);

    const getModelo = async (idModelo: number) => {
        const modelo = await new ModeloService().buscaPorId(idModelo);

        setModelo(modelo);
    }

    const getPatientImages = async (idPaciente: number) => {
        const imagens = await new ImageService().buscaPorPaciente(idPaciente);

        setImagensPaciente(imagens);
    }

    const getExames = async () => {
        const exames = await new ExameService().lista();

        setExames(exames);
    }

    const getProfissionaisSaude = async () => {
        const profissionaisSaude = await new ProfissionalSaudeService().lista();

        setProfissionaisSaude(profissionaisSaude);
    }

    const getNomeDispositivo = () => {
        const [imagemPaciente] = imagensPaciente;
        const fabricanteDispositivo = imagemPaciente?.fabricante;
        const modeloDispositivo = imagemPaciente?.modelo;
        return (imagensPaciente && imagensPaciente.length && !!fabricanteDispositivo && !!modeloDispositivo) ?
            `${fabricanteDispositivo} ${modeloDispositivo}` : ""
    }

    useEffect(() => {
        if (!!atendimento) {
            if (!!exame && !!modelo) {
                criarValoresCampos();
            }
        } else {
            hideLoading(dispatch);

            Swal.fire({
                title: t("Erro ao abrir laudo"),
                text: t("Appointment não encontrado"),
                icon: "error",
                confirmButtonText: t("Voltar a tela inicial")
            })
                .then(() => {
                    resetReduxStates();
                    props.history.push("/app");
                })
        }
    }, []);

    useEffect(() => {
        if (imagensPaciente.length) {
            getImageTabDates();
            getImagesUrl(imagensPaciente);
        }
    }, [imagensPaciente]);

    useEffect(() => {
        if (updateImages) {
            setUpdateImages(false, dispatch);
            handleUpdateImages();
        }
    }, [updateImages])


    // TODO laudo sem atendimento
    // useEffect(() => {
    //     if (!exam || !patient) {
    //         initViewSelectOptions();

    //     } else {
    //         if (!!patient && !!patient.images) {
    //             setPatientImages(patient.images);
    //         }

    //         if (!!template) {
    //             createInitialFieldsValues();
    //             const imageContainer: HTMLImageElement = document.querySelector("#dragAndDropImageDraggable") as HTMLImageElement;

    //             if (!!imageContainer) {
    //                 imageContainer.style.width = 'auto';
    //                 imageContainer.style.height = 'auto';
    //                 imageContainer.style.maxWidth = '100%';
    //                 imageContainer.style.maxHeight = '100%';
    //             }
    //         }
    //     }

    // }, [exam, patient]);


    useEffect(() => {
        if (!!botaoPacienteViewSelectRef && !!paciente) {
            botaoPacienteViewSelectRef.current.text = `${paciente.primeiroNome} ${paciente.ultimoNome}`;
        }
    }, [paciente]);


    useEffect(() => {
        if (!!botaoMedicoViewSelectRef && !!medico) {
            botaoMedicoViewSelectRef.current.text = `${medico.primeiroNome} ${medico.ultimoNome}`;
            showLoading(dispatch);
        }
    }, [medico]);

    useEffect(() => {
        if (!!medicoSolicitanteSelecionado) {
            setMedicoSolicitante(medicoSolicitanteSelecionado);
        }

    }, [medicoSolicitanteSelecionado]);


    useEffect(() => {
        if (!!botaoMedicoSolicitanteViewSelectRef && !!medicoSolicitante) {
            botaoMedicoSolicitanteViewSelectRef.current.text = `${medicoSolicitante.primeiroNome} ${medicoSolicitante.ultimoNome}`;
        }

    }, [medicoSolicitante]);

    useEffect(() => {
        if (imagensLaudo.length) {
            if (laudo && laudo.imagens && laudo.imagens.length) {
                loadImagesToReportRetification();
            }
        }
    }, [imagensLaudo]);

    useEffect(() => {
        //pega ultimo estado do drag and drop images, nao precisa de complementos
    }, [deParaDragAndDrop])

    const initViewSelectOptions = () => {
        if (!!atendimento && !exame && !!botaoExameViewSelectRef) {
            botaoExameViewSelectRef.current.click();

        } else {
            if (!!atendimento && !paciente && !!botaoPacienteViewSelectRef) {
                botaoPacienteViewSelectRef.current.click();
            }
        }
    }

    const criarValoresCampos = () => {
        if (!!laudo && !!laudo.respostas && !!laudo.respostas.length) {
            criarValoresCamposDasRespostas(laudo.respostas);
        } else {
            let answersStorage = localStorage.getItem(`answer_${paciente?.idPaciente}_${exame?.idExame}`);

            if (!!answersStorage && answersStorage.length > 2) {
                criarValoresCamposDoLocalStorage(JSON.parse(answersStorage));
            } else {
                if (!valorCampos.length) {
                    criarValoresDosCampos();
                }
            }
        }
    }

    const examClickBackButtonHandler = () => {
        setStopListening(true, dispatch);
        resetReduxStates();
        return history.push("/app");
    }

    const patientClickBackButtonHandler = () => {
        setStopListening(true, dispatch);
        if (!!botaoExameViewSelectRef) {
            botaoExameViewSelectRef.current.click();
        }
    }


    const criarValoresCamposDasRespostas = (answers: Array<ReportAnswerInterface>) => {
        let answersEditors: Array<any> = [];
        let localStorageAnswers: Array<any> = []

        if (answers.length === modelo?.campos.length) {
            answers.forEach((answer) => {
                let content = null;

                if (typeof answer.htmlDescricao == "string") {
                    content = createEditorStateFromHTMLValue(answer.htmlDescricao);
                    answersEditors.push({
                        idCampo: answer.idCampo,
                        valor: createEditorStateFromHTMLValue(answer.htmlDescricao)
                    })
                } else {
                    content = answer.htmlDescricao;
                    answersEditors.push({
                        idCampo: answer.idCampo,
                        valor: answer.htmlDescricao
                    })
                }

                localStorageAnswers.push({
                    id: answer.idCampo,
                    content: content,
                });
            });

            setValorCampos(answersEditors);
        } else {
            criarValoresDosCampos(answers);
        }
    }

    const criarValoresDosCampos = (answers: Array<any> = []) => {
        let answersEditors: Array<CampoLaudoInterface> = [];
        let answersToLocalStorageAux: Array<any> = [];

        modelo?.campos.sort((campo1, campo2) => campo1.ordem - campo2.ordem);

        (modelo as ModeloInterface)?.campos.forEach((campo) => {
            const index = answers.findIndex((answer) => answer.idCampo == campo.idCampo);

            if ((campo.tipo != "Check Box") && (campo.tipo != "Label")) {
                if (index > -1) {
                    const editor = createEditorStateFromHTMLValue(answers[index].htmlDescricao);
                    answersEditors.push({
                        idCampo: answers[index].idCampo,
                        valor: editor
                    });
                    setFieldToLocalStorage(editor, campo.idCampo);

                    answersToLocalStorageAux.push({
                        id: campo.idCampo,
                        content: convertToRaw(editor.getCurrentContent())
                    });
                } else {
                    answersEditors.push({
                        idCampo: campo.idCampo,
                        valor: EditorState.createEmpty()
                    });
                }
            } else if (campo.tipo == "Check Box") {
                if (index > -1) {
                    answersEditors.push(answers[index]);
                    setFieldToLocalStorage(answers[index].answerHtml as any, campo.idCampo);

                    answersToLocalStorageAux.push({
                        id: campo.idCampo,
                        content: answers[index].answerHtml as any
                    });
                } else {
                    answersEditors.push({
                        idCampo: campo.idCampo,
                        valor: false
                    });
                }
            }
        });

        localStorage.setItem(`answer_${paciente?.idPaciente}_${exame?.idExame}`, JSON.stringify(answersToLocalStorageAux));

        setValorCampos(answersEditors);
        hideLoading(dispatch);
    }

    const criarValoresCamposDoLocalStorage = (localStorageAnswers: Array<any>) => {
        let answersEditors: Array<any> = [];

        setLocalStorageAnswers(localStorageAnswers);

        modelo?.campos.forEach((campo) => {
            const index = localStorageAnswers.findIndex((answer) => answer.id == campo.idCampo);

            if ((campo.tipo != "Check Box") && (campo.tipo != "Label")) {
                if (index > -1) {
                    const editor = createEditorStateFromContent(localStorageAnswers[index].content);
                    answersEditors.push({
                        idCampo: campo.idCampo,
                        valor: editor
                    });
                } else {
                    answersEditors.push({
                        idCampo: campo.idCampo,
                        valor: EditorState.createEmpty()
                    });
                }
            } else if (campo.tipo == "Check Box") {
                if (index > -1) {
                    answersEditors.push({
                        idCampo: campo.idCampo,
                        valor: localStorageAnswers[index].content
                    });
                } else {
                    answersEditors.push({
                        idCampo: campo.idCampo,
                        valor: false
                    });
                }
            }
        });

        setValorCampos(answersEditors);
        hideLoading(dispatch);
    }

    const createEditorStateFromHTMLValue = (HTMLText: string): EditorState => {
        const answerHTMLContent = htmlToDraft(HTMLText);

        const editorState = EditorState.createWithContent(ContentState.createFromBlockArray(answerHTMLContent.contentBlocks, answerHTMLContent.entityMap));

        return editorState;
    }

    const createEditorStateFromContent = (content: RawDraftContentState): EditorState => {
        const editor = EditorState.createEmpty()
        const editorState = EditorState.push(editor, convertFromRaw(content), "insert-fragment");

        return editorState;
    }


    // options: SweetAlertOptions
    const showSweetAlert = (title: string, text: string, icon: SweetAlertIcon, confirmButtonText?: string) => {
        return Swal.fire({
            title: title,
            text: text,
            icon: icon,
            confirmButtonText: confirmButtonText
        })
    }

    const getImagesUrl = (images: Array<ImagemInterface>) => {
        showLoading(dispatch, `${t("Aguarde um momento enquanto as imagens são carregadas")}...`);

        return new Promise(async (resolve: any) => {
            let imagesReport: Array<any> = [];
            for (let i = 0; i < images.length; i++) {
                imagesReport.push({
                    idEvo: images[i].idExterno,
                    id: images[i].idImagem,
                    url: images[i].caminho,
                    legend: images[i].legenda
                });
            }
            resolve(imagesReport);
        })
            .then((images: any) => {
                hideLoading(dispatch);

                setImagensLaudo(images);
            })
            .catch((err: any) => {
                hideLoading(dispatch);
                Swal.fire({
                    title: t("Erro ao carregar imagens do paciente"),
                    text: err,
                    icon: "error",
                    confirmButtonText: "Ok"
                })
            })
    }

    const handleSelectedImage = (imageSelected: any, event: React.MouseEvent) => {
        let exists = imagensSelecionadas.find((image: any) => {
            return image.id === imageSelected.id;
        });

        if (!exists) {
            addSelectedImage(imageSelected, event);
            textoImagensSelecionadas(imageSelected.id);
        } else {
            removeSelectedImage(imageSelected, event);
            textoImagensSelecionadas(imageSelected.id);
        }
    }

    const addSelectedImage = async (imageSelected: any, event: React.MouseEvent) => {
        let divImageSelected = (event.currentTarget as HTMLElement).lastElementChild as HTMLElement;

        if (!!divImageSelected) {
            let image = {
                idEvo: imageSelected.id,
                id: imageSelected.id,
                url: imageSelected.url,
                legend: imageSelected.legend
            }

            if (imageSelected.legend) {
                setLegendaParaImagem(imageSelected.legend, image);
            }

            divImageSelected.classList.remove("d-none");
            setImagensSelecionadas([...imagensSelecionadas, image]);
        }
    }

    const textoImagensSelecionadas = (imageSelected: number) => {
        setIdImagemSelecionada(imageSelected)
        setVeiwTextImagemSelecionada(true)
        setTimeout(() => {
            setVeiwTextImagemSelecionada(false)
        }, 3000)
    }


    const toggleDivSelectedImage = (selectedImage: { id: number }, selected: boolean, callback?: Function) => {
        let divImageSelected: HTMLElement = null as any;
        let divImageMain = document.querySelector(`#card_image_${selectedImage.id}`) as HTMLElement;

        if (!!divImageMain) {
            divImageSelected = divImageMain.lastChild as HTMLElement;

            if (!!divImageSelected) {
                if (selected) {
                    divImageSelected.classList.remove("d-none");

                } else {
                    divImageSelected.classList.add("d-none");
                }

                if (!!callback) {
                    callback();
                }
            }
        }
    }


    const unselectAllImages = () => {
        const spansImagesSelecteds: Array<HTMLSpanElement> = document.querySelectorAll(`span[class*="imgSelectedIcon"]`) as any;

        if (spansImagesSelecteds.length) {
            spansImagesSelecteds.forEach((spanImageSelected: HTMLSpanElement) => {
                spanImageSelected.classList.add("d-none");
            });
        }
    }

    const loadImagesToReportRetification = () => {
        unselectAllImages();

        if (!!laudo && !!laudo.imagens && laudo.imagens.length) {
            let imagesToSelect: Array<ImageReportInterface & { idEvo?: number }> = [];

            for (let index = 0; index < laudo.imagens.length; index++) {
                let imageToSelect = imagensLaudo.find((image: any) => {
                    return image.id === laudo.imagens[index].idImagem;
                });

                if (!!imageToSelect) {
                    toggleDivSelectedImage(imageToSelect, true, () => {

                        imagesToSelect.push({
                            idEvo: laudo.imagens[index].idExterno,
                            id: laudo.imagens[index].idImagem,
                            url: imageToSelect?.url as string,
                            legend: imageToSelect?.legend
                        });
                    })
                }
            }

            setImagensSelecionadas(imagesToSelect);
        }
    }


    const removeSelectedImage = (imageSelected: any, event: React.MouseEvent) => {
        let divImageSelected = (event.currentTarget as HTMLElement).lastElementChild as HTMLElement;

        divImageSelected.classList.add("d-none");
        setImagensSelecionadas(imagensSelecionadas.filter((item: any) => item.id !== imageSelected.id));
    }

    const removeImage = (index: number, id: number) => {
        let arrayImages = [...imagensSelecionadas];
        arrayImages.splice(index, 1);

        setImagensSelecionadas(arrayImages);

        let cardImage = document.querySelector(`#card_image_${id}`) as HTMLDivElement;
        let divImageSelected = cardImage.lastElementChild as HTMLDivElement;
        divImageSelected.classList.add("d-none");
    }

    const setValorCampoCheckbox = (value: boolean, fieldId: number) => {
        let fields = [...valorCampos];

        const field = fields.find((f) => {
            return f.idCampo === fieldId
        });

        if (field) {
            field.idCampo = fieldId;
            field.valor = value;

            setFieldToLocalStorage(value, fieldId);
            setValorCampos(fields);
        }
    }

    const onEditorStateChange = (editorState: EditorState, index: number, fieldId: number) => {
        let editorsStatesAnswers = [...valorCampos];

        const editorAnswer = editorsStatesAnswers.find((editor) => editor.idCampo === fieldId);

        if (editorAnswer) {
            editorAnswer.idCampo = fieldId;
            editorAnswer.valor = editorState;

            setFieldToLocalStorage(editorState, fieldId);
            setValorCampos(editorsStatesAnswers);
        }
    }

    const setFieldToLocalStorage = (field: EditorState | boolean, id: number) => {
        let content = null;

        if (typeof field === "boolean") {
            content = field;
        } else {
            content = convertToRaw((field as EditorState).getCurrentContent());
        }

        const answersLocalStorage = localStorage.getItem(`answer_${paciente?.idPaciente}_${exame?.idExame}`);
        const answers = answersLocalStorage ? JSON.parse(answersLocalStorage) : [];

        if (answers.length) {
            const index = answers.findIndex((localStorageAnswer: any) => localStorageAnswer.id === id);

            if (index > -1) {
                answers[index] = ({
                    id: id,
                    content: content,
                });
            } else {
                answers.push({
                    id: id,
                    content: content,
                });
            }
        } else {
            answers.push({
                id: id,
                content: content,
            })
        }

        setLocalStorageAnswers(answers);
        localStorage.setItem(`answer_${paciente?.idPaciente}_${exame?.idExame}`, JSON.stringify(answers));
    }

    const setRefField = (index: number, value: any) => {
        let fields = [...fieldRefs];
        fields[index] = value;

        setFieldRefs(fields);
    }

    const getRespostasPredefinidas = async (indexCampo: number, idCampo: number) => {
        limparCheckeds();
        const respostasPredefinidas = await new RespostaPredefinidaService().lista(idCampo);
        const maisUtilizados = await new RespostaPredefinidaService().maisUtilizados(idCampo);
        setRespostaPreDefinidasMaisUtilizadas({ indexCampo: indexCampo, values: maisUtilizados });
        setPredefinitionAnswers({ indexCampo: indexCampo, values: respostasPredefinidas });
        setIndexSelecionado({ indexCampo, idCampo });
        hideLoading(dispatch);
    }

    const limparCheckeds = () => {
        const checkboxs: any = Array.from(document.querySelectorAll("input[type=checkbox].custom-control-input.checkbox-predefinition-answer"));
        if (checkboxs.length > 0) {
            for (const item of checkboxs) {
                if (item.checked) {
                    item.checked = false;
                }
            }
        }
    }

    const atualizaRespostasPredefinidas = () => {
        const { indexCampo, idCampo } = indexSelecionado;
        getRespostasPredefinidas(indexCampo, idCampo);
    }

    const getLegendas = async () => {
        const legendas = await new LegendaService().lista({ idExame: exame?.idExame });

        setLegendas(legendas);

        hideLoading(dispatch);
    }

    const visualizarLegendaImagem = (e: any) => {
        if(e.target.checked){
            setVisualizarLegenda(false)
        }else {
            setVisualizarLegenda(true)
        }
    }

    const onClickEditImage = (image: any) => {
        const img = !!imagensPaciente && imagensPaciente.find((img) => img.idImagem === image.id);
        setImageOriginalUrl(image.url, dispatch);
        setImageUrl(image.url, dispatch);
        setImageToEdit(img, dispatch);
    }

    const setAnswerTextRecognizedBySpeech = (text: string, index: number) => {
        const editorsStatesAnswers = [...valorCampos];
        editorsStatesAnswers[index].valor = createEditorStateFromHTMLValue(text);

        setValorCampos(editorsStatesAnswers);
    }

    const createFieldsOnScreen = (key: string) => {
        modelo?.campos.sort((campo1, campo2) => campo1.ordem - campo2.ordem);

        return <div key={key} className="mb-3">
            {!!valorCampos && modelo?.campos.map((campo, index: number) => {
                const valorCampo = valorCampos.find((valCampo) => valCampo.idCampo === campo.idCampo);
                if(campo.tipo === "Check Box") {
                    return(
                        <div className="custom-checkbox custom-control-inline checkbox-element pull-left">
                            <input type="checkbox" checked={valorCampo?.valor ? valorCampo?.valor as boolean : false} value={""} onChange={(event) => setValorCampoCheckbox(event.target.checked, campo.idCampo)} /> 
                            <label className="m-1" >{campo.descricao}</label> 
                        </div>
                    )
                }else if(campo.tipo === "Line"){
                    return (
                        <div className="row line-element w-100 mt-1 mb-1">
                            <div className="col m-0">
                                <div className="border-bottom border-dark" style={{height: "1px"}}></div>
                            </div>
                        </div>
                    )
                }
                return (
                    <div className="row w-100" key={index}>
                        <div className="col-12 pt-1 input-group" key={index}>
                            <label className="w-100 m-0" style={{ fontFamily: "Arial", fontSize: "14px", textAlign: "left", fontWeight: 600 }} >{campo.descricao}</label>
                            {
                                campo.tipo !== "Label" ?
                                        <div className="input-group">
                                            <div className="col p-0 border rounded form-control" style={{ minHeight: "1.5cm", height: "auto", textAlign: "left" }} onClick={(event) => {
                                                let div: HTMLDivElement = event.currentTarget as HTMLDivElement;
                                                div && div.firstChild && div.firstChild.firstChild && (div.firstChild.firstChild.firstChild as HTMLElement).focus()
                                            }}>
                                                {
                                                    valorCampos && valorCampos.length &&
                                                    <Editor
                                                        // @ts-ignore
                                                        editorState={valorCampo?.valor ? valorCampo.valor as EditorState : null}
                                                        toolbarHidden={!(showToolbar.index == index && showToolbar.show)}
                                                        onFocus={() => setShowToolbar({ index: index, show: true })}
                                                        onBlur={() => setShowToolbar({ index: index, show: false })}
                                                        onEditorStateChange={(editorState: EditorState) => onEditorStateChange(editorState, index, campo.idCampo)}
                                                        toolbar={{
                                                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'textAlign', 'colorPicker', 'history'],
                                                            inline: {
                                                                options: ['bold', 'italic', 'underline', 'strikethrough'],
                                                                inDropdown: false,
                                                                className: undefined,
                                                                component: undefined,
                                                                dropdownClassName: undefined,
                                                            },
                                                            fontFamily: {
                                                                options: ['Arial', 'Georgia', 'Montserrat', 'Roboto', 'Times New Roman', 'Tahoma', 'Verdana'],
                                                                className: undefined,
                                                                component: undefined,
                                                                dropdownClassName: undefined,
                                                            },
                                                            blockType: {
                                                                inDropdown: true,
                                                                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                                                                className: undefined,
                                                                component: undefined,
                                                                dropdownClassName: undefined,
                                                            },
                                                        }}
                                                    />
                                                }
                                            </div>

                                            <div className="input-group-append d-inline d-print-none">
                                                <button className="btn btn-sm btn-primary rounded rounded-pill ml-2" style={{ height: 27, width: 27 }} type="button" data-toggle="modal" data-target="#predefinitionAnswersModal" data-backdrop="static" data-keyboard="false"
                                                    onClick={() => {
                                                        // showLoading(dispatch);
                                                        // resetPaginationPredefAnswers();
                                                        // getPredefinitionAnswers(index, campo.idCampo);
                                                        setCampoSelecionado(campo);
                                                        getRespostasPredefinidas(index, campo.idCampo);
                                                    }}
                                                >
                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                </button>
                                            </div>

                                            <SpeechRecognitionButton setReconizedText={(textTranscript: string) => setAnswerTextRecognizedBySpeech(textTranscript, index)} classButton={"btn-sm"} classDivParentButton={"d-inline d-print-none"} />
                                        </div> : null
                            }
                        </div>

                    </div>
                )
            })}
        </div>
    }

    const dragEnter = (event: any) => {
        event.currentTarget.classList.add('drop');
    }

    const dragLeave = (event: any, index: number) => {
        event.currentTarget.classList.remove('drop');
    }

    const drag = (event: any, index: number) => {
        event.currentTarget.classList.remove('drop');
        setDeDragAndDrop(index);
    }

    const drop = (event: any, index: number) => {

        const id = index;
        const newArray = [...imagensSelecionadas];

        function swapArrayElements(arr: object[], index1: number, index2: number) {
            [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
        }

        if (deParaDragAndDrop !== undefined) {
            swapArrayElements(newArray, deParaDragAndDrop, id);
            setImagensSelecionadas([...newArray]);
        }

    }

    const allowDrop = (event: any) => {
        event.preventDefault();
    }

    const createDivColImageExample = (image: any, index: number, col: string = "6", height: string = "210px") => {
        let marginAndPadding = "p-1 m-1";

        if (parseInt(col) == 6 || 3) {
            marginAndPadding = "p-0";
        }

        const showImageButtons = () => {
            let divButtons = document.querySelector(`#divImageButtons_${index}`) as HTMLDivElement;
            let btnView = document.querySelector(`#btn_view_${index}`) as HTMLElement;
            let btnLegend = document.querySelector(`#btn_legend_${index}`) as HTMLElement;
            let btnEdit = document.querySelector(`#btn_edit_${index}`) as HTMLElement;
            let btnRemove = document.querySelector(`#btn_remove_${index}`) as HTMLElement;

            divButtons.style.backgroundColor = "rgba(255,255,255,0.2)";
            btnView.classList.remove("d-none");
            btnLegend.classList.remove("d-none");
            btnEdit.classList.remove("d-none");
            btnRemove.classList.remove("d-none");
        }

        const hideImageButtons = () => {
            let divButtons = document.querySelector(`#divImageButtons_${index}`) as HTMLDivElement;
            let btnView = document.querySelector(`#btn_view_${index}`) as HTMLElement;
            let btnLegend = document.querySelector(`#btn_legend_${index}`) as HTMLElement;
            let btnEdit = document.querySelector(`#btn_edit_${index}`) as HTMLElement;
            let btnRemove = document.querySelector(`#btn_remove_${index}`) as HTMLElement;

            divButtons.style.backgroundColor = "rgba(255,255,255,0)";
            btnView.classList.add("d-none");
            btnLegend.classList.add("d-none");
            btnEdit.classList.add("d-none");
            btnRemove.classList.add("d-none");
        }

        const figCaptionLegend = (legend: string) => (
            <figcaption style={{ textAlign: "center" }}>{legend}</figcaption>
        )

        return (
            <>
                <div key={index} className={`${marginAndPadding} col-${col} imageCol border position-relative`}
                    style={{ marginBottom: 25 }}
                    onMouseOver={showImageButtons}
                    onMouseOut={hideImageButtons}
                    onDragEnter={dragEnter}
                    onDragLeave={(event: any) => {dragLeave(event, index)}}
                    onDragOver={allowDrop}
                >
                    <img className={`divImage-${col}`} src={image.url} id={`image_report_${image.id}`} draggable={true} tabIndex={0} onDrop={(event) => drop(event, index)} onDragStart={(event) => drag(event, index)} data-target="#imageView"/>
                    <div className="text-center p-1 position-absolute w-100 rounded-bottom" id={`divImageButtons_${index}`} style={{ backgroundColor: "rgba(255, 255, 255, 0.05)", bottom: "0" }}>

                        <button onClick={() => imageView(imagensSelecionadas, index)} className="btn btn-outline-light border-0 d-none" title={t("Visualizar")} id={`btn_view_${index}`}><i className="bi bi-eye" aria-hidden="true"></i></button>
                        <button className="btn btn-outline-light border-0 d-none" title={t("Adicionar legenda")} id={`btn_legend_${index}`} data-toggle="modal" data-target="#legendModal"
                            onClick={() => {
                                setImageTarget(imagensSelecionadas[index]);
                                getLegendas();
                            }}
                        >
                            <i className="bi bi-at" aria-hidden="true"></i>
                        </button>

                        <button className="btn btn-sm btn-outline-light border-0 d-none" title={t("Editar")} id={`btn_edit_${index}`} data-toggle="modal" data-target="#imageEditorModal" data-backdrop="static" data-keyboard="false" onClick={() => /*onClickEditImage(image)*/ { }} >
                            <i className="fas fa-pencil-alt" aria-hidden="true"></i>
                        </button>

                        <button className="btn btn-outline-light border-0 d-none" title={t("Remover")} id={`btn_remove_${index}`}
                            onClick={() => {
                                removeLegend(index);
                                removeImage(index, image.id)
                            }}
                        >
                            <i className="fas fa-times" aria-hidden="true"></i></button>
                    </div>
                    {/* </div> */}
                    <div className="text-center p-1 position-absolute w-100 rounded-bottom">
                        {
                            !image.legend ? null :
                                figCaptionLegend(image.legend.descricao)
                        }
                    </div>
                </div>
            </>
        );
    }

    const createImagesOnScreen = (key: string, col?: string, height?: string) => {
        return <div key={key} className="row col-12">{imagensSelecionadas.map((image, index) => createDivColImageExample(image, index, col, height))}</div>
    }

    const traduzirGenero = (genero: string) => {
        if (genero === 'Male') return t('Masculino');
        if (genero === 'Female') return t('Feminino');
        if (genero === 'Other') return t('Outros')
        return genero;
    }

    const loadPaperContent = () => {
        let content: Array<ReactNode> = [];
        const headerInfoData: IHeaderInfoDataInterface = {
            examTitle: !!exame ? exame.titulo : "",
            totalPage: 1,
            currentPage: 1,
            patientName: !!paciente ? joinNames(paciente) : "",
            patientGender: !!paciente && paciente.genero ? traduzirGenero(paciente.genero) : "",
            physicianName: medico ? joinNames(medico) : "",
            dateHourCreated: new Date().toLocaleDateString(LanguageUtils.getLanguage()),
            patientBirthDate: !!paciente ? dateUtil.dateOnlyStringToDate((paciente.dataNascimento as any).split("T")[0]).toLocaleDateString(LanguageUtils.getLanguage()) : "",
            healthPlanBroker: !!modelo?.mostrarPlanoSaude && paciente?.planoSaude?.length ? paciente.planoSaude[0].convenio.nome : "",
            assistantName: !!modelo && !!modelo.mostrarAssistente && atendimento.assistente ? joinNames(atendimento.assistente) : "",
            technicianName: !!modelo && !!modelo.mostrarTecnico && atendimento.tecnico ? joinNames(atendimento.tecnico) : "",
            anesthetistName: !!modelo && !!modelo.mostrarAnestesista && atendimento.anestesista ? joinNames(atendimento.anestesista) : "",
            requesterPhysicianName: !!modelo && !!modelo.mostrarSolicitante && atendimento.solicitante ? joinNames(atendimento.solicitante) : joinNames(medicoSolicitanteSelecionado),
            patientAge: !!paciente ? dateUtil.calcAgeAndMonths(dateUtil.getDateToString(new Date(paciente.dataNascimento as Date), "dd/MM/yyyy")) : "",
            deviceName: (!!modelo && !!modelo.mostrarEquipamento && !!paciente) ? getNomeDispositivo() : "",
        }

        const header = normalizeSection((modelo as ModeloInterface).cabecalho.design);
        content.push(<>{ReactHtmlParser(header)}</>);

        content.push(<PaperHeaderInfo key={content.length} headerInfoData={headerInfoData} modelo={modelo as ModeloInterface}></PaperHeaderInfo>);

        if (modelo?.layoutImagem.nome == "Only Fields") {
            content.push(createFieldsOnScreen(content.length + ""));

        } else {
            let columns: number = 12 / ((modelo as ModeloInterface).layoutImagem.colunas);
            let height: string = columns == 6 ? "210px" : "150px";

            if (modelo?.layoutImagem.posicao == "UP") {
                content.push(createImagesOnScreen(content.length + "", columns.toString(), height));
                content.push(createFieldsOnScreen(content.length + ""));

            } else {
                if (modelo?.layoutImagem.posicao == "RIGHT") {
                    content.push(
                        <div className="row" key={content.length}>
                            <div className="col-8">
                                {createFieldsOnScreen(content.length + "")}
                            </div>

                            <div className="col-4">
                                {createImagesOnScreen(content.length + "", "12", height)}
                            </div>
                        </div>
                    )
                } else {
                    if (modelo?.layoutImagem.posicao == "LEFT") {
                        content.push(
                            <div className="row" key={content.length}>
                                <div className="col-4">
                                    {createImagesOnScreen(content.length + "", "12", height)}
                                </div>

                                <div className="col-8">
                                    {createFieldsOnScreen(content.length + "")}
                                </div>
                            </div>
                        )
                    } else {
                        content.push(createFieldsOnScreen(content.length + ""));
                        content.push(createImagesOnScreen(content.length + "", columns.toString(), height));
                    }
                }

            }
        }

        const footer = normalizeSection((modelo as ModeloInterface).rodape.design);
        content.push(<>{ReactHtmlParser(footer)}</>);

        return content;
    }

    const normalizeSection = (section: string) => {
        const sectionAux = document.createElement("div");
        sectionAux.innerHTML = section;

        const textSection: HTMLDivElement = sectionAux.querySelector("#richTextBox") as HTMLDivElement;
        if (textSection) {
            textSection.contentEditable = "false";
            ((textSection.parentElement as HTMLDivElement).parentElement as HTMLDivElement).style.border = "";
            ((textSection.parentElement as HTMLDivElement).parentElement as HTMLDivElement).classList.remove("border");
        }

        applySectionHeight(sectionAux);

        return sectionAux.innerHTML;

    }

    const applySectionHeight = (section: HTMLElement) => {
        const sectionColLeft = section.querySelector("#sectionColLeft");
        const sectionColRight = section.querySelector("#sectioncolRight");

        if (sectionColLeft) {
            sectionColLeft.classList.add("h-100");
        }

        if (sectionColRight) {
            sectionColRight.classList.add("h-100");
        }

        return section;
    }

    const closeMsg = () => {
        let divMsg = document.getElementById("divMsgImageInserida");
        if (divMsg) {
            divMsg.classList.add("d-none");
        }
    }

    const imageView = (images: Array<any>, index: number) => {
        let arrayOfImages: Array<any> = [];

        images.map(imagem => {
            arrayOfImages.push(imagem.url);
        })


        setIsOpen(true)
        setPhotoIndex(index);
        setModalImages(arrayOfImages);
    }

    const getImageTabDates = () => {
        const images = imagensPaciente.sort((a, b) => new Date(b.dataInclusao).getTime() - new Date(a.dataInclusao).getTime());
        const dates = images.map((image) => new Date(image.dataInclusao).toLocaleString().slice(0, 10));
        const datesFiltered = dates.filter((date, index) => dates.indexOf(date) === index);

        setImageDates(datesFiltered);
    }

    const resetReduxStates = () => {
        resetReportData(dispatch);
        setSelectedExam(null as any, dispatch);
        setSelectedPatient(null as any, dispatch);
        setSelectedPhysician(null as any, dispatch);
        setSelectedReferringPhysician(null as any, dispatch);
    }

    const onClickSelectImage = (imageSelectedOnClick: any): void => {

        if (isAltPressed) {
            return
        }

        if (imagensSelecionadasOnClick !== undefined && imagensSelecionadasOnClick.length !== 0) {
            const getDivImageSelected: HTMLDivElement | any = document.getElementById(`card_image_${imagensSelecionadasOnClick.id}`);
            getDivImageSelected.style.border = "";
            setImagensSelecionadasOnClick([]);

        }

        if (imagesSelecionadasParaExclusao !== undefined && imagesSelecionadasParaExclusao.length !== 0) {
            imagesSelecionadasParaExclusao.map((image: any) => {
                const clearImagesExclusao: HTMLDivElement | any = document.getElementById(`card_image_${image.id}`);
                clearImagesExclusao.style.border = "";
            })
        }

        const getDivImage: any = document.getElementById(`card_image_${imageSelectedOnClick.id}`)

        if (getDivImage.style.border === "" || getDivImage.style.border === "4px solid #ff2401") {

            getDivImage.style.border = "2px solid #0275d8";
            setImagensSelecionadasOnClick(imageSelectedOnClick);
        } else {
            getDivImage.style.border = "";
            setImagensSelecionadasOnClick([]);
        }

    }

    const addLegendOnImageSeletedOnClick = () => {

        if (imagensSelecionadasOnClick === undefined) {
            (document.querySelector("#closeLegendModal") as HTMLButtonElement).click();
            setStopListening(true, dispatch);
            let error = t("Nenhuma imagem selecionada");
            return showSweetAlert(t("Nenhuma imagem selecionada"), error, "error", "ok");
        }

        if (imagensSelecionadasOnClick.length === 0) {
            (document.querySelector("#closeLegendModal") as HTMLButtonElement).click();
            setStopListening(true, dispatch);
            let error = t("Nenhuma imagem selecionada");
            return showSweetAlert(t("Nenhuma imagem selecionada"), error, "error", "ok");
        } else {
            setStateClickNaImage(true);
            getLegendas();
        }

    }

    const imageFullScreenOnClickView = () => {
        if (imagensSelecionadasOnClick === undefined) {
            (document.querySelector("#closeImageEditorModal") as HTMLButtonElement).click();
            let error = t("Nenhuma imagem selecionada");
            return showSweetAlert(t("Nenhuma imagem selecionada"), error, "error", "ok");
        }
        if (imagensSelecionadasOnClick.length === 0) {
            (document.querySelector("#closeImageEditorModal") as HTMLButtonElement).click();
            setStopListening(true, dispatch);
            let error = t("Nenhuma imagem selecionada");
            return showSweetAlert(t("Nenhuma imagem selecionada"), error, "error", "ok");
        } else {
            onClickEditImage(imagensSelecionadasOnClick);
        }

    }


    const handleKeyDown = (event: any) => {

        if (event.key === 'Alt') {
            setIsAltPressed(true);
        }

    };

    const handleKeyUp = (event: any) => {

        if (event.key === 'Alt') {
            setIsAltPressed(false);
        }

    };

    const handleClick = (image: any) => {
        if (isAltPressed) {

            if (imagensSelecionadasOnClick !== undefined && imagensSelecionadasOnClick.length !== 0) {
                const getDivImageSelected: HTMLDivElement | any = document.getElementById(`card_image_${imagensSelecionadasOnClick.id}`);
                getDivImageSelected.style.border = "";
                setImagensSelecionadasOnClick([]);
            }

            const getDivImage: HTMLDivElement | any = document.getElementById(`card_image_${image.id}`);

            if (getDivImage.style.border === "") {
                setImageSelecionadasParaExclusao([...imagesSelecionadasParaExclusao, image]);
                getDivImage.style.border = "4px solid #ff2401";
            } else {
                const newArray = imagesSelecionadasParaExclusao.filter((imageJaSelecionadas: any) => imageJaSelecionadas.id !== image.id);
                setImageSelecionadasParaExclusao(newArray);
                getDivImage.style.border = "";

            }
        }

    };

    const deleteImage = async () => {
        if (imagesSelecionadasParaExclusao.length === 0) {
            let error = t("Nenhuma imagem selecionada");
            return showSweetAlert(t("Erro ao deletar imagem"), error, "error", "ok");
        } else {
            await deleteImagesConfirmation();
        }
    }

    const deleteImageByClickIcon = async (image: any) => {
        await deleteImagesConfirmation(image);
    }

    const filterByPeriod = (arrrayIndice: number) => {
        const countOfItens = imageDates.length;
        if (arrrayIndice === 0) {

            const newArrayOfImages: Array<string> = [...imageDates]
            newArrayOfImages.splice(1, countOfItens);
            setImagesDatesFilter(newArrayOfImages);

            console.log("Filtered by las exam" + newArrayOfImages)
        }
        if (arrrayIndice === 1) {
            if (countOfItens >= 2) {
                const newArrayOfImages: Array<string> = [...imageDates]
                newArrayOfImages.splice(2, countOfItens);
                setImagesDatesFilter(newArrayOfImages);
            } else {
                return
            }

            console.log("Fitered by 2 exams")
        }
        if (arrrayIndice === 2) {

            if (countOfItens >= 2) {
                const newArrayOfImages: Array<string> = [...imageDates]
                newArrayOfImages.splice(3, countOfItens);
                setImagesDatesFilter(newArrayOfImages);
            } else {
                return
            }

            console.log("Filtered by 3 exams");
        }
        if (arrrayIndice === 3) {

            if (countOfItens >= 5) {
                const newArrayOfImages: Array<string> = [...imageDates]
                newArrayOfImages.splice(5, countOfItens);
                setImagesDatesFilter(newArrayOfImages);
            } else {
                return
            }
            console.log("Filtered by 5 exams");

        }
        if (arrrayIndice === 4) {

            if (countOfItens >= 10) {
                const newArrayOfImages: Array<string> = [...imageDates]
                newArrayOfImages.splice(10, countOfItens);
                setImagesDatesFilter(newArrayOfImages);
            } else {
                return
            }

            console.log("Filtered by 10 exams")
        }
        if (arrrayIndice === 5) {
            setImagesDatesFilter([])
            setImageDates([...imageDates])

            console.log("Filtered by all exams")
        }

    }

    const renderNavTab = () => {
        if (imagesDatesFilter.length !== 0) {
            return (
                imagesDatesFilter.map((date, index) => {
                    const dateImage = date.replace(/\//gi, "");
                    return (
                        <a
                            className={`nav-item nav-link p-2 small text-dark ${index === 0 ? "active" : ""}`}
                            data-toggle="tab"
                            href={`#nav-${dateImage}`}
                            role="tab"
                            aria-controls={`nav-${dateImage}`}
                            aria-selected="true"
                        >
                            {date}
                        </a>
                    )
                })
            )
        } else {
            return (
                imageDates.map((date, index) => {
                    const dateImage = date.replace(/\//gi, "");
                    return (
                        <a
                            className={`nav-item nav-link p-2 small text-dark ${index === 0 ? "active" : ""}`}
                            data-toggle="tab"
                            href={`#nav-${dateImage}`}
                            role="tab"
                            aria-controls={`nav-${dateImage}`}
                            aria-selected="true"
                        >
                            {date}
                        </a>
                    )
                })
            )
        }

    }

    const renderNavContent = () => {
        if (imagensPaciente.length) {
            return (
                imageDates.map((date, indexDate) => {
                    const dateImage = date.replace(/\//gi, "");
                    const images = imagensPaciente.filter((image) => new Date(image.dataInclusao).toLocaleString().slice(0, 10) === date);
                    return (
                        <div className={`tab-pane show ${indexDate === 0 ? "active" : ""}`} id={`nav-${dateImage}`}>
                            <div className="row m-0">
                                {
                                    imagensPaciente.map((img, index) => {
                                        const image = imagensLaudo.find((reportImage) => (reportImage.id === img.idImagem && new Date(img.dataInclusao).toLocaleString().slice(0, 10) === date)) as any;
                                        const imagesByDate = imagensLaudo.filter((reportImage) => images.find((i) => i.idImagem === reportImage.id));
                                        const imageIndex = imagesByDate.findIndex((reportImage) => reportImage.id === img.idImagem);
                                        return !image ? null :
                                            (
                                                <div className="p-0 col-12 col-md-12 col-xl-4 mb-1 mt-1 border-0">
                                                    <div key={index} id={`card_image_${image.id}`} className="card card-image mr-2"
                                                        tabIndex={0}
                                                        onDoubleClick={(event) => { handleSelectedImage(image, event) }}
                                                        onClick={(event) => { handleClick(image); onClickSelectImage(image) }}
                                                        onKeyUp={(event) => { handleKeyUp(event) }}
                                                        onKeyDown={(event) => { handleKeyDown(event) }}
                                                        onContextMenu={(event) => {showBtnRemoveLegenda(event, image.id) }}
                                                    >
                                                        <img className="card-img-top w-100 rounded-bottom divImage bg-black" src={image.url}></img>
                                                        <div className="text-center p-1 position-absolute w-100  rounded-bottom " style={{ backgroundColor: "rgba(255, 255, 255, 0.1)", bottom: "0px" }}>
                                                            <button onClick={() => imageView(imagesByDate, imageIndex)} className="btn btn-sm btn-outline-light border-0 no-padding" style={{ flex: 1 }} title={t("Visualizar")}>
                                                                <i className="bi bi-eye" aria-hidden="true"></i>
                                                            </button>
                                                            <button className="btn btn-sm btn-outline-light border-0 flex-1 no-padding" style={{ flex: 1 }} title={t("Editar")} data-toggle="modal" data-target="#legendModal"
                                                                onClick={() => {
                                                                    setAdicionaLegendaMiniatura(true)
                                                                    setImageTarget(image);
                                                                    getLegendas();
                                                                }}>
                                                                <i className="bi bi-at" aria-hidden="true"></i>
                                                            </button>
                                                            <button onClick={() => deleteImageByClickIcon(image)} className="btn btn-sm btn-outline-light border-0 no-padding" style={{ flex: 1 }} title={t("Apagar")}>
                                                                <i className="bi-trash" aria-hidden="true"></i>
                                                            </button>
                                                            <button className="btn btn-sm btn-outline-light border-0 flex-1 no-padding" style={{ flex: 1 }} title={t("Editar")} data-toggle="modal" data-target="#imageEditorModal" data-backdrop="static" data-keyboard="false" onClick={() => onClickEditImage(image)}>
                                                                <i className="fas fa-pencil-alt" aria-hidden="true"></i>
                                                            </button>
                                                        </div>
                                                        {
                                                            veiwTextImagemSelecionada && idImagemSeleciona === image.id ? (
                                                                <div className="textSelectedImage">
                                                                    <p>{imagensSelecionadas.length} imagens selecionadas</p>
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )

                                                        }
                                                        {
                                                            removerLegenda && idImagemSeleciona === image.id && image.legend !== null ? (
                                                                <button className="btnRemoveLegend btn btn-primary" onClick={(event) => {removeLegendOfImage(event, image)}}>{t("Remover legenda")}</button>
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                        <span className="d-none imgSelected">
                                                            <i className="fa fa-check" aria-hidden="true"></i>
                                                        </span>
                                                    </div>
                                                    {
                                                        image.legend && image.legend.descricao && visualizarLegenda &&
                                                        <figcaption className="text-center text-light">{image.legend.descricao}</figcaption>
                                                    }
                                                </div>
                                            )
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            )
        }
        return null;
    }

    const showBtnRemoveLegenda = (event: any, idImage: any) => {
        event.preventDefault();
        setIdImagemSelecionada(idImage);
        setRemoverLegenda(true);

        setTimeout(() => {
            setRemoverLegenda(false)
        }, 3000)
    }

    const removeLegendOfImage = async (event: any, image: any) => {
        setRemoverLegenda(false);
        event.preventDefault();
        await new ImageService().atualizarImagem(image.id, {legend: null}).then(() => {
            getPatientImages(paciente?.idPaciente as number);
            renderNavContent();
    
            for (let imageSelecionada of imagensSelecionadas) {
                if(imageSelecionada.id === image.id){
                    imageSelecionada.legend = null;
                    continue
                }
            }
        }).catch((e) => {
            Swal.fire({
                icon: "error",
                title: t("Erro ao remover legenda"),
                text: t("Por favor contacte o suporte técnico"),
                confirmButtonText: t("Voltar ao laudo")
            })
        });
    }

    const ReportLeftSideInfo = () => {
        return (
            <div className="col-3">
                <div className="form-group row">
                    <div className="input-group col">
                        <div className="input-group-prepend" title={t("Paciente")}>
                            <span className="input-group-text">
                                <i className="fa fa-id-badge" aria-hidden="true" />
                            </span>
                        </div>
                        <button ref={botaoPacienteViewSelectRef} type="button" className="form-control btn btn-light border text-left text-truncate" title={t("Paciente")} data-toggle="modal" data-target="#modalViewSelectPatientViewReportCreateId" data-backdrop="static" data-keyboard="false" value={`${!!paciente ? paciente.idPaciente : -1}`} disabled> {!!paciente ? joinNames(paciente) : t("Paciente")}</button>
                        <div className="input-group-prepend">
                            <button className="mr-0 btn btn-secondary rounded-right rounded-right" title={t("Selecionar paciente")} type="button" data-toggle="modal" data-target="#modalViewSelectPatientViewReportCreateId" disabled>
                                <i className="bi bi-arrow-repeat" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="input-group col">
                        <div className="input-group-prepend" title={t("Médico Examinador")}>
                            <span className="input-group-text">
                                <i className="fa fa-user-md" aria-hidden="true" />
                            </span>
                        </div>
                        <button ref={botaoMedicoViewSelectRef} type="button" className="form-control btn btn-light border text-left text-truncate" title={t("Examinador")} data-toggle="modal" data-target="#modalViewSelectPhysicianViewReportCreateId" value={`${!!medico ? medico.idProfissionalSaude : -1}`} disabled> {!!medico ? joinNames(medico) : t("Examinador")}</button>
                        <div className="input-group-prepend">
                            <button className="mr-0 btn btn-secondary rounded-right rounded-right" title={t("Selecionar examinador")} type="button" data-toggle="modal" data-target="#modalViewSelectPhysicianViewReportCreateId" disabled>
                                <i className="bi bi-arrow-repeat" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
                {
                    <div className="form-group row">
                        <div className="input-group col">
                            <div className="input-group-prepend" title={t("Médico Solicitante")}>
                                <span className="input-group-text">
                                    <i className="fa fa-user-md" aria-hidden="true" />
                                </span>
                            </div>
                            <button ref={botaoMedicoSolicitanteViewSelectRef} type="button" className="form-control btn btn-light border text-left text-truncate" title={t("Médico Solicitante")} data-toggle="modal" data-target="#modalViewSelectReferringPhysicianViewReportCreateId" value={`${!!medicoSolicitante ? medicoSolicitante.idProfissionalSaude : -1}`} onClick={async ()=>{await getProfissionaisSaude()}}> {medicoSolicitante ? joinNames(medicoSolicitante) : ""} </button>
                            <div className="input-group-prepend">
                                <button className="mr-0 btn btn-secondary rounded-right rounded-right" title={t("Selecionar solicitante")} type="button" data-toggle="modal" data-target="#modalViewSelectReferringPhysicianViewReportCreateId" onClick={async ()=>{await getProfissionaisSaude()}}>
                                    <i className="bi bi-arrow-repeat" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                }
                <div className="form-group row">
                    <div className="input-group col">
                        <div className="input-group-prepend" title={t("Dispositivo")}>
                            <span className="input-group-text">
                                <i className="fab fa-wpexplorer" aria-hidden="true" />
                            </span>
                        </div>
                        {
                            <input type="text" className="form-control" id="reportDeviceId" value={`${getNomeDispositivo()}`} readOnly />
                        }
                    </div>
                </div>
                <div className="row" style={{ overflow: "auto" }}>
                    <div className="col">
                        <div className="card">
                            <div className="card-header m-0 p-0 px-1 pt-1 border-0 bg-white">
                                <div className="row m-0">
                                    <div className="col-4 col-sm-4 col-lg-4 col-xl-2 p-0 m-1">
                                        <img className="w-100 h-auto p-0" src={nonprofile} style={{ borderRadius: "100%" }} alt="" />
                                    </div>
                                    <div className="col-7 col-sm-7 col-lg-7 col-xl-9 ml-1">
                                        <div className="row">
                                            #{atendimento && paciente ? paciente.idPaciente : ""} - {!!paciente ? joinNames(paciente) : ""}
                                            <br />
                                            {t("Documento")}: {!!paciente ? paciente.documento : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 w-100 text-center aling-center justify-center">
                                    <button className="btn btn-sm btn-outline-warning rounded-circle border-secondary bg-white mb-2 mt-2 mr-2 ml-2" title={t("Atualizar imagens")} onClick={handleUpdateImages}>
                                        <i className="bi bi-arrow-repeat " aria-hidden="true"></i>
                                    </button>
                                    <button className="btn btn-sm btn-outline-info rounded rounded-pill mr-4btn btn-sm btn-outline-warning rounded-circle border-secondary bg-white mb-2 mt-2 mr-2 ml-2" data-toggle="modal" data-target="#legendModal" title={t("Adicionar legenda")} onClick={() => { addLegendOnImageSeletedOnClick() }}>
                                        <i className="fa fa-at disabled" aria-hidden="true"></i>
                                    </button>
                                    <button className="btn btn-sm btn-outline-info rounded rounded-pill mr-4btn btn-sm btn-outline-warning rounded-circle border-secondary bg-white mb-2 mt-2 mr-2 ml-2" data-toggle="modal" data-target="#imageEditorModal" title={t("Visualizar")} data-backdrop="static" data-keyboard="false" onClick={() => imageFullScreenOnClickView()}>
                                        <i className="i bi-fullscreen text-warning" aria-hidden="true"></i>
                                    </button>
                                    <span className="dropdown">
                                        <button className="btn btn-sm btn-outline-dark rounded rounded-pill border-secondary bg-white dropdown-toggle mb-2 mt-2 mr-2 ml-2" style={{width: 50, height: 33}} type="button" id="reportFilterImages" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title={t("Filtrar Exames")}>
                                            <i className="bi bi-funnel" aria-hidden="true"></i></button>
                                        <span className="dropdown-menu" aria-labelledby="reportFilterImages" id="divFilterImages">
                                            <button className="dropdown-item" type="button" id="lastExam" onClick={() => filterByPeriod(0)}>{t("Último Exame")}</button>
                                            <button className="dropdown-item" type="button" id="lastExams2" onClick={() => filterByPeriod(1)}>{t("Últimos 2 Exames")}</button>
                                            <button className="dropdown-item" type="button" id="lastExams3" onClick={() => filterByPeriod(2)}>{t("Últimos 3 Exames")}</button>
                                            <button className="dropdown-item" type="button" id="lastExams5" onClick={() => filterByPeriod(3)}>{t("Últimos 5 Exames")}</button>
                                            <button className="dropdown-item" type="button" id="lastExams10" onClick={() => filterByPeriod(4)}>{t("Últimos 10 Exames")}</button>
                                            <button className="dropdown-item" type="button" id="allExams" onClick={() => filterByPeriod(5)}>{t("Todos")}</button>

                                        </span>
                                    </span>
                                    <button className="btn btn-sm btn-outline-danger rounded rounded-pill mr-2 ml-2 disabled rounded-circle" type="button" id="reportDeleteAllMarkedImages" title={t("Excluir todas as imagens")} onClick={() => { deleteImage() }}>
                                        <i className="bi-trash" aria-hidden="true"></i>
                                    </button>
                                </div>
                                <div className="row m-0" style={{ overflow: "none" }}>
                                    <div className="nav nav-tabs m-0 p-0 w-100" style={{ flexWrap: "nowrap" }} role="tablist">
                                        {renderNavTab()}
                                    </div>
                                </div>
                            </div>

                            <p id="divMsgImageInserida" style={{ marginLeft: 10, marginTop: 15, marginRight: 10 }} className="alert alert-warning d-none">
                                {t("A imagem já foi inserida")}
                                <button className="btn btn-sm float-right" onClick={() => closeMsg()}>
                                    <i className="fa fa-times"></i></button>
                            </p>

                            <div className="card-body p-1">
                                <div className="tab-content bg-dark" style={{ height: "350px", overflowY: "auto" }}>
                                    {renderNavContent()}
                                </div>
                                <div className="flex mt-2 custom-control custom-switch">
                                    <input type={"checkbox"} id="visualizarLegenda" className="mr-2 custom-control-input" onClick={(e) => (visualizarLegendaImagem(e))}/>
                                    <label className="custom-control-label" htmlFor="visualizarLegenda"> Ocultar legenda</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const ReportPaper = () => {
        return (
            <div className="col-9 bg-dark" id="divPaperId" style={{ padding: "30px", maxHeight: "29.7cm", overflow: "auto", fontFamily: "Arial" }}>
                <div className="border m-auto" style={{ /*width: "21cm",*/ maxWidth: "21cm", /*height: "29.7cm",*/ background: "#fff", padding: "0.5cm" }}>
                    {!!modelo && loadPaperContent()}
                </div>
            </div>

        )
    }

    const setTextDraftEditor = (editorState: EditorState, text: string) => {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();

        text = selection.getEndOffset() == 0 ? text : "\n" + text;

        const contentAfterRemoval = Modifier.removeRange(contentState, selection, 'backward');

        let ncs: ContentState;
        /**
         * Se algum texto estiver selecionado ele é substituído
         */
        if (!selection.isCollapsed()) {
            ncs = Modifier.replaceText(contentState, selection, text);
        } else {
            ncs = Modifier.insertText(contentState, selection, text);
        }

        const newEditorState = EditorState.push(editorState, ncs, "insert-fragment");

        return newEditorState;
    }

    const getTextoDraftEditor = (editorState: EditorState): string => {
        if (typeof editorState != "boolean") {
            const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
            const text = blocks.map(block => (!block.text.trim() && "\n") || block.text).join("\n");
            return text;
        } else {
            return editorState;
        }
    }


    const getDraftEditorHTML = (editorState: EditorState) => {
        if (typeof editorState != "boolean") {
            const raw = convertToRaw(editorState.getCurrentContent());
            let html = draftjsToHtml(raw);

            html = normalizeHtml(html);

            return html;
        } else {
            return editorState;
        }
    }

    const normalizeHtml = (html: string) => {
        const div = document.createElement('div');

        div.innerHTML = html;

        const delElements = div.querySelectorAll("del");

        delElements.forEach((del) => {
            const parent = del.parentElement as HTMLElement;

            const span = document.createElement("span");
            span.style.cssText = del.style.cssText;
            span.style.textDecoration += " line-through";
            span.innerHTML = del.innerHTML;

            parent.insertBefore(span, del);
            parent.removeChild(del);
        });

        const insElements = div.querySelectorAll("ins");

        insElements.forEach((ins) => {
            const parent = ins.parentElement as HTMLElement;

            const span = document.createElement("span");
            span.style.cssText = ins.style.cssText;
            span.style.textDecoration += " underline";
            span.innerHTML = ins.innerHTML;

            parent.insertBefore(span, ins);
            parent.removeChild(ins);
        });


        return div.innerHTML;

    }

    const setPredefAnswerToField = (index: number, text: string) => {
        let answers = [...valorCampos];
        let newEditorState = setTextDraftEditor(answers[index]?.valor as EditorState, text);

        answers[index].valor = newEditorState;

        setFieldToLocalStorage(answers[index].valor, index);
        setValorCampos(answers);

        (document.querySelector("#closePredefinitionAnswersModal") as HTMLButtonElement).click();
    }


    const setFavoriteReportToReport = (favoriteAnswers: Array<RespostaFavoritaInterface>) => {
        const campos = (modelo as ModeloInterface).campos;
        let answers = [...valorCampos];
        for (let index = 0; index < answers.length; index++) {
            const favoriteAnswer = favoriteAnswers.find((favAnswer) => favAnswer.idCampo === answers[index].idCampo);
            const campo = campos.find((c) => c.idCampo === answers[index].idCampo) as CampoInterface;

            if (favoriteAnswer) {

                if (campo.tipo !== "Check Box") {
                    answers[index].valor = createEditorStateFromHTMLValue(favoriteAnswers[index].html);
                } else {
                    answers[index].valor = favoriteAnswers[index].html === "true" ? true : false;
                }

                setFieldToLocalStorage(answers[index].valor, campo.idCampo);
            }
        }

        setValorCampos(answers);

        (document.querySelector("#closefavoriteReportsModalLabel") as HTMLButtonElement).click();
    }

    const setLegendaParaImagem = (legenda: LegendaInterface, imagem = imageTarget) => {
        let target: any;

        if (adicionaLegendaMiniatura) {
            target = imageTarget;
            target.legend = legenda;

            let aux = [...imagensSelecionadas]
            let indexImagem = aux.findIndex((img) => img.id === imagem.id);
            if (indexImagem !== -1) {
                aux[indexImagem] = target;
                setImagensSelecionadas(aux);
            }

            setAdicionaLegendaMiniatura(false);
            return
        }

        if (stateClickNaImage) {
            target = imagensSelecionadasOnClick;

            target.legend = legenda;

            let aux = [...imagensSelecionadas]

            let indexImagem = aux.findIndex((img) => img.id === target.id);
            if (indexImagem !== -1) {
                aux[indexImagem] = target;
                setImagensSelecionadas(aux);
            }

            setStateClickNaImage(false);

        } else {
            target = { ...imagem };

            target.legend = legenda;

            let arrayImagensSelecionadas = [...imagensSelecionadas];

            let indexImagem = arrayImagensSelecionadas.findIndex((img) => img.id === imagem.id);

            indexImagem = indexImagem !== -1 ? indexImagem : 0;

            arrayImagensSelecionadas[indexImagem] = target;

            const obj = {
                idImagem: imagem.id,
                idLegenda: legenda.idLegenda,
                description: legenda.descricao,
            }

            const imageIndex = imagensPaciente.findIndex((img) => {
                return img.idImagem === target.id;
            });

            if (imageIndex !== -1) {
                const imagensPacienteAux: Array<ImagemInterface> = imagensPaciente;
                const imagensLaudoAux: Array<ImageReportInterface> = imagensLaudo;

                imagensPacienteAux[imageIndex] = {
                    ...imagensPacienteAux[imageIndex],
                    legenda: target.legend
                }

                imagensLaudoAux[imageIndex] = {
                    ...imagensLaudoAux[imageIndex],
                    legend: target.legend
                }

                const imageData = imagensPacienteAux[imageIndex];

                setImagensLaudo(imagensLaudoAux);
            }

            setLegendasSelecionadas([...legendasSelecionadas, obj]);
            setImagensSelecionadas(arrayImagensSelecionadas);
            setImageTarget(null);
        }
    }

    const removeLegend = (imageIndex: number) => {
        let image = imagensSelecionadas[imageIndex];

        let indexLegend = legendasSelecionadas.findIndex((legend: any) => {
            return legend.imageUid === image.uid
        })

        let arrayLegendas = [...legendasSelecionadas];

        arrayLegendas.map((legend, index) => {
            if (index > indexLegend) {
                legend.indexImage -= 1;
                return legend
            } else {
                return legend
            }
        })

        if (indexLegend != -1) {
            arrayLegendas.splice(indexLegend, 1)
        }

        setLegendasSelecionadas(arrayLegendas);
    }

    const salvarLaudoComoFavorito = async () => {
        let respostasCampos = [...valorCampos];
        const laudoFavorito: LaudoFavoritoInterface = {
            idContrato: clinicaSelecionada.idContratoPrincipal as number,
            idExame: (exame as ExameInterface).idExame,
            titulo: tituloLaudoFavorito,
            favorito: false,
            maisUtilizados: 0,
            respostasFavoritas: []
        }

        for (let index = 0; index < respostasCampos.length; index++) {
            let respostaFavorita: RespostaFavoritaInterface = {
                idContrato: clinicaSelecionada.idContratoPrincipal as number,
                texto: '',
                html: '',
                idCampo: respostasCampos[index].idCampo,
                titulo: tituloLaudoFavorito
            };

            if (typeof respostasCampos[index].valor != "boolean") {
                let textoResposta = getTextoDraftEditor(respostasCampos[index].valor as EditorState);
                const conteudoCampo = (respostasCampos[index].valor as EditorState).getCurrentContent();

                respostaFavorita = {
                    ...respostaFavorita,
                    texto: textoResposta,
                    html: draftjsToHtml(convertToRaw(conteudoCampo)).replace(/\n/g, '<br/>'),
                }

            } else {
                respostaFavorita = {
                    ...respostaFavorita,
                    texto: (respostasCampos[index].valor as boolean).toString(),
                    html: (respostasCampos[index].valor as boolean).toString()
                }
            }

            laudoFavorito.respostasFavoritas.push(respostaFavorita);
        }

        try {
            const idLaudoFavorito = await new LaudoFavoritoService().inserir(laudoFavorito);

            await showSweetAlert(t("Laudo favorito salvo com sucesso"), t("Clique em ok para continuar"), "success", "OK")
        } catch (error: any) {
            await showSweetAlert(t("Erro ao salvar laudo favorito"), error, "error", "ok");
        } finally {
            setTituloLaudoFavorito("");
            (document.querySelector("#closesaveReportAsfavoriteReportsModalLabel") as HTMLButtonElement).click();
        }

        setTituloLaudoFavorito("");
        (document.querySelector("#closesaveReportAsfavoriteReportsModalLabel") as HTMLButtonElement).click();
    }

    const modalImageView = () => {
        return (
            <div className="modal " id="imageViewModal" role="dialog" aria-labelledby="imageViewModalLabel" aria-hidden="true">
                <div className="modal-dialog w-50" role="document" >
                    <div className="modal-content">
                        <div className="modal-header p-2 ml-2 mr-2 m-2 border-0">
                            <h5 className="modal-title" id="imageViewModalLabel">{t("Visualizador de imagem")}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="closeImageViewModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modalImage">
                            {isOpen && (
                                <Lightbox
                                    mainSrc={modalImages[photoIndex]}
                                    nextSrc={modalImages[(photoIndex + 1) % modalImages.length]}
                                    prevSrc={modalImages[(photoIndex + modalImages.length - 1) % modalImages.length]}
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                        setPhotoIndex((photoIndex + modalImages.length - 1) % modalImages.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setPhotoIndex((photoIndex + 1) % modalImages.length)
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const ModalSaveReportAsFavoriteReport = () => {
        return (
            <div className="modal " id="saveReportAsfavoriteReportsModal" tabIndex={-1} role="dialog" aria-labelledby="saveReportAsfavoriteReportsModalLabel" aria-hidden="true">
                <div className="modal-dialog w-75" role="document" >
                    <div className="modal-content">
                        <div className="modal-header p-2 ml-2 mr-2 m-2 border-0">
                            <h5 className="modal-title" id="saveReportAsfavoriteReportsModalLabel">{t("Salvar laudo como Favorito")}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="closesaveReportAsfavoriteReportsModalLabel">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="row form-group">
                                <div className="col input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <i className="fas fa-file-signature"></i>
                                        </span>
                                    </div>
                                    <input className="form-control" placeholder={t("Nome do laudo")} type="text" title={t("Título do laudo favorito")} value={tituloLaudoFavorito} onChange={(event) => { setTituloLaudoFavorito(event.target.value); }} />
                                </div>
                            </div>
                        </div>

                        <div className="card-footer p-0 pt-1 pb-1">
                            <div className="row p-0 m-0">
                                <div className="col text-right p-2">
                                    <button className="ml-3 btn btn-success" type="button" onClick={(event) => { salvarLaudoComoFavorito(); }}>
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                        &nbsp; {t("Salvar")}
                                    </button>

                                    <button className="ml-3 btn btn-warning" type="button" data-dismiss="modal" aria-label="Close">
                                        <i className="fa fa-ban" aria-hidden="true"></i>
                                        &nbsp; {t("Cancelar")}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }


    const disableFieldsControlReports = (disabled: boolean = true) => {
        const divPaper: HTMLDivElement = document.querySelector("#divPaperId") as HTMLDivElement;
        const buttonsPaper: Array<any> = divPaper.querySelectorAll("button") as any;
        const fieldsEditors: Array<any> = divPaper.querySelectorAll(`div[contenteditable="true"]`) as any;

        const divHeaderButtonsControl: HTMLDivElement = document.querySelector("#divHeaderButtonControlReportId") as HTMLDivElement;
        (divHeaderButtonsControl.querySelectorAll(`button:not([id="buttonCancelReportId"])`) as any)
            .forEach((button: HTMLButtonElement) => {
                button.disabled = disabled;
            });

        buttonsPaper.forEach((button: HTMLButtonElement) => {
            button.disabled = disabled;
        });

        fieldsEditors.forEach((divContentEditabel: HTMLDivElement) => {
            divContentEditabel.contentEditable = (!disabled).toString();
        });
    }

    const getRespostas = () => {
        let respostas: RespostaType[] = [];
        let respostasNosCampos = [...valorCampos];

        for (let index = 0; index < respostasNosCampos.length; index++) {
            if (typeof respostasNosCampos[index].valor != "boolean") {
                let textoResposta = getTextoDraftEditor(respostasNosCampos[index].valor as EditorState);
                const respostaHtml = getDraftEditorHTML(respostasNosCampos[index].valor as EditorState);

                respostas.push({
                    texto: textoResposta,
                    html: respostaHtml,
                    idCampo: respostasNosCampos[index].idCampo
                });

            } else {

                respostas.push({
                    texto: respostasNosCampos[index].valor.toString(),
                    html: respostasNosCampos[index].valor.toString(),
                    idCampo: respostasNosCampos[index].idCampo
                })
            }
        }

        return respostas;
    }

    const montaArrayDeCampos = (respostas: { stack?: any[] }[]) => {
        const campoArray = respostas.map(r => {
            let textFields;
            if (r.stack && r.stack.length > 0) {
                textFields = r.stack.map(s => ({
                    ...s,
                    text: typeof s.text === 'string' ?
                        s.text
                        : s.text.map((t: any) => ({
                            ...s,
                            font: t.font,
                            style: t.style,
                            text: t.text
                        }))
                }));

                return textFields[0]
            }
            return r
        })
        return campoArray
    }

    const mountFieldsToPDF = (answers: RespostaType[]) => {
        let fields: Array<CampoInterface> = (modelo as ModeloInterface).campos;
        let fieldsToPDF: Array<any> = [];
        
        const cheboxAgrupad = groupCheckboxesToPDF(fields, answers);
      
        for (let index = 0; index < answers.length; index++) {
            if (fields[index].tipo === "Line" && fields[index].ativo === true){
                let fieldLine = {
                    canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }]
                }
                fieldsToPDF.push(fieldLine);
            }else if (answers[index].texto !== "true" && answers[index].texto !== "false") {
                if (answers[index].texto !== "\n" && fields[index] !== undefined) {
                    let resposta = (answers[index].html).replace(/\n/g, "<br/>");
                    let respostaPdfMake = htmlToPdfmake(resposta);
                    let respostaArrayTratado = montaArrayDeCampos(respostaPdfMake);

                    let field = {
                        text: [
                            {
                                bold: true,
                                text: `${fields[index].descricao}:\n`,
                                width: 100,
                            },
                            {
                                text: '',
                            }
                        ]
                    }


                    fieldsToPDF.push(field);
                    fieldsToPDF = [...fieldsToPDF, ...respostaPdfMake];
                }
            }else {
                if (answers[index].texto === "true" && fields[index] !== undefined) {
                
                    const checkboxBox = cheboxAgrupad.filter((checkboxGroup: CheckboxGroup) => checkboxGroup.order === fields[index].ordem);

                    if (checkboxBox.length === 1) {
                        const lastItemIndex = checkboxBox[0].items.length - 1;
                        
                        if (lastItemIndex >= 0) {
                            checkboxBox[0].items.push({
                                bold: true,
                                text: "\n\n\n",
                                width: 100,
                            })
                        }

                        fieldsToPDF.push({
                            text: checkboxBox[0].items,
                        });
                    }
                }
            }
        }
        return fieldsToPDF;
    }

    const groupCheckboxesToPDF = (fields: CampoInterface[], answers: RespostaType[]): any[] => {

        const checkBoxToPDf: any[] = [];
        let currentCheckboxGroup: any[] = [];
        let currentCheckboxGroupOrder: number | null = null;

        for (const field of fields) {
            if (field.tipo === "Check Box") {
                if (currentCheckboxGroup.length === 0) {
                    currentCheckboxGroupOrder = field.ordem;
                }

                const filteredAnsw: RespostaType | undefined = answers.find((answ: RespostaType) => answ.idCampo === field.idCampo);

                if (filteredAnsw) {
                    const code = filteredAnsw.texto === "true" ? "\uf14a" : "\ue800";
                    currentCheckboxGroup.push(
                        { text: code, style: { font: "Fontello" } },
                        { bold: true, text: `${field.descricao} `, width: 100 }
                    );
                }
            } else {
        
                if (currentCheckboxGroup.length > 0) {
                    checkBoxToPDf.push({
                        order: currentCheckboxGroupOrder,
                        items: currentCheckboxGroup,
                    });
                    currentCheckboxGroup = []; 
                    currentCheckboxGroupOrder = null;
                }
            }
        }

        if (currentCheckboxGroup.length > 0) {
            checkBoxToPDf.push({
                order: currentCheckboxGroupOrder,
                items: currentCheckboxGroup,
            });
        }

        return checkBoxToPDf;
    }

    const getImagensParaPDF = async () => {
        let index = -1;
        let images: Array<any> = [];
        let imagensSelecionadasLaudo: Array<{ id: number, url: string, legend: LegendaInterface }> = [...imagensSelecionadas];
        let imegeSelect: any;

        for (imegeSelect of imagensSelecionadasLaudo) {
            index = index + 1;

            await axios.get(imegeSelect.url, { responseType: "arraybuffer" })
                .then((response: any) => {
                    let arquivoImagem = response.data;

                    images.push([{
                        width: (PDF_WIDTH_SIZE - marginLeft - marginRight) / 3,
                        order: index,
                        image: `data:image/jpeg;base64,${new Buffer(arquivoImagem, "binary").toString("base64")}`,
                    },
                    {
                        text: imegeSelect.legend?.descricao,
                        marginBottom: 5,
                        alignment: 'center'
                    }])
                })
        }
        return images
    }

    const mountImageColumns = (imagesToPDF: Array<any>) => {
        let tbodyAux: Array<any> = [];
        let columns: number = (modelo as ModeloInterface).layoutImagem.colunas;

        const imageWidthTwoCol = (PDF_WIDTH_SIZE - marginLeft - marginRight) / 2;
        const imageWidthThreeCol = (PDF_WIDTH_SIZE - marginLeft - marginRight) / 3;
        const imageWidthFourCol = (PDF_WIDTH_SIZE - marginLeft - marginRight) / 4;

        const imageWidth: number = columns == 2 ? imageWidthTwoCol : columns == 3 ? imageWidthThreeCol : imageWidthFourCol;

        let tableImages: any = {
            table: {
                body: []
            },
            layout: {
                defaultBorder: false,
                paddingLeft: (i: any, node: any) => { return 0; },
                paddingRight: (i: any, node: any) => { return 0; },
                paddingTop: (i: any, node: any) => { return 0; },
                paddingBottom: (i: any, node: any) => { return 0; },
            }
        }

        for (let index = 0; index < imagesToPDF.length; index++) {
            let image = imagesToPDF[index];

            image[0].width = imageWidth;

            tbodyAux.push(image);

            if ((index + 1) % columns == 0 || (index + 1) == imagesToPDF.length) {
                if (tbodyAux.length != columns) {
                    for (let index = tbodyAux.length; index < columns; index++) {
                        tbodyAux.push({});
                    }
                }

                tableImages.table.body.push(tbodyAux);

                tbodyAux = [];
            }
        }

        return tableImages;
    }

    const getInfoColumns = (currentPage: number, pageCount: number) => {
        return [
            {
                label: `${t("Página")}: `,
                text: `${currentPage} ${t("de")} ${pageCount}`,
                condition: true,
            },

            {
                label: `${t("Paciente")}: `,
                text: `${joinNames(paciente as PacienteInterface)}\n`,
                condition: modelo?.mostrarPaciente,
            },

            {
                label: `${t("Solicitante")}: `,
                text: `${joinNames(medicoSolicitante as ProfissionalSaudeInterface)}\n`,
                condition: modelo?.mostrarSolicitante,
            },

            {
                label: `${t("Examinador")}: `,
                text: `${joinNames(medico as ProfissionalSaudeInterface)}\n`,
                condition: !modelo?.esconderExaminador,
            },

            {
                label: `${t("Data de nascimento")}: `,
                text: `${new Date((paciente as PacienteInterface).dataNascimento).toLocaleDateString()}\n`,
                condition: modelo?.mostrarNascimentoPaciente,
            },

            {
                label: `${t("Idade")}: `,
                text: dateUtil.calcAgeAndMonths(dateUtil.getDateToString(new Date((paciente as PacienteInterface).dataNascimento as Date), "dd/MM/yyyy")),
                condition: modelo?.mostrarIdadePaciente,
            },

            {
                label: `${t("Gênero")}: `,
                text: `${traduzirGenero((paciente as PacienteInterface).genero)}\n`,
                condition: modelo?.mostrarGeneroPaciente,
            },

            {
                label: `${t("Plano de saúde")}: `,
                text: `${paciente?.planoSaude?.length ? paciente.planoSaude[0].convenio.nome : t("Particular")}\n`,
                condition: modelo?.mostrarPlanoSaude,
            },

            {
                label: `${t("Dispositivo")}: `,
                text: getNomeDispositivo(),
                condition: modelo?.mostrarEquipamento,
            },

            {
                label: `${t("Data")}: `,
                text: `${new Date().toLocaleDateString()}\n`,
                condition: modelo?.mostrarData,
            },
        ]
    }

    const mountInfoColumns = (currentPage: number, pageCount: number) => {
        const infoColumns = getInfoColumns(currentPage, pageCount);
        const columns = [
            [] as Array<any>,
            [] as Array<any>,
            [] as Array<any>
        ] as Array<any>;
        let counter = 0;
        for (let index = 0; index < infoColumns.length; index++) {
            if (infoColumns[index].condition) {
                let column = {
                    text: [
                        {
                            bold: true,
                            text: infoColumns[index].label,
                            fontSize: 9,
                        },
                        {
                            text: infoColumns[index].text,
                            fontSize: 9,
                        }
                    ]
                }

                const columnIndex = counter % 3;

                columns[columnIndex].push(column);

                counter++;
            }
        }

        return columns;
    }

    const montarInfoPDF = (currentPage: number, pageCount: number) => {
        let info: any = {
            columns: mountInfoColumns(currentPage, pageCount),
            margin: [
                28.3465,
                0,
                0,
                0
            ],
        }

        return info;
    }

    const montarInfoTitulo = (currentPage: number, pageCount: number) => {
        let info: any = {
            columns: mountInfoColumns(currentPage, pageCount),
            margin: [
                28.3465,
                0,
                0,
                0
            ],
        }

        return info;
    }

    const montarSecaoPDF = (secao: SecaoInterface, margem: {
        margemEsquerda: number,
        margemSuperior: number,
        margemDireita: number,
        margemInferior: number
    }, altura: number) => {
        let secaoTextoPDF: any = {};
        let secaoPDF: any = {
            columns: [],
            margin: [
                margem.margemEsquerda,
                margem.margemSuperior,
                margem.margemDireita,
                margem.margemInferior
            ]
        };

        const layout = getLayout(secao.design);
        const textoSecao = getTextoSecao(secao.design, layout);

        if (!!textoSecao) {
            textoSecao.innerHTML = formatarHTMLSecao(textoSecao);

            secaoTextoPDF = { text: htmlToPdfmake(textoSecao.innerHTML) };

            secaoTextoPDF.text.forEach((text: any, index: number) => {
                if (typeof text == "string") {
                    secaoTextoPDF.text[index] = `${text}\n`;

                } else {
                    if (typeof text == "object" && typeof text.text == "string") {
                        text.text += "\n";
                    }
                }
            });
        }

        const imagemSecao = getImagemSecao(secao.design, layout);

        if (!imagemSecao) {
            secaoPDF.columns.push(secaoTextoPDF);
        } else {
            const larguraImagem = (PDF_WIDTH_SIZE - marginLeft - marginRight) * 0.25;
            const larguraTexto = (PDF_WIDTH_SIZE - marginLeft - marginRight) * 0.75;

            const layout = getLayout(secao.design);

            if (layout == "imgLeft") {
                secaoPDF.columns.push({ image: imagemSecao.src, fit: [larguraImagem, (altura - marginTop)], style: { alignment: "left" } });
                secaoPDF.columns.push({ ...secaoTextoPDF, width: larguraTexto });
            } else {
                if (layout == "imgRight") {
                    secaoPDF.columns.push({ ...secaoTextoPDF, width: larguraTexto });
                    secaoPDF.columns.push({ image: imagemSecao.src, fit: [larguraImagem, (altura - marginTop)], style: { alignment: "right" } });

                } else {
                    if (layout == "justText") {
                        secaoPDF.columns.push(secaoTextoPDF);

                    } else {
                        const larguraSecao = PDF_WIDTH_SIZE - marginLeft - marginRight;
                        secaoPDF.columns.push({ image: imagemSecao.src, fit: [larguraSecao, (altura - marginTop)], style: { alignment: "center" } });
                    }
                }

            }
        }

        return secaoPDF;
    }


    const getSecaoAssinaturaDigital = () => {
        if (medico?.assinatura === "https://localhost:4514/images/images/no-sign.png") {
            return {
                stack: [
                    {}
                ],
                alignment: 'center'
            }
        }

        return {
            stack: [
                modelo?.mostrarAssinatura && medico?.assinatura ?
                    {
                        image: medico?.assinatura,
                        width: 150,
                        height: 100
                    } : {},
                modelo?.mostrarTextoAssinatura ?
                    {
                        text: medico?.assinaturaTexto
                    } : {},
            ],
            alignment: 'center'
        }

    }

    const formatarHTMLSecao = (secao: HTMLElement) => {
        secao.innerHTML = formatarFontesSecao(secao.innerHTML);

        const elements = secao.querySelectorAll("[style]");

        for (let index = 0; index < elements.length; index++) {
            limparEstilosElemento(elements[index] as HTMLElement);
        }

        const fonts = secao.querySelectorAll("font");
        const fontsLength = fonts.length;
        const length = fontsLength;

        for (let fontIndex = 0; fontIndex < length; fontIndex++) {
            const font: any = fonts[fontIndex];
            const nextSibling = font.nextSibling;
            const children = font.children as HTMLCollectionOf<HTMLElement>;

            if (children.length) {
                for (let index = 0; index < children.length; index++) {
                    let child = (children.item(index) as HTMLElement).cloneNode(true) as HTMLElement;

                    child = aplicarEstiloFonte(child, font);

                    if (nextSibling) {
                        secao.insertBefore(child, nextSibling);
                    } else {
                        font.parentElement?.appendChild(child);
                    }
                }
            } else {
                let div = document.createElement("div");
                div.innerText = font.innerText;

                div = aplicarEstiloFonte(div, font) as HTMLDivElement;

                font.parentElement?.appendChild(div);
            }

            font.parentElement?.removeChild(font);
        }

        return secao.innerHTML;
    }

    const aplicarEstiloFonte = (element: HTMLElement, font: HTMLFontElement) => {
        const size = font.getAttribute("size");
        const fontType = font.getAttribute("face");

        if (size) {
            element.style.fontSize = convertFontSizeToPt(parseInt(size));
        }

        if (fontType) {
            element.style.font = fontType;
        }

        return element;
    }

    const convertFontSizeToPt = (size: number): string => {
        if (size === 1) {
            return "7.5pt";
        } else if (size === 2) {
            return "10pt";
        } else if (size === 3) {
            return "12pt";
        } else if (size === 4) {
            return "13.5pt";
        } else if (size === 5) {
            return "18pt";
        } else if (size === 6) {
            return "24pt";
        } else if (size === 7) {
            return "36pt";
        } else {
            return "12pt";
        }
    }

    const formatarFontesSecao = (html: string) => {
        html = html.replace(/font-size: x-small/gi, "font-size: 7.5pt");
        html = html.replace(/font-size: small/gi, "font-size: 10pt");
        html = html.replace(/font-size: medium/gi, "font-size: 12pt");
        html = html.replace(/font-size: large/gi, "font-size: 13.5pt");
        html = html.replace(/font-size: x-large/gi, "font-size: 18pt");
        html = html.replace(/font-size: xx-large/gi, "font-size: 24pt");
        html = html.replace(/font-size: xxx-large/gi, "font-size: 36pt");

        html = html.replace(/font-size: -webkit-x-small/gi, "font-size: 7.5pt");
        html = html.replace(/font-size: -webkit-small/gi, "font-size: 10pt");
        html = html.replace(/font-size: -webkit-medium/gi, "font-size: 12pt");
        html = html.replace(/font-size: -webkit-large/gi, "font-size: 13.5pt");
        html = html.replace(/font-size: -webkit-x-large/gi, "font-size: 18pt");
        html = html.replace(/font-size: -webkit-xx-large/gi, "font-size: 24pt");
        html = html.replace(/font-size: -webkit-xxx-large/gi, "font-size: 36pt");

        return html;
    }

    const limparEstilosElemento = (elemento: HTMLElement) => {
        if ((elemento as HTMLElement).style.fontSize.includes("rem")) {
            (elemento as HTMLElement).style.fontSize = "";
        }
    }

    const getTextoSecao = (secao: string, layout: string) => {
        const divAux = document.createElement("div");
        divAux.innerHTML = secao;

        if (layout === "imgRight") {
            return getTextoSecaoImagemDireita(divAux);
        } else {
            return getTextoSecaoImagemEsquerda(divAux);
        }
    }

    const getTextoSecaoImagemDireita = (secao: HTMLDivElement) => {
        const colunaEsquerdaSecao = secao.querySelector("#sectionColLeft");

        if (colunaEsquerdaSecao && !colunaEsquerdaSecao.classList.contains("d-none")) {
            let textoSecao = colunaEsquerdaSecao.querySelector("div[contenteditable='true']");

            if (!textoSecao) {
                textoSecao = colunaEsquerdaSecao.querySelector("div[contenteditable='false']");
            }

            return textoSecao as HTMLDivElement;
        }

        return null;
    }


    const getTextoSecaoImagemEsquerda = (secao: HTMLDivElement) => {
        const colunaDireitaSecao = secao.querySelector("#sectioncolRight");

        if (colunaDireitaSecao && !colunaDireitaSecao.classList.contains("d-none")) {
            let textoSecao = colunaDireitaSecao.querySelector("div[contenteditable='true']");

            if (!textoSecao) {
                textoSecao = colunaDireitaSecao.querySelector("div[contenteditable='false']");
            }

            return textoSecao as HTMLDivElement;

        }

        return null;
    }

    const getImagemSecao = (secao: string, layout: string) => {
        const divAux = document.createElement("div");
        divAux.innerHTML = secao;

        if (layout === "imgLeft") {
            return getImagemSecaoImagemEsquerda(divAux);
        } else if (layout === "imgRight") {
            return getImagemSecaoImagemDireita(divAux);
        } else {
            return getImagemSecaoImagemEsquerda(divAux);
        }

        return null;
    }

    const getImagemSecaoImagemEsquerda = (secao: HTMLDivElement) => {
        const colunaEsquerdaSecao = secao.querySelector("#sectionColLeft");

        if (colunaEsquerdaSecao && !colunaEsquerdaSecao.classList.contains("d-none")) {
            const imagem = colunaEsquerdaSecao.querySelector("img");
            return imagem as HTMLImageElement;
        }

        return null;
    }

    const getImagemSecaoImagemDireita = (secao: HTMLDivElement) => {
        const colunaDireitaSecao = secao.querySelector("#sectioncolRight");

        if (colunaDireitaSecao && !colunaDireitaSecao.classList.contains("d-none")) {
            const imagem = colunaDireitaSecao.querySelector("img");
            return imagem as HTMLImageElement;
        }

        return null;
    }

    const getLayout = (secao: string): string => {
        const divAux = document.createElement("div");
        divAux.innerHTML = secao;

        const colunaEsquerdaSecao = divAux.querySelector("#sectionColLeft");
        const colunaDireitaSecao = divAux.querySelector("#sectioncolRight");
        if (!!colunaEsquerdaSecao) {
            if (colunaEsquerdaSecao.classList.contains("d-none")) {
                return "justText";
            }
        }
        if (!!colunaDireitaSecao) {
            if (colunaDireitaSecao.classList.contains("d-none")) {
                return "justImage";
            } else if (colunaDireitaSecao.classList.contains("w-75")) {
                return "imgLeft";
            } else {
                return "imgRight";
            }
        }

        return "justText";
    }

    const criarPdf = (apenasVisualizacao: boolean = true) => {
        let pdf: any = null;
        setPdfBlob(null);
        let respostas: RespostaType[] = getRespostas();

        let pdfSenhaConfiguracao = apenasVisualizacao ? {} : {
            userPassword: `${!!paciente?.dataNascimento ? new Date(paciente.dataNascimento as any).getFullYear() : new Date().getFullYear()}`,
            ownerPassword: `zs_8034_@`,
            permissions: {
                printing: "highResolution",
                modifying: false,
                copying: false,
                annotating: true,
                fillingForms: true,
                contentAccessibility: true,
                documentAssembly: true
            },
        }

        let definicaoDocumentoPDF: any = {
            ...pdfSenhaConfiguracao,
            pageSize: "A4",
            pageOrientation: "portrait",
            pageMargins: [
                marginLeft,
                headerHeight + (modelo?.margemSuperior || 0) + 45,
                marginRight,
                footerHeight + (modelo?.margemInferior || 0),
            ],
            info: {
                author: "Ilaudo - Zscan Software Lmtd.",
                subject: t("Laudo criado no app do ilaudo"),
                title: `${t("Laudo")}:  ${joinNames(paciente as PacienteInterface)}`,
            },
            header: function (currentPage: number, pageCount: number, pageSize: number) {
                return [

                    montarSecaoPDF(modelo?.cabecalho as any, {
                        margemEsquerda: marginLeft,
                        margemSuperior: marginTop,
                        margemDireita: marginRight,
                        margemInferior: 0
                    }, headerHeight),
                    montarInfoPDF(currentPage, pageCount)
                ];
            },
            footer: montarSecaoPDF(modelo?.rodape as any, {
                margemEsquerda: marginLeft,
                margemSuperior: 0,
                margemDireita: marginRight,
                margemInferior: marginBottom
            }, footerHeight),
            content: [
                {
                    bold: true,
                    fontSize: 14,
                    alignment: "center",
                    text: `${exame?.titulo}\n`,
                    margin: [5, 0, 0, 5]
                },
            ],
            styles: {
                icon: { font: "Fontello" }
            },
            background: [
                modelo?.mostrarAssinaturaEletronica ?
                    {
                        absolutePosition: { x: 10, y: 825 },
                        text: `${t('Laudo assinado por')} ${joinNames(medico as ProfissionalSaudeInterface)} ${t('em')} ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`,
                        fontSize: 9,
                        alignment: 'center'
                    } : {}
            ],
        }



        if (modelo?.layoutImagem.nome == "Apenas Campos" || !imagensSelecionadas.length) {
            definicaoDocumentoPDF.content.push(mountFieldsToPDF(respostas));
            definicaoDocumentoPDF.content.push(getSecaoAssinaturaDigital());

            definicaoDocumentoPDF = tratarTamanhosDeFonte(definicaoDocumentoPDF);

            pdf = pdfMake.createPdf(definicaoDocumentoPDF)

            if (apenasVisualizacao) {
                pdf.getBlob((PDFBlob: any) => {
                    setPdfBlob(PDFBlob);
                });

            } else {
                setBtnSalvarHabilitado(false);

                if (!medicoSolicitante) {
                    setMedicoSolicitante(medico);
                }

                salvarLaudo(pdf);
            }

        } else {
            setTimeout(async () => {
                await getImagensParaPDF()
                .then((imagesToPDF: Array<any>) => {
                    imagesToPDF.sort((imageA: any, imageB: any) => {
                        let ret = 0;

                        if (imageA.order < imageB.order) {
                            ret = -1;

                        } else {
                            if (imageA.order > imageB.order) {
                                ret = 1;
                            }
                        }

                        return ret;
                    });

                    if (modelo?.layoutImagem.posicao == "UP") {
                        let tableImages = mountImageColumns(imagesToPDF)

                        definicaoDocumentoPDF.content.push([tableImages, ...mountFieldsToPDF(respostas)]);
                        definicaoDocumentoPDF.content.push(getSecaoAssinaturaDigital());

                    } else {
                        let column: any = {
                            columns: []
                        }

                        if (modelo?.layoutImagem.posicao == "RIGHT") {
                            column.columns.push({
                                width: (PDF_WIDTH_SIZE - marginLeft - marginRight) * (2 / 3),
                                marginRight: 10,
                                alignment: "justify",
                                text: mountFieldsToPDF(respostas)
                            });

                            column.columns.push(imagesToPDF);

                            definicaoDocumentoPDF.content.push(column);
                            definicaoDocumentoPDF.content.push(getSecaoAssinaturaDigital());

                        } else {
                            if (modelo?.layoutImagem.posicao == "LEFT") {
                                column.columns.push(imagesToPDF);
                                column.columns.push({
                                    width: (PDF_WIDTH_SIZE - marginLeft - marginRight) * (2 / 3),
                                    marginLeft: 10,
                                    alignment: "justify",
                                    text: mountFieldsToPDF(respostas)
                                });

                                definicaoDocumentoPDF.content.push(column);
                                definicaoDocumentoPDF.content.push(getSecaoAssinaturaDigital());

                            } else {
                                let tableImages = mountImageColumns(imagesToPDF)

                                definicaoDocumentoPDF.content.push([...mountFieldsToPDF(respostas), tableImages]);
                                definicaoDocumentoPDF.content.push(getSecaoAssinaturaDigital());
                            }
                        }
                    }

                    definicaoDocumentoPDF = tratarTamanhosDeFonte(definicaoDocumentoPDF);

                    pdf = pdfMake.createPdf(definicaoDocumentoPDF)

                    if (apenasVisualizacao) {
                        pdf.getBlob((PDFBlob: any) => {
                            setPdfBlob(PDFBlob);
                        });

                        } else {
                            salvarLaudo(pdf);
                        }
                    })
            }, 1000)
        }
    }

    const tratarTamanhosDeFonte = (definicaoDocumentoPDF: any) => {
        return tratarTamanhoDeFonte(definicaoDocumentoPDF);
    }

    const tratarTamanhoDeFonte = (definicaoDocumentoPDF: any) => {
        if (!definicaoDocumentoPDF) return definicaoDocumentoPDF;
        if (definicaoDocumentoPDF instanceof Array) {
            for (const i in definicaoDocumentoPDF) {
                tratarTamanhoDeFonte(definicaoDocumentoPDF[i]);
            }
            return definicaoDocumentoPDF;
        }
        if (definicaoDocumentoPDF["fontSize"] && typeof definicaoDocumentoPDF["fontSize"] === "string") {
            definicaoDocumentoPDF["fontSize"] = parseFloat(definicaoDocumentoPDF["fontSize"].split(" ").join("."));
        }

        if ((typeof definicaoDocumentoPDF == "object") && (definicaoDocumentoPDF !== null)) {
            var children = Object.keys(definicaoDocumentoPDF);
            if (children.length > 0) {
                for (let y = 0; y < children.length; y++) {
                    tratarTamanhoDeFonte(definicaoDocumentoPDF[children[y]]);
                }
            }
        }
        return definicaoDocumentoPDF;
    }

    const sendEmail = (to: string, subject: string, message: string, pdfFormData: FormData, pdfName: string, pdfURL: string) => {

        const dataEmail = {
            to: to,
            type: "PDF",
            text: message,
            subject: subject,
            pdf: pdfURL,
            idioma: i18n.language
        };

        showLoading(dispatch);

        return new EmailService().enviarEmail(dataEmail)
            .then(() => {
                hideLoading(dispatch);
                return showSweetAlert(t("E-mail enviado com sucesso"), t("Clique em ok para continuar"), "success", "ok")
                    .then(() => {
                        return reportSaveActions(pdfURL, pdfFormData, pdfName)
                    })
            })
            .then(() => {
                localStorage.removeItem(`answer_${paciente?.idPaciente}_${exame?.idExame}`);
            })
            .catch((error: any) => {
                hideLoading(dispatch);
                console.error(error);

                return showSweetAlert(t("Erro ao enviar laudo"), error, "error", "ok");
            })
    }


    const sendReportLinkWhatsApp = () => {
        const message: string = getMessageToSendReportLink();
        const patientNumber: string = getPatientNumber();

        if (!!patientNumber) {
            const codigoDiscagem = endereco?.cidade.estado.pais.codigoDiscagem;

            const urlSendReportByWhatsApp: URL = new URL(`https://api.whatsapp.com/send?phone=${codigoDiscagem || '+55'}${patientNumber}&text=${encodeURIComponent(message)}`);

            window.open(urlSendReportByWhatsApp.toString(), "_blank");

        } else {
            Swal.fire({
                icon: "error",
                title: t("Erro ao enviar link do laudo"),
                text: t("Número de celular do paciente não encontrado"),
                confirmButtonText: t("Voltar ao laudo")
            })
        }

    }

    const sendReportLinkBySMS = () => {
        showLoading(dispatch)

        let patientNumber: string = getPatientNumber();

        if ((!!patientNumber && patientNumber != 'undefined') || !clinicaSelecionada.idContratoPrincipal) {

            // if(!endereco) {
            //     hideLoading(dispatch);
            //     Swal.fire({
            //         icon: "error",
            //         title: t("Erro ao enviar link do laudo"),
            //         text: t("Contato sem código DDI para envio de SMS"),
            //         confirmButtonText: t("Voltar ao laudo")
            //     });
            // }

            const codigoDiscagem = endereco?.cidade.estado.pais.codigoDiscagem;
            patientNumber = `${codigoDiscagem || '+55'}${patientNumber}`;

            const dados: SMSInterface = {
                idContrato: clinicaSelecionada.idContratoPrincipal,
                telefone: patientNumber,
                link: pdfURL,
                nomePaciente: paciente?.primeiroNome
            }

            new SMSService().enviaSMS(dados)
                .then(() => {
                    hideLoading(dispatch);

                    return showSweetAlert(t("SMS: link para acesso do laudo enviado com sucesso"), t("Clique em ok para continuar"), "success", "Voltar ao laudo")
                })
                .catch((error) => {
                    hideLoading(dispatch);

                    console.error(error);

                    return showSweetAlert(t("Erro ao enviar link do laudo por sms"), error.message, "error", "Voltar ao laudo");
                })

        } else {
            hideLoading(dispatch);
            Swal.fire({
                icon: "error",
                title: t("Erro ao enviar link do laudo"),
                text: t("Número de celular do paciente não encontrado"),
                confirmButtonText: t("Voltar ao laudo")
            })
        }

    }


    const getPatientNumber = (): string => {
        if (paciente?.contato) {
            return `${paciente.contato?.celular1 || paciente.contato?.celular2 || ""}`;
        }
        return `${paciente?.contato}`
    }

    const getMessageToSendReportLink = (): string => {
        const message: string = `${t("Olá")}, ${paciente?.primeiroNome}.\n\n\n` +
            `${t("Seu laudo está disponível")}. ${t("Data da elaboração")}: ${new Date().toLocaleDateString()}.\n\n` +
            `${t("Elaborado por:")} ${medico?.primeiroNome} ${medico?.ultimoNome}.\n\n` +
            `${t("Para acessá-lo, clique no link")}: ${pdfURL}\n\n` +
            `${t("Digite seu ano de nascimento para ver o conteúdo")}.\n\n`;


        return message;
    }

    const deleteImagesConfirmation = (imageClickIcon?: any): any => {

        Swal.fire({
            title: t("Você realmente deseja excluir images selecionadas?"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Sim"),
            cancelButtonText: t("Não"),
        }).then(async (result) => {
            let imageByIcon = imageClickIcon;

            if (result.value) {

                if (imageByIcon) {

                    if (imagensSelecionadas) {
                        const verififyImageOnReport = imagensSelecionadas.filter((imagesSelected) => imagesSelected.id === imageByIcon.id);
                        if (verififyImageOnReport.length > 0) {
                            let error = t("Ha Imagens Selecionadas no Laudo");

                            imagesSelecionadasParaExclusao.map((image: any) => {
                                const clearImagesExclusao: HTMLDivElement | any = document.getElementById(`card_image_${image.id}`);
                                clearImagesExclusao.style.border = "";
                                setImageSelecionadasParaExclusao([]);
                            })
                            hideLoading(dispatch);

                            return showSweetAlert(t("Erro ao deletar imagem"), error, "error", "ok");
                        }

                        if (imagensSelecionadasOnClick !== undefined && imagensSelecionadasOnClick.length !== 0) {
                            const getDivImageSelected: HTMLDivElement | any = document.getElementById(`card_image_${imagensSelecionadasOnClick.id}`);
                            getDivImageSelected.style.border = "";
                        }

                        if (imagesSelecionadasParaExclusao !== undefined && imagesSelecionadasParaExclusao.length !== 0) {
                            imagesSelecionadasParaExclusao.map((image: any) => {
                                const clearImagesExclusao: HTMLDivElement | any = document.getElementById(`card_image_${image.id}`);
                                clearImagesExclusao.style.border = "";
                            })
                        }

                        await new ImageService().deleteImage(imageByIcon.id);

                        setImageSelecionadasParaExclusao([]);
                        setImagensSelecionadasOnClick([]);
                        handleUpdateImages();
                        showLoading(dispatch);
                        return 

                    }
                }

                let image: any;
                showLoading(dispatch);

                for (image of imagesSelecionadasParaExclusao) {

                    if (imagensSelecionadas) {
                        const verififyImageOnReport = imagensSelecionadas.filter((imagesSelected: any) => imagesSelected.id === image.id);
                        if (verififyImageOnReport.length > 0) {
                            let error = t("Ha Imagens Selecionadas no Laudo");

                            imagesSelecionadasParaExclusao.map((image: any) => {
                                const clearImagesExclusao: HTMLDivElement | any = document.getElementById(`card_image_${image.id}`);
                                clearImagesExclusao.style.border = "";
                                setImageSelecionadasParaExclusao([]);
                            })
                            hideLoading(dispatch);

                            return showSweetAlert(t("Erro ao deletar imagem"), error, "error", "ok");
                        }
                        else {
                            const imageSendoExcluida: HTMLDivElement | any = document.getElementById(`card_image_${image.id}`);
                            imageSendoExcluida.style.border = "";
                            await new ImageService().deleteImage(image.id);
                        }

                    }



                }
                hideLoading(dispatch);
                setImageSelecionadasParaExclusao([]);
                handleUpdateImages();

            }

        })
    }


    const cancelReportHandler = () => {
        Swal.fire({
            title: !reportSaved ? t("Você realmente deseja sair do laudo?") : t("Você realmente deseja voltar para a listagem de laudos?"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Sim"),
            cancelButtonText: t("Não"),
        }).then((result) => {
            if (result.value) {
                resetReduxStates();
                props.history.push("/app");
            }
        })
    }

    const getRespostasLaudo = (): Array<RespostaInterface> => {
        let respostasCampos = [...valorCampos];
        const respostasLaudo: Array<RespostaInterface> = [];
        let conteudoRespostas = getRespostas()

        respostasCampos.forEach((respostaCampo) => {
            let html: string = "";
            let descricao = "";
            if (typeof respostaCampo.valor != "boolean") {
                let conteudoResposta = conteudoRespostas.find(res => res.idCampo === respostaCampo.idCampo)

                descricao = conteudoResposta?.texto || ''

                html = conteudoResposta?.html || ''
            } else {
                descricao = respostaCampo.valor.toString();
                html = respostaCampo.valor.toString();
            }

            respostasLaudo.push({
                idContrato: clinicaSelecionada.idContratoPrincipal as number,
                idCampo: respostaCampo.idCampo,
                descricao: descricao,
                htmlDescricao: html
            });
        })

        return respostasLaudo;
    }

    const getImagensLaudo = () => {
        const imagensLaudo: Array<{
            idLaudo?: number,
            idImagem?: number,
            ordem: number,
            idLegenda?: number,
            descricao?: string,
        }> = [];

        for (let index = 0; index < imagensSelecionadas.length; index++) {
            const imagemLaudo = {
                idImagem: imagensSelecionadas[index].id as number,
                ordem: index,
                idLegenda: imagensSelecionadas[index].legend ? (imagensSelecionadas[index].legend.idLegenda as number) : undefined,
                descricao: imagensSelecionadas[index].legend ? (imagensSelecionadas[index].legend.descricao as string) : undefined,
            }

            const imagem = imagensPaciente.find((imagemPaciente) => imagemPaciente.idImagem === imagemLaudo.idImagem);

            if (imagem) {
                delete imagem.ativo;
                new ImageService().atualizarImagem(imagemLaudo.idImagem, { ...imagem, idLegenda: imagemLaudo.idLegenda });
            }

            imagensLaudo.push(imagemLaudo);
        }

        return imagensLaudo;
    }

    // const mountAnswersToSave = (answers: Array<string>) => {
    //     let answersToSave: Array<ReportAnswerInterface> = [];

    //     answers.forEach((answer: string, index: number) => {
    //         answersToSave.push({
    //             order: index,
    //             answerText: answer,
    //             answerHtml: answer,
    //             fieldId: (modelo as ModeloInterface).campos[index].idCampo
    //         })
    //     })

    //     return answersToSave;
    // }

    const validarDadosLaudo = () => {
        if (!paciente) {
            message.warning(t(`${"Paciente não selecionado"}!`), { backgroundColor: "#fcfaac" });

            botaoPacienteViewSelectRef.current.focus();

            return false;
        }

        if (!medico) {
            message.warning(t(`${"Médico examinador não selecionado"}!`), { backgroundColor: "#fcfaac" });

            botaoMedicoViewSelectRef.current.focus();

            return false;
        }

        return true;
    }

    const salvarLaudo = (pdf: any) => {
        if (validarDadosLaudo()) {
            setBtnSalvarHabilitado(false);

            showLoading(dispatch, `${t("Aguarde um momento enquanto seu laudo é salvo")}...`);

            disableFieldsControlReports();

            pdf.getBlob(async (PDFBlob: any) => {
                try {
                    setPdfBlob(PDFBlob);

                    // let technician: { id: number } | null = modelo?.mostrarTecnico && atendimento.technician ? { id: atendimento.technician.id as number } : null;
                    // let anesthetist: { id: number } | null = modelo?.mostrarAnestesista && atendimento.anesthetist ? { id: atendimento.anesthetist.id as number } : null;
                    // let assistant: { id: number } | null = modelo?.mostrarAssistente && atendimento.assistant ? { id: atendimento.assistant.id as number } : null;
                    const laudoAux: LaudoInterface = {
                        idContrato: clinicaSelecionada.idContratoPrincipal as number,
                        idExame: exame?.idExame as number,
                        idPaciente: paciente?.idPaciente as number,
                        idMedico: medico?.idProfissionalSaude as number,
                        idDispositivo: imagensPaciente.length > 0 ? imagensPaciente[0].idDispositivo : 0,
                        nomeDispositivo: imagensPaciente.length > 0 ? imagensPaciente[0].nomeDispositivo : '',
                        idAtendimento: atendimento.idAtendimento,
                        respostas: getRespostasLaudo(),
                        imagens: getImagensLaudo(),
                        fechado: true,
                        idLaudoSubstituto: laudo ? laudo.idLaudo : 0,
                        copia: laudo ? laudo.copia + 1 : 0,
                        serialPrincipal: clinicaSelecionada.serialPrincipal
                    }
                    const { nomePdf, caminhoPdf } = await new LaudoService().inserir(laudoAux, PDFBlob);

                    setPdfURL(caminhoPdf);

                    if (atendimento) {
                        const atendimentoAux: AtendimentoInterface = {
                            ...atendimento,
                            dataFimAtendimento: new Date(),
                            estado: 2
                        };

                        await new AtendimentoService().atualizar(atendimento.idAtendimento, atendimentoAux);
                    }

                    let pdfFormData = new FormData();
                    pdfFormData.set("file", PDFBlob);

                    setReportSavedData(laudo);
                    setPdfURL(caminhoPdf);
                    setReportSaved(true);
                    hideLoading(dispatch);

                    return reportSaveActions(caminhoPdf, pdfFormData, nomePdf)
                        .then(() => {
                            localStorage.removeItem(`answer_${paciente?.idPaciente}_${exame?.idExame}`);
                        })
                } catch (error: any) {

                    setBtnSalvarHabilitado(true);

                    hideLoading(dispatch);

                    Swal.fire({
                        title: t("Erro ao salvar laudo"),
                        text: error,
                        icon: "error",
                        confirmButtonText: "Ok",
                    })
                        .then(() => {
                            disableFieldsControlReports(false);
                        })
                }
            })
        }
    }

    const reportSaveActions = (pdfURL: string, pdfFormData: FormData, pdfName: string) => {
        const htmlSendByEmail: string = `
            <div class="row form-group">
                <div class="col text-center text-danger">
                    ${t("A senha para acesso ao pdf é o ano de nascimento do paciente")}.
                </div>
            </div>
    
            <div class="row form-group">
                <div class="col input-group">
                    <input class="form-control text-center small" id="foo" value="${pdfURL}">
                </div>
            </div>
    
            <div class="row form-group">
                <div class="col-6 input-group p-1 text-left">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="fas fa-envelope-open-text" aria-hidden="true"></i>
                            </span>
                        </div>
                    
                    <button class="form-control btn btn-primary rounded-0" type="button" style="border: 1px solid #d1d3e2;" data-toggle="collapse" data-target="#collapseSendReportViaEmail" aria-expanded="false" aria-controls="collapseSendReportViaEmail">
                        ${t("Enviar por email")}
                    </button>
                </div>
    
                <div class="col-6 input-group p-1" text-right>
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="far fa-copy" aria-hidden="true"></i>
                        </span>
                    </div>
                    <button class="form-control btn btn-light" type="button" id="buttonClipboardId" style="border: 1px solid #d1d3e2;" data-clipboard-target="#foo">
                        ${t("Copiar link")}
                    </button>
                </div>
                <span class="pl-2 mt-2">
                <!-- <div class="btn-group h-100" role="group">
                    <div class="">
                        <button class="btn btn-sm btn-outline-info" onclick="${() => sendReportLinkWhatsApp()}">
                            <i class="fab fa-whatsapp mr-2" aria-hidden="true"></i>
                            ${t("Enviar link do laudo por whatsapp")}
                        </button>

                        <button class="btn btn-sm btn-outline-primary mt-2" id="reportButtonSendSms" onclick="sendReportLinkBySMS()">
                            <i class="fa fa-comments mr-2" aria-hidden="true"></i>
                            ${t("Enviar link do laudo por SMS")}
                        </button> -->
                    </div>
                </div>
            </span>

            </div>
            
    
            <div class="collapse" id="collapseSendReportViaEmail">
                <div class="card card-body">
                    <div class="row form-group">
                        <div class="col input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fas fa-at" aria-hidden="true"></i>
                                </span>
                            </div>

                            <input class="form-control text-justify" disabled value="noreply@zscansoftware.com" />
                        </div>                                
                    </div>

                    <div class="row form-group">
                        <div class="col input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fas fa-at" aria-hidden="true"></i>
                                </span>
                            </div>
    
                            <input class="form-control text-justify" placeholder="${t("Para")}: " id="inputEmailToSendId" value="">
                        </div>                                
                    </div>

                    <div class="row form-group">
                        <div class="col input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fas fa-inbox" aria-hidden="true"></i>
                                </span>
                            </div>
    
                            <input class="form-control text-justify" placeholder="${t("Assunto")}: " id="inputSubjectOfEmailId" value="">
                        </div>                                
                    </div>

                    <div class="row form-group">
                        <div class="col input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="far fa-comment-dots" aria-hidden="true"></i>
                                </span>
                            </div>
    
                            <textarea class="form-control text-justify" placeholder="${t("Mensagem")}..." id="inputMessageOfEmailId" style="height: auto" value="" rows="4" cols="50"></textarea>
                        </div>                                
                    </div>
    
                    <div class="row form-group">
                        <div class="col input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fas fa-paper-plane" aria-hidden="true"></i>
                                </span>
                            </div>
                            
                            <button class="form-control btn btn-success btn-sm rounded-0" type="button" id="buttonSendReportViaEmailId">
                                ${t("Enviar")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>`;



        return Swal.fire({
            title: t("Laudo salvo com sucesso"),
            icon: "success",
            showCloseButton: true,
            html: htmlSendByEmail,
            onOpen: () => {
                let inputEmailToSend: HTMLInputElement = document.querySelector("#inputEmailToSendId") as HTMLInputElement;
                let inputSubjectOfEmail: HTMLInputElement = document.querySelector("#inputSubjectOfEmailId") as HTMLInputElement;
                let inputMessageOfEmail: HTMLTextAreaElement = document.querySelector("#inputMessageOfEmailId") as HTMLTextAreaElement;
                let buttonSendReportViaEmail: HTMLButtonElement = document.querySelector("#buttonSendReportViaEmailId") as HTMLButtonElement;

                buttonSendReportViaEmail.addEventListener("click", (event: Event) => {
                    event.preventDefault();

                    if (!!inputEmailToSend.value) {
                        showLoading(dispatch);
                        buttonSendReportViaEmail.disabled = true;
                        sendEmail(inputEmailToSend.value, inputSubjectOfEmail.value, inputMessageOfEmail.value, pdfFormData, pdfName, pdfURL);
                    } else {
                        Swal.showValidationMessage(
                            t(`${"O email precisa de um destinatário"}!`)
                        );
                        inputEmailToSend.focus();
                    }
                })
            }
        })
    }

    const onClickExportPdf = (event: any) => {
        if (!reportSaved) {
            event.preventDefault();

            Swal.fire({
                title: t("Exportar PDF"),
                text: t("Para exportar o PDF é necessário salvá-lo! Desejar salvar o laudo?"),
                icon: "error",
                showCancelButton: true,
                confirmButtonText: t("Sim"),
                cancelButtonText: `${t("Não! voltar ao laudo")}.`,
            })
                .then((resp) => {
                    if (resp.value) {
                        criarPdf(false);
                    }
                })
        }
    }

    const handleUpdateImages = () => {
        //  showLoading(dispatch, `${t("Aguarde um momento enquanto as imagens são atualizadas")}...`);
        if (!!paciente) {
            //      showLoading(dispatch);
            getPatientImages(paciente.idPaciente)
                .then(() => {
                    //         hideLoading(dispatch);
                })
        }
    }

    const emailSharedComponent = () => {
        let modal;

        setTimeout(() => {
            modal = <ModalSendEmail file={pdfBlob} reportData={reportSavedData} pdfCaminho={pdfURL} />
        }, 150)
        
        return modal; 
    }
    
    setHeaderTitle(t("Laudos"), dispatch);

    return (
        <PageContainer>
            {
                (!atendimento) ?
                    <p className="text-center text-muted display-4 py-5 mx-3 bg-white shadow"> {t("Informações de laudo não encontradas")} </p> :
                    <div className="card border-0">

                        <div className="card-header p-2 bg-white fixed-top border-0">
                            <div className="row m-0 p-0">
                                <div className="col-4 col-lg-3 p-0">
                                    <div className="form-group m-0 p-0">
                                        <span>
                                            <button className="btn btn-sm btn-dark ml-1" type="button" id="buttonCancelReportId" onClick={(event) => { cancelReportHandler() }}>
                                                <i className="fas fa-arrow-left mr-1" aria-hidden="true"></i>
                                                {t("Voltar")}
                                            </button>
                                        </span>
                                        <span className="pl-2">
                                            <button className="btn btn-sm btn-success" disabled={!btnSalvarHabilitado} type="button" id="buttonSaveReportId" onClick={(event) => { criarPdf(false); }}>
                                                <i className="fas fa-save mr-1" aria-hidden="true"></i>
                                                {t("Salvar")}
                                            </button>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-8 col-lg-9 pr-0 text-right" id="divHeaderButtonControlReportId">
                                    <div className="form-group d-flex m-0 p-0 justify-content-end">
                                        <span className="pl-2">
                                            <button className="btn btn-sm btn-outline-dark" type="button" data-toggle="modal" data-target="#saveReportAsfavoriteReportsModal">
                                                <i className="fas fa-file-alt mr-1" aria-hidden="true"></i>
                                                {t("Salvar como favorito")}
                                            </button>
                                        </span>
                                        <span className="pl-2">
                                            <button className="btn btn-sm btn-outline-dark" type="button" data-toggle="modal" data-target="#favoriteReportsModal" onClick={() => {
                                                setFavoriteReportModalOpen(dispatch);
                                            }}>
                                                <i className="fa fa-star mr-1" aria-hidden="true"></i>
                                                {t("Abrir Favoritos")}
                                            </button>
                                        </span>
                                        <span className="pl-2">
                                            <button className="btn btn-sm btn-outline-primary" type="button" data-toggle="modal" data-target="#previewReportPDFModalId" onClick={(event) => { criarPdf(); }}>
                                                <i className="bi bi-eye mr-1" aria-hidden="true"></i>
                                                {t("Visualizar")}
                                            </button>
                                        </span>

                                        <span className="pl-2">
                                            <a className="btn btn-sm btn-outline-danger" href={!!pdfBlob ? URL.createObjectURL(pdfBlob) : ""} target={"_blank"} download={!!paciente ? `${paciente.primeiroNome}_${paciente.ultimoNome}_${t("laudo")}_${new Date().toJSON().slice(0, 10)}` : ""} onClick={onClickExportPdf} >
                                                <i className="bi bi-file-pdf text-capitalize" aria-hidden="true"></i>
                                                {t("Exportar PDF")}
                                            </a>
                                        </span>

                                        {
                                            reportSaved && pdfURL &&
                                            <span className="pl-2">
                                                <div className="btn-group h-100" role="group">
                                                    <button type="button" id="reportButtonShareId" className="btn btn-sm btn-outline-dark dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fas fa-share-alt mr-1" aria-hidden="true"></i>
                                                        {t("Compartilhar")}
                                                    </button>

                                                    <div className="dropdown-menu" aria-labelledby="reportButtonShareId">
                                                        <button className="btn btn-sm btn-light dropdown-item" data-toggle="modal" data-target="#sendEmailModal" onClick={() => setModalEmailVisible(true)}>
                                                            <i className="fas fa-envelope mr-2" aria-hidden="true"></i>
                                                            {t("Enviar link do laudo por e-mail")}
                                                        </button>

                                                        <button className="btn btn-sm btn-light dropdown-item" onClick={() => sendReportLinkWhatsApp()} disabled={!isContatoPaciente}>
                                                            <i className="fab fa-whatsapp mr-2" aria-hidden="true"></i>
                                                            {t("Enviar link do laudo por whatsapp")}
                                                        </button>

                                                        <button className="btn btn-sm btn-light dropdown-item" id="reportButtonSendSms" onClick={() => sendReportLinkBySMS()} disabled={!isContatoPaciente}>
                                                            <i className="fa fa-comments mr-2" aria-hidden="true"></i>
                                                            {t("Enviar link do laudo por SMS")}
                                                        </button>
                                                    </div>
                                                    {isModalEmailVisible &&
                                                        emailSharedComponent()
                                                    }
                                                </div>
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-body p-2">
                            <div className="row">
                                {
                                    ReportLeftSideInfo()
                                }

                                {
                                    ReportPaper()
                                }
                                {
                                    !!modelo &&
                                    <ModalRespostasPredefinidas
                                        campo={campoSelecionado}
                                        respostasPredefinidas={predefinitionAnswers}
                                        maisUtilizados={respostaPreDefinidasMaisUtilizadas}
                                        enablePreviousButton={enablePreviousButton}
                                        enableForwardButton={enableForwardButton}
                                        callbackPredefinitionAnswerToField={setPredefAnswerToField}
                                        callbackUpdatePredefinitionAnswers={setPredefinitionAnswers}
                                        // callbackSearchPrevious={searchPreviousAnswers}
                                        // callbackSearchForward={searchForwardAnswers}
                                        callbackButtonSearch={atualizaRespostasPredefinidas}
                                    />
                                }
                                {
                                    !!exame && !!legendas &&
                                    <ModalLegenda
                                        legendas={legendas}
                                        exames={exames}
                                        exameSelecionado={exame}
                                        enablePreviousButton={enablePreviousButton}
                                        enableForwardButton={enableForwardButton}
                                        callbackLegendaParaImagem={setLegendaParaImagem}
                                        callbackSetLegendas={setLegendas}
                                        // callbackSearchPrevious={searchPreviousLegends}
                                        // callbackSearchForward={searchForwardLegends}
                                        callbackButtonSearch={getLegendas}
                                    />}
                                {
                                    !!exame &&
                                    <ModalLaudosFavoritos
                                        callbackSelectFavoriteReport={setFavoriteReportToReport}
                                        idExame={exame.idExame}
                                    />
                                }
                                {
                                    <ImageEditor />
                                }

                                {
                                    ModalSaveReportAsFavoriteReport()
                                }

                                {
                                    modalImageView()
                                }

                                {
                                    reportSaved && reportSavedData && <ModalSendEmail file={pdfBlob} reportData={reportSavedData} pdfCaminho={pdfURL} />
                                }
                                <PdfModalView urlReport={!!pdfBlob ? URL.createObjectURL(pdfBlob) : ""} printPDF={false} setPrintPDF={() => { }} idModal={"previewReportPDFModalId"} titleModalElement={t("Visualizador de PDF")} />
                            </div>
                        </div>
                    </div>
            }

            <button ref={botaoExameViewSelectRef} type="button" className="form-control btn btn-light border text-left d-none" title={t("Exame")} data-toggle="modal" data-target="#modalViewSelectExamViewReportCreateId" data-backdrop="static" data-keyboard="false" value={`${!!exame ? exame.idExame : -1}`}> {!!exame ? exame.nome : t("Exame")}</button>

            <ReportViewSelectElements
                examViewModalId={"modalViewSelectExamViewReportCreate"}
                patientViewModalId={"modalViewSelectPatientViewReportCreate"}
                physicianViewModalId={"modalViewSelectPhysicianViewReportCreate"}
                referringPhysicianViewModalId={"modalViewSelectReferringPhysicianViewReportCreate"}
                profissionaisSaude={profissionaisSaude}
                examShowButtonPrevious={!paciente}
                patientShowButtonPrevious={!paciente}
                examViewOnClickButtonPrevious={examClickBackButtonHandler}
                patientViewOnClickButtonPrevious={patientClickBackButtonHandler}
            />

        </PageContainer>
    );
}

export default Report;