import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Elements
import { ImageEditorImage } from './image-editor-image-component';
import { ImageEditorFabric } from './image-editor-fabric-component';
import ImageEditorCropperTools from './image-editor-cropper-tools-component';
import { ImageEditorDrawModal } from '../elements/image-editor-brush-modal-element';
import { ImageEditorDrawArrowModal } from '../elements/image-editor-draw-arrow-modal-element';
import { ImageEditorRichTextModal } from '../elements/image-editor-rich-text-modal-element';

// Intefaces 
import { ApplicationState } from '../../store';
import { HistoryEditorState, CropperImageState } from '../../store/datatypesInterfaces/dataTypeInterfaces';


// States/Redux
import {
    setCropping,
    cleanHistory,
    setImageData,
    setIsSaved,
    clearRedoHistory,
    pushUndoHistory,
    setBtnRedoDisable,
    setBtnUndoDisable,
    setBtnResetDisable,
    popRedoHistory,
    popUndoHistory,
    pushRedoHistory,
    setDataBeforeCropper,
    setImageBeforeCropper,
    showModalApplyClipping,
    hideModalApplyClipping,
    setImageDataTop,
    setImageDataLeft,
    setImageUrl,
    setResetFabricEditing,
    setUndoFabric,
    setRedoFabric,
} from '../../store/actions/imageEditor';


const ImageEditorContent = () => {
    const {
        originalImgUrl,
        cropper,
        imageData,
        isSaved,
        btnRedoDisable,
        btnUndoDisable,
        btnResetDisable,
        redo,
        undo,
        modalClippingEnable,
        dataBeforeCropper,
        imageDataLeft,
        imageDataTop,
        imageBeforeCropper,
        isFabricEdition
    } = useSelector((state: ApplicationState) => state.imageEditor);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (!undo.length) {
            setBtnUndoDisable(true, dispatch);
        } else {
            setBtnUndoDisable(false, dispatch);
            setBtnResetDisable(false, dispatch);
        }

        if (!redo.length) {
            setBtnRedoDisable(true, dispatch);
        } else {
            setBtnRedoDisable(false, dispatch);
        }
    }, [undo, redo]);

    const handleButtonUndo = () => {
        clickBtnUndo();
    }

    const handleButtonRedo = () => {
        clickBtnRedo();
    }

    const handleButtonReset = () => {
        clickBtnReset();
    }

    const clickBtnUndo = () => {

        const undoHistory = undo[undo.length - 1] as HistoryEditorState;

        if (undoHistory.component === "cropper") {
            popUndoHistory(dispatch);

            undoCropper(undoHistory);
            _popHistory(undoHistory);

        } else {
            if (undoHistory.component === "fabric") {
                setUndoFabric(true, dispatch);
            }
        }

    };

    const clickBtnRedo = () => {
        const redoHistory = redo[redo.length - 1] as HistoryEditorState;

        if (redoHistory.component === "cropper") {
            popRedoHistory(dispatch);
            redoCropper(redoHistory);
            _pushHistory(redoHistory, true);

        } else {
            if (redoHistory.component === "fabric") {
                setRedoFabric(true, dispatch);
            }
        }
    }

    const clickBtnReset = () => {
        setIsSaved(true, dispatch);

        if (isFabricEdition) {
            setResetFabricEditing(true, dispatch);

        } else {
            if (cropper && cropper.current) {
                setImage(originalImgUrl);
                setImageUrl(originalImgUrl, dispatch);
                cropper.current.reset();
            }
        }
        cleanHistory(dispatch);
    }

    const undoCropper = (history: HistoryEditorState) => {
        const image: HTMLImageElement = document.getElementById('img') as HTMLImageElement;

        if (cropper && cropper.current) {
            let obj = history.obj;
            if (obj.action === "move") {
                cropper.current.move(-(obj.x as number), -(obj.y as number))
            }
            else if (obj.action === "reflect") {
                if (obj.orientation === "h") {
                    cropper.current.scale(-(obj.x as number), obj.y);
                }
                if (obj.orientation === "v") {
                    cropper.current.scale(obj.x as number, -(obj.y as number));
                }
            }
            else if (obj.action === "rotate") {
                cropper.current.rotate(-(obj.rotate));
            }
            else if (obj.action === "cropp") {
                setImage(obj.img);
                setImageUrl(obj.img, dispatch);
                cropper.current.setData(obj.data);

                image.onload = function () {
                    setTimeout(() => {
                        if (cropper && cropper.current) {
                            cropper.current.moveTo(obj.data.left, obj.data.top);
                            cropper.current.rotateTo(obj.data.rotate);
                            cropper.current.scale(obj.data.scaleX, obj.data.scaleY);
                        }
                        const imageDataAux = imageData;
                        imageDataAux.top = obj.data.top;
                        imageDataAux.left = obj.data.left;
                        imageDataAux.scaleX = obj.data.scaleX;
                        imageDataAux.scaleY = obj.data.scaleY;
                        imageDataAux.rotate = obj.data.rotate;
                        setImageData(imageDataAux, dispatch);
                        // TODO
                        // self.showImageInfo();
                        image.onload = null;
                    }, 100)
                }
            }
        }
    }

    const redoCropper = (history: HistoryEditorState) => {
        if (cropper && cropper.current) {
            let obj = history.obj;
            if (obj.action === "move") {
                cropper.current.move(obj.x as number, obj.y)
            }
            else if (obj.action === "reflect") {
                if (obj.orientation === "h") {
                    cropper.current.scale(obj.x as number, obj.y);
                }
                if (obj.orientation === "v") {
                    cropper.current.scale(obj.x as number, obj.y);
                }
            }
            else if (obj.action === "rotate") {
                cropper.current.rotate(obj.rotate);
            }
            else if (obj.action === "cropp") {
                setImage(obj.img);
                setImageUrl(obj.img, dispatch);
                cropper.current.setData(obj.data);
                const imageDataAux = imageData;
                imageDataAux.top = obj.data.top;
                imageDataAux.left = obj.data.left;
                imageDataAux.scaleX = obj.data.scaleX;
                imageDataAux.scaleY = obj.data.scaleY;
                imageDataAux.rotate = obj.data.rotate;

                setImageData(imageDataAux, dispatch);
            }
        }
    }



    const setImage = (url: string, hasSameSize: boolean = false) => {
        if (cropper && cropper.current) {
            cropper.current.replace(url, hasSameSize)
        }
    }

    const showHideModalApply = (show?: boolean) => {
        if (show) {
            showModalApplyClipping(dispatch);
        } else {
            hideModalApplyClipping(dispatch);
        }
    }

    const handleApplyClipping = () => {
        applyClipping();
    }

    const handleCancelClipping = () => {
        stopCropp();
    }

    const stopCropp = () => {
        if (cropper && cropper.current) {
            setCropping(false, dispatch);
            setDataBeforeCropper(null, dispatch);
            setImageBeforeCropper(null, dispatch)
            cropper.current.setDragMode("none");
            cropper.current.clear();
            showHideModalApply();
        }
    }

    const applyClipping = () => {
        if (cropper && cropper.current) {
            cropper.current.getCroppedCanvas().toBlob((blob: any) => {
                let objetoURL = window.URL.createObjectURL(blob);
                const dataBeforeCropperAux = dataBeforeCropper as CropperImageState;
                dataBeforeCropperAux.top = imageDataTop;
                dataBeforeCropperAux.left = imageDataLeft;
                _pushHistory({
                    obj: {
                        id: -1,
                        action: "cropp",
                        dataBefore: dataBeforeCropperAux,
                        imgBefore: imageBeforeCropper,
                        img: objetoURL,
                        data: (cropper.current as Cropper).getImageData()
                    },
                    component: "cropper"
                })

                setCropping(false, dispatch);
                showHideModalApply();
                (cropper.current as Cropper).setDragMode("none");
                setImageDataTop(0, dispatch);
                setImageDataLeft(0, dispatch);
                //TODO
                // this.showImageInfo();
                setImage(objetoURL);
                setImageUrl(objetoURL, dispatch);
            });
        }
    }

    const _pushHistory = (history: HistoryEditorState, isRedo?: boolean) => {
        setIsSaved(false, dispatch);
        if (!isRedo) {
            clearRedoHistory(dispatch);
            setBtnRedoDisable(true, dispatch);
        }
        pushUndoHistory(history, dispatch);

        if (btnUndoDisable && undo.length) {
            setBtnUndoDisable(false, dispatch);
            setBtnResetDisable(false, dispatch);
        }
    }

    const _popHistory = (undo: HistoryEditorState) => {
        setIsSaved(false, dispatch);

        pushRedoHistory(undo, dispatch);


        if (btnRedoDisable && redo.length) {
            setBtnRedoDisable(false, dispatch);
        }
    }

    return (
        <div className="card-body p-0 position-relative">
            <div className="row m-0 position-absolute h-100 w-100">
                <div className="col-10 p-0 h-100 text-center bg-black d-flex justify-content-center position-relative" style={{ overflow: "none", zIndex: 99 }}>
                    <div id="divImageExibition" className="position-absolute w-100 h-100 border border-info">
                        <ImageEditorImage />
                    </div>

                    {/* Mudar para modal todo */}
                    <div className={`position-absolute bg-light w-25 boder text-center rounded ${modalClippingEnable ? "" : "d-none"}`} style={{ bottom: 0 }} id="div-apply-clipping">
                        <button className="btn btn-success btn-sm mr-2 m-1" id="btn-apply-clipping" onClick={handleApplyClipping}>
                            <i className="mr-2 fa fa-check"></i>
                            {t("Aplicar recorte")}
                        </button>
                        <button className="btn btn-danger btn-sm mr-2 m-1" id="btn-cancel-clipping" onClick={handleCancelClipping}>
                            <i className="mr-2 fa fa-times"></i>
                            {t("Cancelar recorte")}
                        </button>
                    </div>

                    <ImageEditorDrawModal />
                    <ImageEditorDrawArrowModal />
                    <ImageEditorRichTextModal />
                </div>

                <div className="col-2 p-1 h-100" style={{ overflowY: "auto", zIndex: 99998 }}>
                    <div className="row m-2">
                        <button className="btn btn-outline-light bg-dark border-0 ml-4" title={t("Desfazer")} id="btn-undo" disabled={btnUndoDisable}  onClick={handleButtonUndo}>
                            <i className="fa fa-reply"></i>
                        </button>
                        <button className="btn btn-outline-light bg-dark border-0" title={t("Refazer")} id="btn-redo" disabled={btnRedoDisable}  onClick={handleButtonRedo}>
                            <i className="fa fa-share"></i>
                        </button>
                        <button className="btn btn-outline-light bg-dark border-0" title={t("Redefinir")} id="btn-reset" disabled={btnResetDisable}  onClick={handleButtonReset}>
                            <i className="mr-2 fa fa-window-close"></i>
                            {t("Redefinir")}
                        </button>
                        <ImageEditorCropperTools />

                        <ImageEditorFabric />
                    </div>
                </div>
            </div>
        </div>
    )
}

const style = {
    button: {
        backgroundColor: 'rgb(221,221,221)'
    }
}

export default ImageEditorContent;