import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'

import CardSelectLang from '../../app/elements/card-select-lang';
import i18next from '../../app/utils/i18nextTranslation';
import ilaudoLogo from '../../assets/icons/logo128.png';
import background from '../../assets/images/background.png';
import zscanLogo from '../../assets/svg/logo-zscan.svg';
import { AuthService } from '../../service/auth.service';
import { ApplicationState } from '../../store';
import { setClinicaSelecionada, setUserDoctor } from '../../store/actions/app';
import { hideLoading, showLoading } from '../../store/actions/loading';
import { setLogout } from '../../store/actions/logout';
import { sendForgetPassword } from '../services/api-service';

const LoginPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const { usuario } = useSelector((state: ApplicationState) => state.app);

    const [submitEnabled, setSubmitEnabled] = useState(true);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [cardIdioma, setCardIdioma] = useState(false)
    const [idioma, setIdioma] = useState('');

    useEffect(() => {
        if (usuario && usuario.token) {
            autenticacaoConcluida();
        }

    }, [usuario]);

    useEffect(() => {
        if (!idioma) {
            setIdioma(i18next.language);
        }
    });

    const autenticacaoConcluida = () => {
        const licencasILaudo = getLicencasILaudo(usuario?.contratos as Array<any>);
        
        if (licencasILaudo?.length) {
            setClinicaSelecionada(licencasILaudo[0], dispatch);
            hideLoading(dispatch);
            history.push('/app')
        } else {
            setLogout(true, dispatch);
            history.replace("/login");
        }

    }

    const getLicencasILaudo = (licencas: Array<any>) => {
        let licencaIlaudo = licencas.filter((licenca) => licenca.produto.idProdutoLegado === 'iLaudo2');

        if(!licencaIlaudo.length) {
            hideLoading(dispatch);
            Swal.fire({
                title: t('Atenção'),
                text: t('Você não possui licenças ativas para edição de laudo online! Por favor entrem em contato com o suporte.'),
                icon: 'warning',
                confirmButtonText: 'Ok'
            });

            setLogout(true, dispatch);

            return;
        }

        let produtoPrincipal = licencas.filter((licenca) => licenca.idContrato === licencaIlaudo[0].idContratoPrincipal)
        const serialPrincipal = produtoPrincipal[0]?.serialPrincipal;
        
        licencaIlaudo[0].serialPrincipal = serialPrincipal;

        return licencaIlaudo;
    }

    const handlerEsqueceuSenha = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const alertConfig: any = {
            title: t('Informe o endereço de email'),
            input: 'email',
            inputPlaceholder: t('Endereço de email'),
            inputAttributes: { autocapitalize: 'off' },
            confirmButtonText: t('Enviar'),
            showLoaderOnConfirm: true,
            showCloseButton: true,
            preConfirm: (email: string) => {
                return sendForgetPassword(email)
                    .then(response => {
                        if (!response) {
                            throw new Error(`${t('Email não encontrado')}!`);
                        }
                        return Swal.fire({
                            icon: 'info',
                            title: t('Sucesso'),
                            text: t('Em instantes você receberá um email com os dados necessários para acessar o sistema'),
                        });
                    })
                    .catch((err: Error) => {
                        console.log(err);
                        hideLoading(dispatch);
                        return Swal.fire({
                            icon: 'warning',
                            title: t('Alerta'),
                            text: t('Não foi encontrado o email informado!'),
                        });
                    });
            },
            allowOutsideClick: () => !Swal.isLoading()
        };
        return Swal
            .fire(alertConfig)
            .then(() => {
                return true;
            });
    };

    const handlerLogin = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmitEnabled(false);
        if (submitEnabled) {
            if (!username || !password) {
                hideLoading(dispatch);
                Swal.fire({
                    title: t('Atenção'),
                    text: t('Você precisa informar seu email e senha para fazer login'),
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                })
                setSubmitEnabled(true);
                return;
            }

            new AuthService().login(username, password)
                .then((user: any) => {
                    if (user.erro) {
                        if (user.erro.response.status === 401) {
                            hideLoading(dispatch);
                            Swal.fire({
                                title: t('Atenção'),
                                text: t('O endereço de email ou a senha que você inseriu não é válido'),
                                icon: 'warning',
                                confirmButtonText: 'Ok'
                            })
                            setSubmitEnabled(true);
                            return;
                        }
                        Swal.fire({
                            title: t('Atenção'),
                            text: t('Ocorreu um erro interno'),
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        })
                        setSubmitEnabled(true);
                        return;
                    }
                    setUserDoctor(user, dispatch);
                })
                .catch((err) => {
                    console.info("Not Logged user");
                })
        }
    }

    const handleSelectLang = (l: string) => {
        setCardIdioma(false);
        setIdioma(l);
        i18next.changeLanguage(l, (err: Error) => { if (!!err) console.error(err) });
    }

    return (
        <div className="selectLanguage container-fluid position-absolute h-100 d-flex flex-column align-items-center justify-content-center p-0" style={{ backgroundImage: `url(${background})`, backgroundSize: "cover" }}>
            <div className="row w-75 position-relative">
                <div className="col-xs-10 col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                    <div className="card border-0 shadow m-0" style={{ borderRadius: 10 }}>
                        <div className="card-body px-3 shadow-lg">

                            <div className="text-center d-flex flex-column justify-content-center align-items-center mt-0 mb-4">
                                <img src={ilaudoLogo} width="48" alt="" />
                                <p className="h3 my-0 py-0  text-muted">{t("iLaudo Editor")}</p>
                            </div>

                            <form className="user" onSubmit={handlerLogin}>
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text border-0 bg-white">
                                                <i className="bi bi-person"></i>
                                            </span>
                                        </div>
                                        <input type="email" disabled={!submitEnabled} className="form-control form-control-user border-top-0 border-left-0 border-right-0 bg-white mb-2 mt-2 p-2" style={{ fontSize: 14 }} onChange={(e: any) => { setUsername(e.target.value) }} placeholder={`${t("Insira seu email")}...`} autoFocus={true} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text border-0 bg-white">
                                                <i className="bi bi-key"></i>
                                            </span>
                                        </div>
                                        <input type="password" disabled={!submitEnabled} className="form-control form-control-user border-top-0 border-left-0 border-right-0 bg-white mb-2 mt-2 p-2" style={{ fontSize: 14 }}  onChange={(e: any) => { setPassword(e.target.value) }} placeholder={t("Senha")} />
                                    </div>
                                </div>
                                <div className="text-center">
                                <button style={{ fontSize: 13 }} type="button" className="btn btn-link small text-warning float-right mb-4" onClick={handlerEsqueceuSenha} disabled={!submitEnabled}>
                                    {t("Esqueceu a senha?")}
                                </button>
                                </div>
                                <button type="submit" className="btn bg-primary btn-block text-white" onClick={(event) => {
                                        showLoading(dispatch);
                                         }} style={{ borderRadius: 100, fontSize: 14 }} disabled={!submitEnabled}> 
                                    <i className="bi bi-box-arrow-in-right mr-3"></i> {t("Entrar")}
                                </button>
                                <br></br>
                                {/* <button style={{ fontSize: 11 }} type="button" className="btn rounded btn-danger btn-google" onClick={handlerLoginWithGoogle} disabled={!submitEnabled}>
                                    <i className="fab fa-google fa-fw"></i> {t("Logar com o Google")}
                                </button>
                                <button style={{ backgroundColor: "#1b68b6", color: "white", fontSize: 11 }} type="button" className="btn rounded btn-facebook float-right" onClick={handlerLoginWithFacebook} disabled={!submitEnabled}>
                                    <i className="fab fa-facebook-f fa-fw"></i> {t("Logar com o Facebook")}
                                </button> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ position: 'absolute', bottom: 20, left: 40 }}>
                <span onClick={() => setCardIdioma(true)} className="h4 text-white shadow-sm align-middle btn btn-sm py-0 px-1 float-left font-weight-bold position-static">
                    <strong>{idioma}</strong>
                </span>
                <CardSelectLang className="shadow" lang={idioma} onChange={handleSelectLang} position={{ position: 'relative', top: 25, right: 4, width: 300 }} onCloseClick={() => setCardIdioma(false)} visible={cardIdioma} />
            </div>
            <br></br>
            <div style={{ position: 'absolute', bottom: 20, right: 20, backgroundColor: "rgba(0, 0, 0, 0.3)" }} className="border shadow-sm rounded mr-3 p-2 text-light">
                <i className="mr-3 text-light">{t("Powered by")}</i>
                <img src={zscanLogo} alt="Zscan Software" width="96" />
            </div>
        </div>
    );
};

export default LoginPage;