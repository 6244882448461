import { ViewSelectTypes } from "./types/ActionsTypes"
import { ExamInterface } from "../../interfaces/examInterface"
import { PatientInterface } from "../../interfaces/patientInterface"
import { PeopleInterface } from "../../interfaces/peopleInterface"
import { ProfissionalSaudeInterface } from "../../interfaces/new/profissionalSaudeInterface"
import { PacienteInterface } from "../../interfaces/new/pacienteInterface"

export const setSelectedExam = (selectedExam: ExamInterface & { idModal: string }, dispatch: Function) => {
    dispatch({
        type: ViewSelectTypes.SELECTED_EXAM,
        payload: selectedExam
    })
}

export const setSelectedPatient = (selectedPatient: PacienteInterface & { idModal: string }, dispatch: Function) => {
    dispatch({
        type: ViewSelectTypes.SELECTED_PATIENT,
        payload: selectedPatient
    })
}

export const setSelectedPhysician = (selectedPhysician: ProfissionalSaudeInterface & { idModal: string }, dispatch: Function) => {
    dispatch({
        type: ViewSelectTypes.SELECTED_PHYSICIAN,
        payload: selectedPhysician
    })
}

export const setSelectedReferringPhysician = (selectedReferringPhysician: ProfissionalSaudeInterface & { idModal: string }, dispatch: Function) => {
    dispatch({
        type: ViewSelectTypes.SELECTED_REFERRING_PHYSICIAN,
        payload: selectedReferringPhysician
    })
}