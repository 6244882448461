import React from 'react';
import PropTypes from 'prop-types';

import { PDF } from '../elements/pdf';

interface propsInterface {
    urlReport: Buffer | string,
    printPDF?: boolean,
    idModal: string,
    titleModalElement: string
}

const PdfModalView = ({ urlReport, printPDF, idModal, titleModalElement }: propsInterface) => {
    const idTitleLabelModal = `pad_modal_view_${Math.random()}`;
    return (
        <div className="modal  p-0 h-100" id={idModal} tabIndex={-1} role="dialog" aria-labelledby={idTitleLabelModal} aria-hidden="true">
            <div className="modal-dialog w-100 mw-100 m-0 h-100" role="document">
                <div className="modal-content h-100">
                    <div className="modal-header p-2 bg-dark text-white border-0 text-uppercase">
                        <h5 className="modal-title" style={{marginLeft: "40%", marginTop: 5}} id={idTitleLabelModal}>{titleModalElement}</h5>
                        <button type="button" className="close mr-4 mt-1" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body w-100 h-100 bg-dark p-2" style={{ overflowX: "auto", overflowY: "hidden" }}>
                        {(!!urlReport) ?
                            <PDF src={urlReport} printPDF={printPDF} /> :
                            <p></p>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};


PdfModalView.propTypes = {
    urlReport: PropTypes.any,
    printPDF: PropTypes.bool,
    setPrintPDF: PropTypes.func,
    idModal: PropTypes.string,
    titleModalElement: PropTypes.string,
};

export default PdfModalView;
