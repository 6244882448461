"use strict"
import React, { useState, useEffect, createRef, CSSProperties } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../store";
import { setStopListening } from "../../store/actions/stopListening";

const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
const recognition = !!SpeechRecognition ? new SpeechRecognition() : null as any;


const SpeechRecognitionButton = (props: { setReconizedText: Function, classButton?: string, styleButton?: CSSProperties, classDivParentButton?: string, styleDivParentButton?: CSSProperties }) => {
	const {
		classButton,
		styleButton,
		setReconizedText,
		classDivParentButton,
		styleDivParentButton,
	} = props;

	const stopListeningState = useSelector((state: ApplicationState) => state.stopListening.stopListening);
	const dispatch = useDispatch();
	const buttonSearchRef = createRef<HTMLButtonElement>();
	const [listening, setListening] = useState(false);

	useEffect(() => {
		if (!!stopListeningState){
			setListening(false);
		}
	}, [stopListeningState])

	useEffect(() => {
		if (!!recognition){
			handleListen();
		}
	}, [listening]);


	const handleListen = () => {
		const buttonSearch: HTMLButtonElement = buttonSearchRef.current as HTMLButtonElement;

		if (listening) {
			recognition.continuous = true;
			recognition.lang = "pt-BR";

			recognition.onstart = () => {
				console.log("SpeechRecognition: Captura do áudio iniciada");

				buttonSearch.classList.add("btn-danger");
				buttonSearch.classList.remove("btn-info");
			}

			recognition.onend = () => {
				console.log("SpeechRecognition: Captura do áudio finalizada");
			};

			recognition.onerror = (error: Event) => {
				console.error("SpeechRecognition: ", error);

				setListening(false);
			}

			recognition.onresult = (event: any) => {
				let finalTranscriptText = "";

				for (let i = event.resultIndex; i < event.results.length; i++) {
					const transcript = event.results[i][0].transcript;
					if (event.results[i].isFinal) finalTranscriptText += transcript + " ";
				}

				if (!!setReconizedText) {
					setReconizedText(finalTranscriptText);
				}

				console.log("SpeechRecognition: Texto obtido -> ", finalTranscriptText);

				setListening(false);
				
			};

			recognition.start();

		} else {
			if (!listening) {
				recognition.stop();

				buttonSearch.classList.add("btn-info");
				buttonSearch.classList.remove("btn-danger");
			}
		}
	}

	const toggleListen = () => {
		setListening(!listening);
		setStopListening(listening, dispatch);
	};

	return (
		!!recognition ?
			<div className={`input-group-append ${classDivParentButton || ""}`} style={styleDivParentButton || {}}>
				<button type="button" ref={buttonSearchRef} className={`btn ml-2 rounded rounded-pill ${classButton || ""}`} style={{width:27, height:27, padding: 4}} onClick={toggleListen} >
					<i className="bi bi-mic"></i>
				</button>
			</div>
			: null
	)
}

export { SpeechRecognitionButton };