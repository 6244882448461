import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { FirebaseService } from '../services/firebaseService';
import { firebaseStorage } from '../../config/firebase-config';

import { ReportInterface } from '../../interfaces/reportInterface';
import { ExamInterface } from '../../interfaces/examInterface';
import { PatientInterface } from "../../interfaces/patientInterface";

import PdfModalView from '../elements/pdf-modal-view';
import Modal from '../elements/modal-element';

import Swal from 'sweetalert2';

import { useTranslation } from 'react-i18next';
import { showLoading, hideLoading } from '../../store/actions/loading';

const PatientLaudos = (props: { uid: string, serialPath: string }) => {
    const uid = props.uid;
    const serialPath = props.serialPath;

    const { t } = useTranslation();

    const [reports, setReports] = useState([] as Array<ReportInterface>);
    const [ready, setReady] = useState(false);
    const [urlReport, setUrlReport] = useState('');
    const [printPDF, setPrintPDF] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        printPDF ? showLoading(dispatch) : hideLoading(dispatch);
    }, [printPDF]);

    useEffect(() => {
        FirebaseService
            .getReportsByPatient(serialPath, uid)
            .then(async (reportsSnapshots: any) => {
                if (!reportsSnapshots) {
                    return [];
                }
                const storageRef = firebaseStorage.ref();
                const reportsArr: Array<ReportInterface> = [];
                for (let index = 0; index < reportsSnapshots.docs.length; index++) {
                    const snapshot = reportsSnapshots.docs[index];
                    const data = snapshot.data();

                    const localFile = (!!data.storage) ? data.storage.filepath : data.pdfPath;
                    if (!!localFile) {
                        const reportRef = storageRef.child(localFile);
                        data.urlReport = await reportRef.getDownloadURL();
                    } else {
                        data.urlReport = null;
                    }

                    if (!!data.examRef && !data.exam) {
                        data.exam = (await data.examRef.get()).data() as ExamInterface;
                    }

                    if (!!data.patientRef && !data.patient) {
                        data.patient = (await data.patientRef.get()).data() as PatientInterface
                    }

                    delete data.examRef;
                    delete data.patientRef;

                    reportsArr.push(data);
                }
                return reportsArr;
            })
            .then((data: any) => {
                setReports(data as Array<ReportInterface>);
                setReady(true);
            })
            .catch((error: Error) => {
                console.error(error);
                setReports([])
                setReady(true);
            })
    }, []);

    const openReportHandler = (URLReport: string, printPDF: boolean = false) => {
        setPrintPDF(printPDF);
        setUrlReport(URLReport);
    }

    const copyReportLink = (report: any) => {
        const el = document.createElement('textarea');
        el.value = report.urlReport;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        Swal.fire(
            `Link laudo`,
            `O link do laudo foi copiado com sucesso!`
        );
    }

    const idModal = "previewReportPDFModalId";
    const titleModalElement = "Visualizador de PDF";
    const idTitleLabelModal = "titleLabelPreviewReportPDFModalId";
    const idButtonCloseModal = "buttonClosePreviewReportPDFModalId";

    return (
        <>
            {
                (!ready) ?
                    <div className="tab-pane" role="tabpanel" aria-labelledby="nav-laudos-tab" id="nav-laudos">
                        <p>{t("Carregando")}...</p>
                    </div> :
                    <div className="tab-pane" role="tabpanel" aria-labelledby="nav-laudos-tab" id="nav-laudos">
                        <PdfModalView urlReport={urlReport} printPDF={printPDF} setPrintPDF={setPrintPDF} idModal={idModal} titleModalElement={titleModalElement} />
                        {
                            (!!reports.length) ?
                                <table className="table table-striped table-hover table-sm">
                                    <tbody>
                                        {
                                            reports.map((report: any, index: number) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="laudos align-middle w-100 p-2">
                                                            <strong>{report.exam.name}</strong>
                                                            <p>{`Médico solicitante: ${report.requester ? `${report.requester.firstName} ${report.requester.lastName}` : `${report.doctor.firstName} ${report.doctor.lastName}`}`}</p>
                                                            <p>{`Médico: ${report.doctor.firstName} ${report.doctor.lastName}`}</p>
                                                            <strong>{`${report.date.toDate().toLocaleDateString()} - ${report.date.toDate().toLocaleTimeString()}`}</strong>
                                                        </td>
                                                        <td style={{ textAlign: 'right' }} className="align-middle w-25">
                                                            <button className="btn btn-light border border-secondary mr-2" data-target={"#" + idModal} data-toggle="modal" onClick={(event) => { openReportHandler(report.urlReport) }}><i className="fa fa-eye"></i></button>
                                                            <button className="btn btn-light border border-secondary mr-2" onClick={(event) => { openReportHandler(report.urlReport, true); }}><i className="fa fa-print"></i></button>
                                                            <button className="btn btn-light border border-secondary mr-2" onClick={(event) => { copyReportLink(report); }}><i className="fas fa-link"></i></button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table> :
                                <div>
                                    <p className="h4 text-muted text-center py-5">{t("Nenhum laudo encontrado!")}</p>
                                </div>
                        }
                    </div >
            }
        </>
    );
}

export { PatientLaudos };
